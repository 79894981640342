import * as React from 'react';
import * as dateFns from 'date-fns';
import log from 'loglevel';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';

import { useDataStore } from '../../comp/DataStore';
import { ProposalStatus } from '../../comp/DataStore/Proposals';
import { SalesKPI } from '../../comp/DataStore/SalesKPI';
import settings from '../../settings';
import { UserRoles } from '../../settings';
import { Period, GeneralFilters } from '../../types/dashboard';

function usePeriod(now: Date) {
  const weekStartsOn = 1; // Monday

  const periods = React.useMemo<Period[]>(
    () => [
      {
        id: 'this-week',
        label: 'This week',
        groupBy: 'day',
        dateFrom: dateFns.startOfWeek(now, { weekStartsOn }),
        dateTo: dateFns.endOfDay(now),
      },
      {
        id: 'this-month',
        label: 'This month',
        groupBy: 'day',
        dateFrom: dateFns.startOfMonth(now),
        dateTo: dateFns.endOfDay(now),
      },
      {
        id: 'last-three-months',
        label: 'Last 3 months',
        groupBy: 'month',
        dateFrom: dateFns.startOfMonth(dateFns.addMonths(now, -3)),
        dateTo: dateFns.endOfMonth(dateFns.addMonths(now, -1)),
      },
      {
        id: 'seven-days',
        label: 'Last 7 days',
        groupBy: 'day',
        dateFrom: dateFns.startOfDay(dateFns.addDays(now, -7)),
        dateTo: dateFns.endOfDay(now),
      },
    ],
    [now]
  );
  return periods;
}

type DashboardFiltersProps = {
  onChange?: (f: GeneralFilters) => void;
};

function DashboardFilters(props: DashboardFiltersProps) {
  const datastore = useDataStore();
  const initAuthState = datastore.auth.getState();

  const { onChange } = props;
  const periods = usePeriod(new Date());
  const defaultPeriod = periods.find((x) => x.id === 'this-week');

  const [authState, setAuthState] = React.useState(initAuthState);
  const [loading] = React.useState(false);
  const [filterState, setFilter] = React.useState<GeneralFilters>({
    proposalStatus: ProposalStatus.ACTIVE,
    assignedTo: 'me',
    period: defaultPeriod,
    salesKpi: SalesKPI.SOLD
  });

  const onChangeProposalStatus = (e: any) => {
    setFilter((c) => ({ ...c, proposalStatus: e.target.value }));
  };

  const onChangeAssignedTo = (e: any) => {
    setFilter((c) => ({ ...c, assignedTo: e.target.value }));
  };

  const onChangeSalesKpi = (e: any) => {
    setFilter((c) => ({ ...c, salesKpi: e.target.value }));
  };

  const onChangePeriod = (e: any) => {
    const period = periods.find((x) => x.id === e.target.value);
    
    if (!period) {
      log.error(`Invalid period: ${e.target.value}`);
      return;
    }
    log.debug('set period', period);
    setFilter((c) => ({ ...c, period }));
  };

  React.useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(filterState);
    }
  }, [onChange, filterState]);

  React.useEffect(() => {
    const authId = datastore.auth.subscribe(setAuthState);
    return () => {
      datastore.auth.unsubscribe(authId);
    }
  }, [datastore]);

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="flex-start">
        <FormControl size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="period-filter-label">Period</InputLabel>
          <Select
            key={`period`}
            labelId="period-filter-label"
            id="period-filter"
            name="period-filter"
            value={filterState.period?.id || 'this-week'}
            label="Period"
            onChange={onChangePeriod}
          >
            {periods.map((p) => (
              <MenuItem key={p.id} value={p.id}>
                {p.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="status-filter-label">Status</InputLabel>
          <Select
            key={`status`}
            labelId="status-filter-label"
            id="status-filter"
            name="status-filter"
            value={filterState.proposalStatus || '-'}
            label="Status"
            onChange={onChangeProposalStatus}
          >
            <MenuItem value="-">
              <i>None Selected</i>
            </MenuItem>
            {settings.proposalStatus.map((s) => (
              <MenuItem key={s.value} value={s.value}>
                {s.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="status-filter-label">Sales Lead</InputLabel>
          <Select
            key={`assigned-to`}
            labelId="assigned-to-filter-label"
            id="assigned-to-filter"
            name="assigned-to-filter"
            value={filterState.assignedTo || 'me'}
            label="Assigned to"
            onChange={onChangeAssignedTo}
          >
            <MenuItem value="me">Me</MenuItem>
            {authState.record?.role === UserRoles.ADMIN ? (
              <MenuItem value="anyone">Anyone</MenuItem>
            ) : (
              undefined
            )}
          </Select>
        </FormControl>
        <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
        <FormControl size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="status-filter-label">Sales KPI</InputLabel>
          <Select
            key={`sales-kpi`}
            labelId="sales-kpi-filter-label"
            id="sales-kpi-filter"
            name="sales-kpi-filter"
            value={filterState.salesKpi || '-'}
            label="Sales KPI"
            onChange={onChangeSalesKpi}
          >
            {settings.salesKpi.map((s) => (
              <MenuItem key={s.value} value={s.value}>
                {s.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>

      </Stack>
    </>
  );
}

export default DashboardFilters;
export type { GeneralFilters };
