import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

type PaperTileProps = {
  children?: React.ReactNode;
}
function PaperTile(props: PaperTileProps) {
  const { children } = props;

  return (
    <Paper elevation={6}>
      <Box
        sx={{
          padding: '20px',
          borderRadius: 2,
        }}
      >{children}</Box>
    </Paper>
  );
}

export default PaperTile;
