import React from 'react';
// import log from 'loglevel';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

import Table from '../../comp/Table';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import settings from '../../settings';
import { ActionType } from '../../types/datastore.d';

function getHeaders(props: any) {
  const createLink = (to: string) => (e: any) => {
    e.preventDefault();
    props.navigate(to);
  };

  const headers = [
    {
      Header: 'Name',
      accessor: (row: any) => {
        let fullName = row.first_name;
        if (row.last_name) {
          fullName += ` ${row.last_name}`;
        }
        return fullName;
      },
      Cell: (props: any) => {
        const to = `/users/${props.row.original.id}`;
        if (String(props.row.original.id).startsWith('tmp-id-')) {
          return props.value;
        }
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value)}
          </div>
        );
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (props: any) => {
        const to = `/users/${props.row.original.id}`;
        if (String(props.row.original.id).startsWith('tmp-id-')) {
          return props.value;
        }
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value)}
          </div>
        );
      },
    },
    {
      Header: 'Role',
      accessor: 'role',
      Cell: (props: any) => {
        const to = `/users/${props.row.original.id}`;
        if (String(props.row.original.id).startsWith('tmp-id-')) {
          return props.value;
        }
        const role = settings.roles.find((x) => x.value === props.value);
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(role?.label)}
          </div>
        );
      },
    },
    {
      accessor: 'search_column',
    },
  ];
  return headers;
}

let runOnce: boolean = false;

function ListUsers() {
  const datastore = useDataStore();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState<string>('');

  const initUsers = datastore.users.getState();
  const [usersState, setUsersState] = React.useState<any>(initUsers);

  const headers = getHeaders({ navigate });
  const columns = React.useMemo(() => headers, [headers]);
  const data = React.useMemo(() => {
    const records = usersState.records;
    return records.sort((a: any, b: any) => {
      if (a.first_name === b.first_name) {
        return String(a.last_name).localeCompare(String(b.last_name));
      } else if (a.last_name === b.last_name) {
        return String(a.email).localeCompare(String(b.email));
      }
      return String(a.first_name).localeCompare(String(b.first_name));
    });
  }, [usersState]);

  const onChangeSearch = () => {
    let timeout: any;
    return (e: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    };
  };

  const memoSearch = React.useMemo(() => search, [search]);

  React.useEffect(() => {
    if (!search) return;
    datastore.users
      .refresh({
        custom: (q: any) => {
          q.textSearch('search_column', search);
        },
        type: ActionType.UPSERT,
      })
      .then((result: any[] | undefined) => {
        // do something with result
      });
  }, [datastore.users, search, memoSearch]);

  React.useEffect(() => {
    const t = setInterval(() => {
      const hasTmp = (usersState.records || []).find((x: any) =>
        String(x.id).startsWith('tmp-id-')
      );
      if (hasTmp) {
        datastore.users.refresh();
      }
    }, 5000);
    return () => {
      clearInterval(t);
    };
  }, [usersState, datastore.users]);

  React.useEffect(() => {
    if (runOnce) return;
    runOnce = true;
    datastore.users.refresh();
  }, [datastore.users]);

  React.useEffect(() => {
    const usersId = datastore.users.subscribe(setUsersState);
    return () => {
      datastore.users.unsubscribe(usersId);
    };
  }, [datastore]);

  return (
    <Paperbase title="Users" bottomSpacer="100px">
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => navigate('/users/new')}>
          New User
        </Button>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search..."
          onChange={onChangeSearch()}
        />
      </Stack>

      <div style={{ minHeight: 400, width: '100%' }}>
        <Table
          columns={columns}
          data={data}
          globalFilterValue={memoSearch}
          initialState={{
            hiddenColumns: ['search_column'],
            globalFilter: memoSearch,
          }}
        />
      </div>
    </Paperbase>
  );
}

export default ListUsers;
