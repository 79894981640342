import React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';

export default function SignIn() {
  const { auth } = useDataStore();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const result = await auth.login({
      email: data.get('email'),
      password: data.get('password'),
    });
    if (result.error && result.error.message) {
      setError(result.error.message);
    } else if (!result.error && result.user) {
      navigate('/');
    }
    setLoading(false);
  };

  const handleFocus = () => {
    setError('');
  };

  return (
    <>
      <Paperbase>
        <Box className="overlay"></Box>
        <Container component="main" maxWidth="xs" className="signin">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Grid container textAlign="center">
              <Grid item xs={12}>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onFocus={handleFocus}
                        onKeyDown={handleFocus}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onFocus={handleFocus}
                        onKeyDown={handleFocus}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* <Grid item xs={12}>
              ---&nbsp;or&nbsp;---
            </Grid>
            <Grid item xs={12}>
              <ButtonBase
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                onClick={microsoftSignin}
              >
                <img
                  src={`${PUBLIC_URL}/img/ms-symbollockup_signin_light_short.svg`}
                  alt="Microsoft Signin"
                />
              </ButtonBase>
            </Grid> */}
              {/* </Grid> */}
              <Grid item xs={12}>
                <Snackbar
                  open={Boolean(error)}
                  autoHideDuration={4000}
                  onClose={handleFocus}
                  message={error}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <Alert
                    onClose={handleFocus}
                    variant="filled"
                    severity="error"
                  >
                    {error}
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paperbase>
    </>
  );
}
