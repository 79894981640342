import * as React from 'react';
import log from 'loglevel';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import * as dateFns from 'date-fns';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import { ProposalStatus, ProposalType } from '../../comp/DataStore/Proposals';
import { getImageUrl } from '../../comp/GoogleMap/GmapImage';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import ProspectsAutocomplete from '../../comp/Autocomplete/Prospects';
import { recomputeService } from './SelectServices';
import settings from '../../settings';
import ErrorHandler from '../../comp/ErrorHandler';

type Props = {
  newRecord?: boolean;
};

function EditProposal(props: Props) {
  const datastore = useDataStore();
  const initAuth = datastore.auth.getState();

  const { newRecord = false } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const proposalsState = datastore.proposals.getState();
  const existing = proposalsState.records.find((p: any) => p.id === id);
  const [proposal, setProposal] = React.useState<any>(existing || {});
  const [loading, setLoading] = React.useState(false);
  const [prospect, setProspect] = React.useState<any>({
    id: proposal.prospect_id,
  });
  const [map, setMap] = React.useState<any>({
    area: null,
    ...(proposal.map || {}),
  });
  const areaRef = React.useRef<any>();
  const [recentProposals, setRecentProposals] = React.useState<any[]>([]);
  const [overrideArea, setOverrideArea] = React.useState(
    proposal.map?.area !== undefined && proposal.map?.area !== null
  );
  const [error, setError] = React.useState<any>();
  const [proposalTemplate, setProposalTemplate] = React.useState<ProposalType>(
    proposal.proposal_type ||
      initAuth.record?.def_proposal_template ||
      ProposalType.LAWN_AND_IRRIGATION
  );
  // if proposal template field, manually changed
  const [changedPt, setChangedPt] = React.useState(false);

  const errorObj = React.useMemo(() => {
    log.debug('updating error Object');
    const value: any = {};
    if (!error) return value;
    if (error.name === 'ValidationError') {
      (error.inner || []).forEach((innerErr: any) => {
        value[innerErr.path] = innerErr;
      });
    }
    return value;
  }, [error]);

  // Computed Area
  const computedArea = React.useMemo<number>(() => {
    return (map.overlays || []).reduce(
      (prev: number, curr: any) => prev + curr.area_sqft,
      0
    );
  }, [map]);

  const handleSubmit = React.useCallback(async () => {
    setLoading(true);

    const totalArea =
      overrideArea && areaRef.current.value
        ? Number(areaRef.current.value)
        : computedArea;

    const serviceRecords = await Promise.all(
      (proposal.services?.records || []).map((service: any) => {
        return recomputeService({
          datastore,
          service,
          percentIncrease: proposal.services?.percentIncrease,
          difficulty: proposal.services?.difficulty,
          area: totalArea,
        });
      })
    );

    // payload
    const payload: any = {
      id: newRecord ? uuidv4() : id,
      prospect_id: prospect.id,
      assigned_to: newRecord ? undefined : proposal.assigned_to,
      map: {
        overlays: [],
        ...map,
        area: overrideArea ? totalArea : null,
      },
      status: newRecord ? ProposalStatus.ACTIVE : proposal.status,
      services: newRecord
        ? {
            records: [],
          }
        : {
            ...(proposal.services || {}),
            records: serviceRecords,
          },
      file: null,
      notes: { records: [] },
      proposal_type: proposalTemplate,
    };

    setMap(payload.map);

    const promise = newRecord
      ? datastore.proposals.create(payload)
      : datastore.proposals.update(payload);
    const result = await promise;
    if (result?.$error) {
      setError(result.$error);
      setLoading(false);
      throw result.$error;
    }

    if (!newRecord) {
      const children = await datastore.proposals.getChildren({
        id: payload.id,
      });

      await Promise.all(
        (children || []).map(async (child: any) => {
          const sr = await Promise.all(
            (child.services?.records || []).map((service: any) => {
              return recomputeService({
                datastore,
                service,
                percentIncrease: child.services?.percentIncrease,
                difficulty: child.services?.difficulty,
                area: totalArea,
              });
            })
          );

          await datastore.proposals.update({
            ...child,
            services: { ...child.services, records: sr },
          });
        })
      ).catch(setError);
    }

    setLoading(false);
    return payload;
  }, [
    datastore,
    id,
    map,
    newRecord,
    proposal,
    prospect,
    overrideArea,
    computedArea,
    proposalTemplate,
  ]);

  const onSelectProspect = (e: any, value: any, reason: any) => {
    if (reason === 'selectOption') {
      if (value.id !== prospect.id) {
        setMap(value.map);
      }
      setProspect(value);
      setError(null);
    }
  };

  // Update user settings
  const handleSetDefaultTemplate = async (tmpl: ProposalType) => {
    const userPayload = {
      email: initAuth.record.email,
      def_proposal_template: tmpl,
    };
    datastore.users
      .updateAppSettings(userPayload, {
        pick: ['email', 'def_proposal_template'],
      })
      .then(log.debug)
      .catch(log.error);
  };

  // Get prospect (if specified)
  const initProspectId: string = searchParams.get('prospect_id') || '';
  if (initProspectId) {
    if (!loading && prospect.id !== initProspectId) {
      datastore.prospects
        .get({ id: initProspectId })
        .then((initProspect: any) => {
          onSelectProspect(null, initProspect, 'selectOption');
        });
    }
  }

  const onEditMap = async () => {
    const payload = await handleSubmit();
    navigate(`/proposals/${payload.id}/edit-map`);
  };

  const onClickUpdateProposal = async () => {
    const payload = await handleSubmit();
    navigate(`/proposals/${payload.id}`);
  };

  const onClickCancel = async () => {
    if (newRecord) {
      navigate(`/proposals`);
      return;
    }
    navigate(`/proposals/${id}`);
  };

  const toggleOverrideArea = (e: any) => {
    setOverrideArea(e.target.checked);
    if (!e.target.checked) {
      areaRef.current.value = map.area || Number(computedArea).toFixed(2);
    }
  };

  const gmapImage = React.useMemo(() => {
    return getImageUrl(map);
  }, [map]);

  const listRecentProposals = React.useMemo(
    () =>
      recentProposals
        .filter((p: any) => !p.deleted_at)
        .filter((p: any) => !p.parent_id)
        .sort((a: any, b: any) => {
          const aCreatedAt = dateFns.parseISO(a.created_at);
          const bCreatedAt = dateFns.parseISO(b.created_at);
          const aYear = a.year || dateFns.format(aCreatedAt, 'yyyy');
          const bYear = b.year || dateFns.format(bCreatedAt, 'yyyy');
          if (aYear !== bYear) {
            return String(bYear).localeCompare(String(aYear));
          } else {
            return String(b.updated_at).localeCompare(a.updated_at);
          }
        })
        .map((p: any) => {
          const createdAt = dateFns.parseISO(p.created_at);
          const updatedAt = dateFns.parseISO(p.updated_at);
          const year = p.year || dateFns.format(createdAt, 'yyyy');
          const statusCode = p.parent_status || p.status;
          const status = settings.proposalStatus.find(
            (x) => x.value === statusCode
          );
          return (
            <ListItemButton
              key={p.id}
              sx={{ bgcolor: '#EBEDEF', mb: '5px' }}
              onClick={() => {
                navigate(`/proposals/${p.id}`);
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <ListItemText primary={`${year} - ${status?.label}`} />
                </Grid>
                <Grid item xs={6} textAlign='right'>
                  <ListItemText
                    primary={dateFns.format(updatedAt, 'M/d/yyyy')}
                  />
                </Grid>
              </Grid>
            </ListItemButton>
          );
        }),
    [recentProposals, navigate]
  );

  React.useEffect(() => {
    if (!prospect || !prospect.id) return;
    datastore.proposals
      .refresh({
        custom: (query: any) => {
          query.eq('prospect_id', prospect.id);
          if (!newRecord) {
            query.neq('id', id);
          }
          query.is('deleted_at', null);
          query.order('created_at', { ascending: false });
          query.limit(5);
        },
      })
      .then((result: any[] | undefined) => {
        setRecentProposals(
          (result || [])
            .filter((p) => !p.parent_id)
            .filter((p) => !p.deleted_at)
        );
      });
  }, [id, newRecord, datastore.proposals, prospect]);

  // Load Proposal
  React.useEffect(() => {
    if (newRecord) return;
    if (loading) return;
    if (id === proposal.id) return;
    setLoading(true);
    datastore.proposals
      .get({ id: String(id || '') })
      .then((p) => {
        setProposal(p || { id });
        if (!p) {
          setError(new Error(`Unable to load proposal id: ${id}`));
          return;
        }
        log.debug('========= loaded proposal ==', p);
        setMap(p.map);
        setProspect({ id: p.prospect_id });
        setOverrideArea(p.map?.area !== undefined && p.map?.area !== null);
        setProposalTemplate(p.proposal_type);
      })
      .finally(() => setLoading(false));
  }, [newRecord, id, proposal, datastore, loading]);

  // Default Proposal Template
  React.useEffect(() => {
    if (!newRecord) return;
    if (!initAuth.record?.email) return;
    if (changedPt) return;
    setChangedPt(true);
    datastore.users
      .get({ email: initAuth.record.email, force: true })
      .then((r: any) => {
        if (r.def_proposal_template) {
          setProposalTemplate(r.def_proposal_template);
        }
      });
  }, [datastore, changedPt, initAuth, newRecord]);

  return (
    <Paperbase
      title={newRecord ? 'New Proposal' : 'Edit Proposal'}
      bottomSpacer='100px'
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12}>
              <ProspectsAutocomplete
                noOptionsText={
                  <Link to={`/prospects/new?back-to=${location.pathname}`}>
                    No prospects found. Tap here to create a prospect.
                  </Link>
                }
                renderInput={(p: any) => {
                  const { isLoading, ...textFieldParams } = p;
                  return (
                    <TextField
                      {...textFieldParams}
                      key={`prospect_id-${proposal.id}`}
                      required
                      fullWidth
                      id='prospect_id'
                      label='Prospect'
                      name='prospect_id'
                      InputProps={{
                        ...textFieldParams.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {textFieldParams.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={Boolean(errorObj.prospect_id)}
                      helperText={
                        errorObj.prospect_id ? `This is required` : ''
                      }
                    />
                  );
                }}
                disabled={loading}
                value={prospect}
                onChange={onSelectProspect}
                onInputChange={onSelectProspect}
                // optional: TODO
                options={[]}
                loading={false}
                getOptionLabel={() => ''}
                isOptionEqualToValue={() => true}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                key={`area-${proposal.id}`}
                fullWidth
                id='area'
                label='Area (in SQFT)'
                name='area'
                type='number'
                inputRef={areaRef}
                disabled={loading || !overrideArea}
                placeholder={Number(computedArea).toFixed(2)}
                InputLabelProps={{ shrink: true }}
                defaultValue={map.area}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={`override-area-${proposal.id}`}
                      id='override_area'
                      name='override_area'
                      checked={overrideArea}
                      disabled={loading}
                      onChange={toggleOverrideArea}
                    />
                  }
                  label='Override area'
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <ButtonBase onClick={onEditMap} sx={{ position: 'relative' }}>
                <Box>
                  <img src={gmapImage.url} alt='gmap' width='100%' />
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#17202A99',
                    position: 'absolute',
                    color: 'white',
                    top: 10,
                    left: '50%',
                    p: '30px',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <Typography>Tap to Edit Map</Typography>
                </Box>
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {recentProposals.length === 0 ? (
            <>No Proposals Found</>
          ) : (
            <>
              Previous Proposals
              {listRecentProposals}
            </>
          )}
        </Grid>
      </Grid>
      <ErrorHandler error={error} />
      <ControlBar>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <Stack direction='row' spacing={3} justifyContent='left'>
              <FormControl size='small' style={{ width: '200px' }}>
                <InputLabel id='proposal-template-label'>
                  Proposal Template
                </InputLabel>
                <Select
                  labelId='proposal-template-label'
                  value={proposalTemplate}
                  label='Proposal Template'
                  onChange={(e) => {
                    console.log(e.target.value);
                    const value = e.target.value as ProposalType;
                    if (value !== proposalTemplate) {
                      setChangedPt(true);
                    }
                    handleSetDefaultTemplate(value);
                    setProposalTemplate(value);
                  }}
                >
                  {settings.proposalType.map((ptype) => {
                    return (
                      <MenuItem key={ptype.value} value={ptype.value}>
                        {ptype.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction='row' spacing={3} justifyContent='right'>
              <Button
                variant='outlined'
                onClick={onClickCancel}
                disabled={loading}
              >
                Cancel
              </Button>

              <Button
                variant='contained'
                onClick={onClickUpdateProposal}
                disabled={loading}
              >
                Update Proposal
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </ControlBar>
    </Paperbase>
  );
}

export default EditProposal;
