import React from 'react';
// import log from 'loglevel';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

import Table from '../../comp/Table';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import ImageSet, { ImageSize } from '../../comp/ImageSet';
import { ActionType } from '../../types/datastore.d';

function getHeaders(props: any) {
  const createLink = (to: string) => (e: any) => {
    e.preventDefault();
    props.navigate(to);
  };
  const root = 'programs';

  const headers = [
    {
      accessor: 'image_url',
      // minWidth: '300px',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <Box
            className='clickable'
            onClick={createLink(to)}
            sx={{ minWidth: '100px', maxWidth: '150px' }}
          >
            <ImageSet src={props.value} alt='service' size={ImageSize.xs} />
          </Box>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <Box
            className='clickable'
            onClick={createLink(to)}
            sx={{ minWidth: '200px' }}
          >
            {String(props.value)}
          </Box>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <Box
            className='clickable'
            onClick={createLink(to)}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {String(props.value)}
          </Box>
        );
      },
    },
    {
      accessor: 'search_column',
    },
  ];
  return headers;
}

let runOnce = false;
function ListPrograms() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>('');

  const initPrograms = datastore.programs.getState();
  const [programsState, setProgramsState] = React.useState<any>(initPrograms);

  React.useEffect(() => {
    if (runOnce) return;
    runOnce = true;
    datastore.programs.refresh();
  }, [datastore]);

  const headers = getHeaders({ navigate });
  const columns = React.useMemo(() => headers, [headers]);

  const data = React.useMemo(
    () => programsState.records,
    [programsState.records]
  );

  const onChangeSearch = () => {
    let timeout: any;
    return (e: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    };
  };

  const memoSearch = React.useMemo(() => search, [search]);

  React.useEffect(() => {
    if (!search) return;
    datastore.programs.refresh({
      custom: (q: any) => {
        q.textSearch('search_column', search);
      },
      type: ActionType.UPSERT,
    });
  }, [datastore.programs, search, memoSearch]);

  React.useEffect(() => {
    const programsId = datastore.programs.subscribe(setProgramsState);
    return () => {
      datastore.programs.unsubscribe(programsId);
    };
  }, [datastore]);

  return (
    <Paperbase title='Programs' bottomSpacer='100px'>
      <Stack direction='row' spacing={2}>
        <Button variant='contained' onClick={() => navigate('/programs/new')}>
          New Program
        </Button>
        <Input
          id='input-with-icon-adornment'
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          }
          placeholder='Search...'
          onChange={onChangeSearch()}
        />
      </Stack>
      <div style={{ minHeight: 400, width: '100%' }}>
        <Table
          columns={columns}
          data={data}
          globalFilterValue={memoSearch}
          initialState={{
            hiddenColumns: ['search_column'],
            globalFilter: memoSearch,
          }}
        />
      </div>
    </Paperbase>
  );
}

export default ListPrograms;
