import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
// import * as api from '../../api/irrigationApi';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  UpsertMode,
  ActionType,
  RecordStatus,
} from '../../types/datastore.d';
import { RealtimeChannel } from '@supabase/realtime-js';

const dataset = 'messages';
const dbTable = 'messages';
// const vwDataset = 'vw_messages';

enum MessageType {
  NOTIFICATION = 'NOTIFICATION',
  CALL = 'CALL',
  COMMENT = 'COMMENT',
  ATTACHMENTS = 'ATTACHMENTS',
}

function MessageSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      created_at: yup.string().required(),
      created_by: yup.string().optional().email(),
      updated_at: yup.string().required().default(new Date().toISOString()),
      message_type: yup.string().required().oneOf(Object.values(MessageType)),
      proposal_id: yup.string().optional().uuid(),
      prospect_id: yup.string().optional().uuid(),
      assigned_to: yup.string().optional().email(),
      data: yup
        .object()
        .required()
        .noUnknown()
        .when('message_type', (messageType, schema) => {
          if (messageType === MessageType.NOTIFICATION) {
            return schema.shape({
              title: yup.string().required(),
              message: yup.string().required(),
              url: yup.string().optional(),
              topic: yup.string().optional(),
              module: yup.string().optional(),
              read: yup.boolean().default(false),
            });
          } else if (messageType === MessageType.COMMENT) {
            return schema.shape({
              message: yup.string().required(),
            });
          } else if (messageType === MessageType.CALL) {
            return schema.shape({
              message: yup.string().required(),
            });
          } else if (messageType === MessageType.ATTACHMENTS) {
            return schema.shape({
              message: yup.string().required(),
              attachments: yup.array().min(1).of(
                yup.object().noUnknown().shape({
                  filename: yup.string().optional(),
                  url: yup.string().required(),
                })
              ),
            });
          }

          return schema.shape({});
        }),
    });
}

type MessageModel = {
  id: string;
  created_at: string;
  created_by?: string;
  updated_at: string;
  message_type: MessageType;
  proposal_id: string;
  prospect_id: string;
  assigned_to: string;
  data: {
    title?: string;
    message: string;
    url?: string;
    topic?: string;
    module?: string;
    read?: string;
    attachments?: {
      filename?: string,
      url: string,
    }[],
  };
};

function getInitial() {
  const init: {
    records: any[];
    error: string | null;
    page: {};
  } = {
    records: [],
    error: null,
    page: {},
  };

  return init;
}

// const expiryMs = 1000 * 60 * 60; // 60 minutes
// function isExpired(record: { $refreshedAt: number }) {
//   const now = Date.now();
//   return now - (record.$refreshedAt || 0) > expiryMs;
// }

function getFunctions(params: GetFunctionParams) {
  const { dispatch, subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      ilike,
      eq,
      gte,
      lte,
      order,
      custom,
      type = ActionType.UPSERT,
    } = opts || {};
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(dbTable).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (ilike) query.ilike(ilike.column, ilike.pattern);
    if (gte) query.gte(gte.column, gte.value);
    if (lte) query.lte(lte.column, lte.value);
    if (eq) query.eq(eq.column, eq.value);
    if (custom) custom(query);
    if (order) query.order(order.column, order.value);
    const result = await query;

    if (result.error) {
      log.error(`fetch ${dataset} error`, result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Gets data from state by default.
   * If it does not exist - tries to get from database
   *
   * @param params
   */
  const getRangeFn = async (params: any) => {
    const {
      id,
      assigned_to,
      proposal_id,
      prospect_id,
      beforeDate,
      message_type,
    } = params || {};
    log.debug({ message_params: params });
    const type = ActionType.UPSERT;
    let result: any = await refreshFn({
      custom: (query) => {
        query.limit(100);
        if (id) {
          query.eq('id', id);
        }
        if (assigned_to) {
          query.eq('assigned_to', assigned_to);
        }
        if (proposal_id) {
          query.eq('proposal_id', proposal_id);
        }
        if (prospect_id) {
          query.eq('prospect_id', prospect_id);
        }
        if (message_type) {
          query.eq('message_type', message_type);
        }
        if (beforeDate) {
          query.lte('created_at', beforeDate);
        }
      },
      order: {
        column: 'created_at',
        value: { ascending: false },
      },
      type,
    });

    return result;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: { records: any[]; error: any }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: any,
      opts?: { pick?: string[]; onConflict?: string; match?: any }
    ) => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = MessageSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: any = await schema
        .validate(record, { abortEarly: false })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      const client = await supabase.getClient();
      if (mode === UpsertMode.UPSERT) {
        const { onConflict = 'id' } = opts || {};
        const result: any = await client
          .from(dbTable)
          .upsert([valid], { onConflict })
          .select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await client.from(dbTable).insert([valid]).select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const { match = { id: valid.id } } = opts || {};
        const result: any = await client
          .from(dbTable)
          .update(valid)
          .match(match)
          .select();
        respond({ records: result?.data, error: result.error });
      }
      return getRangeFn({ id: valid.id });
    };
  };

  const chRefList: any[] = [];

  const findReference = (refObj: any) => {
    const match = chRefList.find((ref: any) => {
      const m = Object.keys(ref).reduce((result, key) => {
        return result && ref[key] === refObj[key];
      }, true);
      return m;
    });
    return match;
  };

  /**
   * Allow a reference to be added to local memory
   * @param refObj
   */
  const addReference = (refObj: any) => {
    const match = findReference(refObj);
    if (!match) {
      chRefList.push(refObj);
    }
    log.debug('after add reference list', chRefList);
  };

  const deleteReference = (refObj: any) => {
    const match = findReference(refObj);
    if (match) {
      const idx = chRefList.indexOf(match);
      chRefList.splice(idx, 1);
    }
    log.debug('after delete reference list', chRefList);
  };

  let channel: RealtimeChannel;
  const getChannelFn = async () => {
    if (channel) {
      log.debug('existing channel found:', channel);
      return channel;
    }

    const client = await supabase.getClient();
    channel = client.channel(`public.${dbTable}`).on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: dbTable,
      },
      (data: any) => {
        const match = findReference(data.new);
        if (match) {
          log.debug('message reference match', { data, match });
          dispatch({
            type: ActionType.UPSERT,
            dataset,
            payload: [data.new],
          });
        }
      }
    );

    log.debug('messages listening');
    return channel;
  };

  const listener = (status: string, err: any) => {
    log.debug(`${dbTable} subscription: ${status}`);
    if (err) {
      log.error(err);
    }
  };

  const subscribeCh = async () => {
    const ch = await getChannelFn();
    return ch.subscribe(listener);
  };

  const unsubscribeCh = async () => {
    const ch = await getChannelFn();
    return ch.unsubscribe();
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getRangeFn,
    getChannel: getChannelFn,
    getState: () => state[dataset],
    subscribeCh,
    unsubscribeCh,
    addReference,
    deleteReference,
    create: async (...d: any) => {
      const fn = getUpsertFn(UpsertMode.CREATE_ONLY);
      await fn.apply({}, d);
    },
    update: async (payload: any, opts?: any) => {
      const fn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      await fn(payload, opts);
    },
    refresh: refreshFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: MessageModel[] = action.payload;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: any) => x.id === y.id
      );
      if (existing) Object.assign(existing, x);
      else newState[dataset].records.push(x);
    });

    return newState;
    /**
     */
  } else if (action.type === ActionType.DELETE) {
    const records: MessageModel[] = action.payload;
    const ids = records.map((r) => r.id);
    newState[dataset].records = newState[dataset].records
      .filter((r: MessageModel) => !ids.includes(r.id))
      .filter((x: any) => x.deleted_at === null);
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE_PAGE) {
    const newVars: any = action.payload;
    const currVars: any = newState[dataset].page;
    newState[dataset].page = {
      ...currVars,
      ...newVars,
    };
    return newState;
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildMessages(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ state, dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  React.useEffect(() => {
    const ds = datastore.current[dataset];
    ds.subscribeCh();
    return () => ds.unsubscribeCh();
  }, [datastore]);

  return <>{children}</>;
}

export default BuildMessages;
export { dataset, getInitial, getFunctions, reducer, MessageType };
export type { MessageModel };
