import React from 'react';
// import log from 'loglevel';
import * as dateFns from 'date-fns';
import { useNavigate, } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import FeedOutlined from '@mui/icons-material/FeedOutlined';

import { useDataStore } from '../../comp/DataStore';
import UserNameText from '../../comp/HelperText/UserName';

const sorter = (ascending: boolean) => (a: any, b: any) => {
  const aStart = dateFns.parseISO(a.start);
  const bStart = dateFns.parseISO(b.start);

  if(ascending) return aStart.getTime() -  bStart.getTime();
  // else 
  return bStart.getTime() -  aStart.getTime()
};

type AppointmentBoxProps = {
  record: any;
}

function AppointmentBox(props: AppointmentBoxProps) {
  const { record } = props;
  const navigate = useNavigate();

  const start = dateFns.parseISO(record.start);

  const goToProposal = () => navigate(`/proposals/${record.proposal_id}`);

  return (
    <Paper elevation={0} sx={{ p: '10px', ml: '10px', mb: '10px', bgcolor: '#EBEDEF' }}>
      <Typography><b>{record.title}</b>{" assigned to "}<UserNameText user={{ email: record.assigned_to }}/></Typography>
      <Typography>{dateFns.format(start, 'MMMM d, yyyy h:mm bbb')}</Typography>
      {record.proposal_id ? <Chip onClick={goToProposal} label="proposal" icon={<FeedOutlined/>} /> : <></>}
    </Paper>
  )
}

type ViewProspectAppointmentsProps = {
  prospectId: string;
}

function ViewProspectAppointments(props: ViewProspectAppointmentsProps) {
  const { prospectId } = props;
  const datastore = useDataStore();

  const [appointmentsState, setAppointmentsState] = React.useState<any[] | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [now, setNow] = React.useState(new Date());

  const records = React.useMemo(() => {
    if (!appointmentsState) return;
    const response: any = {
      past: [],
      upcoming: [],
    };
    appointmentsState.forEach((a: any) => {
      const end = dateFns.parseISO(a.end);
      if(end >= now) {
        response.upcoming.push(a);
      } else {
        response.past.push(a);
      } 
    });
    response.past.sort(sorter(false));
    response.upcoming.sort(sorter(true));
    return response;
  }, [now, appointmentsState])

  React.useEffect(() => {
    if (loading) return;
    if (appointmentsState !== null) return;
    if (!prospectId) {
      setAppointmentsState([]);
      return;
    }
    setLoading(true);

    const now = new Date();
    const start = dateFns.addDays(now, -7);
    const end = dateFns.addDays(now, 7);

    datastore.appointments.getRange({
      prospect_id: prospectId,
      start: start.toISOString(),
      end: end.toISOString(),
    })
      .then((result: any) => setAppointmentsState(result || []))
      .finally(() => setLoading(false));
  }, [prospectId, loading, datastore, appointmentsState]);


  // Timer
  React.useEffect(() => {
    const i = setInterval(() => {
      setNow(new Date());
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(i);
    }
  });

  return (
    <Box>
      {!records || records.upcoming.length === 0 ? <></> : (
        <Box>
          <Typography variant="h6" sx={{ mb: '10px' }}>Upcoming Appointments</Typography>
          <Grid container>
            {records.upcoming.slice(0,5).map((r: any) => {
              return (
                <Grid item xs={12} key={r.id}>
                  <AppointmentBox record={r} />
                </Grid>
                );
              })
            }
          </Grid>
        </Box>
      )}
      {!records || records.past.length === 0 ? <></> : (
        <Box>
          <Typography variant="h6" sx={{ mb: '10px' }}>Past Appointments</Typography>
          <Grid container>
            {records.past.slice(0,5).map((r: any) => {
              return (
                <Grid item xs={12} key={r.id}>
                  <AppointmentBox record={r} />
                </Grid>
                );
              })
            }
          </Grid>
        </Box>
      )}
      {!records || (records.past.length === 0 && records.upcoming.length === 0) ? <i>No related appointments</i> : <></>}
    </Box>
  )
}

export default ViewProspectAppointments;
