import React from 'react';
import log from 'loglevel';
import Box from '@mui/material/Box';
import * as color from '@mui/material/colors';

import { EventType, EventStatus } from '../../comp/DataStore/Appointments';

/**
 * Update the colors in
 * src/comp/Layout/Paperbase/customTheme.tsx
 */

const getSx = (event: any) => {
  return (theme: any) => {
    const getPalette = (p: string) => {
      let eStatus = 'main';
      if (event.status === EventStatus.DONE) eStatus = 'light';
      return theme.palette[p][eStatus];
    };

    const eventSx: any = {
      [EventType.APPOINTMENT]: {
        border: 1,
        borderColor: color.grey[200],
        backgroundColor: getPalette('appointment'),
      },
      [EventType.DROP_OFF]: {
        border: 1,
        borderColor: color.grey[200],
        backgroundColor: getPalette('dropoff'),
      },
    };

    return eventSx[event.type];
  };
};

function CustomEventWrapper(props: any) {
  const { event, children } = props;
  const classes = children.props.className + ' custom-rbc-event';
  return (
    <>
      <Box {...children.props} className={classes} sx={getSx(event)}>
        {children.props.children}
      </Box>
    </>
  );
}

function CustomAgendaTime(props: any) {
  const { event } = props;
  log.debug('props agenda time', props);
  if (event.allDay) {
    if (event.type === EventType.DROP_OFF) return 'drop off anytime';
    return 'anytime';
  }
  return props.label;
}

export { CustomEventWrapper, CustomAgendaTime };
