import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function BlankTile() {
  const boxRef = React.useRef<any>();
  const [opacity, setOpacity] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => setOpacity(100), 10);
  }, [boxRef]);

  return (
    <Paper elevation={0} sx={{
      borderWidth: '1px',
      borderColor: 'darkgrey',
      borderStyle: 'dashed',
      backgroundColor: 'whitesmoke',
    }}>
      <Box
        ref={boxRef}
        sx={{
          minHeight: (boxRef.current?.offsetWidth * 9) / 16,
          opacity,
        }}
      ></Box>
    </Paper>
  );
}

export default BlankTile;
