import React from 'react';
// import log from 'loglevel';a
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useDataStore } from '../../comp/DataStore';
import { EventType, EventStatus } from '../../comp/DataStore/Appointments';
import { openGmapTab } from '../../comp/GoogleMap/GetDirections';

type ViewEventProps = {
  event: any;
};

function ViewEvent(props: ViewEventProps) {
  const { event } = props;

  const { appointments } = useDataStore();
  const navigate = useNavigate();

  const [done, setDone] = React.useState(event.status === EventStatus.DONE);

  const toggleMarkDone = (e: any) => {
    e.preventDefault();
    const d = {
      id: event.id,
      status:
        event.status === EventStatus.DONE
          ? EventStatus.NOT_DONE
          : EventStatus.DONE,
    };
    appointments.updateStatus(d);
    setDone(d.status === EventStatus.DONE);
  };

  const onGetDirections = () => {
    openGmapTab({ to: event.address });
  };

  return (
    <Grid container spacing={2} textAlign="center">
      <Grid item xs={12} justifyContent="center">
        <Typography variant="h4">{event.title}</Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center">
        <Typography variant="subtitle1">{event.address}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: event.allDay ? 'block' : 'none' }}
        justifyContent="center"
      >
        <Typography variant="body1">
          {event.type === EventType.DROP_OFF ? 'Drop off ' : 'Appointment '}
          anytime on {event.allDayFmt}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: event.allDay ? 'none' : 'block' }}
        justifyContent="center"
      >
        {event.type === EventType.DROP_OFF ? 'Drop Off' : ' Appointment'}
        <Typography variant="body1">Starts at {event.startFmt}</Typography>
        <Typography variant="body1">Ends at {event.endFmt}</Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center">
        <Typography variant="body2">{event.note}</Typography>
      </Grid>
      <Grid item xs={12} justifyContent="center">
        <FormControlLabel
          onClick={toggleMarkDone}
          control={<Checkbox color="success" checked={done} />}
          label={event.status === EventStatus.DONE ? 'Done' : 'Mark Done'}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/appointments/${event.id}/edit`)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            startIcon={<DirectionsCarFilledIcon />}
            // onClick={toggleDetails}
            onClick={onGetDirections}
          >
            Get Directions
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default ViewEvent;
