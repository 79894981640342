import React from 'react';
import log from 'loglevel';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import QuillEditor from '../../comp/Quill';
import { useDataStore } from '../../comp/DataStore';
import { SettingsKey, SettingsModel } from '../../comp/DataStore/Settings';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';

function WalkthroughEmail() {
  const datastore = useDataStore();

  const initSettings = datastore.settings.getState();
  const [settingsState, setSettingsState] = React.useState<any>(initSettings);

  const initialData: SettingsModel = settingsState.records.find(
    (x: SettingsModel) => x.key === SettingsKey.WALKTHROUGH_EMAIL
  );
  const quill = React.useRef<any>({});
  const [data, setData] = React.useState<any>(initialData?.data || {});
  const [loading, setLoading] = React.useState(false);

  const init = () => Boolean(quill.current && quill.current.instance);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!init()) return;
    setLoading(true);
    log.debug('saving');
    const formdata = new FormData(event.currentTarget);
    const delta = quill.current?.instance.getContents();
    log.debug('content', delta);
    const record: SettingsModel = {
      key: SettingsKey.WALKTHROUGH_EMAIL,
      data: {
        fromName: formdata.get('fromName') || null,
        fromEmail: formdata.get('fromEmail') || null,
        subject: formdata.get('subject'),
        body: delta,
      },
    };
    await datastore.settings.update([record]);
    setData(record.data);
    setLoading(false);
  };

  React.useEffect(() => {
    if (!init()) return;
    if (quill.current.runOnce) return;
    quill.current.runOnce = true;
    setLoading(true);
    datastore.settings
      .getKey(SettingsKey.WALKTHROUGH_EMAIL)
      .then((template: any) => {
        if (!template) return;
        log.debug('setting contents');
        quill.current?.instance.setContents(template.data.body);
        setData(template.data);
      })
      .finally(() => setLoading(false));
  }, [datastore.settings, quill]);

  React.useEffect(() => {
    const settingsId = datastore.settings.subscribe(setSettingsState);
    return () => {
      datastore.settings.unsubscribe(settingsId);
    }
  }, [datastore]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Email sent to Teed &amp; Brown employees when a walkthrough is
              scheduled for them
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              key={`from-name`}
              fullWidth
              id="fromName"
              label="From Name"
              name="fromName"
              helperText="The default name that will appear with the email. Leave blank to use sender's name"
              disabled={loading}
              defaultValue={data.fromName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              key={`from-email`}
              fullWidth
              type="email"
              id="fromEmail"
              label="From Email"
              name="fromEmail"
              helperText="The email to use in sending the email. Leave blank to use sender's email"
              disabled={loading}
              defaultValue={data.fromEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              key={`subject`}
              required
              fullWidth
              id="subject"
              label="Subject"
              name="subject"
              disabled={loading}
              defaultValue={data.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <QuillEditor quillRef={quill} disabled={loading} options={{}} />
          </Grid>
        </Grid>
        <ControlBar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="right">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  Update
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ControlBar>
      </Box>
    </>
  );
}

export default WalkthroughEmail;
