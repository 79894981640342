import React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

import settings from '../../settings';

type Props = {
  children?: React.ReactNode | string;
};

function LoadScript(props: Props) {
  const { children } = props;
  const libraries: any = ['drawing', 'places'];

  const render = (status: Status) => {
    if (status === Status.LOADING) {
      return <CircularProgress />;
    }
    if (status === Status.FAILURE) {
      return <Alert severity="error">Unable to load Google Maps.</Alert>;
    }
    if (status === Status.SUCCESS) {
      const Children = () => <>{children}</>;
      return <Children />;
    }
    return <></>;
  };

  return (
    <Wrapper
      apiKey={settings.maps.googleApiKey}
      libraries={libraries}
      render={render}
    >
      {children}
    </Wrapper>
  );
}

export default LoadScript;
