import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import CallIcon from '@mui/icons-material/Call';

import { useDataStore } from '../DataStore';
import { MessageType } from '../DataStore/Messages';

type SubmitFormProps = {
  references: any;
};

function SubmitForm(props: SubmitFormProps) {
  const datastore: any = useDataStore();

  const [msgType, setMsgType] = React.useState<MessageType>(MessageType.COMMENT);  
  const inputMessage = React.useRef<any>();
  const authState = datastore.auth.getState();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formdata = new FormData(event.currentTarget);
    const message = String(formdata.get('message') || '').trim();
    if (!message) return;

    const record = {
      ...props.references,
      id: uuidv4(),
      created_at: new Date().toISOString(),
      created_by: authState.user?.email,
      message_type: msgType,
      data: {
        message: formdata.get('message'),
      },
    };

    if (inputMessage.current) {
      inputMessage.current.value = '';
      inputMessage.current.focus();
    }

    datastore.messages.create(record);
  };

  const toggleMsgType = (e: any, value: any) => {
    if (!value) return;
    log.debug(value);
    setMsgType(value);
  }

  return (
    <Box sx={{ width: '100%' }} component="form" onSubmit={handleSubmit}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <ToggleButtonGroup
          size="small"
          value={msgType}
          exclusive
          onChange={toggleMsgType}
        >
          <ToggleButton value={MessageType.COMMENT}>
            <ModeCommentIcon />
          </ToggleButton>
          <ToggleButton value={MessageType.CALL}>
            <CallIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        <TextField
          autoFocus
          inputRef={inputMessage}
          id="input-message"
          name="message"
          variant="filled"
          fullWidth
          placeholder="Aa"
          inputProps={{
            autoComplete: "off",
            sx: { paddingTop: '8px' },
          }}
        />
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <IconButton type="submit">
            <SendIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default SubmitForm;
