import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

import { uploadFile } from '../../api/irrigationApi';

const Input = styled('input')({
  display: 'none',
});

enum FileCategory {
  IMAGES = 'images',
  DOCS = 'docs',
}

export type UploadFileObject = {
  info: File,
  uploaded: boolean,
  filename: string,
  category: FileCategory,
  urlPath?: string,
  body?: string,
  error?: any,
}

type UploadButtonProps = {
  inputProps?: {
    accept?: string;
    multiple?: boolean;
    id?: string;
    name?: string;
  };
  buttonProps?: ButtonProps;
  category: FileCategory;
  onUpload?: (file: UploadFileObject) => void;
  children?: React.ReactNode | string | number;
};

function UploadButton(props: UploadButtonProps) {
  const [loading, setLoading] = React.useState(false);
  const { children, inputProps, buttonProps, category, onUpload } = props;
  const inProps: any = Object.assign(
    {
      accept: '*/*',
      multiple: false,
      id: `upload_button_${uuidv4()}`,
    },
    inputProps
  );

  const [files, setFiles] = React.useState({});

  const finishedUpload = (fObj: UploadFileObject) => {
    if (typeof onUpload === 'function') onUpload(fObj);
  };

  const handleUpload = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const fList = (event.currentTarget as HTMLInputElement).files;
    const targetFiles: File[] = [...(fList || [])];

    log.debug(targetFiles);
    targetFiles.forEach((file, idx: number) => {
      const key = `n${idx + 1}`;
      const fileObj: UploadFileObject = {
        info: file,
        uploaded: false,
        filename: file.name,
        category,
      };
      setFiles({ ...files, [key]: fileObj });

      // read then upload
      log.debug(`Reading [${key}] file ${file.name}`);
      const reader: any = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = async () => {
        fileObj.body = window.btoa(reader.result);
        setFiles({ ...files, [key]: fileObj });
        // start uploading
        log.debug(`Uploading [${key}] file ${file.name}`);
        const result = await uploadFile(fileObj);
        log.debug(`Upload result [${key}] file ${file.name}`, result);
        fileObj.uploaded = true;
        fileObj.urlPath = result.file;
        setFiles({ ...files, [key]: fileObj });
        event.target.value = '';
        finishedUpload(fileObj);
        setLoading(false);
      };
      reader.onerror = (err: any) => {
        fileObj.error = err;
        setFiles({ ...files, [key]: fileObj });
        log.error(err);
        log.error(`Error uploading file ${file.name}`);
        setLoading(false);
      };
    });
  };

  return (
    <label htmlFor={inProps.id}>
      <Input
        accept={inProps.accept}
        id={inProps.id}
        multiple={inProps.multiple}
        type="file"
        onChange={handleUpload}
      />
      <Button
        disabled={loading}
        variant="contained"
        component="span"
        startIcon={<InsertPhotoIcon />}
        {...buttonProps}
      >
        {children}
      </Button>
    </label>
  );
}

export default UploadButton;
export { UploadButton, FileCategory };
