import React from 'react';
// import log from 'loglevel';

import useDataStore from '../DataStore';
import Loading from '../Loading';

type Props = {
  children?: React.ReactNode | string | number;
  fallback?: React.ReactNode;
};

function Protected(props: Props) {
  const datastore = useDataStore();
  const { children, fallback } = props;
  const thenFallback = fallback ? <>{fallback}</> : <></>;

  const [authState, setAuthState] = React.useState<any>({});

  React.useEffect(() => {
    const authId = datastore.auth.subscribe(setAuthState);
    return () => datastore.auth.unsubscribe(authId);
  }, [datastore]);

  return (
    <>
      {authState.loading ? (
        <Loading fullPage />
      ) : authState.user ? (
        <>{children}</>
      ) : (
        <>{thenFallback}</>
      )}
    </>
  );
}

export default Protected;
