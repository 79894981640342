/**
 * Service Worker
 */
import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';

const dataset = 'sw';

type SwType = {
  registration?: ServiceWorkerRegistration;
  updates: boolean;
}

const sw: SwType = {
  updates: false,
};

function getInitial() {
  return sw;
}

enum SwEventType {
  SUCCESS = 'SUCCESS',
  UPDATE = 'UPDATE',
};

const subscribers: any = {};

const getRegistration = () => sw.registration;

const setRegistration = (registration: ServiceWorkerRegistration) => {
  log.debug('service worker set:', registration);
  sw.registration = registration;
};

const onSuccess = (registration: ServiceWorkerRegistration) => {
  log.debug('service worker on success:', registration);
  sw.registration = registration;

  Object.keys(subscribers)
    .filter((key) => subscribers[key].event === SwEventType.SUCCESS)
    .forEach((key) => {
      const sub = subscribers[key];
      if(typeof sub.fn === 'function') {
        sub.fn(sw);
      }
    });
};

const onUpdate = (registration: ServiceWorkerRegistration) => {
  log.debug('service worker on update:', registration);
  sw.registration = registration;
  sw.updates = true;

  Object.keys(subscribers)
    .filter((key) => subscribers[key].event === SwEventType.UPDATE)
    .forEach((key) => {
      const sub = subscribers[key];
      if(typeof sub.fn === 'function') {
        sub.fn(sw);
      }
    });
};

const subscribe = (event: SwEventType, fn: Function, id?: string) => {
  const sid = id || uuidv4();
  if (!subscribers[sid]) {
    subscribers[sid] = { sid, event, fn };
  }
  return sid;
}

const unsubscribe = (sid: string) => {
  if (subscribers[sid]) {
    delete subscribers[sid];
    return true;
  }
  return false;
}

function getFunctions() {
  return {
    setRegistration,
    getRegistration,
    onSuccess,
    onUpdate,
    getState: () => sw,
    subscribe,
    unsubscribe,
  };
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
};

function BuildSw(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions();
    reload();
  }, [datastore, reload]);

  return <>{children}</>;
}

export default BuildSw;
export {
  SwEventType,
  getInitial,
  getFunctions,
  setRegistration,
  getRegistration,
  onSuccess,
  onUpdate,
};
