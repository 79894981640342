import React from 'react';
import log from 'loglevel';
import Quill from 'quill';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import AttachmentIcon from '@mui/icons-material/Attachment';
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { usePdfFn, getFileName, MailParams } from './usePdfFn';
import type { Proposal } from './getProposalModel';
import ErrorHandler from '../../comp/ErrorHandler';
import * as irrigationApi from '../../api/irrigationApi';
import QuillEditor, {
  QuillEditorProps,
  deltaTemplate,
  deltaToHtml,
} from '../../comp/Quill/Quill';
import emailTemplate from './emailTemplate';

type SendProposalModalProps = {
  proposals: Proposal[];
};

function SendProposalModal(props: SendProposalModalProps) {
  const { proposals } = props;

  const { refreshMailParams, downloadPdf, sendMail, getProposalSettings } =
    usePdfFn();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [mailParams, setMailParams] = React.useState<MailParams>();
  const [error, setError] = React.useState<any>();
  const [typeSettings, setTypeSettings] = React.useState<any>({});
  const [qInstance, setQInstance] = React.useState<Quill>();
  // const [showAcceptBtn, setShowAcceptBtn] = React.useState(false);

  const parent = React.useMemo(() => {
    const p = proposals.find((x) => !x.parent_id);
    return p;
  }, [proposals]);

  const handleRefresh = React.useCallback(async () => {
    if (!parent) return;
    log.debug('refreshing email preview...');
    setLoading(true);
    const p1 = getProposalSettings({ proposals });
    const p2 = refreshMailParams({ proposals });
    const [settings, mparams] = await Promise.all([p1, p2]).catch((err) => {
      log.error(err);
      setError(err);
      throw err;
    });
    log.debug('proposal settings', settings);
    setTypeSettings(settings);
    setMailParams(mparams);

    const delta = deltaTemplate({
      delta: settings.proposalType.emailBody,
      proposal: parent,
    });
    // setQDelta((curr) => ({ id: curr?.id, delta }));
    if (qInstance) {
      qInstance.setContents(delta as any);
    }

    setLoading(false);
    return { delta };
  }, [getProposalSettings, proposals, parent, qInstance, refreshMailParams]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpen = () => {
    setDialogOpen(true);
    handleRefresh();
  };

  const onInitEditor = React.useCallback<Required<QuillEditorProps>['onInit']>(
    (params) => {
      log.debug('editor initiated');
      setQInstance(params.instance);
      handleRefresh().then((p) => {
        if (!p) return;
        params.instance.setContents(p.delta as any);
      });
    },
    [handleRefresh]
  );

  const handleSend = React.useCallback(async () => {
    setLoading(true);
    if (!qInstance) return;

    const htmlTemplate = emailTemplate({
      message: deltaToHtml(qInstance.getContents()).replace(
        /<p>/g,
        '<p style="margin: 0px;">'
      ),
      // acceptButtonUrl: showAcceptBtn ? typeSettings?.acceptButtonUrl : undefined,
    });

    const upMailParams: MailParams = {
      ...(mailParams as MailParams),
      templateData: {},
      htmlTemplate,
    };

    await sendMail(upMailParams)
      .then((r) => {
        if (console.table) {
          const table: any[] = [];
          Object.keys(r).forEach((key) => {
            let value = r[key];
            if (value === null || value === undefined) {
              // do nothing
            } else if (Array.isArray(value)) {
              value = value.join(', ');
            } else if (typeof value === 'object') {
              value = JSON.stringify(value);
            }
            table.push({
              key,
              value,
            });
          });
          console.table(table);
        }
      })
      .catch((err) => {
        log.error(err);
        setError(err);
      })
      .finally(() => {
        setDialogOpen(false);
        setLoading(false);
      });
  }, [qInstance, mailParams, sendMail]);

  const handleDownloadPdf = (p: Proposal) => () => {
    const filename = getFileName(p);
    if (!p.file) {
      throw new Error('Cannot download PDF');
    }
    return downloadPdf({
      file: p.file,
      name: filename,
    });
  };

  const handleDownloadAttachment = (a: any) => () => {
    return irrigationApi.downloadDoc({
      file: a.path,
      name: a.filename,
    });
  };

  // const toggleAcceptProposalBtn = () => {
  //   setShowAcceptBtn(!showAcceptBtn);
  // };

  return (
    <>
      <Button
        disabled={loading}
        variant='contained'
        startIcon={<MailIcon />}
        onClick={handleOpen}
      >
        Preview Email
      </Button>
      <ErrorHandler error={error} />

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        scroll='paper'
        sx={(theme) => ({ zIndex: theme.zIndex.appBar + 1 })}
      >
        <DialogTitle id='scroll-dialog-title'>
          Subject: {(mailParams || {}).subject}
        </DialogTitle>
        <DialogContent dividers>
          <QuillEditor
            key={parent?.id}
            disabled={typeSettings.loading}
            onInit={onInitEditor}
            // onChange={onChangeEditor}
          />
          <Box sx={{ mt: '10px' }}>
            {(proposals || []).map((p) => {
              if (!p.file) {
                return (
                  <Chip
                    sx={{ mr: '5px', mb: '5px' }}
                    icon={<AttachmentIcon />}
                    key={p.id}
                    size='small'
                    label='generating...'
                    // onClick={handleDownloadPdf(p)}
                  />
                );
              }
              const filename = getFileName(p);
              return (
                <Chip
                  sx={{ mr: '5px', mb: '5px' }}
                  icon={<AttachmentIcon />}
                  key={p.id}
                  size='small'
                  label={filename}
                  onClick={handleDownloadPdf(p)}
                />
              );
            })}
            {(parent?.attachments?.records || []).map((a) => {
              return (
                <Chip
                  sx={{ mr: '5px', mb: '5px' }}
                  icon={<AttachmentIcon />}
                  key={a.path}
                  size='small'
                  label={a.filename}
                  onClick={handleDownloadAttachment(a)}
                />
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleRefresh} disabled={loading} variant='outlined'>
            Refresh
          </Button>
          {/* <Button
            onClick={toggleAcceptProposalBtn}
            disabled={loading}
            color={showAcceptBtn ? "primary" : "info"}
            variant="outlined"
            startIcon={
              showAcceptBtn ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />
            }
          >
            Add Accept Button
          </Button> */}
          <Button onClick={handleSend} disabled={loading} variant='contained'>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SendProposalModal;
