import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import log from 'loglevel';

import Grid from '@mui/material/Grid';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import GoogleMap from '../../comp/GoogleMap/GoogleMap';
import { useDataStore } from '../../comp/DataStore';
import { recomputeService } from './SelectServices';
import settings from '../../settings';

function EditMap() {
  const datastore = useDataStore();
  const navigate = useNavigate();
  const { id } = useParams();

  const proposalsState = datastore.proposals.getState();

  const existing = proposalsState.records.find((p: any) => p.id === id);
  const [proposal, setProposal] = React.useState<any>(existing || {});
  const [loading, setLoading] = React.useState(false);

  const saveData = async (map: any) => {
    log.debug(map);

    let totalAreaSqft = (map?.overlays || [])
      .reduce((prev: number, curr: any) => prev + curr.area_sqft, 0);
    if (map.area) {
      totalAreaSqft = map.area;
    }

    const serviceRecords = await Promise.all(
      (proposal.services.records || []).map((service: any) => {
        return recomputeService({
          datastore,
          service,
          percentIncrease: proposal.services.percentIncrease,
          difficulty: proposal.services.difficulty,
          area: totalAreaSqft,
        });
      }),
    );

    const payload = {
      id,
      map: {
        ...(proposal.map || {}),
        ...map,
      },
      services: {
        ...proposal.services,
        records: serviceRecords,
      }
    };

    await datastore.proposals.update(payload, { pick: ['id', 'map', 'services'] });
    navigate(`/proposals/${id}/edit`);
  };

  const data = proposal.map || {
    zoom: settings.maps.zoom,
    center: settings.maps.center,
  };

  React.useEffect(() => {
    if (loading) return;
    if (id === proposal.id) return;
    datastore.proposals.get({ id: String(id || '') })
      .then((p: any) => setProposal(p || {}))
      .finally(() => setLoading(false));
  }, [id, proposal, datastore, loading])

  return (
    <Paperbase title={'Edit Map'} bottomSpacer="100px">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          {proposal.id ? (
          <GoogleMap
            // dataRef={dataRef}
            data={data}
            colors={settings.maps.colors}
            onSave={saveData}
            />
          ): <></>}
        </Grid>
      </Grid>
    </Paperbase>
  );
}

export default EditMap;
