import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';

import UploadButton, { FileCategory } from '../../comp/UploadButton';
import ImageSet from '../../comp/ImageSet';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import InputAddress, { geocoder } from '../../comp/GoogleMap/InputAddress';
import GmapImage from '../../comp/GoogleMap/GmapImage';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import { SettingsKey } from '../../comp/DataStore/Settings';
import { AddressComponentModel, ProspectTitle } from '../../comp/DataStore/Prospects';
import ErrorHandler from '../../comp/ErrorHandler';
import settings from '../../settings';

const Input = styled('input')({
  display: 'none',
});

type Props = {
  newRecord?: boolean;
};

function EditProspects(props: Props) {
  const datastore = useDataStore();
  const { newRecord = false } = props;

  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const prospectsState = datastore.prospects.getState();
  const existing = prospectsState.records.find((x: any) => x.id === id);

  const settingsState = datastore.settings.getState();
  const existingSettings = settingsState.records.find((x: any) => x.key === SettingsKey.PROSPECTS);

  // Record state (selection)
  const [loading, setLoading] = React.useState(false);
  const [loadSettings, setLoadSettings] = React.useState(false);
  const [prospect, setProspect] = React.useState<any>(
    newRecord
      ? {
          id: uuidv4(),
          title: '',
          avatar_url: '',
        }
      : existing || {}
  );
  const [map, setMap] = React.useState<any>(newRecord ? {} : existing?.map || {});
  const [error, setError] = React.useState<any>(null);
  const [warning, setWarning] = React.useState('');
  const [prospectSettings, setProspectSettings] = React.useState<any>(existingSettings?.data || {});
  const refAvatar = React.useRef<any>({});

  const getFullAddr = () => {
    return [
      prospect.address_1,
      prospect.address_2,
      prospect.city,
      prospect.state,
      prospect.zip,
    ]
      .filter((x) => Boolean(x))
      .join(', ');
  };

  const calcAddressComp = React.useMemo(() => {
    if (map.address_components) {
      return map.address_components;
    }
    const ac: AddressComponentModel[] = [
      {
        types: ['political', 'administrative_area_level_2', 'locality'],
        long_name: prospect.city,
        short_name: prospect.city,
      },
      {
        types: ['administrative_area_level_1'],
        long_name: prospect.state,
        short_name: prospect.state,
      },
      {
        types: ['postal_code'],
        long_name: prospect.zip,
        short_name: prospect.zip,
      },
      {
        types: ['country', 'political'],
        long_name: 'United States',
        short_name: 'US',
      },
    ];
    return ac;
  }, [prospect, map]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formdata = new FormData(event.currentTarget);

    const prospectFullName = [
      formdata.get('title'),
      formdata.get('first_name'),
      formdata.get('last_name'),
    ].filter((x) => Boolean(x)).join(' ');

    log.debug('map', map);

    const payload: any = {
      ...prospect,
      id: prospect.id,
      full_name: prospectFullName,
      title: formdata.get('title'),
      first_name: formdata.get('first_name'),
      middle_name: formdata.get('middle_name'),
      last_name: formdata.get('last_name'),
      address: getFullAddr(),
      address_1: formdata.get('address_1'),
      address_2: formdata.get('address_2'),
      city: formdata.get('city'),
      state: formdata.get('state'),
      zip: formdata.get('zip'),
      map: map || {
        // default map
        zoom: settings.maps.zoom,
        center: settings.maps.center,
        address_components: calcAddressComp,
      },
      primary_phone: formdata.get('primary_phone'),
      send_sms: formdata.get('send_sms') === 'on',
      phone_2: formdata.get('phone_2'),
      send_sms_2: formdata.get('send_sms_2') === 'on',
      email: formdata.get('email'),
      email_cc: String(formdata.get('email_cc'))
        .split(',')
        .map((x) => String(x).trim())
        .filter((x) => String(x) !== ''),
      email_bcc: String(formdata.get('email_bcc'))
        .split(',')
        .map((x) => String(x).trim())
        .filter((x) => String(x) !== ''),
      avatar_url: formdata.get('avatar_url'),
      source_channel: formdata.get('source_channel'),
      source_channel_details: formdata.get('source_channel_details')
    };

    const upsert = newRecord
      ? datastore.prospects.create(payload)
      : datastore.prospects.update(payload);
    const result = await upsert;
    log.debug('submit result', result);

    // setData({ ...data, record: { ...data.record, ...payload } });
    setLoading(false);

    const backTo: string = searchParams.get('back-to') || '';

    if (result?.$error) {
      setError(result.$error);
    } else if (backTo) {
      navigate(`${backTo}?prospect_id=${payload.id}`);
    } else {
      navigate(`/prospects/${payload.id}`);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    if (newRecord || !id) return;
    datastore.prospects.delete({ id });
    setLoading(false);
    navigate('/prospects');
  };

  const handleUpload = (imgObj: any) => {
    const { urlPath } = imgObj;
    setProspect((curr: any) => ({ ...curr, avatar_url: urlPath }))
    refAvatar.current.value = urlPath;
    // log.debug({ map });
  };

  const handleFocus = () => {
    setError(null);
  };

  const checkTaxRate = React.useCallback(async (addrComp: any) => {
    setWarning('');
    log.debug('checking tax rate...');
    const rate = await datastore.taxRate.getTaxRate(addrComp);
    if (!rate) {
      setWarning('This address has no tax rate specified');
    }
  }, [datastore.taxRate]);

  const onSelectAddress = (addr: any) => {
    const addrComp = datastore.taxRate.getAddress(addr.address_components);
    log.debug(addrComp);
    const addr1 = `${addrComp.street_num?.long_name || ''} ${
      addrComp.street_name?.long_name || ''
    }`;

    // separate address fields
    setProspect((curr: any) => ({
      ...curr,
      address_1: addr1.trim(),
      address_2: addrComp.subpremise?.short_name || '',
      city:
        addrComp.city?.short_name ||
        addrComp.sublocality?.short_name ||
        addrComp.county?.short_name,
      state:  addrComp.state?.short_name,
      zip: addrComp.zip?.short_name,
    }));

    setMap({
      address_components: addr.address_components,
      center: addr.geometry.location.toJSON(),
    });

    checkTaxRate(addr.address_components);
  };

  const onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const inputValue = type === 'checkbox' ? checked : value;
    log.debug(`[${name}]: ${value}`);
    setProspect((curr: any) => ({
      ...curr,
      [name]: inputValue,
    }));
  };

  const errorObj = React.useMemo(() => {
    const value: any = {};
    if (!error) return value;
    if (error.name === 'ValidationError') {
      log.debug('updating error Object', error.inner);
      (error.inner || []).forEach((innerErr: any) => {
        const key = String(innerErr.path).replace(/\[[0-9]+\]$/, '');
        value[key] = innerErr;
      });
    }
    log.debug('updated error object', value);
    return value;
  }, [error]);

  const scMenu = React.useMemo(() => {
    log.debug({ prospectSettings });
    if (prospectSettings?.sourceChannel?.records.length === 0) return [];
    return prospectSettings?.sourceChannel?.records.map((sc: any) => (
      <MenuItem key={sc.id} value={sc.id}>{sc.name}</MenuItem>
    ));
  }, [prospectSettings]);

  React.useEffect(() => {
    const t = setTimeout(() => checkTaxRate(calcAddressComp), 1000);
    return () => {
      clearTimeout(t);
    }
  },[checkTaxRate, calcAddressComp]);

  // Get prospect
  React.useEffect(() => {
    if (newRecord) return;
    if (loading) return;
    if (!id) return;
    if (prospect.id === id) return;
    setLoading(true);
    datastore.prospects
      .get({ id })
      .then((p) => {
        if (!p) {
          setError(new Error('Unable to retrieve prospect'))
          setProspect({ id });
          return;
        }
        setProspect(p);
        setMap(p.map);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [newRecord, loading, prospect, id, datastore]);

  // Get settings
  React.useEffect(() => {
    if (loadSettings) return;
    if (prospectSettings.sourceChannel) return;
    setLoadSettings(true);
    datastore.settings.getKey(SettingsKey.PROSPECTS)
      .then((s: any) => {
        if (!s) return;
        setProspectSettings(s.data);
      })
      .finally(() => setLoadSettings(false));
  }, [datastore, loadSettings, prospectSettings]);

  return (
    <Paperbase
      title={newRecord ? 'New Prospect' : 'Edit Prospect'}
      bottomSpacer="100px"
    >
      <Container component="main">
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={9}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="input-title">Title</InputLabel>
                    <Select
                      key={`title-${prospect.id}`}
                      fullWidth
                      labelId="input-title"
                      id="title"
                      label="Title"
                      name="title"
                      disabled={loading}
                      defaultValue={prospect.title || ''}
                      error={Boolean(errorObj['title'])}
                      onFocus={handleFocus}
                    >
                      {Object.keys(ProspectTitle).map((key) => (
                        <MenuItem key={key} value={key}>{key}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`first-name-${prospect.id}`}
                    required
                    fullWidth
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    disabled={loading}
                    defaultValue={prospect.first_name || ''}
                    error={Boolean(errorObj['first_name'])}
                    helperText={errorObj['first_name']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={`middle-name-${prospect.id}`}
                    fullWidth
                    id="middle_name"
                    label="Middle Name"
                    name="middle_name"
                    disabled={loading}
                    defaultValue={prospect.middle_name || ''}
                    error={Boolean(errorObj['middle_name'])}
                    helperText={errorObj['middle_name']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={`last-name-${prospect.id}`}
                    required
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    disabled={loading}
                    defaultValue={prospect.last_name || ''}
                    error={Boolean(errorObj['last_name'])}
                    helperText={errorObj['last_name']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputAddress
                    key={`address-1-${prospect.id}`}
                    textFieldProps={{
                      required: true,
                      fullWidth: true,
                      id: 'address_1',
                      label: 'Address Line 1',
                      name: 'address_1',
                      disabled: loading,
                      value:
                        prospect.address_1 || prospect.address || '',
                      onChange: onChangeAddress,
                      error: Boolean(errorObj['address_1']),
                      helperText: errorObj['address_1']?.message,
                      onFocus: handleFocus,
                      InputLabelProps: { shrink: true },
                    }}
                    onSelect={onSelectAddress}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={`address-2-${prospect.id}`}
                    fullWidth
                    id="address_2"
                    label="Address Line 2"
                    name="address_2"
                    disabled={loading}
                    value={prospect.address_2 || ''}
                    onChange={onChangeAddress}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errorObj['address_2'])}
                    helperText={errorObj['address_2']?.message}
                    onFocus={handleFocus}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={`city-${prospect.id}`}
                    required
                    fullWidth
                    id="city"
                    label="City"
                    name="city"
                    disabled={loading}
                    value={prospect.city || ''}
                    onChange={onChangeAddress}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errorObj['city'])}
                    helperText={errorObj['city']?.message}
                    onFocus={handleFocus}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={`state-${prospect.id}`}
                    required
                    fullWidth
                    id="state"
                    label="State"
                    name="state"
                    disabled={loading}
                    value={prospect.state || ''}
                    onChange={onChangeAddress}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errorObj['state'])}
                    helperText={errorObj['state']?.message}
                    onFocus={handleFocus}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={`zip-${prospect.id}`}
                    required
                    fullWidth
                    id="zip"
                    label="Zip"
                    name="zip"
                    disabled={loading}
                    value={prospect.zip || ''}
                    onChange={onChangeAddress}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errorObj['zip'])}
                    helperText={errorObj['zip']?.message}
                    onFocus={handleFocus}
                  />
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    key={`phone-${prospect.id}`}
                    fullWidth
                    id="primary_phone"
                    label="Primary Phone"
                    name="primary_phone"
                    disabled={loading}
                    defaultValue={prospect.primary_phone}
                    error={Boolean(errorObj['primary_phone'])}
                    helperText={errorObj['primary_phone']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={`sms-${prospect.id}`}
                          id="send_sms"
                          name="send_sms"
                          checked={prospect.send_sms || true}
                          disabled={loading}
                        />
                      }
                      label="Can Receive SMS"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    key={`phone-2-${prospect.id}`}
                    fullWidth
                    id="phone_2"
                    label="Secondary Phone"
                    name="phone_2"
                    disabled={loading}
                    defaultValue={prospect.phone_2 || ''}
                    error={Boolean(errorObj['phone_2'])}
                    helperText={errorObj['phone_2']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={`sms-2-${prospect.id}`}
                          id="send_sms_2"
                          name="send_sms_2"
                          defaultChecked={prospect.send_sms_2 || false}
                          disabled={loading}
                        />
                      }
                      label="Can Receive SMS"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    key={`email-${prospect.id}`}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    disabled={loading}
                    defaultValue={prospect.email || ''}
                    error={Boolean(errorObj['email'])}
                    helperText={errorObj['email']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`email-cc-${prospect.id}`}
                    fullWidth
                    id="email_cc"
                    label="Cc (Separated by ,)"
                    name="email_cc"
                    disabled={loading}
                    defaultValue={prospect.email_cc}
                    error={Boolean(errorObj['email_cc'])}
                    helperText={errorObj['email_cc']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`email-bcc-${prospect.id}`}
                    fullWidth
                    id="email_bcc"
                    label="Bcc (Separated by ,)"
                    name="email_bcc"
                    disabled={loading}
                    defaultValue={prospect.email_bcc}
                    error={Boolean(errorObj['email_bcc'])}
                    helperText={errorObj['email_bcc']?.message}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="input-source_channel">Source Channel</InputLabel>
                    <Select
                      key={`source_channel-${prospect.id}`}
                      fullWidth
                      required
                      labelId="input-source_channel"
                      id="source_channel"
                      label="Source Channel"
                      name="source_channel"
                      disabled={loading || loadSettings}
                      defaultValue={prospect.source_channel || ''}
                      error={Boolean(errorObj['source_channel'])}
                      onFocus={handleFocus}
                    >
                      {scMenu}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                      key={`source_channel_details-${prospect.id}`}
                      fullWidth
                      id="source_channel_details"
                      label="Source Details"
                      name="source_channel_details"
                      type="source_channel_details"
                      disabled={loading}
                      defaultValue={prospect.source_channel_details || ''}
                      error={Boolean(errorObj['source_channel_details'])}
                      helperText={errorObj['source_channel_details']?.message}
                      onFocus={handleFocus}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Prospect's Photo</Typography>
                  <ImageSet
                    key={`avatar-url-${prospect.id}`}
                    src={prospect.avatar_url || ''}
                    alt={prospect.name}
                    useOrig
                  />
                  <Input
                    key={`avatar-inp-${prospect.id}`}
                    ref={refAvatar}
                    type="hidden"
                    name="avatar_url"
                    defaultValue={String(prospect.avatar_url || '')}
                    readOnly
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Address Preview</Typography>
                  <GmapImage center={map?.center} />
                </Grid>
                <Grid item xs={12}>
                  {!warning ? (
                    <></>
                  ) : (
                    <Alert severity="warning">
                      {'Warning: '}
                      {warning}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ControlBar>
            <Grid container>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Stack direction="row" spacing={3} justifyContent="right">
                    {newRecord ? (
                      <></>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={loading}
                        startIcon={<DeleteIcon />}
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                    <UploadButton
                      category={FileCategory.IMAGES}
                      inputProps={{
                        accept: 'image/png, image/jpeg',
                      }}
                      buttonProps={{
                        disabled: loading,
                        variant: 'outlined',
                      }}
                      onUpload={handleUpload}
                    >
                      Upload Image
                    </UploadButton>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                      endIcon={<SendIcon />}
                    >
                      {newRecord ? 'Add' : 'Update'}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </ControlBar>
        </Box>
        <ErrorHandler error={error} />
      </Container>
    </Paperbase>
  );
}

export default EditProspects;
