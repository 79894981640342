import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';

import * as supabase from '../../api/supabase';
import * as api from '../../api/irrigationApi';

const dataset = 'auth';

// https://supabase.com/docs/reference/javascript/auth-onauthstatechange
enum AuthStateType {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_OUT = 'SIGNED_OUT',
  TOKEN_REFRESHED = 'TOKEN_REFRESHED',
  USER_UPDATED = 'USER_UPDATED',
  USER_DELETED = 'USER_DELETED',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
}

enum ActionType {
  UPDATE = 'UPDATE',
}

function getInitial() {
  return {
    listening: false,
    loading: false,
    session: null,
    user: null,
    error: null,
    redirect: null,
    lastEvent: null,
    provider: null,
    record: null,
  };
}

function getFunctions(params: any) {
  const {
    dispatch,
    subscribers,
  } = params;
  let checking = false;

  // initialize
  const state: any = { [dataset]: getInitial() };

  supabase.getClient();
  const authState = state[dataset];

  const getUser = async(params: { email?: string }) => {
    let { email } = params;
    if (!email && authState.user?.email) {
      email = authState.user?.email;
    }
    if (!email) return;
    if (email === state[dataset].record?.email) return;
    const client = await supabase.getClient();
    const records = await client.from('users').select().match({ email });
    const record = records?.data?.slice(0,1)[0];
    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: { record },
    });
    log.debug('User Record', record);
    return record;
  };

  const loginPassword = async (credentials: any): Promise<any> => {
    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: { loading: false, error: null, type: 'password' },
    });
    const client = await supabase.getClient();
    const result: any = await client.auth
      .signInWithPassword(credentials)
      .catch((e: any) => {
        return { error: e };
      });
      return result;
  };

  const logoutFn = async (): Promise<void> => {
    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: { loading: false, error: null },
    });
    const client = await supabase.getClient();
    await client.auth.signOut();
  };

  const checkFn = async () => {
    if (checking) return;
    checking = true;
    log.debug('checking auth session ...');

    const client = await supabase.getClient();
    const { data: { session }} = await client.auth.getSession();

    if (!session) {
      // stop propagation if already logged out
      if (state[dataset].loading === false && !state[dataset].session) {
        return false;
      }

      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload: {
          loading: false,
          session: null,
          user: null,
        },
      });
      checking = false;
      return false;
    }

    // stop propagation if already logged in
    if (
      state[dataset].loading === false &&
      session.access_token === state[dataset].session?.access_token
    ) {
      return true;
    }

    await api.getClient({ force: true, session });
    const user = session?.user;
    getUser({ email: user?.email });

    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: {
        loading: false,
        session,
        user,
      },
    });

    checking = false;
    return user && user.id && user.email;
  };

  const listenFn = async (): Promise<void> => {
    if (state[dataset].listening) {
      log.debug(`Auth already listening.`);
      return;
    }
    log.debug(`${dataset} listening`);
    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: {
        listening: true,
      },
    });
    const client = await supabase.getClient();

    client.auth.onAuthStateChange((event: any, session: any) => {
      const currState = state[dataset];
      if (currState.loading) return;
      log.debug(`Current State`, currState);
      log.debug(`Auth State Change: ${event}`, { session });
      if (session) {
        if (currState.session?.access_token === session.access_token) {
          return;
        }
        const expiresAt = new Date((session.expires_at || 0) * 1000);
        log.debug(`Session expires at ${expiresAt.toString()}`);
      }
      api.getClient({ force: true, session: session });
      if (
        event === AuthStateType.SIGNED_OUT ||
        event === AuthStateType.USER_DELETED
      ) {
        dispatch({
          type: ActionType.UPDATE,
          dataset,
          payload: {
            loading: false,
            session: null,
            user: null,
            error: null,
            lastEvent: event,
          },
        });
      } else {
        getUser({ email: session?.user?.email });
        dispatch({
          type: ActionType.UPDATE,
          dataset,
          payload: {
            loading: false,
            session,
            user: session?.user,
            error: null,
            lastEvent: event,
            redirect:
              event === AuthStateType.PASSWORD_RECOVERY
                ? '/users/profile'
                : null,
          },
        });
      }
    });
  };

  const clearRedirectFn = async () => {
    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: {
        redirect: null,
      },
    });
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    getState: () => state[dataset],
    getRootState: () => state,
    login: loginPassword,
    logout: logoutFn,
    check: checkFn,
    listen: listenFn,
    clearRedirect: clearRedirectFn,
    getUser,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.UPDATE) {
    Object.assign(newState[dataset], action.payload || {});
    return newState;
  } else {
    throw new Error(`Invalid action type in ${dataset}: ${action.type}`);
  }
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildAuth(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildAuth;
export { BuildAuth, dataset, getInitial, getFunctions, reducer, ActionType };
