import React from 'react';
import format from 'date-fns/format';

function Timer() {
  const [time, setTime] = React.useState(new Date());
  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  });

  return <>{format(time, 'h:mm aaa')}</>;
}

export default Timer;
