/**
 * Reference:
 * https://developers.google.com/maps/documentation/javascript/react-map
 * http://jsfiddle.net/geocodezip/hk82v0zp/2/
 */
import * as React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import EventEmitter from 'events';
import Box from '@mui/material/Box';
import { deepmerge } from '@mui/utils';

import LoadScript from './LoadScript';
import GmapControls from './GmapControls';
import { toJsonFn, drawOverlay } from './helper';
import settings from '../../settings';

enum MapEventType {
  CENTER_CHANGED = 'CENTER_CHANGED',
  NEW_OVERLAY = 'NEW_OVERLAY',
  OVERLAY_SELECTED = 'OVERLAY_SELECTED',
  CLEAR_SELECTION = 'CLEAR_SELECTION',
}

type GMap = {
  map?: google.maps.Map;
  draw?: google.maps.drawing.DrawingManager;
};

/**
 *
 * @param params
 * @returns
 */
function getController(params: any) {
  const event = new EventEmitter();
  const ref: any = params.ref;
  const gmap: GMap = params.gmap || {};

  const position = {
    zoom: ref.current.zoom,
    center: ref.current.center,
  };
  const overlays: any[] = ref.current.overlays;
  const drawOpts: google.maps.drawing.DrawingManagerOptions =
    ref.current.drawOpts;

  const init = (): Boolean => Boolean(gmap.map) && Boolean(gmap.draw);

  const setGmap = (opts: GMap) => Object.assign(gmap, opts);

  const getData = () => {
    if (!init()) return;
    return {
      zoom: gmap.map?.getZoom()?.valueOf(),
      center: gmap.map?.getCenter()?.toJSON(),
      bounds: gmap.map?.getBounds()?.toJSON(),
      overlays: overlays.map((o) => o.toJSON()),
    };
  };

  const clearSelection = () => {
    log.debug('clearing selection');
    overlays.forEach((shape) => {
      log.debug('shape', shape);
      shape.selected = false;
      if (!shape.overlay) return;
      shape.overlay.setDraggable(false);
      shape.overlay.setEditable(false);
    });
    event.emit(MapEventType.CLEAR_SELECTION);
  };

  const setSelection = (shape: any) => {
    clearSelection();
    const existing = overlays.find((s) => s.id === shape.id);
    log.debug('selected shape', existing);
    if (existing) {
      existing.selected = true;
      existing.overlay.setDraggable(true);
      existing.overlay.setEditable(true);
    }
    event.emit(MapEventType.OVERLAY_SELECTED, { shape });
  };

  const deleteSelection = () => {
    if (!init()) return;
    const existing = overlays.find((s) => s.selected === true);
    log.debug('delete selected shape', existing);
    if (existing) {
      existing.selected = false;
      existing.overlay.setDraggable(false);
      existing.overlay.setEditable(false);
      existing.overlay.setMap(null);
      overlays.splice(overlays.indexOf(existing), 1);
    }
    log.debug('overlays', overlays.length);
  };

  const deleteAll = () => {
    if (!init()) return;
    clearSelection();
    overlays.forEach((s) => {
      s.overlay.setDraggable(false);
      s.overlay.setEditable(false);
      s.overlay.setMap(null);
    });
    overlays.splice(0, overlays.length);
    log.debug('overlays', overlays.length);
  };

  const setDrawOptions = (opts: google.maps.drawing.DrawingManagerOptions) => {
    if (!init()) return;
    const newOpts = deepmerge(drawOpts, opts, { clone: true });
    Object.assign(drawOpts, newOpts);
    gmap.draw?.setOptions(drawOpts);
  };

  const setSelectedShapeColor = (color: string) => {
    const existing = overlays.find((s) => s.selected === true);
    if (existing) {
      log.debug(`set color to ${color}`, existing);
      if (existing.overlay.type === google.maps.drawing.OverlayType.POLYLINE) {
        existing.overlay.set('strokeColor', color);
      } else {
        existing.overlay.set('strokeColor', color);
        existing.overlay.set('fillColor', color);
      }
    }
  };

  const setColor = (color: string) => {
    if (!init()) return;
    const shapeOpts = {
      strokeColor: color,
      fillColor: color,
    };
    setDrawOptions({
      circleOptions: shapeOpts,
      polygonOptions: shapeOpts,
      rectangleOptions: shapeOpts,
      polylineOptions: { strokeColor: color },
    });
    setSelectedShapeColor(color);
  };

  const changeCenter = () => {
    if (!init()) return;
    Object.assign(position, {
      zoom: gmap.map?.getZoom()?.valueOf(),
      center: gmap.map?.getCenter()?.toJSON(),
    });
    event.emit(MapEventType.CENTER_CHANGED, position);
  };

  const newOverlay = (e: any) => {
    if (!init()) return;
    log.debug('new overlay', e);
    const existing = overlays.find((x) => e.id && x.id === e.id);
    const data: any = existing || {
      id: uuidv4(),
      type: e.type,
    };

    data.overlay = e.overlay;
    data.toJSON = toJsonFn(data);

    if (!existing) overlays.push(data);
    else Object.assign(existing, data);

    google.maps.event.addListener(e.overlay, 'click', () => {
      setSelection(data);
    });

    event.emit(MapEventType.NEW_OVERLAY, data);
    setSelection(data);
  };

  const on = (type: MapEventType, listener: any) => {
    event.on(type, listener);
  };

  return {
    on,
    setGmap,
    getData,
    clearSelection,
    setSelection,
    deleteSelection,
    deleteAll,
    setSelectedShapeColor,
    setDrawOptions,
    setColor,
    newOverlay,
    changeCenter,
  };
}

type GoogleMapProps = {
  data?: {
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
    overlays?: any[];
  };
  // dataRef?: { current: { getData: () => any } };
  width?: string | number;
  height?: string | number;
  colors?: string[];
  onSave?: (d?: any) => void;
};

/**
 *
 * @param props
 * @returns
 */
function GoogleMap(props: GoogleMapProps) {
  const { data, colors = ['#283747'], onSave, ...options } = props;

  const {
    zoom = settings.maps.zoom,
    center = settings.maps.center,
    overlays = [],
  } = data || {};

  const ref = React.useRef<HTMLDivElement>(null);
  const [gmap, setGmap] = React.useState<GMap>({
    map: undefined,
    draw: undefined,
  });

  // const eventHandler = setEventHandler({ listener, gmap });
  const ctrlRef = React.useRef({
    overlays: JSON.parse(JSON.stringify(overlays)),
    drawOpts: {},
  });
  const ctrl = getController({ ref: ctrlRef, gmap });

  React.useEffect(() => {
    // if (dataRef && !dataRef.current.getData) {
    //   dataRef.current.getData = ctrl.getData;
    // }

    if (ref.current && !gmap.map) {
      const map: any = new google.maps.Map(ref.current, {
        zoom: zoom || 20,
        center: new google.maps.LatLng(center.lat, center.lng),
        mapTypeId: 'satellite',
        mapTypeControl: false,
        disableDefaultUI: true,
        zoomControl: false,
        panControl: false,
        gestureHandling: 'greedy',
        tilt: 0,
      });
      map.addListener('dragend', ctrl.changeCenter);
      map.addListener('zoom_changed', ctrl.changeCenter);
      map.addListener('click', ctrl.clearSelection);

      // Creates a drawing manager attached to the map that allows the user to draw markers, lines, and shapes.
      const shapeOpts = {
        editable: false,
        draggable: true,
      };
      const drawOpts: google.maps.drawing.DrawingManagerOptions = {
        drawingControl: false,
        markerOptions: shapeOpts,
        polylineOptions: shapeOpts,
        circleOptions: shapeOpts,
        rectangleOptions: shapeOpts,
        polygonOptions: shapeOpts,
        map,
      };
      const draw = new google.maps.drawing.DrawingManager(drawOpts);
      setGmap({ map, draw });
      ctrl.setGmap({ map, draw });

      // initialize options
      ctrl.setDrawOptions(drawOpts);
      ctrl.setColor(colors[0]);

      // create overlays
      if (overlays) {
        overlays.forEach((overlay) => {
          const e = drawOverlay(overlay);
          log.debug(e);
          e.overlay.setMap(map);
          ctrl.newOverlay(e);
        });
        ctrl.clearSelection();
      }

      // add listeners
      draw.addListener('overlaycomplete', ctrl.newOverlay);
      draw.addListener('click', ctrl.clearSelection);
    }
  }, [ref, gmap, zoom, options, center, ctrl, colors, overlays]);

  const sx = {
    width: options.width || '100%',
    height: options.height || '80vh',
  };

  const onSaveData = () => {
    if (typeof onSave === 'function') {
      onSave(ctrl.getData());
    }
  };

  if (!google) return <>Unable to load google library</>;
  return (
    <>
      <GmapControls
        controller={ctrl}
        colors={colors}
        onSave={typeof onSave === 'function' ? onSaveData : undefined}
      />
      <Box ref={ref} sx={sx} />
    </>
  );
}

/**
 *
 * @param props
 * @returns
 */
function GoogleMapWrapper(props: GoogleMapProps) {
  return (
    <LoadScript>
      <GoogleMap {...props} />
    </LoadScript>
  );
}

export default GoogleMapWrapper;
export { MapEventType };
