import React from 'react';
import log from 'loglevel';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useDataStore } from '../../comp/DataStore';
import { PricingModel, SeasonList } from '../../comp/DataStore/Services';
import { SettingsKey, SettingsModel } from '../../comp/DataStore/Settings';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';

function ServiceSettings() {
  const datastore = useDataStore();

  const initSettings = datastore.settings.getState();
  const [settingsState, setSettingsState] = React.useState<any>(initSettings);

  const initialData: SettingsModel = settingsState.records.find(
    (x: SettingsModel) => x.key === SettingsKey.SERVICES
  );

  const defaultData = {
    defaultPricing: [
      {
        name: 'Normal Service',
        pricing: PricingModel.SQFT,
        start_low_price: '109',
        start_high_price: '130',
        low_price_rate: '6',
        high_price_rate: '10',
      },
      {
        name: 'Spring Seeding',
        pricing: PricingModel.SQFT,
        season: [
          SeasonList.EARLY_SPRING,
          SeasonList.MID_SPRING,
          SeasonList.LATE_SPRING,
        ],
        start_low_price: '325',
        start_high_price: '385',
        low_price_rate: '16',
        high_price_rate: '29',
      },
      {
        name: 'Fall Service',
        pricing: PricingModel.SQFT,
        season: [
          SeasonList.EARLY_FALL,
          SeasonList.MID_FALL,
          SeasonList.LATE_FALL,
        ],
        start_low_price: '425',
        start_high_price: '515',
        low_price_rate: '22',
        high_price_rate: '39',
      },
    ],
  };

  const [data, setData] = React.useState<any>(initialData?.data || defaultData);
  const [loading, setLoading] = React.useState(false);
  const [percInc, setPercInc] = React.useState<string>('0.00');
  const [percDec, setPercDec] = React.useState<string>('0.00');
  const [incDialog, setIncDialog] = React.useState(false);
  const [error, setError] = React.useState<any>({});
  const runOnceRef = React.useRef<boolean>(false);

  const computeChange = (price: string | number) => {
    let p: number = typeof price === 'string' ? parseFloat(price) : Number(price);
    const pInc = parseFloat(percInc) / 100;
    const pDec = parseFloat(percDec) / 100;

    p = p * (1 + pInc - pDec);
    if (p < 0) p = 0;
    return p.toFixed(2);
  }

  const handleSubmit = async () => {
    setLoading(true);
    log.debug('saving');

    setData((curr: typeof defaultData) => {
      const record: SettingsModel = {
        key: SettingsKey.SERVICES,
        data,
      };
  
      datastore.settings.update([record])
        .finally(() => setLoading(false));
      return curr;
    });
  };

  const checkBeforeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) event.preventDefault();
    const inc = parseFloat(percInc);
    const dec = parseFloat(percDec);
    const p = parseFloat(computeChange(1));

    if (Number.isNaN(p)) {
      return;
    }

    else if (inc === 0 && dec === 0) {
      handleSubmit();
      return;
    }

    else setIncDialog(true);
  }

  const increaseThenSubmit = async () => {
    log.debug('Price increase then');
    setIncDialog(false);
    const multiplier = 1 + (parseFloat(percInc)/100) - (parseFloat(percDec)/100);
    log.debug(`Price increase multiplier: ${multiplier.toFixed(2)}`);
    setPercInc('0.00');
    setPercDec('0.00');
    setLoading(true)

    setData((curr: typeof defaultData) => ({
      ...curr,
      defaultPricing: curr.defaultPricing.map((p) => ({
        ...p,
        start_low_price: computeChange(p.start_low_price),
        start_high_price: computeChange(p.start_high_price),
        low_price_rate: computeChange(p.low_price_rate),
        high_price_rate: computeChange(p.high_price_rate),
      }))
    }));

    await datastore.services.updatePrice({ multiplier });
    await handleSubmit();
  }

  React.useEffect(() => {
    if (runOnceRef.current) return;
    runOnceRef.current = true;
    setLoading(true);
    datastore.settings
      .getKey(SettingsKey.SERVICES)
      .then((d: any) => setData(d.data))
      .finally(() => setLoading(false));
  }, [datastore.settings]);

  const updatePrice = (idx: number, key: string) => (e: any) => {
    setData((curr: any) => {
      const newData = { ...curr };
      newData.defaultPricing[idx][key] = e.target.value;
      return newData;
    });
  };

  const updatePercIncrease = (e: any) => {
    const val = parseFloat(e.target.value);
    setError((curr: any) => ({
      ...curr,
      percentInc: (!val === null || val === undefined || Number.isNaN(val))
        ? 'Must be a valid number' : undefined,
    }));
    setPercInc(e.target.value);
  }

  const updatePercDecrease = (e: any) => {
    const val = parseFloat(e.target.value);
    setError((curr: any) => ({
      ...curr,
      percentDec: (!val === null || val === undefined || Number.isNaN(val))
        ? 'Must be a valid number' : undefined,
    }));
    setPercDec(e.target.value);
  }

  const handleIncDialog = () => {
    setIncDialog(false);
  }

  const priceHelperText = (p1: any) => {
    const p2 = computeChange(p1);
    const inc = parseFloat(percInc);
    const dec = parseFloat(percDec);
    if ((inc - dec) === 0) {
      return `No effect on percent change`;
    } else {
      return `Percent change effect: ${p2}`;
    }
  }

  React.useEffect(() => {
    const settingsId = datastore.settings.subscribe(setSettingsState);
    return () => {
      datastore.settings.unsubscribe(settingsId);
    }
  }, [datastore]);

  const pricingNodes = data.defaultPricing.map((dp: any, idx: number) => {
    return (
      <Grid container spacing={2} key={`${idx}`} sx={{ pb: '20px'}}>
        <Grid item xs={12}>
          <Divider textAlign="left">{dp.name}</Divider>
        </Grid>
        <Grid item xs={3}>
          <TextField
              key={`start_low_price-${idx}`}
              fullWidth
              type="number"
              id="start_low_price"
              label="Start Low Price"
              name="start_low_price"
              disabled={loading}
              value={dp.start_low_price}
              onChange={updatePrice(idx, 'start_low_price')}
              helperText={priceHelperText(dp.start_low_price)}
            />
        </Grid>
        <Grid item xs={3}>
          <TextField
              key={`start_high_price-${idx}`}
              fullWidth
              type="number"
              id="start_high_price"
              label="Start High Price"
              name="start_high_price"
              disabled={loading}
              value={dp.start_high_price}
              onChange={updatePrice(idx, 'start_high_price')}
              helperText={priceHelperText(dp.start_high_price)}
            />
        </Grid>
        <Grid item xs={3}>
          <TextField
              key={`low_price_rate-${idx}`}
              fullWidth
              type="number"
              id="low_price_rate"
              label="Low Price Rate"
              name="low_price_rate"
              disabled={loading}
              value={dp.low_price_rate}
              onChange={updatePrice(idx, 'low_price_rate')}
              helperText={priceHelperText(dp.low_price_rate)}
            />
        </Grid>
        <Grid item xs={3}>
          <TextField
              key={`high_price_rate-${idx}`}
              fullWidth
              type="number"
              id="high_price_rate"
              label="High Price Rate"
              name="high_price_rate"
              disabled={loading}
              value={dp.high_price_rate}
              onChange={updatePrice(idx, 'high_price_rate')}
              helperText={priceHelperText(dp.high_price_rate)}
            />
        </Grid>
      </Grid>
    )
  });

  return (
    <>
      <Box component="form" onSubmit={checkBeforeSubmit}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Default Pricing Configuration
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {pricingNodes}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Change price of all services
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  key={`percent-increase`}
                  fullWidth
                  type="number"
                  id="percent-inc"
                  label="Percent Increase"
                  name="percent-inc"
                  disabled={loading}
                  value={percInc}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  inputProps={{ min: 0 }}
                  onChange={updatePercIncrease}
                  error={Boolean(error.percentInc)}
                  helperText={error.percentInc}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  key={`percent-decrease`}
                  fullWidth
                  type="number"
                  id="percent-dec"
                  label="Percent Decrease"
                  name="percent-dec"
                  disabled={loading}
                  value={percDec}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  inputProps={{ min: 0 }}
                  onChange={updatePercDecrease}
                  error={Boolean(error.percentDec)}
                  helperText={error.percentDec}
                />
              </Grid>
              <Grid item xs>
                <Dialog open={incDialog} onClose={handleIncDialog}>
                  <DialogTitle>
                    {"Price Change"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {"Default pricing and all existing services will change"}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="outlined" onClick={handleIncDialog}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={increaseThenSubmit}
                      autoFocus
                    >
                      Proceed
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ControlBar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="right">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  Update
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ControlBar>
      </Box>
    </>
  );
}

export default ServiceSettings;
