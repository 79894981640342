import React from 'react';
import log from 'loglevel';
import * as supabase from '../../api/supabase';

type UseDataParams = {
  name: string;
  sqlQuery: string;
}

function useData(params: UseDataParams) {
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState<any[]>([]);

  const { name, sqlQuery } = params;

  const fetch = React.useCallback(async () : Promise<any[]> => {
    const client = await supabase.getClient();

    log.debug('SQL', { name, sqlQuery: sqlQuery.trim() });
    const result = await client.rpc('dashboard', { expression: sqlQuery });

    if (result.error) {
      log.error(`useData fetch ${name} error: `, result.error);
      return [];
    }

    log.debug(`useData fetch ${name} result: `, result.data);
    setRecords(result.data);

    return result.data;
  }, [name, sqlQuery]);

  const refresh = React.useCallback(async () => {
    setLoading(true);
    // setRecords(() => []); // clear
    const data = await fetch()
      .then((d: any[]) => {
        setRecords(d);
        return d;
      })
      .finally(() => setLoading(false));

    return data || [];
  }, [fetch]);

  return {
    loading,
    records,
    refresh,
  }
}

export default useData;
export type { UseDataParams }
