import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as supabase from '../../api/supabase';
import { GetFunctionParams } from '../../types/datastore';

const dataset = 'settings';
const dbTable = 'settings';

enum SettingsKey {
  BUSINESS_NAME = 'businessName',
  ADDRESS = 'address',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
  PHONE = 'phone',
  PROPOSAL_EMAIL = 'email.proposalEmail', // deprecated starting version 0.38
  WALKTHROUGH_EMAIL = 'email.walkthroughEmail',
  PROPOSALS = 'proposals',
  SERVICES = 'services',
  PROSPECTS = 'prospects',
}

function SettingsSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      key: yup.string().required().oneOf(Object.values(SettingsKey)),
      value: yup.string().optional().nullable(),
      data: yup.object().optional().nullable(),
    });
}

type SettingsModel = {
  key: SettingsKey;
  value?: string;
  data?: object;
};

enum ActionType {
  UPSERT = 'UPSERT',
  ERROR = 'ERROR',
}

// enum RecordStatus {
//   LOADING = 'LOADING',
//   OK = 'OK',
//   ERROR = 'ERROR',
// }

function getInitial() {
  const records: SettingsModel[] = [];
  return { records, error: null };
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  const updateFn = async (records: any[]) => {
    dispatch({
      type: ActionType.UPSERT,
      dataset,
      payload: { records },
    });
    const result = await yup
      .array()
      .of(SettingsSchema())
      .validate(records, { abortEarly: false })
      .then(async (valid: any) => {
        log.debug({ valid });
        const client = await supabase.getClient();
        return client
          .from(dbTable)
          .upsert(valid, { onConflict: 'key' })
          .select();
      })
      .catch((err) => ({ data: null, error: err }));
    dispatch({
      type: ActionType.UPSERT,
      dataset,
      payload: { records: result.data },
    });
  };

  const refreshFn = async () => {
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();
    const result = await client.from(dbTable).select();
    if (result.error) {
      log.error(result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
    }
    log.debug(dataset, result.data);
    dispatch({
      type: ActionType.UPSERT,
      dataset,
      payload: { records: result.data },
    });

    return result.data;
  };

  const getKey = async (key: SettingsKey): Promise<SettingsModel> => {
    let existing = state[dataset].records.find(
      (x: SettingsModel) => x.key === key
    );
    if (existing) return existing;

    const data = await refreshFn();

    // look again
    existing = data?.find((x: SettingsModel) => x.key === key);
    if (!existing) log.warn(`Missing configuration: ${key}`);

    return existing;
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    getState: () => state[dataset],
    update: updateFn,
    refresh: refreshFn,
    getKey,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.UPSERT) {
    const records: SettingsModel[] = action.payload.records;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: SettingsModel) => x.key === y.key
      );
      if (existing) {
        Object.assign(existing, x);
      } else {
        newState[dataset].records.push(x);
      }
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.ERROR) {
    newState[dataset].error = action.payload;
    return newState;
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
};

function BuildSettings(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, {
    [dataset]: getInitial(),
  });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({
      state,
      dispatch,
      datastore: datastore.current,
      subscribers,
    });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildSettings;
export { dataset, getInitial, getFunctions, reducer, ActionType, SettingsKey };

export type { SettingsModel };
