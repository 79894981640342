import jp from 'jsonpath';

const DOC_VAR_PATTERN = /\{\{[a-zA-Z0-9_.:*$,@?()\[\]]+\}\}/g;

/**
 * Document Variable to JsonPath
 */
const varToJsonPath = (s: string) =>
  String(s)
    .replace(/^\{\{/, '')
    .replace(/\}\}$/, '')
    .replace(/^(?:\$(\.{1,2}))?/, (m, p1) => '$' + (p1 || '.'));

function getVars(params: { template: string }) {
  const { template } = params;

  const list: string[] = [];
  const patterns = template.match(DOC_VAR_PATTERN);
  if (!patterns) return list;
  patterns.forEach((patt) => {
    if (list.includes(patt)) return;
    list.push(patt);
  });

  return list;
}

// https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string-in-javascript
function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function updateTemplate(params: { template: string; data: object | object[] }) {
  const { template, data } = params;

  const vars = getVars({ template });
  let updated = template;
  vars.forEach((v) => {
    const result = jp.query(data, varToJsonPath(v));
    let replacement = '';
    if (result.length > 0) replacement = result[0] || '';
    updated = updated.replace(new RegExp(escapeRegExp(v), 'g'), replacement);
  });

  return updated;
}

export default updateTemplate;
export { updateTemplate, varToJsonPath, getVars, DOC_VAR_PATTERN };
