import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  UpsertMode,
  ActionType,
  RecordStatus,
} from '../../types/datastore.d';

const dataset = 'prospects';
const vwDataset = 'vw_prospects';
const dbTable = 'prospects';

enum ProspectTitle {
  Mr = 'Mr',
  Ms = 'Ms',
  Mrs = 'Mrs',
  Dr = 'Dr',
};

function AddressComponentSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      long_name: yup.string().required(),
      short_name: yup.string().required(),
      types: yup.array().of(yup.string()).ensure(),
    });
}

function ProspectSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      updated_at: yup.string().required().default(new Date().toISOString()),
      title: yup.string().optional().oneOf(Object.values(ProspectTitle)),
      full_name: yup.string().required(),
      first_name: yup.string().required(),
      middle_name: yup.string().optional(),
      last_name: yup.string().required(),
      address: yup.string().required(),
      address_1: yup.string().optional(),
      address_2: yup.string().optional(),
      city: yup.string().required(),
      state: yup.string().min(2).max(2).required(),
      zip: yup
        .string()
        .required()
        .matches(
          /^[0-9]{5}(?:-[0-9]{4})?$/,
          // eslint-disable-next-line no-template-curly-in-string
          '${path} must be a valid US Zip Code'
        ),
      map: yup
        .object()
        .required()
        // .default(null)
        // .optional()
        // .nullable()
        .noUnknown()
        .shape({
          address_components: yup
            .array()
            .required()
            .of(AddressComponentSchema()),
          zoom: yup.number().default(20).required(),
          center: yup.object().required().shape({
            lat: yup.number().required(),
            lng: yup.number().required(),
          }),
        }),
      primary_phone: yup.string().nullable().optional(),
      send_sms: yup.boolean().required().default(false),
      phone_2: yup.string().nullable().optional(),
      send_sms_2: yup.boolean().required().default(false),
      email: yup.string().email().required(),
      email_cc: yup
        .array()
        .of(
          yup
            .string()
            // eslint-disable-next-line no-template-curly-in-string
            .email('"${originalValue}" must be a valid email address')
        )
        .optional()
        .nullable(),
      email_bcc: yup
        .array()
        .of(
          yup
            .string()
            // eslint-disable-next-line no-template-curly-in-string
            .email('"${originalValue}" must be a valid email address')
        )
        .optional()
        .nullable(),
      assigned_to: yup.array().of(yup.string().email()).optional().nullable(),
      avatar_url: yup.string().nullable().optional(),
      enabled: yup.bool().default(true).required(),
      source_channel: yup.string().required(),
      source_channel_details: yup.string().optional().nullable(),
    });
}

type MapOverlay = {
  id: string;
  type: string;
  area_sqft: number;
  area_sqm: number;
  info: any;
};

type AddressComponentModel = {
  long_name: string;
  short_name: string;
  types: string[];
};

type ProspectRecord = {
  id: string;
  created_at: string;
  full_name: string;
  address: string;
  primary_phone?: string;
  send_sms: boolean;
  email: string;
  email_cc?: string[];
  email_bcc?: string[];
  owned_by: string;
  assigned_to?: string;
  enabled: boolean;
  map: {
    area: number;
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
    overlays: MapOverlay[];
    address_components: {
      types: string[];
      long_name: string;
      short_name: string;
    }[];
  };
  updated_at: string;
  avatar_url?: string;
  first_name?: string;
  middle_nam?: string;
  last_name?: string;
  title?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  address_1?: string;
  phone_2?: string;
  send_sms_2?: boolean;
  source_channel?: string;
  source_channel_details?: string;
};

// deprecated
type ProspectModel = ProspectRecord;

type ProspectViewRecord = ProspectRecord & {
  search_column?: string;
  $refreshedAt?: number;
  $status?: RecordStatus;
  $error?: any;
}

function getInitial() {
  const records: ProspectViewRecord[] = [];
  return { records, error: null };
}

function isExpired(record: { $refreshedAt?: number }) {
  const now = Date.now();
  const expiryMs = 1000 * 60 * 10; // 5 minutes
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, subscribers } = params;

  // initialize
  const state = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      ilike,
      limit = 300,
      custom,
      type = ActionType.REFRESH,
    } = opts || {};
    log.debug(`fetching ${vwDataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (ilike) query.ilike(ilike.column, ilike.pattern);
    if (limit) query.limit(limit);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload: ProspectViewRecord[] = result.data.map((x) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: { id: string }) => {
    const { id } = opts || {};

    const existing = state[dataset].records.find((x) => x.id === id);
    if (existing && !isExpired(existing)) return existing;

    const data = await refreshFn({ match: { id }, type: ActionType.UPSERT });
    return data ? data[0] : undefined;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: { records: Partial<ProspectRecord>[]; error: any }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: Partial<ProspectRecord>,
      opts?: {
        pick?: (keyof ProspectRecord)[];
        onConflict?: keyof ProspectRecord;
        match?: Partial<ProspectRecord>
      }
    ) => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = ProspectSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: Partial<ProspectViewRecord> = await schema
        .validate(record, { abortEarly: false })
        .then((result: any) => {
          return result as Partial<ProspectViewRecord>;
        })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      const client = await supabase.getClient();
      if (mode === UpsertMode.UPSERT) {
        const { onConflict = 'id' } = opts || {};
        const result: any = await client
          .from(dbTable)
          .upsert([valid], { onConflict })
          .select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await client.from(dbTable).insert([valid]).select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const { match = { id: valid.id } } = opts || {};
        const result: any = await client
          .from(dbTable)
          .update(valid)
          .match(match)
          .select();
        respond({ records: result?.data, error: result.error });
      }

      if (!valid.id) return;
      return getFn({ id: valid.id });
    };
  };

  const currSearch: any = { searchStr: '', timeout: null };
  const searchFn = async (params: any) => {
    const { searchStr = '' } = params;

    if (searchStr.length < 1) return;

    // wait for timeout to finish
    if (currSearch.timeout) {
      currSearch.searchStr = searchStr;
      return;
    }

    currSearch.timeout = new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 1000);
    });
    await currSearch.timeout;
    currSearch.timeout = null;

    return refreshFn({
      ilike: { column: 'full_name', pattern: `*${currSearch.searchStr}*` },
      type: ActionType.UPSERT,
    });
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getState: () => state[dataset],
    create: getUpsertFn(UpsertMode.CREATE_ONLY),
    update: getUpsertFn(UpsertMode.UPDATE_ONLY),
    delete: async (payload: any) => {
      const deleteFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      return deleteFn(
        { id: payload.id, enabled: false },
        { pick: ['id', 'enabled'] }
      );
    },
    refresh: refreshFn,
    search: searchFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: ProspectModel[] = action.payload;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: any) => x.id === y.id
      );
      if (existing) Object.assign(existing, x);
      else newState[dataset].records.push(x);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.REFRESH) {
    newState[dataset].records = action.payload;
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.ERROR) {
    newState[dataset].error = action.payload;
    return newState;
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildProspects(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ state, dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildProspects;
export {
  dataset,
  getInitial,
  getFunctions,
  reducer,
  ActionType,
  AddressComponentSchema,
  ProspectTitle,
};

export type { ProspectModel, ProspectRecord, ProspectViewRecord, AddressComponentModel };
