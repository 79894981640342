import React from 'react';
// import { v4 as uuidv4 } from 'uuid';
// import log from 'loglevel';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';

import UploadButton, { FileCategory, UploadFileObject } from '../../comp/UploadButton';
import ErrorHandler from '../../comp/ErrorHandler';

type Attachment = {
  path: string;
  filename?: string;
};

type ViewAttachmentsProp = {
  attachments?: {
    records: Attachment[];
  };
  onChange?: (attachments: { records: Attachment[] }) => void;
};

function ViewAttachments(props: ViewAttachmentsProp) {
  const { attachments, onChange } = props;
  const [records, setRecords] = React.useState<Attachment[]>(attachments?.records || []);
  const [error, setError] = React.useState<any>();

  const onUpload = (x: UploadFileObject) => {
    if (x.error) {
      setError(error);
      return;
    }
    const newRecords: Attachment[] = [
      ...records,
      {
        filename: x.filename,
        path: x.urlPath || '',
      }
    ];
    setRecords(newRecords);
    if (typeof onChange === 'function') {
      onChange({ records: newRecords });
    }
  }

  const onDelete: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    const newRecords: Attachment[] = records.filter((x) => x.path !== e.currentTarget.id);
    setRecords(newRecords);
    if (typeof onChange === 'function') {
      onChange({ records: newRecords });
    }
  }

  React.useEffect(() => {
    setRecords(attachments?.records || []);
  },[attachments])

  return (
    <Box>
      <Box>
        {records.map((n) => {
          return (
            <Box key={n.path}>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flexGrow: 1 }}>{n.filename}</Typography>
                <IconButton id={n.path} onClick={onDelete} size="small">
                  <DeleteIcon />
                </IconButton>
              </Stack>
              <Divider sx={{ mt: '10px', mb: '10px' }} />
            </Box>
          );
        })}
        <UploadButton
          category={FileCategory.DOCS}
          onUpload={onUpload}
          buttonProps={{
            startIcon: <AttachmentIcon />,
            size: 'small',
            variant: 'text',
          }}
        >Add Attachments</UploadButton>
      </Box>
      <ErrorHandler error={error} />
    </Box>
  );
}

export default ViewAttachments;
