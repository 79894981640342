import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import ReactGA from "react-ga4";
import * as yup from 'yup';

import { useDataStore } from '../../comp/DataStore';
import type { Proposal } from './getProposalModel';
import * as irrigationApi from '../../api/irrigationApi';
import { SettingsKey, SettingsModel } from '../../comp/DataStore/Settings';
import { ProposalSettingsData } from '../Settings/Proposals';
import type { DownloadDocParams } from '../../api/irrigationApi';
import { deltaToHtml } from '../../comp/Quill';
import { MessageType } from '../../comp/DataStore/Messages';
import updateTemplate from '../../api/templateHelper';

function getFileName(data: Proposal) {
  // TODO: Replace Teed & Brown from settings
  return [
    'Teed & Brown - ',
    data.prospect.last_name,
    ', ',
    data.prospect.first_name,
    ' ',
    data.year,
    '.pdf',
  ].join('');
}

function MailParamsSchema() {
  const emailAddress = () => yup.object().noUnknown().shape({
    name: yup.string().optional(),
    address: yup.string().email().required(),
  });

  const mailAttachmentSchema = () => yup.object().noUnknown().shape({
    filename: yup.string().required(),
    app: yup.string().required(),
  });

  return yup.object().noUnknown().shape({
    to: yup.array().of(emailAddress()).ensure().required().min(1),
    cc: yup.array().of(emailAddress()).ensure().required().min(1),
    bcc: yup.array().of(emailAddress()).ensure().optional(),
    replyTo: emailAddress().required(),
    subject: yup.string().required(),
    htmlTemplate: yup.string().required(),
    templateData: yup.object().required(),
    attachments: yup.array().min(1).of(mailAttachmentSchema())
  });
}

type MultiProposals = {
  proposals: Proposal[],
};

export type MailAttachment = {
  filename: string,
  app: string,
};

export type EmailAddress = {
  name?: string,
  address: string,
};

export type MailParams = {
  to: EmailAddress[],
  cc: EmailAddress[],
  bcc: EmailAddress[],
  replyTo?: {
    name?: string,
    address: string,
  },
  subject: string, // emailTmpl.data?.subject,
  htmlTemplate: string,
  templateData: any,
  attachments?: MailAttachment[],
};

function usePdfFn() {
  const datastore = useDataStore();
  const { REACT_APP_IRRIGATION_BASE_URL: baseUrl } = process.env;

  const initAuthState = datastore.auth.getState();
  const [proposalSettings, setProposalSettings] = React
    .useState<Partial<SettingsModel>>({});
  const [authState, setAuthState] = React.useState<any>(initAuthState);
  const [loading, setLoading] = React.useState(false);
  const [parentProposal, setParentProposal] = React.useState<Proposal>();
  // const [sendMailParams, setSendMailParams] = React.useState<any>(undefined);

  const refreshProposalSettings = React.useCallback(async () => {
    if (proposalSettings.key === SettingsKey.PROPOSALS) {
      return proposalSettings;
    }

    return datastore.settings.getKey(SettingsKey.PROPOSALS)
      .then((result: SettingsModel) => {
        if (!result) {
          setProposalSettings({ key: SettingsKey.PROPOSALS });
          throw new Error(`Cannot get proposal settings: ${SettingsKey.PROPOSALS}`);
        }
        setProposalSettings(result);
        return result;
      })
      .catch((err: any) => {
        setProposalSettings({ key: SettingsKey.PROPOSALS });
        throw new Error(`${err.message}: ${SettingsKey.PROPOSALS}`);
      })
  }, [proposalSettings, datastore.settings]);

  const getProposalSettings = React.useCallback(async (params: MultiProposals) => {
    // CHECK for proposal settings
    const value: any = await refreshProposalSettings();
    const typeSettings = value.data as ProposalSettingsData;

    // CHECK for parent proposal
    const parent = params.proposals.find((x) => !x.parent_id);
    if (!parent) {
      throw new Error(`Cannot find parent proposal`);
    }
    setParentProposal(parent);
  
    const pTypeSettings = typeSettings.proposalType.find((x) => x.type === parent.typeKey?.value);
    if (!pTypeSettings) {
      throw new Error(`Cannot get proposal settings: ${parent.typeKey?.label}`);
    }

    const acceptButtonUrl = updateTemplate({
      template: typeSettings.acceptButtonUrl,
      data: parent,
    });

    return {
      acceptButtonUrl,
      proposalType: pTypeSettings
    };
  }, [refreshProposalSettings]);

  /**
   * 
   */
  const createPdf = React.useCallback(async (data: Proposal) => {
    const value: any = (await refreshProposalSettings()).data;
    const typeSettings = value as ProposalSettingsData;
    
    const settings = typeSettings.proposalType.find((x) => x.type === data.typeKey?.value);
    if (!settings) {
      throw new Error(`Cannot get proposal settings: ${data.typeKey?.label}`);
    }

    const result = await irrigationApi.createPdf({
      data,
      imageUrl: { base: baseUrl },
      gTemplateId: settings.gDocTemplate,
      gFolderId: settings.gFolderId,
    })
      .then((r) => ({
        file: r.file,
        name: getFileName(data),
      }));

    await datastore.proposals.update(
      { id: data.id, file: result.file },
      { pick: ['id', 'file', 'updated_at'] }
    );

    return result;
  }, [baseUrl, datastore.proposals, refreshProposalSettings]);

  /**
   * 
   */
  const downloadPdf = React.useCallback(async (p: DownloadDocParams) => {
    return irrigationApi.downloadDoc(p).then(() => p);
  }, []);

  /**
   * Refresh Email Parameters
   */
  const refreshMailParams = React.useCallback(async (params: MultiProposals) => {
    // CHECK for proposal settings
    const value: any = await refreshProposalSettings();
    const typeSettings = value.data as ProposalSettingsData;

    // CHECK for parent proposal
    const parent = params.proposals.find((x) => !x.parent_id);
    if (!parent) {
      throw new Error(`Cannot find parent proposal`);
    }
    setParentProposal(parent);
  
    const pTypeSettings = typeSettings.proposalType.find((x) => x.type === parent.typeKey?.value);
    if (!pTypeSettings) {
      throw new Error(`Cannot get proposal settings: ${parent.typeKey?.label}`);
    }

    const attachments: MailAttachment[] = await Promise.all(params.proposals.map(async (p) => {
      if (p.file && p.file.length > 0) {
        return {
          filename: getFileName(p),
          app: p.file,
        };
      }

      log.debug('proposal does not have a file ready: ', p.year);
      return createPdf(p)
        .then((response) => ({
          filename: getFileName(p),
          app: response.file,
        }));
    }));

    const addAttachments: MailAttachment[] = (parent.attachments?.records || []).map((a) => ({
      filename: a.filename || '',
      app: a.path,
    })) || [];

    const htmlTemplate = deltaToHtml(pTypeSettings.emailBody);

    if (!parent.prospect.email) {
      throw new Error(`Invalid prospect email`);
    }

    if (!parent.assigned_to.email) {
      throw new Error('Invalid assigned to email');
    }

    const mailParams: MailParams = {
      to: [
        {
          name: parent.prospect.full_name,
          address: parent.prospect.email,
        },
      ],
      cc: (pTypeSettings.cc?.map((x) => ({ address: x }))) || [],
      bcc: (pTypeSettings.bcc?.map((x) => ({ address: x }))) || [],
      replyTo: {
        name: parent.assigned_to.full_name,
        address: parent.assigned_to.email,
      },
      subject: pTypeSettings.emailSubject, // emailTmpl.data?.subject,
      htmlTemplate,
      templateData: parent,
      attachments: [
        ...attachments,
        ...addAttachments,
      ],
    };

    // custom function
    const pushEmailIfExist = (e: Partial<EmailAddress>, where: EmailAddress[]) => {
      if (!e || !e.address) return;
      const existing = where.find((x) => x.address === e.address);
      if (!existing) {
        where.push(e as EmailAddress);
      }
    }

    pushEmailIfExist({
      name: parent.assigned_to.full_name,
      address: parent.assigned_to.email,
    }, mailParams.cc);

    pushEmailIfExist({
      name: parent.prepared_by.full_name,
      address: parent.prepared_by.email,
    }, mailParams.cc);

    if (parent.records.prospect.email_cc) {
      parent.records.prospect.email_cc.forEach((address) => {
        pushEmailIfExist({ address }, mailParams.cc);
      });
    }

    if (parent.records.prospect.email_bcc) {
      parent.records.prospect.email_bcc.forEach((address) => {
        pushEmailIfExist({ address }, mailParams.bcc);
      });
    }

    if (!attachments || attachments.length === 0) {
      throw new Error('Unable to generate attachments');
    }

    log.debug('email params', mailParams);
    // setSendMailParams(mailParams);

    return mailParams;
  }, [createPdf, refreshProposalSettings]);

  /**
   * 
   */
  const sendProposals = React.useCallback(async(params: MultiProposals) => {
    // const value: any = proposalSettings.data;
    // const typeSettings = value as ProposalSettingsData;
    const parent = params.proposals.find((x) => !x.parent_id);
    if (!parent) {
      throw new Error(`Cannot find parent proposal`);
    }
  
    const mailParams = await refreshMailParams(params);

    if (!mailParams) {
      throw new Error('Unable to generate mail parameters');
    }

    const result = await irrigationApi.sendEmail(mailParams);

    ReactGA.event({
      category: "proposals",
      action: "send_proposal",
      value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });

    // log notification
    datastore.messages.create({
      id: uuidv4(),
      created_at: new Date().toISOString(),
      created_by: authState.user?.email,
      message_type: MessageType.ATTACHMENTS,
      assigned_to: parent.assigned_to.email,
      proposal_id: parent.id,
      prospect_id: parent.prospect.id,
      data: {
        message: 'Sent proposals to prospect',
        attachments: mailParams.attachments?.map((a: any) => ({
          filename: a.filename,
          url: a.app,
        })),
      },
    });

    log.info('*** EMAIL RESULT ***', result);
    return result;
  }, [datastore.messages, authState, refreshMailParams]);

  /**
   * 
   */
  const sendMail = React.useCallback(async (params: MailParams) => {
    if (!parentProposal) {
      throw new Error(`Parent proposal not set`);
    }

    const mailParams = await MailParamsSchema().validate(params);
    log.debug({ mailParams });

    const result = await irrigationApi.sendEmail(mailParams);

    ReactGA.event({
      category: "proposals",
      action: "send_proposal",
      value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });

    // log notification
    datastore.messages.create({
      id: uuidv4(),
      created_at: new Date().toISOString(),
      created_by: authState.user?.email,
      message_type: MessageType.ATTACHMENTS,
      assigned_to: parentProposal.assigned_to.email,
      proposal_id: parentProposal.id,
      prospect_id: parentProposal.prospect.id,
      data: {
        message: 'Sent proposals to prospect',
        attachments: mailParams.attachments?.map((a: any) => ({
          filename: a.filename,
          url: a.app,
        })),
      },
    });

    log.info('*** EMAIL RESULT ***', result);
    return result;
  },[authState.user, datastore.messages, parentProposal]);

  /**
   * 
   */
  React.useEffect(() => {
    if (loading) return;
    setLoading(true);
    if (proposalSettings.key === SettingsKey.PROPOSALS) {
      return;
    }
    refreshProposalSettings()
      .finally(() => {
        setLoading(false);
      });
  }, [refreshProposalSettings, proposalSettings.key, datastore.settings, loading]);

  /**
   * 
   */
  React.useEffect(() => {
    const authId = datastore.auth.subscribe(setAuthState);
    return () => {
      datastore.auth.unsubscribe(authId);
    }
  }, [datastore]);

  return {
    refreshProposalSettings,
    getProposalSettings,
    createPdf,
    downloadPdf,
    refreshMailParams,
    sendProposals,
    sendMail,
  };
}

// type PreviewPdfProps = {
//   buttonProps: LoadingButtonProps,
//   data: Proposal,
//   children?: React.ReactNode | string | number;
// };

// function PreviewPdf(props: PreviewPdfProps) {
//   const { buttonProps, data, children } = props;
//   const { REACT_APP_IRRIGATION_BASE_URL: baseUrl } = process.env;

//   const datastore: any = useDataStore();
//   const [loadingPdf, setLoadingPdf] = React.useState(false);

//   const filename = getFileName(data);

//   const generatePdf = React.useCallback(
//     (p: any) => {
//       return async (generatePdfParams: any) => {
//         const { download = true } = generatePdfParams || {};
//         setLoadingPdf(true);
//         log.debug('using pdf data', p);
//         let filename;
//         await irrigationApi.createPdf({
//           data: p,
//           imageUrl: { base: baseUrl },
//         })
//           .then(({ file }) => {
//             log.debug('filename', file);
//             filename = file;
//             if (download) {
//               irrigationApi.downloadDoc({
//                 file,
//                 name: filename,
//               });
//             }
//             return datastore.proposals.update(
//               { id: p.id, file },
//               { pick: ['id', 'file', 'updated_at'] }
//             );
//           })
//           .finally(() => setLoadingPdf(false));

//         return { file: filename };
//       };
//     },
//     [baseUrl, datastore.proposals]
//   );

//   return (
//     <LoadingButton
//       {...buttonProps}
//       disabled={buttonProps.disabled || loadingPdf}
//       loading={loadingPdf}
//       loadingPosition="start"
//       startIcon={<DownloadIcon />}
//       variant="outlined"
//       onClick={generatePdf(data)}
//     >
//       {children}
//     </LoadingButton>
//   );
// }

export default usePdfFn;
export { usePdfFn, getFileName };
