import React from 'react';
import log from 'loglevel';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import settings from '../../settings';

interface GetDirectionsProps {
  open?: boolean;
  from?: string;
  to: string;
  onClose?: () => void;
}

async function openGmapTab(params: { from?: string; to: string }) {
  const { from, to } = params;
  let fromEncoded = encodeURIComponent(
    from || `${settings.maps.center.lat},${settings.maps.center.lng}`
  );
  const toEncoded = encodeURIComponent(to);

  if (!from && 'geolocation' in navigator) {
    await new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          fromEncoded = encodeURIComponent(
            `${position.coords.latitude},${position.coords.longitude}`
          );
          resolve(null);
        },
        (err) => {
          log.error(err);
          resolve(null); // do not do anything
        }
      );
    });
  } else {
    // TODO: Should prompt user
  }

  const url = `https://www.google.com/maps/dir/${fromEncoded}/${toEncoded}`;
  window.open(url, '_blank', 'noopener,noreferrer');
}

function GetDirections(props: GetDirectionsProps) {
  const { open = false, onClose } = props;

  // const from = '33+Maiden+Lane,+New+York,+NY+10038,+USA';
  // const to = '100+Raton+Dr,+Milford,+CT+06461';
  // const others =
  //   '@40.9692994,-73.8282235,10z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x89c25a178b6679ad:0xe046c92604bdecc3!2m2!1d-74.0085099!2d40.7091273!1m5!1m1!1s0x89e80b497c3a3ceb:0xf01a81cfbd1475c4!2m2!1d-73.0938923!2d41.2313495';
  // const url = `https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d771297.3709293051!2d-74.10846368826986!3d40.962199807196434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x89c25a178b6679ad%3A0xe046c92604bdecc3!2s33%20Maiden%20Lane%2C%20New%20York%2C%20NY%2010038%2C%20USA!3m2!1d40.7091273!2d-74.00850989999999!4m5!1s0x89e80b497c3a3ceb%3A0xf01a81cfbd1475c4!2s100%20Raton%20Dr%2C%20Milford%2C%20CT%2006461!3m2!1d41.2313495!2d-73.0938923!5e0!3m2!1sen!2sph!4v1658278126141!5m2!1sen!2sph`;
  // const url =
  // 'https://www.google.com/maps/embed?pb=knjmEnjunUbKCfEA?_@]@kMBeE@qIIoF@wH@eFFk@WOUI_@?u@j@k@`@EXLTZHh@Y`AgApAaCrCUd@cDpDuAtAoApA{YlZiBdBaIhGkFrDeCtBuFxFmIdJmOjPaChDeBlDiAdD}ApGcDxU}@hEmAxD}[tt@yNb\\yBdEqFnJqB~DeFxMgK~VsMr[uKzVoCxEsEtG}BzCkHhKWh@]t@{AxEcClLkCjLi@`CwBfHaEzJuBdEyEhIaBnCiF|K_Oz\\{MdZwAbDaKbUiB|CgCnDkDbEiE|FqBlDsLdXqQra@kX|m@aF|KcHtLm@pAaE~JcTxh@w\\`v@gQv`@}F`MqK`PeGzIyGfJiG~GeLhLgIpIcE~FsDrHcFfLqDzH{CxEwAbBgC|B}F|DiQzKsbBdeA{k@~\\oc@bWoKjGaEzCoEzEwDxFsUh^wJfOySx[uBnCgCbCoFlDmDvAiCr@eRzDuNxC_EvAiFpCaC|AqGpEwHzFoQnQoTrTqBlCyDnGmCfEmDpDyGzGsIzHuZzYwBpBsC`CqBlAsBbAqCxAoBrAqDdDcNfMgHbHiPtReBtCkD|GqAhBwBzBsG~FoAhAaCbDeBvD_BlEyM``@uBvKiA~DmAlCkA|B}@lBcChHoJnXcB`GoAnIS~CIjFDd]A|QMlD{@jH[vAk@`CoGxRgPzf@aBbHoB~HeMx^eDtJ}BnG{DhJU`@mBzCoCjDaAx@mAnAgCnBmAp@uAj@{Cr@wBPkB@kBSsEW{GV}BEeCWyAWwHs@qH?cIHkDXuDn@mCt@mE`BsH|CyAp@}AdAaAtAy@lBg@pCa@jE]fEcBhRq@pJKlCk@hLFrB@lD_@xCeA`DoBxDaHvM_FzImDzFeCpDeC|CkExDiJrHcBtAkDpDwObVuCpFeCdHoIl\\uBjIuClJsEvMyDbMqAhEoDlJ{C|J}FlZuBfLyDlXwB~QkArG_AnDiAxC{G|OgEdLaE`LkBbEwG~KgHnLoEjGgDxCaC`BuJdFkFtCgCnBuClD_HdMqEzHcBpB_C|BuEzCmPlIuE|B_EtDeBhCgAdCw@rCi@|DSfECrCAdCS~Di@jDYhA_AlC{AxCcL`U{GvM_DjFkBzBsB`BqDhBaEfAsTvEmEr@iCr@qDrAiFnCcEzCaE~D_@JmFdGQDwBvCeErEoD|BcFjC}DbEuD~D`@Zr@h@?d@Wr@}@vAgCbEaHfMqA`Cy@dAg@bAO`@gCi@w@W';
  // https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d771297.3709293051!2d-74.10846368826986!3d40.962199807196434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x89c25a178b6679ad%3A0xe046c92604bdecc3!2s33%20Maiden%20Lane%2C%20New%20York%2C%20NY%2010038%2C%20USA!3m2!1d40.7091273!2d-74.00850989999999!4m5!1s0x89e80b497c3a3ceb%3A0xf01a81cfbd1475c4!2s100%20Raton%20Dr%2C%20Milford%2C%20CT%2006461!3m2!1d41.2313495!2d-73.0938923!5e0!3m2!1sen!2sph!4v1658278126141!5m2!1sen!2sph

  /*
<iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d771297.3709293051!2d-74.10846368826986!3d40.962199807196434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x89c25a178b6679ad%3A0xe046c92604bdecc3!2s33%20Maiden%20Lane%2C%20New%20York%2C%20NY%2010038%2C%20USA!3m2!1d40.7091273!2d-74.00850989999999!4m5!1s0x89e80b497c3a3ceb%3A0xf01a81cfbd1475c4!2s100%20Raton%20Dr%2C%20Milford%2C%20CT%2006461!3m2!1d41.2313495!2d-73.0938923!5e0!3m2!1sen!2sph!4v1658278126141!5m2!1sen!2sph" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  */
  // `https://www.google.com/maps/dir/${from}/${to}/${others}`;
  // 'https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=11&ie=UTF8&iwloc=&output=embed';
  // frameborder="0" marginheight="0" scrolling="no"  marginwidth="0"
  const url = '';

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth>
      <Box sx={{ width: '100%', height: '90vh' }}>
        <iframe
          title="gmap-directions"
          width="100%"
          height="1000"
          id="gmap_canvas"
          src={url}
          // style="border:0;"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Dialog>
  );
}

export default GetDirections;
export { openGmapTab };
