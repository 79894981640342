import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

type Props = {
  children?: React.ReactNode | string | number;
};

function Content(props: Props) {
  const { children } = props;

  return (
    <Paper
      sx={{
        margin: 'auto',
        boxShadow: 'none',
      }}
    >
      <Box sx={{ px: 2, py: 2 }}>{children}</Box>
    </Paper>
  );
}

export default Content;
