import React from 'react';
import log from 'loglevel';

import BuildAuth from './Auth';
import BuildUsers, * as users from './Users';
import BuildServices, * as services from './Services';
import BuildPrograms, * as programs from './Programs';
import BuildProspects, * as prospects from './Prospects';
import BuildAppointments from './Appointments';
import BuildSettings from './Settings';
import BuildProposals, * as proposals from './Proposals';
import BuildNotifications from './Notifications';
import BuildMessages from './Messages';
import BuildTaxRate, * as taxRate from './TaxRate';
import BuildSw from './Sw';
import BuildProposalCalls from './ProposalCalls';

import { DatastoreKey } from '../../types/datastore.d';

type DataContext = {
  [DatastoreKey.PROPOSALS]: ReturnType<typeof proposals.getFunctions>;
  [DatastoreKey.SERVICES]: ReturnType<typeof services.getFunctions>;
  [DatastoreKey.USERS]: ReturnType<typeof users.getFunctions>;
  [DatastoreKey.PROSPECTS]: ReturnType<typeof prospects.getFunctions>;
  [DatastoreKey.TAX_RATE]: ReturnType<typeof taxRate.getFunctions>;
  [DatastoreKey.PROGRAMS]: ReturnType<typeof programs.getFunctions>;
  [key: string]: any;
};

const data: any = {};
const DataStore = React.createContext(data);

type WaitForDataStoreProps = {
  datastore: React.MutableRefObject<any>;
  children?: React.ReactNode;
  reload?: number;
};

function WaitForDataStore(props: WaitForDataStoreProps) {
  const { datastore, children, reload } = props;

  const keys = React.useMemo(() => Object.values(DatastoreKey), []);

  const isComplete = React.useMemo(() => {
    let complete = true;
    keys.forEach((key) => {
      const exists = datastore.current[key] !== undefined;
      if (!exists) {
        log.debug(`Waiting for ${key} to load...`);
      }
      complete = complete && exists;
    });
    log.debug(`Reloading ${reload}...`);

    return complete;
  }, [datastore, keys, reload]);

  return isComplete ? <>{children}</> : <></>;
}

type DataStoreProviderProps = {
  children?: React.ReactNode | string | number;
};

/**
 *
 */
function DataStoreProvider(props: DataStoreProviderProps) {
  const datastore = React.useRef<any>({});

  const [reloadCount, setReloadCount] = React.useState<number>(0);

  const reload = () => {
    setReloadCount((curr) => (curr += 1));
  };

  return (
    <>
      <BuildAuth datastore={datastore} reload={reload}>
        <BuildUsers datastore={datastore} reload={reload} />
        <BuildNotifications datastore={datastore} reload={reload} />
        <BuildAppointments datastore={datastore} reload={reload} />
        <BuildProposals datastore={datastore} reload={reload} />
        <BuildMessages datastore={datastore} reload={reload} />
        <BuildServices datastore={datastore} reload={reload} />
        <BuildPrograms datastore={datastore} reload={reload} />
        <BuildProspects datastore={datastore} reload={reload} />
        <BuildSettings datastore={datastore} reload={reload} />
        <BuildProposalCalls datastore={datastore} reload={reload} />
      </BuildAuth>
      <BuildTaxRate datastore={datastore} reload={reload} />
      <BuildSw datastore={datastore} reload={reload} />
      <WaitForDataStore datastore={datastore} reload={reloadCount}>
        <DataStore.Provider value={datastore.current}>
          {props.children}
        </DataStore.Provider>
      </WaitForDataStore>
    </>
  );
}

/**
 *
 */
const useDataStore = () => {
  const context = React.useContext<DataContext>(DataStore);
  if (context === undefined) {
    throw new Error('useDataStore must be used within DataStore Context');
  }
  return context;
};

export default useDataStore;
export { useDataStore, DataStoreProvider };
export type { DataContext };
