import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

type Props = {
  fullPage?: boolean;
};

function Loading(props: Props) {
  const { fullPage = false } = props;

  if (fullPage) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        sx={{ height: '100vh' }}
      >
        <Grid item xs={1}>
          <CircularProgress size={80} />
        </Grid>
      </Grid>
    );
  }

  return <CircularProgress />;
}

export default Loading;
