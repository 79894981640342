import React from 'react';
import Grid from '@mui/material/Grid';

import { ProgramViewRecord } from '../../comp/DataStore/Programs';

type SelectedServicesProps = {
  services: ProgramViewRecord['services'];
  onClickService?: (prog: any) => void;
};

function SelectedServices(props: SelectedServicesProps) {
  const { services = [], onClickService } = props;

  const handleClickService: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    const key = event.currentTarget.getAttribute('data-service');
    const d = String(key || '').split(':');
    if (d[0] === 'custom' && onClickService) {
      const s = services.find((x) => x.id === d[1] && x.custom);
      onClickService(s);
    }
  };

  return (
    <Grid container rowSpacing={1}>
      {services.map((service) => {
        const key = `${service.custom ? 'custom:' : ''}${service.id}`;
        return (
          <React.Fragment key={key}>
            <Grid item xs={8}>
              <div onClick={handleClickService} data-service={key}>
                {service.name}
              </div>
            </Grid>
            <Grid item xs={4} textAlign='right'>
              <div onClick={handleClickService} data-service={key}>
                {service.custom?.quantity || 1}
                {service.custom?.uom}
              </div>
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

export default SelectedServices;
