import React from 'react';

import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  FilterTypes,
} from 'react-table';

type Props = {
  columns: any;
  data: any;
  defaultColumn?: object;
  filterTypes?: FilterTypes<object>;
  globalFilterValue?: string | number;
  initialState?: object;
};

function Table(props: Props) {
  const {
    columns,
    data,
    initialState = {},
    defaultColumn = {},
    filterTypes = {},
    globalFilterValue,
  } = props;

  const dcMemo = React.useMemo(() => defaultColumn, [defaultColumn]);
  const ftMemo = React.useMemo(() => filterTypes, [filterTypes]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    // state,
    // preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState,
      defaultColumn: dcMemo,
      filterTypes: ftMemo,
    },
    useFilters,
    useGlobalFilter
  );

  // watch out for changes
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  React.useEffect(() => {
    onChange(globalFilterValue);
  }, [globalFilterValue, onChange]);

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps()}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default Table;
