import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4';

import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import settings, { UserRoles } from '../../settings';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
// import { addUser, updateUser, deleteUser } from '../../api/irrigationApi';

function getRefresh(params: any) {
  const { datastore, setLoading, setData } = params;

  return async (id: string) => {
    setLoading(true);
    const record = await datastore.users.get({ id });
    setData({
      loaded: true,
      record,
    });
    setLoading(false);
  };
}

type Props = {
  newRecord?: boolean;
};

function EditUser(props: Props) {
  const datastore = useDataStore();
  const { newRecord = false } = props;

  const navigate = useNavigate();
  const { id } = useParams();

  const initUsers = datastore.users.getState();
  const [usersState, setUsersState] = React.useState<any>(initUsers);
  const existing = usersState.records.find((x: any) => x.id === id);

  const initial = {
    record: existing || {
      id,
      role: UserRoles.USER,
      salesperson: false,
    },
    loaded: false,
  };

  // Record state (selection)
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(initial);
  const [error, setError] = React.useState<any>(null);

  const refresh = getRefresh({ datastore, setLoading, setData });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formdata = new FormData(event.currentTarget);
    const email: string = newRecord ? formdata.get('email') : data.record.email;
    const record: any = {
      id: !newRecord ? id : `tmp-id-${uuidv4()}`,
      email: email.toLowerCase(),
      first_name: formdata.get('first_name'),
      last_name: formdata.get('last_name'),
      role: formdata.get('role'),
      phone: formdata.get('phone'),
      salesperson: data.record.salesperson,
      signature: formdata.get('signature'),
    };

    const password = formdata.get('password');
    if (password) record.password = password;
    log.debug('user record', record);

    const promise = newRecord
      ? datastore.users.create(record)
      : datastore.users.update(record);
    await promise
      .then((result: any) => {
        if (result.$error) {
          setError(result.$error);
        } else {
          navigate('/users');
        }
      })
      .catch((err: Error) => {
        ReactGA.event({
          category: 'error',
          action: 'submit user',
          label: err.message,
        });
        log.error(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    const inputValue = type === 'checkbox' ? checked : value;

    setData((curr: any) => ({
      ...curr,
      record: {
        ...curr.record,
        [name]: inputValue,
      },
    }));
  };

  const handleDelete = async () => {
    if (newRecord || !id) return;
    setLoading(true);
    const payload = { id };
    await datastore.users
      .delete(payload)
      .then(() => {
        navigate('/users');
      })
      .catch((err: Error) => {
        ReactGA.event({
          category: 'error',
          action: 'delete user',
          label: err.message,
        });
        log.error(err);
        setError(err);
      });
  };

  const handleSelectRole = (e: any) => {
    log.debug(e.target.value);
    setData((curr: any) => ({
      ...curr,
      record: { ...curr.record, role: e.target.value },
    }));
  };

  const handleFocus = () => {
    setError(null);
  };

  const errorObj = React.useMemo(() => {
    log.debug('updating error Object');
    const value: any = {};
    if (!error) return value;
    if (error.name === 'ValidationError') {
      (error.inner || []).forEach((innerErr: any) => {
        value[innerErr.path] = innerErr;
      });
      return value;
    }
    value._UnknownError = error;
    return value;
  }, [error]);

  React.useEffect(() => {
    if (newRecord) return;
    if (loading) return;
    if (data.loaded && data.record.id === id) {
      return;
    }
    refresh(String(id));
  }, [newRecord, loading, refresh, data.loaded, data.record.id, id]);

  React.useEffect(() => {
    const usersId = datastore.users.subscribe(setUsersState);
    return () => {
      datastore.users.unsubscribe(usersId);
    };
  }, [datastore]);

  return (
    <Paperbase
      title={newRecord ? 'New User' : 'Edit User'}
      bottomSpacer="100px"
    >
      <Container component="main">
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={9}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <TextField
                    key={`email-${data.record.id}`}
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    value={data.record.email}
                    // defaultValue={data.record.email}
                    disabled={!newRecord}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`first_name-${data.record.id}`}
                    fullWidth
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    value={data.record.first_name}
                    // defaultValue={data.record.first_name}
                    disabled={loading}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`last_name-${data.record.id}`}
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    name="last_name"
                    value={data.record.last_name}
                    // defaultValue={data.record.last_name}
                    disabled={loading}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={`phone-${data.record.id}`}
                    fullWidth
                    id="phone"
                    label="Phone"
                    name="phone"
                    value={data.record.phone}
                    // defaultValue={data.record.phone}
                    disabled={loading}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth required>
                    <InputLabel id="role">Role</InputLabel>
                    <Select
                      key={`role-${data.record.id}`}
                      labelId="role"
                      id="role"
                      label="Role"
                      name="role"
                      disabled={loading}
                      value={data.record.role}
                      onFocus={handleFocus}
                      onChange={handleSelectRole}
                    >
                      {settings.roles.map((r) => (
                        <MenuItem key={r.value} value={r.value}>
                          {r.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={`salesperson-${data.record.id}`}
                          id="salesperson"
                          name="salesperson"
                          checked={data.record.salesperson}
                          disabled={loading}
                          onChange={handleChange}
                        />
                      }
                      label="Salesperson"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    id="password"
                    label="New Password"
                    name="password"
                    placeholder={newRecord ? '' : '** No changes yet **'}
                    disabled={loading}
                    required={newRecord}
                    onFocus={handleFocus}
                    inputProps={{ minLength: 6 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="signature"
                    label="Signature"
                    name="signature"
                    placeholder={newRecord ? '' : 'First and Lastname\nPosition\nPhone\nEmail'}
                    multiline
                    minRows={5}
                    disabled={loading}
                    onFocus={handleFocus}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>

          <ControlBar>
            <Grid container>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Stack direction="row" spacing={3} justifyContent="right">
                    {newRecord ? (
                      <></>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={loading}
                        startIcon={<DeleteIcon />}
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                      // size="large"
                      endIcon={<SendIcon />}
                    >
                      {newRecord ? 'Add' : 'Update'}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </ControlBar>

        </Box>
        <Snackbar
          open={Boolean(errorObj._UnknownError)}
          autoHideDuration={10000}
          onClose={handleFocus}
          message={error}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={(theme) => ({ zIndex: theme.zIndex.appBar + 10 })}
        >
          <Alert onClose={handleFocus} variant="filled" severity="error">
            {`Something went wrong: ${errorObj._UnknownError?.message}`}
          </Alert>
        </Snackbar>
      </Container>
    </Paperbase>
  );
}

export default EditUser;
