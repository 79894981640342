import React from 'react';
import * as dateFns from 'date-fns';
// import * as ChartJs2 from 'react-chartjs-2';
// import * as ChartJs from 'chart.js';
// import { Chart } from 'chart.js';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import log from 'loglevel';

import { DashboardTileParams } from '../../types/dashboard';
import useData, { UseDataParams } from './useData';
import useDataStore from '../DataStore';
import { toNum } from '../../pages/Proposals/getProposalModel';
import UserName from '../HelperText/UserName';
// import { UserRoles } from '../../settings';

// Chart.register(ChartJs.CategoryScale);
// Chart.register(ChartJs.LinearScale);
// Chart.register(ChartJs.LineElement);
// Chart.register(ChartJs.PointElement);

const showWhichKPI = (toShow: string) => {
  switch(toShow){
    case 'Sales':
      return 'accepted_value_dollar';
    case 'Booked':
      return 'booked_value_dollar';
    case 'Closing Rate':
      return 'closing_rate_perc';      
  }
}

function useSqlQuery(params: any): UseDataParams {
  const datastore = useDataStore();
  const { user } = datastore.auth.getState();
  const { filter } = params;
  console.log(filter);
  const dateFrom = dateFns.format(filter?.period?.dateFrom || new Date(), 'yyyy-MM-dd');
  const dateTo = dateFns.format(filter?.period?.dateTo || new Date(), 'yyyy-MM-dd');

  const kpi = showWhichKPI(filter?.salesKpi || 'ACTIVE');


  // const whereInner = [];
  const whereOuter = [];

  if (filter?.assignedTo === 'me' && user.email) {
    whereOuter.push(`a.assigned_to = '${user.email}'`);
  }

  // if (filter?.proposalStatus !== '-') {
  //   whereInner.push(`pc.proposal_status = '${filter?.proposalStatus}'`);
  // }

  const name = 'By Employee';
  const sqlQuery = `
    select
    a.assigned_to dim1,
    a.ranknum as dim2,
    null as dim3,
    a.val
  from
    (
    select
      u.email as assigned_to,
      sum(coalesce(pc.${kpi}, 0)) as val,
      rank() over (
        order by
        sum(coalesce(pc.${kpi}, 0)) desc
      ) as ranknum
    from
      (
        select distinct email
        from
          (
          select email from vw_users u
          union
          select assigned_to from vw_sales_summary pc
          ) allu
        order by email		
      ) u
      left join get_sales_summary('${dateFrom}T00:00:00.000+0000'::date, '${dateTo}T00:00:00.000+0000'::date) pc
      on (
        u.email = pc.assigned_to
      )
    group by
      u.email
    ) a
  order by
    dim2 asc,
    dim1 asc
  `;
        // ${whereInner.length > 0 ? ' and ' : ''}${whereInner.join(' and ')}

  return { name, sqlQuery };
}

function SalesByEmployee(params: DashboardTileParams) {
  const datastore = useDataStore();
  const { user } = datastore.auth.getState();

  const { filter } = params;
  const [currentSql, setCurrentSql] = React.useState('');
  const [records, setRecords] = React.useState<any[]>([]);

  const dataParams = useSqlQuery({ filter });
  const { loading, refresh } = useData(dataParams);

  React.useEffect(() => {
    if (loading) return;
    if (currentSql === dataParams.sqlQuery) return;
    setCurrentSql(dataParams.sqlQuery);
    refresh().then(setRecords);
  }, [loading, refresh, currentSql, dataParams]);

  const hideNames = (filter?.assignedTo === 'me' && user.email); // user.role === UserRoles.USER || false; // || 

  return (
    <>
      <Typography variant='h6' sx={{ mb: '10px' }}>{filter?.salesKpi !== 'Closing Rate' ? `${filter?.salesKpi} By Employee` : 'Closing Rate Per Employee'}</Typography>
      {records
      .map((r) => (
        <Box key={r.dim1} sx={{ display: 'flex', mb: '2px', width: '100%' }}>
          <Typography sx={{ flexGrow: 1, textAlign: 'left' }}>
            {'['}{r.dim2}{'] '}
            {hideNames && r.dim1 !== user.email ? <></> : <UserName user={{ email: r.dim1 }} />}
          </Typography>
          <Typography sx={{ textAlign: 'right', ml: '10px' }}>
            {filter?.salesKpi !== 'Closing Rate' && '$'}
            {toNum(r.val || 0)} 
            {filter?.salesKpi === 'Closing Rate' && '%'}
          </Typography>
        </Box>
      ))}
    </>
  )
}

export default SalesByEmployee;
