import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import log from 'loglevel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import SelectServices, { recomputeService } from './SelectServices';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import ErrorHandler from '../../comp/ErrorHandler';

function EditProposalServices() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  const { id } = useParams();

  const initProposals = datastore.proposals.getState();
  const existing = initProposals.records.find((x: any) => x.id === id);
  const [proposal, setProposal] = React.useState<any>(existing || {});
  const [loading, setLoading] = React.useState(false);
  const dataRef = React.useRef<any>({
    map: (existing?.map || {}),
    services: {
      records: [],
      ...(existing?.services || {}),
    }
  });
  const [error, setError] = React.useState<any>();

  const handleSubmit = React.useCallback(async () => {
    setLoading(true);

    const computedArea = (proposal.map?.overlays || []).reduce(
      (prev: number, curr: any) => prev + curr.area_sqft,
      0
    );

    const overrideArea = dataRef.current.map?.area !== undefined && dataRef.current.map?.area !== null;
    const totalArea = overrideArea ? dataRef.current.map?.area : computedArea;

    // update all relevant records
    const parentId = proposal.parent_id || id;
    const parent = await datastore.proposals.get({ id: parentId });
    const children = await datastore.proposals.getChildren({ id: parentId });
    const allProposals = [ parent, ...(children || []) ];
    log.debug({ parentId, totalArea, allProposals });
    await Promise.all(
      (allProposals || []).map(async (p: any) => {
        const sr = await Promise.all(
          (p.services?.records || []).map((service: any) => {
            return recomputeService({
              datastore,
              service,
              percentIncrease: p.services?.percentIncrease,
              difficulty: p.services?.difficulty,
              area: totalArea,
            });
          })
        );

        const payload = {
          ...p,
          map: {
            ...(p.map || {}),
            area: overrideArea ? dataRef.current.map?.area : null,
          },
          services: id === p.id ? dataRef.current.services : {
            ...(p.services || {}),
            records: sr,
          },
          file: null,
        }

        log.debug(`payload ${p.id}`, payload);
        await datastore.proposals.update(payload, {
          pick: ['id', 'map', 'services', 'updated_at', 'file'],
        });
      })
    ).catch(setError);

    setLoading(false);
  }, [id, datastore, proposal]);

  const onClickUpdate = async () => {
    await handleSubmit();
    const pid = proposal.parent_id;
    navigate(`/proposals/${pid || id}`);
  };

  const onClickCancel = async () => {
    const pid = proposal.parent_id;
    navigate(`/proposals/${pid || id}`);
  };

  const refresh = React.useCallback(async () => {
    if (loading) return;
    setLoading(true);
    datastore.proposals.get({ id: id || '' })
      .then((p: any) => {
        setProposal(p || {});
        Object.assign(dataRef.current, {
          map: p.map,
          services: p.services,
        });
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [loading, datastore.proposals, id]);

  // LOAD RECORD
  React.useEffect(() => {
    if (id === proposal.id) return;
    refresh();
  }, [id, proposal, datastore, refresh]);

  React.useEffect(() => {
    const proposalsId = datastore.proposals.subscribe(() => refresh());
    return () => {
      datastore.proposals.unsubscribe(proposalsId);
    }
  }, [datastore, refresh]);

  return (
    <Paperbase title={'Edit Proposal Services'} bottomSpacer="100px">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          {proposal.id && !loading ? (
          <SelectServices
            key={`${proposal.id}`}
            loading={loading}
            proposal={proposal}
            dataRef={dataRef}
          />
          ): <></>}
        </Grid>
      </Grid>
      <ErrorHandler error={error} />
      <ControlBar>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Stack direction="row" spacing={3} justifyContent="right">
              <Button
                variant="outlined"
                onClick={onClickCancel}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={onClickUpdate}
                disabled={loading}
              >
                Update Proposal
              </Button>
              {/* )} */}
            </Stack>
          </Grid>
        </Grid>
      </ControlBar>
    </Paperbase>
  );
}

export default EditProposalServices;
