import * as Sentry from "@sentry/react";

console.log(`STAGE = '${process.env.REACT_APP_STAGE}'`);

const targets = {
  dev: [
    "localhost",
    /^https:\/\/dev-proposals\.devsimple\.click/,
  ],
  prod: [
    /^https:\/\/proposals\.devsimple\.click/,
    /^https:\/\/proposals\.wonsei\.com/,
  ],
}
const stage = (process.env.REACT_APP_STAGE || 'x') as keyof typeof targets;

let target: any[] = [];
if (stage && targets[stage]) {
  target = targets[stage];
}

Sentry.init({
  dsn: "https://e9047e4663258f1d3f7668e5c8b98360@o4507952460070912.ingest.us.sentry.io/4508066724708352",
  environment: stage,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: target,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
