export enum UpsertMode {
  UPSERT = 'UPSERT',
  CREATE_ONLY = 'CREATE_ONLY',
  UPDATE_ONLY = 'UPDATE_ONLY',
}

export enum ActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPSERT = 'UPSERT',
  DELETE = 'DELETE',
  REFRESH = 'REFRESH',
  ERROR = 'ERROR',
  UPDATE_PAGE = 'UPDATE_PAGE',
}

export enum RecordStatus {
  LOADING = 'LOADING',
  OK = 'OK',
  ERROR = 'ERROR',
}

export interface RefreshOptions {
  match?: any;
  range?: {
    from: number;
    to: number;
  };
  in?: {
    column: string;
    values: any[];
  };
  ilike?: {
    column: string;
    pattern: string;
  };
  eq?: {
    column: string;
    value: string;
  };
  gte?: {
    column: string;
    value: string;
  };
  lte?: {
    column: string;
    value: string;
  };
  order?: {
    column: string;
    value: any;
  };
  limit?: number,
  type?: ActionType;
  custom?: (builder: any) => any;
}

export interface GetFunctionParams {
  state?: any;
  dispatch: any;
  datastore: any;
  subscribers: React.MutableRefObject<any>;
}

export enum DatastoreKey {
  AUTH = 'auth',
  USERS = 'users',
  SERVICES = 'services',
  PROGRAMS = 'programs',
  PROSPECTS = 'prospects',
  APPOINTMENTS = 'appointments',
  SETTINGS  ='settings',
  PROPOSALS = 'proposals',
  NOTIFICATIONS = 'notifications',
  MESSAGES = 'messages',
  TAX_RATE = 'taxRate',
  SERVICE_WORKER = 'sw',
  PROPOSAL_CALLS = 'proposalCalls',
};
