import * as React from 'react';
import log from 'loglevel';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import * as dateFns from 'date-fns';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import { v4 as uuidv4 } from 'uuid';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import { ProposalStatus } from '../../comp/DataStore/Proposals';
import { getImageUrl } from '../../comp/GoogleMap/GmapImage';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import ProspectsAutocomplete from '../../comp/Autocomplete/Prospects';
import { recomputeService } from './SelectServices';
import GmapImage from '../../comp/GoogleMap/GmapImage';
import settings from '../../settings';

type ShowMapDialogProps = {
  open?: boolean;
  onSave?: (payload: any) => void;
  onClose?: () => void;
};

function ShowMapDialog(props: ShowMapDialogProps) {
  const datastore = useDataStore();
  const { open = false, onClose, onSave } = props;

  // const navigate = useNavigate();
  // const location = useLocation();
  const { id } = useParams();
  // const [searchParams] = useSearchParams();

  const proposalsState = datastore.proposals.getState();
  const existing = proposalsState.records.find((p: any) => p.id === id);
  const [proposal, setProposal] = React.useState<any>(existing || {});
  const [loading, setLoading] = React.useState(false);
  // const [prospect, setProspect] = React.useState<any>({
  //   id: proposal.prospect_id,
  // });
  const [map, setMap] = React.useState<any>({
    area: null,
    ...(proposal.map || {}),
  });
  const areaRef = React.useRef<any>();
  const [overrideArea, setOverrideArea] = React.useState(
    proposal.map?.area !== undefined && proposal.map?.area !== null
  );
  // const [error, setError] = React.useState<any>();

  // const errorObj = React.useMemo(() => {
  //   log.debug('updating error Object');
  //   const value: any = {};
  //   if (!error) return value;
  //   if (error.name === 'ValidationError') {
  //     (error.inner || []).forEach((innerErr: any) => {
  //       value[innerErr.path] = innerErr;
  //     });
  //   }
  //   return value;
  // }, [error]);

  // Computed Area
  const computedArea = React.useMemo<number>(() => {
    return (map.overlays || []).reduce(
      (prev: number, curr: any) => prev + curr.area_sqft,
      0
    );
  }, [map]);

  const handleSubmit = React.useCallback(async () => {
    setLoading(true);

    const totalArea =
      overrideArea && areaRef.current.value
        ? Number(areaRef.current.value)
        : computedArea;

    // if there are no changes
    if (totalArea === proposal.map.area) {
      setLoading(false)
      return;
    }
  
    setMap((curr: any) => ({
      ...curr,
      area: overrideArea ? totalArea : null,
    }));

    if (typeof onSave === 'function') {
      onSave({ map: {
        ...map,
        area: overrideArea ? totalArea : null,
      }});
      setLoading(false);
      return;
    }

    // update all relevant records
    const parentId = proposal.parent_id || id;
    const parent = await datastore.proposals.get({ id: parentId });
    const children = await datastore.proposals.getChildren({ id: parentId });
    const allProposals = [ parent, ...(children || []) ];
    log.debug({ allProposals });
    await Promise.all(
      (allProposals || []).map(async (p: any) => {
        const sr = await Promise.all(
          (p.services?.records || []).map((service: any) => {
            return recomputeService({
              datastore,
              service,
              percentIncrease: p.services?.percentIncrease,
              difficulty: p.services?.difficulty,
              area: totalArea,
            });
          })
        );

        const payload = {
          ...p,
          map: {
            overlays: [],
            ...(p.map || {}),
            area: overrideArea ? totalArea : null,
          },
          services: {
            ...(p.services || {}),
            records: sr,
          },
          file: null,
        }

        await datastore.proposals.update(payload, {
          pick: ['id', 'map', 'services', 'updated_at', 'file'],
        });
      })
    );

    setLoading(false);
  }, [datastore, id, map, proposal, overrideArea, computedArea, onSave]);

  // const onSelectProspect = (e: any, value: any, reason: any) => {
  //   if (reason === 'selectOption') {
  //     if (value.id !== prospect.id) {
  //       setMap(value.map);
  //     }
  //     setProspect(value);
  //     setError(null);
  //   }
  // };

  // Get prospect (if specified)
  // const initProspectId: string = searchParams.get('prospect_id') || '';
  // if (initProspectId) {
  //   if (!loading && prospect.id !== initProspectId) {
  //     datastore.prospects
  //       .get({ id: initProspectId })
  //       .then((initProspect: any) => {
  //         onSelectProspect(null, initProspect, 'selectOption');
  //       });
  //   }
  // }

  // const onEditMap = async () => {
  //   const payload = await handleSubmit();
  //   navigate(`/proposals/${payload.id}/edit-map`);
  // };

  const onSaveDialog = async () => {
    await handleSubmit();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const onCloseDialog = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const toggleOverrideArea = (e: any) => {
    setOverrideArea(e.target.checked);
    if (!e.target.checked) {
      areaRef.current.value = map.area || Number(computedArea).toFixed(2);
    }
  };

  const gmapImage = React.useMemo(() => {
    return getImageUrl(map);
  }, [map]);

  // const listRecentProposals = React.useMemo(
  //   () =>
  //     recentProposals
  //     .sort((a: any, b: any) => {
  //       const aCreatedAt = dateFns.parseISO(a.created_at);
  //       const bCreatedAt = dateFns.parseISO(b.created_at);
  //       const aYear = a.year || dateFns.format(aCreatedAt, 'yyyy');
  //       const bYear = b.year || dateFns.format(bCreatedAt, 'yyyy');
  //       if (aYear !== bYear) {
  //         return String(bYear).localeCompare(String(aYear));
  //       } else {
  //         return String(b.updated_at).localeCompare(a.updated_at);
  //       }
  //     })
  //     .map((p: any) => {
  //       const createdAt = dateFns.parseISO(p.created_at);
  //       const updatedAt = dateFns.parseISO(p.updated_at);
  //       const year = p.year || dateFns.format(createdAt, 'yyyy');
  //       const statusCode = p.parent_status || p.status;
  //       const status = settings.proposalStatus.find((x) => x.value === statusCode);
  //       return (
  //         <ListItemButton
  //           key={p.id}
  //           sx={{ bgcolor: '#EBEDEF', mb: '5px' }}
  //           onClick={() => {
  //             navigate(`/proposals/${p.id}`);
  //           }}
  //         >
  //           <Grid container>
  //             <Grid item xs={6}>
  //               <ListItemText primary={`${year} - ${status?.label}`} />
  //             </Grid>
  //             <Grid item xs={6} textAlign="right">
  //               <ListItemText primary={dateFns.format(updatedAt, 'M/d/yyyy')} />
  //             </Grid>
  //           </Grid>
  //         </ListItemButton>
  //       );
  //     }),
  //   [recentProposals, navigate]
  // );

  // React.useEffect(() => {
  //   if (!prospect || !prospect.id) return;
  //   datastore.proposals.refresh({
  //     custom: (query: any) => {
  //       query.eq('prospect_id', prospect.id);
  //       query.neq('id', id);
  //       query.is('deleted_at', null);
  //       query.order('created_at', { ascending: false })
  //       query.limit(5);
  //     }
  //   }).then((result: any[]) => {
  //     setRecentProposals(result
  //       .filter((p) => !p.parent_id)
  //       .filter((p) => !p.deleted_at));
  //   });
  // }, [id, newRecord, datastore.proposals, prospect]);

  // Load Proposal
  React.useEffect(() => {
    if (loading) return;
    if (id === proposal.id) return;
    setLoading(true);
    datastore.proposals
      .get({ id: String(id || '') })
      .then((p: any) => {
        setProposal(p);
        setMap(p.map);
        // setProspect({ id: p.prospect_id });
        setOverrideArea(p.map?.area !== undefined && p.map?.area !== null);
      })
      .finally(() => setLoading(false));
  }, [id, proposal, datastore, loading]);

  return (
    <Dialog open={open} onClose={onCloseDialog}>
      <DialogTitle>Map</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: '5px' }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <TextField
              key={`area-${proposal.id}`}
              fullWidth
              id="area"
              label="Area (in SQFT)"
              name="area"
              type="number"
              inputRef={areaRef}
              disabled={loading || !overrideArea}
              placeholder={Number(computedArea).toFixed(2)}
              InputLabelProps={{ shrink: true }}
              defaultValue={map.area}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    key={`override-area-${proposal.id}`}
                    id="override_area"
                    name="override_area"
                    checked={overrideArea}
                    disabled={loading}
                    onChange={toggleOverrideArea}
                  />
                }
                label="Override area"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            {/* <ButtonBase onClick={onEditMap} sx={{ position: 'relative' }}> */}
            <Box>
              <img src={gmapImage.url} alt="gmap" width="100%" />
            </Box>
            {/* <Box
              sx={{
                backgroundColor: '#17202A99',
                position: 'absolute',
                color: 'white',
                top: 10,
                left: '50%',
                p: '30px',
                transform: 'translateX(-50%)',
              }}
            >
              <Typography>Tap to Edit Map</Typography>
            </Box> */}

            {/* </ButtonBase> */}
          </Grid>
        </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Cancel</Button>
        <Button onClick={onSaveDialog} variant="contained">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type ShowMapProps = {
  map: any;
  onSave?: (payload: any) => void;
};

function ShowMap(props: ShowMapProps) {
  const { map, onSave } = props;

  const [open, setOpen] = React.useState(false);

  const onClickMap = () => {
    setOpen(true);
  };

  return map.center ? (
    <>
      <GmapImage {...map} />
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={onClickMap}>Override Area</Button>
      </Stack>
      <ShowMapDialog open={open} onClose={() => setOpen(false)} onSave={onSave} />
    </>
  ) : (
    <></>
  );
}

export default ShowMap;
export { ShowMap, ShowMapDialog };
