import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as api from '../../api/irrigationApi';
import * as supabase from '../../api/supabase';
import { AddressComponentSchema } from './Prospects';
import {
  GetFunctionParams,
  RefreshOptions,
  UpsertMode,
  ActionType,
  RecordStatus,
} from '../../types/datastore.d';
import { MessageType } from './Messages';

const dataset = 'appointments';
const vwDataset = 'vw_appointments';
const dbTable = 'appointments';

enum EventType {
  DROP_OFF = 'DROP_OFF',
  APPOINTMENT = 'APPOINTMENT',
}

enum EventStatus {
  NOT_DONE = 'NOT_DONE',
  DONE = 'DONE',
}

function AppointmentSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      updated_at: yup.string().required().default(new Date().toISOString()),
      title: yup.string().required(),
      note: yup.string().optional().nullable(),
      type: yup
        .string()
        .default(EventType.APPOINTMENT)
        .required()
        .oneOf(Object.values(EventType)),
      status: yup
        .string()
        .default(EventStatus.NOT_DONE)
        .required()
        .oneOf(Object.values(EventStatus)),
      start: yup
        .string()
        .matches(
          /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/,
          'Must be an ISO date'
        )
        .required(),
      end: yup
        .string()
        .matches(
          /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/,
          'Must be an ISO date'
        )
        .required(),
      allDay: yup.boolean().default(false).required(),
      address: yup.string().required(),
      map: yup
        .object()
        .default(null)
        .optional()
        .nullable()
        .noUnknown()
        .shape({
          address_components: yup
            .array()
            .required()
            .of(AddressComponentSchema()),
          zoom: yup.number().default(20).required(),
          center: yup.object().required().shape({
            lat: yup.number().required(),
            lng: yup.number().required(),
          }),
        }),
      assigned_to: yup.string().email().optional().nullable(),
      prospect_id: yup.string().uuid().required(),
      proposal_id: yup.string().uuid().optional().nullable(),
      enabled: yup.bool().default(true).required(),
    });
}

type AppointmentModel = {
  id: string;
  title: string;
  note?: string;
  start: string;
  end: string;
  allDay: boolean;
  address: string;
  map?: {
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
  };
  created_by: string;
  assigned_to?: string;
  prospect_id?: string;
  proposal_id?: string;
};

type PageVars = {
  defaultDate: string;
};

function getInitial() {
  const defaultDate = new Date().toISOString();
  const records: AppointmentModel[] = [];

  const init: {
    records: AppointmentModel[];
    page: PageVars;
    error: string | null;
  } = {
    records,
    page: { defaultDate },
    error: null,
  };

  return init;
}

const expiryMs = 1000 * 60 * 10; // 5 minutes
function isExpired(record: { $refreshedAt: number }) {
  const now = Date.now();
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, datastore, subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   *
   * @param record
   * @returns
   */
  const notifyFn = async (record: any) => {
    const authState = datastore.auth.getState();

    const result = await datastore.messages.create({
      id: uuidv4(),
      created_at: new Date().toISOString(),
      created_by: authState.user?.email,
      message_type: MessageType.NOTIFICATION,
      assigned_to: record.assigned_to,
      proposal_id: record.proposal_id,
      prospect_id: record.prospect_id,
      data: {
        title: `New Event`,
        message: `Event titled '${record.title}' has been assigned to you`,
        url: `/appointments/${record.id}`,
        module: 'appointments',
        topic: 'assigned-to',
      },
    });
    log.debug('notification', result);

    await api.createNotification({
      id: uuidv4(),
      assigned_to: record.assigned_to,
      module: 'appointments',
      reference_id: record.id,
      topic: 'assigned-to',
      payload: {
        title: `New Event`,
        body: `Event titled '${record.title}' has been assigned to you`,
        data: {
          url: `/appointments/${record.id}`,
        },
      },
    });

    return result;
  };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      ilike,
      eq,
      gte,
      lte,
      type = ActionType.REFRESH,
    } = opts || {};
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (ilike) query.ilike(ilike.column, ilike.pattern);
    if (gte) query.gte(gte.column, gte.value);
    if (lte) query.lte(lte.column, lte.value);
    if (eq) query.eq(eq.column, eq.value);
    const result = await query;

    if (result.error) {
      log.error(`fetch ${dataset} error`, result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: { id: string }) => {
    const { id } = opts || {};

    const existing = state[dataset].records.find((x: any) => x.id === id);
    if (existing && !isExpired(existing)) return existing;

    const data = await refreshFn({ match: { id }, type: ActionType.UPSERT });
    return data ? data[0] : undefined;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: { records: any[]; error: any }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: any,
      opts?: { pick?: string[]; onConflict?: string; match?: any }
    ) => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = AppointmentSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: any = await schema
        .validate(record, { abortEarly: false })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      const client = await supabase.getClient();
      if (mode === UpsertMode.UPSERT) {
        const { onConflict = 'id' } = opts || {};
        const result: any = await client
          .from(dbTable)
          .upsert([valid], { onConflict })
          .select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await client.from(dbTable).insert([valid]).select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const { match = { id: valid.id } } = opts || {};
        const result: any = await client
          .from(dbTable)
          .update(valid)
          .match(match)
          .select();
        respond({ records: result?.data, error: result.error });
      }
      return getFn({ id: valid.id });
    };
  };

  /**
   *
   * @param params
   */
  const updatePageFn = (params: PageVars) => {
    dispatch({
      type: ActionType.UPDATE_PAGE,
      dataset,
      payload: params,
    });
  };

  /**
   *
   * @param filters
   */
  let rangeFilters: any[] = [];
  const getRangeFn = async (filters: any) => {
    const filterStr = JSON.stringify(filters);
    let existing = rangeFilters.find((x: any) => x.filterStr === filterStr);
    if (existing && !isExpired(existing)) return existing.result;
    // update filter
    if (existing) Object.assign(existing, { $refreshedAt: Date.now() });
    else {
      existing = { filterStr, $refreshedAt: Date.now(), result: [] };
      rangeFilters.push(existing);
    }
    // cleanup memory
    const now = Date.now();
    rangeFilters = rangeFilters.filter(
      (x: any) => now - x.$refreshedAt <= expiryMs
    );
    // refresh
    const refreshParams: RefreshOptions = { type: ActionType.UPSERT };
    if (filters.assigned_to) {
      refreshParams.eq = { column: 'assigned_to', value: filters.assigned_to };
    }
    if (filters.proposal_id) {
      refreshParams.eq = { column: 'proposal_id', value: filters.proposal_id };
    }
    if (filters.prospect_id) {
      refreshParams.eq = { column: 'prospect_id', value: filters.prospect_id };
    }
    if (filters.id) {
      refreshParams.eq = { column: 'id', value: filters.id };
    }
    if (filters.start && filters.end) {
      refreshParams.gte = { column: 'start', value: filters.start };
      refreshParams.lte = { column: 'end', value: filters.end };
      // update page vars
      updatePageFn({ defaultDate: filters.start });
    }

    existing.result = await refreshFn(refreshParams);
    return existing.result;
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getRange: getRangeFn,
    getState: () => state[dataset],
    create: async (...p: any) => {
      const fn = getUpsertFn(UpsertMode.CREATE_ONLY);
      const record = await fn.apply({}, p);
      if (record && !record.$error && record.assigned_to) {
        notifyFn(record);
      }
    },
    update: async (payload: any, opts?: any) => {
      const existing = await getFn({ id: payload.id });
      const notify =
        existing &&
        payload.assigned_to &&
        existing.assigned_to !== payload.assigned_to;

      const fn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      const record = await fn(payload, opts);
      if (notify && record && !record.$error && record.assigned_to) {
        notifyFn(record);
      }
    },
    delete: async (payload: any) => {
      const deleteFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      return deleteFn(
        { id: payload.id, enabled: false },
        { pick: ['id', 'enabled', 'updated_at'] }
      );
    },
    updateStatus: async (payload: any) => {
      const updateStatusFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      return updateStatusFn(payload, { pick: ['id', 'status', 'updated_at'] });
    },
    updatePage: updatePageFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: AppointmentModel[] = action.payload;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: any) => x.id === y.id
      );
      if (existing) Object.assign(existing, x);
      else newState[dataset].records.push(x);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE_PAGE) {
    const newVars: PageVars = action.payload;
    const currVars: PageVars = newState[dataset].page;
    log.debug('page vars', { currVars, newVars });
    Object.assign(currVars, newVars);
    return newState;
  }
  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildAppointments(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildAppointments;
export { dataset, getInitial, getFunctions, reducer, EventType, EventStatus };
export type { AppointmentModel };
