import React from 'react';
import log from 'loglevel';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as datefns from 'date-fns';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import ViewEvent from './Event';
import { Calendar } from '../../comp/Calendar';
import { useDataStore } from '../../comp/DataStore';
import { EventStatus } from '../../comp/DataStore/Appointments';
import HelperBox from '../../comp/HelperBox';
import { CustomEventWrapper, CustomAgendaTime } from './CustomComp';
// import GetDirections from './GetDirections';

function formatDate(record: any) {
  const nextHour = datefns.startOfHour(datefns.addHours(new Date(), 1));
  const defStart = datefns.startOfHour(nextHour).toISOString();
  const defEnd = datefns.addHours(nextHour, 1).toISOString();
  const startFmt = datefns.parseISO(record.start || defStart);
  const endFmt = datefns.parseISO(record.end || defEnd);
  return {
    ...record,
    startFmt: datefns.format(startFmt, 'MMMM d, yyyy h:mm bbb'),
    endFmt: datefns.format(endFmt, 'MMMM d, yyyy h:mm bbb'),
    allDayFmt: datefns.format(startFmt, 'MMMM d, yyyy'),
  };
}

function WarnFilters(props: { filter?: any }) {
  const { prospects } = useDataStore();
  const navigate = useNavigate();
  const f = props.filter;

  const [text, setText] = React.useState<string>(
    'Showing only filtered appointments'
  );
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);

  React.useEffect(() => {
    if (loading) return;
    if (done) return;

    setLoading(true);
    if (f?.assigned_to) {
      setText(`Showing only your appointments`);
      setLoading(false);
      setDone(true);
    } else if (f?.proposal_id && f?.prospect_id) {
      prospects.get({ id: f.prospect_id }).then((prospect: any) => {
        setText(
          `Showing only appointments of the proposal for ${prospect.full_name}`
        );
        setLoading(false);
        setDone(true);
      });
    } else {
      setLoading(false);
      setDone(true);
    }
  }, [prospects, f, loading, done]);

  if (!f) return <></>;
  return (
    <Alert
      severity="warning"
      icon={<FilterAltIcon />}
      action={
        <Button
          size="small"
          color="inherit"
          onClick={() => {
            navigate('/appointments');
          }}
        >
          Clear
        </Button>
      }
      sx={{ mb: 2, border: 1, borderColor: 'orange' }}
    >
      {text}
    </Alert>
  );
}

function MainCalendar() {
  const datastore = useDataStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const initAppointments = datastore.appointments.getState();
  const [appointmentsState, setAppointmentsState] = React.useState<any>(initAppointments);

  const defaultDate = React.useMemo(
    () => datefns.parseISO(appointmentsState.page.defaultDate),
    [appointmentsState.page.defaultDate]
  );
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  // const [directionsOpen, setDirectionsOpen] = React.useState(false);
  const [event, setEvent] = React.useState<any>({ current: {} });

  const onRangeChange = React.useCallback(
    (range: any) => {
      let start;
      let end;
      if (Array.isArray(range)) {
        start = range[0];
        end = range.slice(-1)[0];
      } else {
        ({ start, end } = range);
      }

      if (typeof start === 'string') start = datefns.parseISO(start);
      if (typeof end === 'string') end = datefns.parseISO(end);

      start = datefns.startOfDay(start);
      end = datefns.endOfDay(end);

      log.debug('new date range', { start, end, range });

      datastore.appointments
        .getRange({ start: start.toISOString(), end: end.toISOString() })
        .then((d: any) => log.debug('events', d));
    },
    [datastore.appointments]
  );

  const getFilter = React.useCallback((): undefined | any => {
    const p: string = searchParams.get('p') || '';
    let filter: any = undefined;
    try {
      if (p) filter = JSON.parse(decodeURIComponent(p));
    } catch (e) {
      log.error(e);
    }
    return filter;
  }, [searchParams]);

  const filter = React.useMemo(() => getFilter(), [getFilter]);

  const onSelectSlot = (slotInfo: any) => {
    const q = [];

    const hourDiff = Math.abs(
      datefns.differenceInHours(slotInfo.end, slotInfo.start)
    );

    const pObj = {
      start: new Date(slotInfo.start).toISOString(),
      end: new Date(slotInfo.end).toISOString(),
      allDay: hourDiff === 24,
    };
    const p = `p=${encodeURIComponent(JSON.stringify(pObj))}`;
    q.push(p);

    let f: string = '';
    if (filter && (filter.proposal_id || filter.prospect_id)) {
      f = `f=${encodeURIComponent(JSON.stringify(filter))}`;
      q.push(f);
    }

    log.debug('select slot', slotInfo);
    navigate(`/appointments/new?${q.join('&')}`);
  };

  const onSelectEvent = (e: any) => {
    setEvent({ current: formatDate(e) });
    // event.current = formatDate(e);
    setDetailsOpen(true);
  };

  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
  };

  const titleAccessor = (e: any) => {
    let done = '';
    if (e.status === EventStatus.DONE) done = '[Done] ';
    return `${done}${e.title}`;
  };

  const onEventDrop = React.useCallback((params: any) => {
    log.debug('onEventDrop', params);
  }, []);

  const onEventResize = React.useCallback((params: any) => {
    log.debug('onEventResize', params);
  }, []);

  // const toggleMarkDone = (e: any) => {
  //   e.preventDefault();
  //   const d = {
  //     id: event.current.id,
  //     status:
  //       event.current.status === EventStatus.DONE
  //         ? EventStatus.NOT_DONE
  //         : EventStatus.DONE,
  //   };
  //   setEvent({ current: { ...event.current, ...d } });
  //   datastore.appointments.updateStatus(d);
  //   toggleDetails();
  // };

  // For selected event
  const eventMemo = React.useMemo(() => {
    const e = appointmentsState.records.find(
      (x: any) => x.id === event.current.id
    );
    return formatDate(e || {});
  }, [event, appointmentsState.records]);

  // For the calendar
  const eventsMemo = React.useMemo(() => {
    return appointmentsState.records.filter((r: any) => {
      let matches = true;
      if (filter?.proposal_id) {
        matches = matches && filter?.proposal_id === r.proposal_id;
      }
      if (filter?.prospect_id) {
        matches = matches && filter?.prospect_id === r.prospect_id;
      }
      if (filter?.assigned_to) {
        matches = matches && filter?.assigned_to === r.assigned_to;
      }
      return matches;
    });
  }, [filter, appointmentsState.records]);

  const components = React.useMemo(
    () => ({
      eventWrapper: CustomEventWrapper,
      agenda: {
        time: CustomAgendaTime,
      },
    }),
    []
  );

  React.useEffect(() => {
    const appointmentsId = datastore.appointments.subscribe(setAppointmentsState);
    return () => {
      datastore.appointments.unsubscribe(appointmentsId);
    }
  }, [datastore]);

  return (
    <Paperbase title="Appointments"  bottomSpacer="100px">
      <>
        <HelperBox
          width={500}
          open={detailsOpen}
          onClose={toggleDetails}
        >
          <ViewEvent event={eventMemo} />
        </HelperBox>
        <WarnFilters filter={getFilter()} />
        <Calendar
          defaultDate={defaultDate}
          events={eventsMemo}
          components={components}
          onRangeChange={onRangeChange}
          onInit={onRangeChange}
          onSelectSlot={onSelectSlot}
          onSelectEvent={onSelectEvent}
          titleAccessor={titleAccessor}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          formats={{
            timeGutterFormat: (date, culture, localizer: any) => {
              return localizer.format(date, 'h a', culture);
            },
          }}
          selectable
        />
      </>
    </Paperbase>
  );
}

export default MainCalendar;
