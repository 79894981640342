import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';

import Table from '../../comp/Table';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import ImageSet, { ImageSize } from '../../comp/ImageSet';
import settings from '../../settings';
import { ActionType } from '../../types/datastore.d';

function getHeaders(props: any) {
  const createLink = (to: string) => (e: any) => {
    e.preventDefault();
    props.navigate(to);
  };
  const root = 'services';

  const headers = [
    {
      accessor: 'image_url',
      minWidth: '300px',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <Box
            className="clickable"
            onClick={createLink(to)}
            sx={{ minWidth: '100px', maxWidth: '150px' }}
          >
            <ImageSet src={props.value} alt="service" size={ImageSize.xs} />
          </Box>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <Box
            className="clickable"
            onClick={createLink(to)}
            sx={{ minWidth: '200px' }}
          >
            {String(props.value)}
          </Box>
        );
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <Box
            className="clickable"
            onClick={createLink(to)}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {String(props.value || '')}
          </Box>
        );
      },
    },
    {
      Header: 'Season',
      accessor: 'season',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        const season = settings.seasons.find((x) => x.value === props.value);
        return (
          <Box className="clickable" onClick={createLink(to)}>
            {String(season?.label || props.value)}
          </Box>
        );
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        const serviceType = settings.serviceType.find(
          (x) => x.value === props.value
        );
        return (
          <Box className="clickable" onClick={createLink(to)}>
            {String(serviceType?.label || props.value)}
          </Box>
        );
      },
    },
    {
      Header: 'Pricing',
      accessor: 'pricing',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        const pricing = settings.pricingModel.find(
          (x) => x.value === props.value
        );
        return (
          <Box className="clickable" onClick={createLink(to)}>
            {String(pricing?.label || props.value)}
          </Box>
        );
      },
    },
    {
      accessor: 'search_column',
    },
  ];
  return headers;
}

let runOnce = false;
function ListServices() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>('');

  const initServices = datastore.services.getState();
  const [servicesState, setServicesState] = React.useState<any>(initServices);
  
  React.useEffect(() => {
    if (runOnce) return;
    runOnce = true;
    datastore.services.refresh();
  }, [datastore.services]);

  const headers = getHeaders({ navigate });
  const columns = React.useMemo(() => headers, [headers]);
  const data = React.useMemo(
    () => servicesState.records,
    [servicesState.records]
  );

  const onChangeSearch = () => {
    let timeout: any;
    return (e: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    };
  };

  const memoSearch = React.useMemo(() => search, [search]);

  React.useEffect(() => {
    if (!search) return;
    datastore.services.refresh({
      custom: (q: any) => {
        q.textSearch('search_column', search);
      },
      type: ActionType.UPSERT,
    }).then((result: any[] | undefined) => {
      // do something with result
    });
  }, [datastore.services, search, memoSearch])

  React.useEffect(() => {
    const servicesId = datastore.services.subscribe(setServicesState);
    return () => {
      datastore.services.unsubscribe(servicesId);
    }
  }, [datastore]);

  return (
    <Paperbase title="Services" bottomSpacer="100px">
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => navigate('/services/new')}>
          New Service
        </Button>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search..."
          onChange={onChangeSearch()}
        />
      </Stack>

      <div style={{ minHeight: 400, width: '100%' }}>
        <Table
          columns={columns}
          data={data}
          globalFilterValue={memoSearch}
          initialState={{
            hiddenColumns: [
              'search_column',
            ],
            globalFilter: memoSearch,
          }}
        />
      </div>
    </Paperbase>
  );
}

export default ListServices;
