import React from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Alert from '@mui/material/Alert';

import ProspectNameHelper from '../../comp/HelperText/ProspectName';

type WarnFiltersProps = {
  prospect?: any;
}

function WarnFilters(props: WarnFiltersProps) {
  const { prospect } = props;

  return (
    <Alert
      severity="warning"
      icon={<FilterAltIcon />}
      sx={{ border: 1, borderColor: 'orange' }}
    >
      {'This is linked to the proposal for '}
      <ProspectNameHelper prospect={prospect} />
    </Alert>
  );
}

export default WarnFilters;
