import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Container from '@mui/material/Container';

const drawerWidth = 240;

interface HelperBoxProps extends DrawerProps {
  width?: number;
}

function HelperBox(props: HelperBoxProps) {
  const theme = useTheme();

  const {
    open,
    onClose,
    onClick,
    children,
    width = drawerWidth,
    ...drawerProps
  } = props;

  return (
    <Drawer
      {...drawerProps}
      anchor="right"
      variant="temporary"
      sx={{
        maxWidth: '80vw',
        width: width,
        flexShrink: 0,
        zIndex: theme.zIndex.appBar + 10,
        [`& .MuiDrawer-paper`]: {
          maxWidth: '80vw',
          width,
          boxSizing: 'border-box',
          // zIndex: theme.zIndex.appBar - 10,
        },
      }}
      open={open}
      onClose={onClose}
      onClick={onClick}
    >
      {/* <Toolbar /> */}
      <Container disableGutters sx={{ p: '15px' }}>
        {children}
      </Container>
    </Drawer>
  );
}

export default HelperBox;
