import React from 'react';
import log from 'loglevel';
import Box from '@mui/material/Box';

import settings from '../../settings';

const gmapBaseUrl = 'https://maps.googleapis.com/maps/api/staticmap';

interface OverlayProp {
  id: string;
  type: string;
  info: any;
  area_sqft: number;
}

interface GmapImageProps {
  gmapBaseUrl?: string;
  zoom?: number;
  center?: {
    lat: number;
    lng: number;
  };
  bounds?: {
    north: number;
    east: number;
    west: number;
    south: number;
  };
  maptype?: string;
  width?: number;
  height?: number;
  overlays?: OverlayProp[];
  sx?: any;
}

function htmlToHex(c: string) {
  return c.replace(/^#/, '0x');
}

function toLatLng(point: { lat: number; lng: number }): string {
  return `${point.lat.toFixed(6)},${point.lng.toFixed(6)}`;
}

function boundsToArr(bounds: any) {
  const path = [];
  const { north: n, east: e, west: w, south: s } = bounds;
  path.push({ lat: n, lng: e });
  path.push({ lat: s, lng: e });
  path.push({ lat: s, lng: w });
  path.push({ lat: n, lng: w });
  return path;
}

// from https://stackoverflow.com/questions/7316963/drawing-a-circle-google-static-maps
function circleToArr(params: any) {
  const path = [];

  const {
    center = {},
    radius, // in meters
    detail = 10,
  } = params;

  const earthRadius = 6378137;
  const lat = (center.lat * Math.PI) / 180;
  const lng = (center.lng * Math.PI) / 180;
  const d = radius / earthRadius;

  for (let i = 0; i <= 360; i += detail) {
    const brng = (i * Math.PI) / 180;
    const pLatTmp = Math.asin(
      Math.sin(lat) * Math.cos(d) + Math.cos(lat) * Math.sin(d) * Math.cos(brng)
    );
    const pLng =
      ((lng +
        Math.atan2(
          Math.sin(brng) * Math.sin(d) * Math.cos(lat),
          Math.cos(d) - Math.sin(lat) * Math.sin(pLatTmp)
        )) *
        180) /
      Math.PI;
    const pLat = (pLatTmp * 180) / Math.PI;

    path.push({ lat: pLat, lng: pLng });
  }

  return path;
}

function toPathParam(overlay: OverlayProp) {
  const path = [];

  // path styles
  if (overlay.info.strokeColor)
    path.push(`color:${htmlToHex(overlay.info.strokeColor)}`);
  if (overlay.info.fillColor)
    path.push(`fillcolor:${htmlToHex(overlay.info.fillColor)}`);

  if (overlay.type === 'rectangle') {
    const pathArr = boundsToArr(overlay.info.bounds).map(toLatLng);
    path.push(...pathArr);
    // add closing path
    if (pathArr[0]) path.push(pathArr[0]);
  } else if (overlay.type === 'polygon') {
    const pathsArr: any[] = overlay.info.paths;
    pathsArr[0].forEach((point: any) => {
      path.push(toLatLng(point));
    });
    // add closing path
    if (pathsArr[0][0]) path.push(toLatLng(pathsArr[0][0]));
    if (pathsArr.length > 1) {
      log.warn(
        `Polygon looks complex, containing multiple arrays: ${pathsArr.length}`
      );
    }
  } else if (overlay.type === 'circle') {
    const pathArr = circleToArr(overlay.info).map(toLatLng);
    path.push(...pathArr);
    if (pathArr[0]) path.push(pathArr[0]);
  } else {
    log.warn(`Unknown overlay type: ${overlay.type}`);
  }

  return path.join('|');
}

function getImageUrl(props: any) {
  const {
    zoom = settings.maps.zoom,
    center = settings.maps.center,
    // bounds,
    width = 640,
    maptype = 'satellite',
    overlays = [],
  } = props;

  const { height = (width * 9) / 16 } = props;

  // gmap query
  const query: any = {
    center: encodeURIComponent(`${center.lat},${center.lng}`),
    size: `${width}x${height || width}`,
    maptype,
    scale: 2,
    key: settings.maps.googleApiKey,
  };

  // query array
  const qArr = Object.keys(query).map((key: string) => {
    return `${key}=${query[key]}`;
  });

  overlays.forEach((overlay: any) => {
    qArr.push(`path=${encodeURIComponent(toPathParam(overlay))}`);
  });

  // if (!bounds) {
  //   const visible = boundsToArr(bounds).map(toLatLng);
  //   qArr.push(`visible=${visible.join('|')}`);
  // } else {
  qArr.push(`zoom=${zoom - 1}`);
  // }

  const url = `${gmapBaseUrl}?${qArr.join('&')}`;
  return { url, height, width };
}

function GmapImage(props: GmapImageProps) {
  const { sx } = props;

  const { url } = getImageUrl(props);

  // https://developers.google.com/maps/documentation/maps-static/start#url-size-restriction
  if (url.length >= 8192) {
    log.warn(`URL to long: ${url.length}`);
  } else {
    log.debug(`URL length: ${url.length}`);
  }

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Box sx={sx}>
          <img src={url} alt="gmap" style={{ width: '100%' }} />
        </Box>
      </Box>
    </div>
  );
}

export default GmapImage;
export { getImageUrl };
export type { GmapImageProps };
