import * as supabase from '@supabase/supabase-js';
// import log from 'loglevel';

let client: supabase.SupabaseClient;

async function getClient() {
  if (client) return client;

  const { REACT_APP_SUPABASE_URL = '', REACT_APP_SUPABASE_ANON_KEY = '' } =
    process.env;

  client = supabase.createClient(
    REACT_APP_SUPABASE_URL,
    REACT_APP_SUPABASE_ANON_KEY,
    {
      auth: {
        autoRefreshToken: true,
      },
      realtime: {
        params: {
          eventsPerSecond: 10,
        },
      },
    }
  );

  return client;
}

async function signIn(params: any) {
  const client = await getClient();
  const { data: { user }, error } = await client.auth.signInWithPassword(params);
  return { user, error };
}

async function signOut() {
  const client = await getClient();
  return client.auth.signOut();
}

async function getUser() {
  const client = await getClient();
  const { data: { user } } = await client.auth.getUser();
  return user;
}

async function onAuthStateChange(fn: any) {
  const client = await getClient();
  return client.auth.onAuthStateChange(fn);
}

async function updateUser(params: any) {
  const client = await getClient();
  return client.auth.updateUser(params);
}

async function azureSignIn() {
  const client = await getClient();
  return client.auth.signInWithOAuth(
    {
      provider: 'azure',
    },
    // {
    //   scopes: 'User.Read Mail.Send',
    // }
  );
}

export default getClient;
export {
  getClient,
  signIn,
  signOut,
  getUser,
  updateUser,
  onAuthStateChange,
  azureSignIn,
};
