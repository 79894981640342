import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import log from 'loglevel';
import * as datefns from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import { EventType } from '../../comp/DataStore/Appointments';
import InputAddress from '../../comp/GoogleMap/InputAddress';
import ProspectsAutocomplete from '../../comp/Autocomplete/Prospects';
import UsersAutocomplete from '../../comp/Autocomplete/Users';
import ErrorHandler from '../../comp/ErrorHandler';

import WarnFilters from './WarnFilters';

type Props = {
  newRecord?: boolean;
};

function formatDate(record: any) {
  const nextHour = datefns.startOfHour(datefns.addHours(new Date(), 1));
  const defStart = datefns.startOfHour(nextHour).toISOString();
  const defEnd = datefns.addHours(nextHour, 1).toISOString();
  const startFmt = datefns.parseISO(record.start || defStart);
  const endFmt = datefns.parseISO(record.end || defEnd);
  return {
    ...record,
    start: datefns.format(startFmt, "yyyy-MM-dd'T'HH:mm"),
    end: datefns.format(endFmt, "yyyy-MM-dd'T'HH:mm"),
  };
}

function getRefresh(params: any) {
  const {
    datastore,
    setLoading,
    setData,
    setProspect,
    // setProspectsLoading,
    setMap,
    setProposal,
    setAssignedTo,
    // setAssignedToLoading,
  } = params;

  return async (id: string) => {
    setLoading(true);
    const record = await datastore.appointments.get({ id });

    setData({
      loaded: true,
      record: formatDate(record),
    });
    setMap(record.map);

    // setProspectsLoading(true);
    // const prospectPromise = prospects
    //   .get({ id: record.prospect_id })
    //   .then((p: any) => {
    //     log.debug('set prospect', p);
    //     setProspect(p);
    //   })
    //   .finally(() => setProspectsLoading(false));
    setProspect({ id: record.prospect_id });

    // set assigned to
    // let userPromise: Promise<any> = Promise.resolve();
    if (record.assigned_to) {
      setAssignedTo({
        email: record.assigned_to
      });
      // setAssignedToLoading(true);
      // userPromise = users
      //   .refresh()
      //   .then(async () => {
      //     return users.get({ email: record.assigned_to });
      //   })
      //   .then((u: any) => {
      //     log.debug('set assigned to', u);
      //     setAssignedTo(u);
      //   })
      //   .finally(() => {
      //     setAssignedToLoading(false);
      //   });
    }

    // set proposal id
    let proposalPromise: Promise<any> = Promise.resolve();
    if (record.proposal_id) {
      proposalPromise = datastore.proposals
        .get({ id: record.proposal_id })
        .then((p: any) => setProposal(p));
    }

    await Promise.all([
      // prospectPromise,
      proposalPromise,
      // userPromise
    ]);

    setLoading(false);
  };
}



function EditAppointment(props: Props) {
  const datastore = useDataStore();
  const { newRecord = false } = props;

  const navigate = useNavigate();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  let newRecordDefaults: any = {};
  if (newRecord) {
    id = uuidv4();
    try {
      const p: string = searchParams.get('p') || '';
      if (p) newRecordDefaults = JSON.parse(decodeURIComponent(p));
    } catch (e) {
      log.error(e);
    }
  }

  const appointmentsState = datastore.appointments.getState();
  const existing = appointmentsState.records.find((x: any) => x.id === id);

  const initial = {
    record: formatDate(existing || { ...newRecordDefaults, id }),
    proposal: {},
    prospect: {},
    loaded: false,
  };

  // Record state (selection)
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(initial);
  const [error, setError] = React.useState<any>();
  const [prospect, setProspect] = React.useState<any>({});
  const [assignedTo, setAssignedTo] = React.useState<any>({});
  const [map, setMap] = React.useState<any>();
  const [proposal, setProposal] = React.useState<any>({});
  const addressRef = React.useRef<HTMLInputElement>(null);
  const titleRef = React.useRef<HTMLInputElement>(null);

  const refresh = getRefresh({
    datastore,
    setLoading,
    setData,
    setProspect,
    setMap,
    setProposal,
    setAssignedTo,
  });

  const filter = React.useMemo(() => {
    const f: string = searchParams.get('f') || '';
    let fobj: {
      proposal_id: string;
      prospect_id: string;
    } | undefined;
    try {
      if (f) fobj = JSON.parse(decodeURIComponent(f));
      if (fobj?.proposal_id) setProposal({ id: fobj.proposal_id });
      if (fobj?.prospect_id) setProspect({ id: fobj.prospect_id});
    } catch (e) {
      log.error(e);
    }
    return fobj;
  }, [searchParams]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formdata = new FormData(event.currentTarget);

    const startDate = new Date(formdata.get('start')?.toString() || Number.NaN);
    const endDate = new Date(formdata.get('end')?.toString() || Number.NaN);

    const payload: any = {
      id,
      title: formdata.get('title'),
      note: formdata.get('note'),
      type:
        formdata.get('type') === 'on'
          ? EventType.DROP_OFF
          : EventType.APPOINTMENT,
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      allDay: formdata.get('allDay') === 'on',
      address: addressRef.current?.value,
      map,
      assigned_to: assignedTo?.email,
      prospect_id: prospect?.id,
      proposal_id: proposal?.id,
    };

    const promise = newRecord
      ? datastore.appointments.create(payload)
      : datastore.appointments.update(payload);
    const result = await promise;
    if (result?.$error) {
      setError(result.$error);
      setLoading(false);
      throw result.$error;
    }

    log.debug('payload', payload);
    setData({ ...data, record: { ...data.record, ...payload } });

    setLoading(false);

    let navp: string = '';
    if (filter?.proposal_id || filter?.prospect_id) {
      navp =
        '?p=' +
        encodeURIComponent(
          JSON.stringify({
            proposal_id: filter?.proposal_id,
            prospect_id: filter?.prospect_id,
          })
        );
    }
    navigate(`/appointments${navp}`);
  };

  const handleDelete = async () => {
    setLoading(true);
    if (newRecord || !id) return;
    datastore.appointments.delete({ id });
    setLoading(false);
    navigate('/appointments');
  };

  const handleFocus = () => {
    setError(undefined);
  };

  const onChangeAddress = () => {
    setMap(null);
  };

  const handleAddress = (addr: any) => {
    setMap({
      center: addr.geometry.location.toJSON(),
    });
  };

  const onSelectProspect = React.useCallback(
    (e: any, value: any, reason: any) => {
      if (reason === 'selectOption') {
        log.debug('=== on select ===', { value });
        setProspect(value);
      }
    },
    []
  );

  const onSelectAssignedTo = (e: any, value: any, reason: any) => {
    if (reason === 'selectOption') {
      setAssignedTo(value);
    }
  };

  const toggleDropOff = () => {
    const newType =
      data.record.type === EventType.DROP_OFF
        ? EventType.APPOINTMENT
        : EventType.DROP_OFF;

    setData((curr: any) => ({
      ...curr,
      record: {
        ...(curr.record || {}),
        type: newType,
      },
    }));
  };

  const toggleAnytime = () => {
    const bool = Boolean(data.record?.allDay);
    setData((curr: any) => ({
      ...curr,
      record: {
        ...(curr.record || {}),
        allDay: !bool,
      },
    }));
  };

  // refresh loaded record
  React.useEffect(() => {
    if (newRecord) return;
    if (loading) return;
    if (data.loaded && data.record.id === id) {
      return;
    }
    refresh(String(id));
  }, [newRecord, loading, refresh, data.loaded, data.record.id, id]);

  // refresh address input
  React.useEffect(() => {
    if (!titleRef.current) return;
    if (!addressRef.current) return;
    const addr = data.record.address;
    if (addr && addr !== addressRef.current.value) {
      addressRef.current.value = addr;
    }
    const title = data.record.title;
    if (title && title !== titleRef.current.value) {
      titleRef.current.value = title;
    }
  }, [data.record.address, data.record.title]);

  // update proposal
  React.useEffect(() => {
    if (!proposal.id) return;
    if (proposal.id === data.proposal?.id) return;

    datastore.proposals.get({ id: proposal.id })
      .then((value: any) => {
        setData((curr: any) => ({
          ...curr,
          record: {
            ...curr.record,
            title: `Go to ${value.prospect_full_name}`,
            address: value.address,
            map: value.map,
          },
          proposal: value,
        }));
        setProposal(value);
        setProspect({ id: value.prospect_id });
        setMap(value.map);
        if (value.assigned_to) {
          setAssignedTo({ email: value.assigned_to });
        }
      });
  }, [
    datastore,
    filter,
    data,
    prospect,
    proposal,
  ]);

  // update prospect
  React.useEffect(() => {
    if (!prospect.id) return;
    if (prospect.id === data.prospect?.id) return;
    datastore.prospects.get({ id: prospect.id })
      .then((value: any) => {
        setData((curr: any) => ({
          ...curr,
          record: {
            ...curr.record,
            title: `Go to ${value.full_name}`,
            address: value.address,
            map: value.map,
          },
          prospect: value,
        }));
        setProspect(value);
        setMap(value.map);
      });
  }, [datastore, data, prospect]);

  return (
    <Paperbase title={newRecord ? 'New Appointment' : 'Edit Appointment'}  bottomSpacer="100px">
      {/* <HelperBox
        open={filterDrawer}
        onClick={toggleFilterDrawer}
        onClose={toggleFilterDrawer}
      >
        <Container sx={{ overflow: 'auto' }}>More filters if any ...</Container>
      </HelperBox> */}
      <Container component="main">
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Grid container spacing={2} justifyContent="center">
                {filter ? (
                  <Grid item xs={12}>
                    <WarnFilters prospect={prospect} />
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={8}>
                  <ProspectsAutocomplete
                    disabled={Boolean(proposal) || loading}
                    renderInput={(params: any) => {
                      const { isLoading, ...textFieldParams } = params;
                      return (
                        <TextField
                          {...textFieldParams}
                          key={`prospect_id-${data.record.id}`}
                          required
                          fullWidth
                          id="prospect_id"
                          label="Prospect"
                          name="prospect_id"
                          InputProps={{
                            ...textFieldParams.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {textFieldParams.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      );
                    }}
                    value={prospect}
                    onChange={onSelectProspect}
                    onInputChange={onSelectProspect}
                    // optional: TODO
                    options={[]}
                    loading={false}
                    getOptionLabel={() => ''}
                    isOptionEqualToValue={() => true}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    disabled={loading}
                    control={
                      <Switch
                        key={`type-${data.record.id}`}
                        id="type"
                        name="type"
                        checked={data.record.type === EventType.DROP_OFF}
                        // defaultChecked={data.record.type === EventType.DROP_OFF}
                        onChange={() => toggleDropOff()}
                        color="primary"
                      />
                    }
                    label="Drop Off"
                    labelPlacement="bottom"
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    disabled={loading}
                    control={
                      <Switch
                        key={`allDay-${data.record.id}`}
                        id="allDay"
                        name="allDay"
                        checked={data.record.allDay}
                        onChange={() => toggleAnytime()}
                        // defaultChecked={data.record.allDay}
                        color="primary"
                      />
                    }
                    label="Anytime"
                    labelPlacement="bottom"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`start-${data.record.id}`}
                    required
                    fullWidth
                    id="start"
                    label="Starts At"
                    name="start"
                    type="datetime-local"
                    disabled={loading}
                    defaultValue={data.record.start}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`end-${data.record.id}`}
                    required
                    fullWidth
                    id="end"
                    label="Ends At"
                    name="end"
                    type="datetime-local"
                    disabled={loading}
                    defaultValue={data.record.end}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputAddress
                    key={`address-${data.record.id}`}
                    textFieldProps={{
                      required: true,
                      fullWidth: true,
                      id: 'address',
                      label: 'Address',
                      name: 'address',
                      disabled: Boolean(proposal) || loading,
                      defaultValue: data.record.address,
                      InputLabelProps: {
                        shrink: true,
                      },
                      onChange: onChangeAddress,
                    }}
                    // customValue={data.record.address}
                    inputRef={addressRef}
                    onSelect={handleAddress}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    inputRef={titleRef}
                    key={`title-${data.record.title}`}
                    required
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    disabled={loading}
                    defaultValue={data.record.title}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UsersAutocomplete
                    disabled={loading}
                    renderInput={(params: any) => {
                      const { isLoading, ...textFieldParams } = params;
                      return (
                        <TextField
                          {...textFieldParams}
                          key={`assigned_to-${data.record.id}`}
                          required
                          fullWidth
                          id="assigned_to"
                          label="Assigned To"
                          name="assigned_to"
                          InputProps={{
                            ...textFieldParams.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {textFieldParams.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      );
                    }}
                    value={assignedTo}
                    onChange={onSelectAssignedTo}
                    onInputChange={onSelectAssignedTo}
                    // optional: TODO
                    options={[]}
                    loading={false}
                    getOptionLabel={() => ''}
                    isOptionEqualToValue={() => true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={`note-${data.record.id}`}
                    multiline
                    fullWidth
                    id="note"
                    label="Note"
                    name="note"
                    disabled={loading}
                    defaultValue={data.record.note}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Stack direction="row" spacing={3} justifyContent="right">
                      {newRecord ? (
                        <></>
                      ) : (
                        <Button
                          type="button"
                          variant="outlined"
                          disabled={loading}
                          startIcon={<DeleteIcon />}
                          onClick={handleDelete}
                        >
                          Delete
                        </Button>
                      )}
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        // size="large"
                        endIcon={<SendIcon />}
                      >
                        {newRecord ? 'Add' : 'Update'}
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ErrorHandler error={error} />
      </Container>
    </Paperbase>
  );
}

export default EditAppointment;
