import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as dateFns from 'date-fns';
import log from 'loglevel';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useDataStore } from '../../comp/DataStore';
import settings from '../../settings';

function getRefresh(params: any) {
  const { datastore, setLoading, prospectId, setProposalsState } = params;

  return async () => {
    setLoading(true);
    if (!prospectId) return;
    datastore.proposals.refresh({
      custom: (query: any) => {
        query.eq('prospect_id', prospectId);
        query.is('deleted_at', null);
        query.order('created_at', { ascending: false })
        query.limit(5);
      }
    })
      .then((result: any) => {
        setProposalsState((c: any) => ({
          ...c,
          records: result || []
        }))
      })
      .catch((err: any) => {
        log.error(err);
      })
      .finally(() => {
        setLoading(false);
      })
  };
}

type RecentProposalProps = {
  prospectId: string;
}

function RecentProposals(props: RecentProposalProps) {
  const { prospectId } = props;
  const datastore = useDataStore();
  const navigate = useNavigate();

  const initProposals = datastore.proposals.getState();
  const [proposalsState, setProposalsState] = React.useState<any>(initProposals);

  const [loading, setLoading] = React.useState(false);
  // const [genConfig, setGenConfig] = React.useState<any>({
  //   proposalStatus: '-',
  //   assignedTo: 'me',
  // }); // General Configuration
  const refreshRef = React.useRef({ runOnce: false });

  const refresh = getRefresh({ datastore, setLoading, prospectId, setProposalsState });

  const list = React.useMemo(() => {
    const pRecords = (proposalsState.records || [])
      .filter((p: any) => !p.deleted_at)
      .filter((p: any) => !p.parent_id)
      .filter((p: any) => {
        return p.prospect_id === prospectId;
      })
      .sort((a: any, b: any) => {
        const aDate = dateFns.parseISO(a.updated_at);
        const bDate = dateFns.parseISO(b.updated_at)
        return dateFns.differenceInMilliseconds(bDate, aDate);
      })
      .map((p: any) => {
        const updatedAt = dateFns.parseISO(p.updated_at);
        const createdAt = dateFns.parseISO(p.created_at);
        const year = p.year || dateFns.format(createdAt, 'yyyy');
        const statusCode = p.parent_status || p.status;
        const status = settings.proposalStatus.find((x) => x.value === statusCode);
        return (
          <ListItemButton
            key={p.id}
            sx={{ bgcolor: '#EBEDEF', mb: '5px' }}
            onClick={() => {
              navigate(`/proposals/${p.id}`);
            }}
          >
            <Grid container>
              <Grid item xs>
                <ListItemText primary={`${year} - ${status?.label}`} />
              </Grid>
              {/* <Grid item xs>
                <ListItemText primary={p.assigned_to} />
              </Grid> */}
              <Grid item xs={6} textAlign="right">
                <ListItemText primary={dateFns.format(updatedAt, 'M/d/yyyy')} />
              </Grid>
            </Grid>
          </ListItemButton>
        );
      });

    return pRecords;
  }, [navigate, proposalsState, prospectId]);

  // const onChangeGenConfig = (str: string) => (e: any) => {
  //   setGenConfig((curr: any) => ({
  //     ...curr,
  //     [str]: e.target.value,
  //   }));
  // };

  React.useEffect(() => {
    if (!refreshRef.current || refreshRef.current.runOnce) return;
    refreshRef.current.runOnce = true;
    refresh();
  }, [refresh]);

  React.useEffect(() => {
    const proposalId = datastore.proposals.subscribe(setProposalsState);
    return () => {
      datastore.proposals.unsubscribe(proposalId);
    }
  }, [datastore]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5">Recent Proposals</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none ' }}
            onClick={() => navigate(`/proposals/new?prospect_id=${prospectId}`)}
          >
            Add New
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <List>{list.length !== 0 ? list.slice(0, 5) : (
          <ListItemButton
            key={`no-records-found`}
            sx={{ bgcolor: '#EBEDEF', mb: '5px' }}
          >
            <ListItemText primary={`No records found`} />
          </ListItemButton>
        )}</List>
      </Grid>
    </Grid>
  );
}

export default RecentProposals;
