import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import log from 'loglevel';
import * as datefns from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import ViewEvent from './Event';

type Props = {
  newRecord?: boolean;
};

function formatDate(record: any) {
  const nextHour = datefns.startOfHour(datefns.addHours(new Date(), 1));
  const defStart = datefns.startOfHour(nextHour).toISOString();
  const defEnd = datefns.addHours(nextHour, 1).toISOString();
  const startFmt = datefns.parseISO(record.start || defStart);
  const endFmt = datefns.parseISO(record.end || defEnd);
  return {
    ...record,
    start: datefns.format(startFmt, "yyyy-MM-dd'T'HH:mm"),
    end: datefns.format(endFmt, "yyyy-MM-dd'T'HH:mm"),
    startFmt: datefns.format(startFmt, 'MMMM d, yyyy h:mm bbb'),
    endFmt: datefns.format(endFmt, 'MMMM d, yyyy h:mm bbb'),
    allDayFmt: datefns.format(startFmt, 'MMMM d, yyyy'),
  };
}

function getRefresh(params: any) {
  const {
    datastore,
    setData,
  } = params;

  return async (id: string) => {
    const record = await datastore.appointments.get({ id });
    setData({
      loaded: true,
      record: formatDate(record),
    });
  };
}

function ViewAppointment(props: Props) {
  // const { state, prospects, users, appointments, proposals } = useDataStore();
  const datastore = useDataStore();
  const { newRecord = false } = props;

  // const navigate = useNavigate();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  let newRecordDefaults: any = {};
  if (newRecord) {
    id = uuidv4();
    const p: string = searchParams.get('p') || '';
    try {
      if (p) newRecordDefaults = JSON.parse(p);
    } catch (e) {
      log.error(e);
    }
  }

  const initAppointments = datastore.appointments.getState();
  const [appointmentsState, setAppointmentsState] = React.useState<any>(initAppointments);

  const existing = appointmentsState.records.find((x: any) => x.id === id);

  const initial = {
    record: formatDate(existing || { ...newRecordDefaults, id }),
    loaded: false,
  };

  // Record state (selection)
  const [data, setData] = React.useState<any>(initial);

  const refresh = getRefresh({
    datastore,
    setData,
  });

  // refresh loaded record
  React.useEffect(() => {
    if (newRecord) {
      return;
    }
    if (data.loaded && data.record.id === id) {
      return;
    }
    refresh(String(id));
  }, [newRecord, refresh, data.loaded, data.record.id, id]);

  React.useEffect(() => {
    const appointmentsId = datastore.appointments.subscribe(setAppointmentsState);
    return () => {
      datastore.appointments.unsubscribe(appointmentsId);
    }
  }, [datastore]);

  return (
    <Paperbase title={'View Appointment'}  bottomSpacer="100px">
      <ViewEvent event={data.record} />
    </Paperbase>
  );
}

export default ViewAppointment;
