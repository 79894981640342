import React from 'react';
import log from 'loglevel';
import * as dateFns from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Table from '../../comp/Table';
import ProposalStatus from './ProposalStatus';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import settings from '../../settings';
import { ActionType } from '../../types/datastore.d';

function getHeaders(props: any) {
  const createLink = (to: string) => (e: any) => {
    e.preventDefault();
    props.navigate(to);
  };
  const root = 'proposals';

  const headers = [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props: any) => {
        const proposalId = props.row.original.id;
        const to = `/${root}/${proposalId}`;
        const status = settings.proposalStatus.find(
          (x) => x.value === props.value
        );
        return (
          <div className="clickable" onClick={createLink(to)}>
            <ProposalStatus proposalId={proposalId} status={props.value}>{String(status?.label)}</ProposalStatus>
          </div>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'prospect_full_name',
      Cell: (props: any) => {
        const row = props.row.original;
        const to = `/${root}/${row.id}`;

        const createdAt = dateFns.parseISO(row.created_at);
        const year = row.year || dateFns.format(createdAt, 'yyyy');

        return (
          <div className="clickable" onClick={createLink(to)}>
            <Typography>{`${String(props.value)} - ${year}`}</Typography>
            <Typography sx={{ fontSize: '90%' }}>
              {row.prospect_address}
            </Typography>
          </div>
        );
      },
    },
    {
      Header: 'Sales Lead',
      accessor: 'assigned_to',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value || '-')}
          </div>
        );
      },
    },
    {
      Header: 'Phone',
      accessor: 'prospect_phone_1',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value || '-')}
          </div>
        );
      },
    },
    {
      Header: 'Created At',
      accessor: 'createdAtFmt',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value || '-')}
          </div>
        );
      },
    },
    {
      // hidden field
      accessor: 'prospect_address',
    },
    {
      // hidden field
      accessor: 'search_column',
    },
  ];
  return headers;
}

function ListProposals() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = React.useState(false);
  const proposalRef = React.useRef({ loaded: false });
  const [search, setSearch] = React.useState<string>('');

  const initProposals = datastore.proposals.getState();
  const [proposalsState, setProposalsState] = React.useState<any>(initProposals);

  const paramObj = React.useMemo(() => {
    let initParamObj: any = {};
    const p: string = searchParams.get('p') || '';
    try {
      if (p) initParamObj = JSON.parse(p);
    } catch (e) {
      log.error(e);
    }
    return initParamObj;
  }, [searchParams]);

  React.useEffect(() => {
    if (proposalRef.current.loaded) return;
    if (loading) return;
    setLoading(true);
    datastore.proposals.getRange(paramObj).finally(() => {
      proposalRef.current.loaded = true;
      setLoading(false);
    });
  }, [loading, datastore.proposals, paramObj]);

  const headers = getHeaders({ navigate });
  const columns = React.useMemo(() => headers, [headers]);
  const data = React.useMemo(
    () =>
      (proposalsState.records || [])
        .filter((x: any) => !x.deleted_at)
        .filter((x: any) => !x.parent_id)
        .filter((x: any) => {
          if (paramObj.assigned_to) {
            return x.assigned_to === paramObj.assigned_to;
          }
          return true;
        })
        .map((r: any) => {
          const cat = dateFns.parseISO(r.created_at);
          const createdAtFmt = dateFns.format(cat, 'MMM d, yyyy h:mm bbb');
          const uat = dateFns.parseISO(r.updated_at);
          const updatedAtFmt = dateFns.format(uat, 'MMM d, yyyy h:mm bbb');
          return {
            ...r,
            updatedAtFmt,
            createdAtFmt,
          };
        }),
    [proposalsState, paramObj]
  );

  const onChangeSearch = () => {
    let timeout: any;
    return (e: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    };
  };

  const memoSearch = React.useMemo(() => search, [search]);

  React.useEffect(() => {
    if (!search) return;
    datastore.proposals.refresh({
      custom: (q: any) => {
        q.textSearch('search_column', search);
        q.is('deleted_at', null);
        q.is('parent_id', null);
      },
      type: ActionType.UPSERT,
    }).then((result: any[] | undefined) => {
      // do something with result
    });
  }, [datastore.proposals, search, memoSearch])

  React.useEffect(() => {
    const proposalsId = datastore.proposals.subscribe(setProposalsState);
    return () => {
      datastore.proposals.unsubscribe(proposalsId);
    }
  }, [datastore]);

  return (
    <Paperbase title="Proposals" bottomSpacer="100px">
      {paramObj.assigned_to ? (
        <Alert
          severity="warning"
          icon={<FilterAltIcon />}
          action={
            <Button
              size="small"
              color="inherit"
              onClick={() => {
                // setParamObj({});
                navigate('/proposals');
              }}
            >
              Clear
            </Button>
          }
          sx={{ mb: 2, border: 1, borderColor: 'orange' }}
        >
          Showing only proposals assigned to you
        </Alert>
      ) : (
        <></>
      )}
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => navigate('/proposals/new')}>
          New Proposal
        </Button>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search..."
          onChange={onChangeSearch()}
        />
      </Stack>

      <div style={{ minHeight: 400, width: '100%' }}>
        <Table
          columns={columns}
          data={data}
          globalFilterValue={memoSearch}
          initialState={{
            hiddenColumns: [
              'prospect_address',
              'search_column',
            ],
            globalFilter: memoSearch,
          }}
        />
      </div>
    </Paperbase>
  );
}

export default ListProposals;
