import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Auth from './comp/Auth';
import Protected from './comp/Auth/Protected';

import Dashboard from './pages/Dashboard';

import Proposals from './pages/Proposals';
import EditProposal from './pages/Proposals/EditProposal';
import EditMap from './pages/Proposals/EditMap';
import EditProposalServices from './pages/Proposals/EditServices';
import ViewProposal from './pages/Proposals/ViewProposal';

import Appointments from './pages/Appointments';
import EditAppointment from './pages/Appointments/EditAppointment';
import ViewAppointment from './pages/Appointments/ViewAppointment';

import Prospects from './pages/Prospects';
import ViewProspect from './pages/Prospects/ViewProspect';
import EditProspects from './pages/Prospects/EditProspects';

import Services from './pages/Services';
import EditService from './pages/Services/EditService';

import Programs from './pages/Programs';
import EditProgram from './pages/Programs/EditProgram';

import Settings from './pages/Settings';

import Users from './pages/Users';
import SignIn from './pages/Users/SignIn';
import Profile from './pages/Users/Profile';
import EditUser from './pages/Users/EditUser';
import NotFound from './pages/NotFound';
import RedirectNotification from './pages/Notifications/Redirect';

import NotificationListener from './comp/Notifications/Listener';
import ServiceWorker from './comp/ServiceWorker';

import IsAdmin from './comp/Auth/IsAdmin';
import usePageTracking from './comp/GoogleAnalytics/usePageTracking';

function App() {
  usePageTracking();
  
  const protectPage = (elem: React.ReactNode) => (
    <Protected fallback={<SignIn />}>{elem}</Protected>
  );

  return (
    <>
      <ServiceWorker />
      <NotificationListener />
      <Auth />
      <Routes>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/" element={protectPage(<Dashboard />)}></Route>
        <Route path="/dashboard" element={protectPage(<Dashboard />)}></Route>

        <Route path="/proposals" element={protectPage(<Proposals />)}></Route>
        <Route
          path="/proposals/:id"
          element={protectPage(<ViewProposal />)}
        ></Route>
        <Route
          path="/proposals/:id/edit-map"
          element={protectPage(<EditMap />)}
        ></Route>
        <Route
          path="/proposals/:id/edit-services"
          element={protectPage(<EditProposalServices />)}
        ></Route>
        <Route
          path="/proposals/:id/edit"
          element={protectPage(<EditProposal />)}
        ></Route>
        <Route
          path="/proposals/new"
          element={protectPage(<EditProposal newRecord />)}
        ></Route>

        <Route
          path="/appointments"
          element={protectPage(<Appointments />)}
        ></Route>
        <Route
          path="/appointments/:id"
          element={protectPage(<ViewAppointment />)}
        ></Route>
        <Route
          path="/appointments/:id/edit"
          element={protectPage(<EditAppointment />)}
        ></Route>
        <Route
          path="/appointments/new"
          element={protectPage(<EditAppointment newRecord />)}
        ></Route>

        <Route path="/prospects" element={protectPage(<Prospects />)}></Route>
        <Route
          path="/prospects/new"
          element={protectPage(<EditProspects newRecord />)}
        ></Route>
        <Route
          path="/prospects/:id"
          element={protectPage(<ViewProspect />)}
        ></Route>
        <Route
          path="/prospects/:id/edit"
          element={protectPage(<EditProspects />)}
        ></Route>

        <Route path="/services" element={protectPage(<Services />)}></Route>
        <Route
          path="/services/new"
          element={protectPage(<EditService newRecord />)}
        ></Route>
        <Route
          path="/services/:id"
          element={protectPage(<EditService />)}
        ></Route>

        <Route path="/programs" element={protectPage(<Programs />)}></Route>
        <Route
          path="/programs/new"
          element={protectPage(<EditProgram newRecord />)}
        ></Route>
        <Route
          path="/programs/:id"
          element={protectPage(<EditProgram />)}
        ></Route>
        <Route
          path="/notifications/:id"
          element={protectPage(<RedirectNotification />)}
        ></Route>

        <Route path="/users/profile" element={protectPage(<Profile />)}></Route>

        <Route
          path="/settings/*"
          element={protectPage(
            <IsAdmin>
              <Settings />
            </IsAdmin>
          )}
        ></Route>
        {/* <Route
          path="/settings"
          element={protectPage(
            <IsAdmin>
              <Settings />
            </IsAdmin>
          )}
        ></Route> */}
        <Route
          path="/users"
          element={protectPage(
            <IsAdmin>
              <Users />
            </IsAdmin>
          )}
        ></Route>
        <Route
          path="/users/new"
          element={protectPage(
            <IsAdmin>
              <EditUser newRecord />
            </IsAdmin>
          )}
        />
        <Route
          path="/users/:id"
          element={protectPage(
            <IsAdmin>
              <EditUser />
            </IsAdmin>
          )}
        />
      </Routes>
    </>
  );
}

export default App;
