import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    appointment: Palette['primary'];
    dropoff: Palette['primary'];
  }
  interface PaletteOptions {
    appointment: PaletteOptions['primary'];
    dropoff: PaletteOptions['primary'];
  }
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#117A65',
    },
    secondary: {
      main: '#76D7C4',
    },
    info: {
      main: '#64748B',
      contrastText: '#fff',
    },
    appointment: {
      main: '#D68910',
      light: '#F8C471',
      dark: '#873600',
      contrastText: '#FFF',
    },
    dropoff: {
      main: '#2980B9',
      light: '#85C1E9',
      dark: '#1A5276',
      contrastText: '#FFF',
    },
  },
  zIndex: {
    appBar: 50000,
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 100000,
        },
      },
    },
  },
});

export default customTheme;
