import * as React from 'react';
import log from 'loglevel';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';

import BlankTile from '../../comp/DashboardTiles/BlankTile';
import PaperTile from '../../comp/DashboardTiles/PaperTile';
import TotalSales from '../../comp/DashboardTiles/TotalSales';
import SalesByEmployee from '../../comp/DashboardTiles/SalesByEmployee';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import RecentProposals from './RecentProposals';
import MyAppointments from './MyAppointments';
import ProposalStats from './Stats';
import Filters, { GeneralFilters } from './Filters';

const Tiles: any = [
  {
    id: 'blank-tile',
    Component: BlankTile,
  },
  {
    id: 'recent-proposals',
    Component: RecentProposals,
  },
  {
    id: 'appointments',
    Component: MyAppointments,
  },
  {
    id: 'total-sales',
    Component: TotalSales,
  },
  {
    id: 'sales-by-employee',
    Component: SalesByEmployee,
  },
  {
    id: 'proposal-stats',
    Component: ProposalStats,
  }
];

const tileLayout = [
  {
    tileId: '',
  },
  {
    tileId: '',
  },
  {
    tileId: '',
  },
];

function Dashboard() {
  const [generalFilters, setGeneralFilters] = React.useState<GeneralFilters>();
  const onChangeFilter = (f: GeneralFilters) => setGeneralFilters(f);

  const getTileComponent = React.useCallback((tileId: string, options?: any) => {
    const Tile = Tiles.find((x: any) => x.id === tileId);
    if (!Tile) return <></>;
    return (
      <PaperTile>
        <Tile.Component filter={generalFilters} />
      </PaperTile>
    );
  }, [generalFilters]);
  
  return (
    <Paperbase title="Dashboard" bottomSpacer="100px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Filters onChange={onChangeFilter}/>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {getTileComponent('recent-proposals')}
            </Grid>
            <Grid item xs={12} style={{width: '0px'}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="stats"
                >
                  <Typography variant="h5">Metrics</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {getTileComponent('proposal-stats')}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="calendar"
                >
                  <Typography variant="h5">Calendar</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {getTileComponent('appointments')}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {getTileComponent('total-sales')}
            </Grid>
            <Grid item xs={12}>
              {getTileComponent('sales-by-employee')}
            </Grid>
            {/* <Grid item xs={12}>
              <BlankTile />
            </Grid>
            <Grid item xs={12}>
              <BlankTile />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Paperbase>
  );
}

export default Dashboard;
