import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

import UploadButton, { FileCategory } from '../../comp/UploadButton';
import ImageSet from '../../comp/ImageSet';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import { useDataStore } from '../../comp/DataStore';
import settings from '../../settings';
import { SettingsKey } from '../../comp/DataStore/Settings';
import {
  SeasonList,
  ServiceType,
  PricingModel,
} from '../../comp/DataStore/Services';

const Input = styled('input')({
  display: 'none',
});

function getRefresh(params: any) {
  const { datastore, setLoading, setPriceModel, setData } = params;

  return async (id: string) => {
    setLoading(true);
    const record = await datastore.services.get({ id });
    setPriceModel(record.pricing);
    setData({
      loaded: true,
      record,
    });
    setLoading(false);
  };
}

const priceFields = {
  [PricingModel.SQFT]: [
    { field: 'start_low_price', label: 'Start Low Price' },
    { field: 'start_high_price', label: 'Start High Price' },
    { field: 'low_price_rate', label: 'Low Price Rate' },
    { field: 'high_price_rate', label: 'High Price Rate' },
  ],
  [PricingModel.FIXED]: [
    { field: 'start_low_price', label: 'Start Price', xs: 12 },
  ],
  [PricingModel.OPEN]: [],
};

type Props = {
  newRecord?: boolean;
};

function EditService(props: Props) {
  const datastore = useDataStore();
  const { newRecord = false } = props;

  const navigate = useNavigate();
  const { id } = useParams();

  const initServices = datastore.services.getState();
  const [servicesState, setServicesState] = React.useState<any>(initServices);

  const existing = servicesState.records.find((x: any) => x.id === id);

  const initial = {
    record: existing || {
      id: uuidv4(),
      season: SeasonList.ALL,
      type: ServiceType.CORE,
      pricing: PricingModel.SQFT,
      price_multiplier: 1,
    },
    loaded: false,
  };

  // Record state (selection)
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(initial);
  const [servSettings, setServSettings] = React.useState<any>({});
  const [priceModel, setPriceModel] = React.useState<PricingModel>(
    PricingModel.SQFT
  );
  const [warning, setWarning] = React.useState<string>('');
  const [error, setError] = React.useState<any>(null);
  const runOnce = React.useRef<boolean>(false);

  const refresh = getRefresh({ datastore, setLoading, setPriceModel, setData });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formdata = new FormData(event.currentTarget);
    const payload: any = {
      id: data.record.id,
      name: formdata.get('name'),
      description: formdata.get('description'),
      season: formdata.get('season'),
      type: formdata.get('type'),
      pricing: formdata.get('pricing'),
      price_multiplier: formdata.get('price_multiplier'),
      start_low_price: formdata.get('start_low_price'),
      start_high_price: formdata.get('start_high_price'),
      low_price_rate: formdata.get('low_price_rate'),
      high_price_rate: formdata.get('high_price_rate'),
      image_url: formdata.get('image_url'),
    };
    log.debug('payload', payload);
    const promise = newRecord
      ? datastore.services.create(payload)
      : datastore.services.update(payload);
    const result = await promise;
    log.debug('submit result', result);

    setData((curr: any) => ({
      ...curr,
      record: { ...curr.record, ...payload },
    }));
    setLoading(false);

    if (result.$error) {
      setError(result.$error);
    } else {
      navigate('/services');
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    if (newRecord || !id) return;
    datastore.services.delete({ id });
    setLoading(false);
    navigate('/services');
  };

  const handleUpload = (imgObj: any) => {
    const { urlPath } = imgObj;
    setData({ ...data, record: { ...data.record, image_url: urlPath } });
  };

  const onImageLoad = (p: any) => {
    const {
      naturalWidth,
      naturalHeight,
    } = p.ref;
    if (!naturalHeight || !naturalWidth) return;
    log.debug('Image size', { naturalWidth, naturalHeight});
    if (naturalHeight > naturalWidth) {
      setWarning('The image is too long for the proposal. Use a landscape photo or crop the photo.');
    } else {
      setWarning('')
    }
  }

  const handleFocus = () => {
    setError(null);
  };

  const handleSelectPricing = (e: any) => {
    setPriceModel(e.target.value);
  };

  const onChangeField = (key?: string) => (event: any) => {
    let newData = event;
    if (key) {
      newData = { [key]: event.target.value };
    }
    setData((curr: any) => ({ ...curr, record: { ...curr.record, ...newData }}));
  }

  const errorObj = React.useMemo(() => {
    log.debug('updating error Object');
    const value: any = {};
    if (!error) return value;
    if (error.name === 'ValidationError') {
      (error.inner || []).forEach((innerErr: any) => {
        value[innerErr.path] = innerErr;
      });
    }
    return value;
  }, [error]);

  const priceInput = priceFields[priceModel].map((item: any) => {
    return (
      <Grid item xs={item.xs || 6} key={item.field}>
        <TextField
          key={`${item.field}-${data.record.id}`}
          fullWidth
          type="number"
          id={item.field}
          label={item.label}
          name={item.field}
          value={data.record[item.field]}
          onChange={onChangeField(item.field)}
          disabled={loading}
          onFocus={handleFocus}
          error={Boolean(errorObj[item.field])}
          helperText={errorObj[item.field]?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    );
  });

  const defaultPriceButtons = React.useMemo(
    () =>
      (servSettings.defaultPricing || [])
        .filter((dp: any) => true)
        .map((dp: any) => {
          const defPrice = {
            start_low_price: dp.start_low_price,
            start_high_price: dp.start_high_price,
            low_price_rate: dp.low_price_rate,
            high_price_rate: dp.high_price_rate,
          };
          const onClick = () => onChangeField()(defPrice);
          return (
            <Button key={dp.name} variant="outlined" onClick={onClick}>
              {dp.name}
            </Button>
          );
        }),
    [servSettings]
  );

  React.useEffect(() => {
    if (runOnce.current) return;
    runOnce.current = true;
    datastore.settings
      .getKey(SettingsKey.SERVICES)
      .then((d: any) => setServSettings(d.data));
  }, [datastore.settings]);

  React.useEffect(() => {
    if (newRecord) return;
    if (loading) return;
    if (data.loaded && data.record.id === id) {
      return;
    }
    refresh(String(id));
  }, [
    newRecord,
    loading,
    refresh,
    data.loaded,
    data.record.id,
    id,
  ]);

  React.useEffect(() => {
    const servicesId = datastore.services.subscribe(setServicesState);
    return () => {
      datastore.services.unsubscribe(servicesId);
    }
  }, [datastore]);

  return (
    <Paperbase
      title={newRecord ? 'New Service' : 'Edit Service'}
      bottomSpacer="100px"
    >
      <Container component="main">
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={9}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={9}>
                  <TextField
                    key={`name-${data.record.id}`}
                    required
                    fullWidth
                    id="name"
                    label="Service Name"
                    name="name"
                    disabled={loading}
                    onFocus={handleFocus}
                    value={data.record?.name}
                    onChange={onChangeField('name')}
                    error={Boolean(errorObj['name'])}
                    helperText={errorObj['name']?.message}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth required>
                    <InputLabel id="pricing">Pricing</InputLabel>
                    <Select
                      key={`pricing-${data.record.id}`}
                      labelId="pricing"
                      id="pricing"
                      label="Pricing"
                      name="pricing"
                      disabled={loading}
                      value={priceModel}
                      onFocus={handleFocus}
                      onChange={handleSelectPricing}
                      error={Boolean(errorObj['pricing'])}
                    >
                      {settings.pricingModel.map((priceModel) => (
                        <MenuItem
                          key={priceModel.value}
                          value={priceModel.value}
                        >
                          {priceModel.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth required>
                    <InputLabel id="season">Season</InputLabel>
                    <Select
                      key={`season-${data.record.id}`}
                      labelId="season"
                      id="season"
                      label="Season"
                      name="season"
                      disabled={loading}
                      value={data.record?.season}
                      onFocus={handleFocus}
                      onChange={onChangeField('season')}
                      error={Boolean(errorObj['season'])}
                    >
                      {settings.seasons
                        .sort((a: any, b: any) => a.order - b.order)
                        .map((season) => (
                          <MenuItem key={season.value} value={season.value}>
                            {season.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth required>
                    <InputLabel id="type">Type</InputLabel>
                    <Select
                      key={`type-${data.record.id}`}
                      labelId="type"
                      id="type"
                      label="Type"
                      name="type"
                      disabled={loading}
                      value={data.record?.type}
                      onFocus={handleFocus}
                      onChange={onChangeField('type')}
                      error={Boolean(errorObj['type'])}
                    >
                      {settings.serviceType.map((sType) => (
                        <MenuItem key={sType.value} value={sType.value}>
                          {sType.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    key={`name-${data.record.id}`}
                    required
                    fullWidth
                    id="price_multiplier"
                    label="Multiplier"
                    name="price_multiplier"
                    type="number"
                    disabled={loading}
                    inputProps={{ min: 1 }}
                    value={data.record?.price_multiplier || 1}
                    error={Boolean(errorObj['price_multiplier'])}
                    onChange={onChangeField('price_multiplier')}
                    helperText={errorObj['price_multiplier']?.message}
                  />
                </Grid>

                {priceInput.length === 0 ? (
                  <></>
                ) : (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {priceInput}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    key={`desc-${data.record.id}`}
                    fullWidth
                    multiline
                    id="description"
                    label="Description"
                    name="description"
                    value={data.record?.description}
                    disabled={loading}
                    onFocus={handleFocus}
                    onChange={onChangeField('description')}
                    error={Boolean(errorObj['description'])}
                    helperText={errorObj['description']?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <ImageSet
                    key={`image-url-${data.record.id}`}
                    src={data.record.image_url}
                    alt={data.record.name}
                    useOrig
                    onLoad={onImageLoad}
                  />
                  <Input
                    key={`image-inp-${data.record.id}`}
                    type="hidden"
                    name="image_url"
                    value={data.record.image_url}
                    readOnly
                  />
                  {errorObj['image_url']?.message ? (
                    <Typography
                      color="error"
                      sx={{
                        fontSize: '80%',
                      }}
                    >
                      {errorObj['image_url']?.message}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {!warning ? (
                    <></>
                  ) : (
                    <Alert severity="warning">
                      {'Warning: '}
                      {warning}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Divider>Default Pricing</Divider>
                  <Stack direction="column" spacing={1}>
                    {defaultPriceButtons}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ControlBar>
            <Grid container>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Stack direction="row" spacing={3} justifyContent="right">
                    {newRecord ? (
                      <></>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={loading}
                        startIcon={<DeleteIcon />}
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                    <UploadButton
                      category={FileCategory.IMAGES}
                      inputProps={{
                        accept: 'image/png, image/jpeg',
                      }}
                      buttonProps={{
                        disabled: loading,
                        variant: 'outlined',
                      }}
                      onUpload={handleUpload}
                    >
                      Upload Image
                    </UploadButton>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                      // size="large"
                      endIcon={<SendIcon />}
                    >
                      {newRecord ? 'Add' : 'Update'}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </ControlBar>
        </Box>
      </Container>
    </Paperbase>
  );
}

export default EditService;
