import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import customTheme from './customTheme';

let theme = createTheme(
  deepmerge(
    {
      shape: {
        borderRadius: 0,
      },
      components: {
        MuiTab: {
          defaultProps: {
            disableRipple: true,
          },
        },
      },
    },
    customTheme
  )
);

const drawerWidth = 200;

type Props = {
  title?: React.ReactNode | string;
  children?: React.ReactNode | string | number;
  bottomSpacer?: string | number;
};

function Paperbase(props: Props) {
  const { title, children, bottomSpacer = '0px' } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <CssBaseline />
        <Box component="nav">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onClick={handleDrawerToggle}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Header title={title} onDrawerToggle={handleDrawerToggle} />
          <Box component="main">
            <Content>{children}</Content>
          </Box>
          {bottomSpacer ? (
            <Box sx={{ display: 'flex', minHeight: bottomSpacer }}></Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Paperbase;
