import React from 'react';
import { useNavigate } from 'react-router-dom';
import useDataStore from '../DataStore';

type Props = {
  children?: React.ReactNode | string | number;
};

// let runOnce = false;
function Auth(props: Props) {
  const { auth } = useDataStore();
  const runOnce = React.useRef<any>(false);

  const navigate = useNavigate();

  // Listen to auth
  React.useEffect(() => {
    if (runOnce.current === undefined) return;
    if (runOnce.current) return; // already listening
    runOnce.current = true;
    auth.listen();
  }, [auth]);

  React.useEffect(() => {
    const { redirect } = auth.getState();
    if (redirect) {
      auth.clearRedirect().then(() => navigate(redirect));
    }
  }, [auth, navigate]);

  // auth.listen();
  auth.check();

  return <>{props.children}</>;
}

export default Auth;
