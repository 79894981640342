// import log from 'loglevel';

// https://developers.google.com/maps/documentation/javascript/reference/geometry#spherical
const earthRadius = 6378137;

// compute area of circle on a sphere
// https://math.stackexchange.com/questions/1832110/area-of-a-circle-on-sphere#:~:text=The%20circumference%20of%20a%20sphere,the%20circumference%20of%20the%20sphere).
function computeCircleArea(r: number, options?: any) {
  const { R = earthRadius } = options || {};
  const area = 2 * Math.PI * Math.pow(R, 2) * (1 - Math.cos(r / R));
  return area;
}

function rectangleGetPath(bounds: google.maps.LatLngBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const se = new google.maps.LatLng(sw.lat(), ne.lng());
  const nw = new google.maps.LatLng(ne.lat(), sw.lng());

  const path: google.maps.MVCArray<google.maps.LatLng> =
    new google.maps.MVCArray([ne, se, sw, nw]);
  return path;
}

function toJsonFn(data: any) {
  const { overlay } = data;
  return () => {
    const jsonData = {
      id: data.id,
      type: data.type,
      info: {},
      area_sqm: 0,
      area_sqft: 0,
    };

    if (data.type === google.maps.drawing.OverlayType.CIRCLE) {
      Object.assign(jsonData.info, {
        strokeColor: overlay.get('strokeColor'),
        fillColor: overlay.get('fillColor'),
        center: overlay?.getCenter().toJSON(),
        radius: overlay.getRadius(),
      });
      jsonData.area_sqm = computeCircleArea(overlay.getRadius());
    } else if (data.type === google.maps.drawing.OverlayType.RECTANGLE) {
      Object.assign(jsonData.info, {
        strokeColor: overlay.get('strokeColor'),
        fillColor: overlay.get('fillColor'),
        bounds: overlay.getBounds().toJSON(),
      });
      const recPath = rectangleGetPath(overlay.getBounds());
      jsonData.area_sqm = google.maps.geometry?.spherical.computeArea(recPath);
    } else if (data.type === google.maps.drawing.OverlayType.POLYGON) {
      Object.assign(jsonData.info, {
        strokeColor: overlay.get('strokeColor'),
        fillColor: overlay.get('fillColor'),
        paths: overlay
          .getPaths()
          .getArray()
          .map((arr: any) => arr.getArray().map((x: any) => x.toJSON())),
      });
      jsonData.area_sqm = google.maps.geometry?.spherical.computeArea(
        overlay.getPath()
      );
    }

    jsonData.area_sqft = jsonData.area_sqm * 10.7639; // convert
    return jsonData;
  };
}

function drawOverlay(params: any) {
  const data: any = { ...params, overlay: null };
  if (data.type === google.maps.drawing.OverlayType.CIRCLE) {
    data.overlay = new google.maps.Circle(data.info);
  } else if (data.type === google.maps.drawing.OverlayType.RECTANGLE) {
    data.overlay = new google.maps.Rectangle(data.info);
  } else if (data.type === google.maps.drawing.OverlayType.POLYGON) {
    data.overlay = new google.maps.Polygon(data.info);
  } else {
    throw new Error(`Unsupported overlay: ${data.type}`);
  }

  data.toJSON = toJsonFn(data);

  return data;
}

export { computeCircleArea, rectangleGetPath, toJsonFn, drawOverlay };
