import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as datefns from 'date-fns';
import log from 'loglevel';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { EventStatus } from '../../comp/DataStore/Appointments';
import { useDataStore } from '../../comp/DataStore';
import Calendar from '../../comp/Calendar';
import HelperBox from '../../comp/HelperBox';
import ViewEvent from '../Appointments/Event';
import { CustomEventWrapper } from '../Appointments/CustomComp';
import { GeneralFilters } from './Filters';

function formatDate(record: any) {
  const nextHour = datefns.startOfHour(datefns.addHours(new Date(), 1));
  const defStart = datefns.startOfHour(nextHour).toISOString();
  const defEnd = datefns.addHours(nextHour, 1).toISOString();
  const startFmt = datefns.parseISO(record.start || defStart);
  const endFmt = datefns.parseISO(record.end || defEnd);
  return {
    ...record,
    startFmt: datefns.format(startFmt, 'MMMM d, yyyy h:mm bbb'),
    endFmt: datefns.format(endFmt, 'MMMM d, yyyy h:mm bbb'),
    allDayFmt: datefns.format(startFmt, 'MMMM d, yyyy'),
  };
}

function getRefresh(params: any) {
  const { datastore, filter } = params;

  return async () => {
    const { user } = datastore.auth.getState();

    const now = new Date();
    const start = datefns.startOfWeek(now);
    const end = datefns.endOfWeek(now);
    const events = await datastore.appointments.getRange({
      assigned_to: user.email,
      start: start.toISOString(),
      end: end.toISOString(),
    });
    log.debug('assigned events', events);
  };
}

type ShowAppointmentsProps = {
  filter?: GeneralFilters;
}

function ShowAppointments(props: ShowAppointmentsProps) {
  const datastore = useDataStore();
  const navigate = useNavigate();

  const { filter } = props;

  const authState = datastore.auth.getState();

  const initAppointments = datastore.appointments.getState();
  const [appointmentsState, setAppointmentsState] = React.useState<any>(initAppointments);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  // const [directionsOpen, setDirectionsOpen] = React.useState(false);
  const [event, setEvent] = React.useState<any>({ current: {} });
  const refreshRef = React.useRef({ runOnce: false });

  const refresh = getRefresh({ datastore, filter });

  const defaultDate = new Date();

  // For selected event
  const eventMemo = React.useMemo(() => {
    const e = appointmentsState.records.find(
      (x: any) => x.id === event.current.id
    );
    return formatDate(e || {});
  }, [event, appointmentsState.records]);

  // For the calendar
  const eventsMemo = React.useMemo(() => {
    const myEvents = appointmentsState.records.filter((x: any) => {
      return x.assigned_to === authState.user?.email;
    });
    return myEvents;
  }, [appointmentsState.records, authState.user]);

  const onViewAll = React.useCallback(() => {
    const p = { assigned_to: authState.user?.email };
    const url = `/appointments?p=${encodeURIComponent(JSON.stringify(p))}`;
    navigate(url);
  }, [navigate, authState.user]);

  const toggleDetails = () => {
    setDetailsOpen(!detailsOpen);
  };

  const onSelectEvent = (e: any) => {
    setEvent({ current: formatDate(e) });
    setDetailsOpen(true);
  };

  const titleAccessor = (e: any) => {
    let done = '';
    if (e.status === EventStatus.DONE) done = '[Done] ';
    return `${done}${e.title}`;
  };

  React.useEffect(() => {
    if (!refreshRef.current || refreshRef.current.runOnce) return;
    refreshRef.current.runOnce = true;
    refresh();
  }, [refresh]);

  const components = React.useMemo(
    () => ({
      eventWrapper: CustomEventWrapper,
    }),
    []
  );

  React.useEffect(() => {
    const appointId = datastore.appointments.subscribe(setAppointmentsState);
    return () => datastore.appointments.unsubscribe(appointId);
  }, [datastore]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5">Upcoming Appointments</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none ' }}
            onClick={onViewAll}
          >
            View All
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <HelperBox
          width={500}
          open={detailsOpen}
          onClose={toggleDetails}
        >
          <ViewEvent event={eventMemo} />
        </HelperBox>
        <Calendar
          defaultDate={defaultDate}
          components={components}
          events={eventsMemo}
          toolbar={false}
          onSelectEvent={onSelectEvent}
          titleAccessor={titleAccessor}
          formats={{
            timeGutterFormat: (date, culture, localizer: any) => {
              return localizer.format(date, 'h a', culture);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ShowAppointments;
