import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as api from '../../api/irrigationApi';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  UpsertMode,
  ActionType,
  RecordStatus,
} from '../../types/datastore.d';
import { AddressComponentSchema, ProspectRecord } from './Prospects';

import settings from '../../settings';
import { ServiceModel } from './Services';
import { MessageType } from './Messages';
import { SeasonList, ServiceType } from './Services';
import { UserViewRecord } from './Users';
import { DatastoreKey } from '../../types/datastore.d';

const dataset = DatastoreKey.PROPOSALS;
const vwDataset = 'vw_proposals';
const dbTable = 'proposals';

enum ProposalStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REQUEST = 'REQUEST',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  ACCEPTED = 'ACCEPTED',
}

enum ProposalType {
  LAWN = 'LAWN',
  IRRIGATION = 'IRRIGATION',
  LAWN_AND_IRRIGATION = 'LAWN_AND_IRRIGATION',
  CUSTOM = 'CUSTOM',
}

function CustomServiceSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      name: yup.string().required(),
      description: yup.string().nullable().optional(),
      season: yup.string().required().oneOf(Object.values(SeasonList)),
      type: yup.string().required().oneOf(Object.values(ServiceType)),
      image_url: yup.string().optional(),
      quantity: yup.number().min(1).required().default(1),
      uom: yup.string().optional().default(''),
      fixed_price: yup.number().min(0),
    });
}

function ProposalSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      prospect_id: yup.string().uuid().required(),
      assigned_to: yup
        .string()
        .email()
        .default(undefined)
        .nullable()
        .optional(),
      updated_at: yup.string().required().default(new Date().toISOString()),
      map: yup
        .object()
        .default(null)
        .optional()
        .nullable()
        .noUnknown()
        .shape({
          address_components: yup
            .array()
            .required()
            .of(AddressComponentSchema()),
          zoom: yup.number().default(20).required(),
          center: yup.object().required().shape({
            lat: yup.number().required(),
            lng: yup.number().required(),
          }),
          area: yup.number().optional().nullable(),
          overlays: yup
            .array()
            .default(null)
            .nullable()
            .of(
              yup.object().shape({
                id: yup.string().uuid().required(),
                type: yup.string().required(),
                area_sqft: yup.number().required(),
                area_sqm: yup.number().required(),
                info: yup.object().required(),
              })
            ),
        }),
      status: yup.string().required().oneOf(Object.values(ProposalStatus)),
      address: yup.string().nullable(),
      prepared_by: yup.string().email().optional(),
      file: yup.string().nullable(),
      services: yup
        .object()
        .optional()
        .nullable()
        .noUnknown()
        .shape({
          percentIncrease: yup.number().min(0).optional(),
          difficulty: yup.number().min(0).max(100).optional(),
          records: yup
            .array()
            .default([])
            .of(
              yup
                .object()
                .noUnknown()
                .shape({
                  id: yup.string().required().uuid(),
                  quantity: yup.number().nullable(),
                  uom: yup.string().optional().default(''),
                  price: yup.number().nullable(),
                  fixed_price: yup.number().nullable(),
                  computed_price: yup.number().nullable(),
                  custom: CustomServiceSchema()
                    .optional()
                    .nullable()
                    .default(undefined),
                })
            ),
        }),
      parent_id: yup.string().nullable().optional(),
      year: yup.string().nullable().optional(),
      deleted_at: yup.string().optional().nullable(),
      accepted_at: yup.string().optional().nullable(),
      notes: yup
        .object()
        .noUnknown()
        .optional()
        .nullable()
        .shape({
          records: yup
            .array()
            .required()
            .of(
              yup.object().noUnknown().shape({
                id: yup.string().uuid().required(),
                note: yup.string().required(),
              })
            ),
        }),
      proposal_type: yup
        .string()
        .required()
        .oneOf(Object.values(ProposalType))
        .default(ProposalType.LAWN_AND_IRRIGATION),
      attachments: yup
        .object()
        .noUnknown()
        .optional()
        .nullable()
        .shape({
          records: yup.array().of(
            yup.object().noUnknown().shape({
              path: yup.string().required(),
              filename: yup.string().optional(),
            })
          ),
        }),
    });
}

type MapOverlay = {
  id: string;
  type: string;
  area_sqft: number;
  area_sqm: number;
  info: any;
};

interface Service extends ServiceModel {
  price: number;
}

type PageVars = {
  step: number;
  prospect: object;
  map: {
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
    overlays?: MapOverlay[];
    area?: number;
  };
  services: {
    hash?: string;
    difficulty: number;
    percentIncrease: number;
    records: Service[];
  };
};

type ProposalRecordService = {
  id: string;
  price?: number;
  fixed_price?: number;
  computed_price?: number;
  quantity?: number;
  uom?: string;
  custom?: {
    id: string;
    name: string;
    type: ServiceType;
    season: SeasonList;
    quantity: number;
    uom?: string;
    image_url: string;
    description: string;
    fixed_price: number;
  };
};

type ProposalRecord = {
  id: string;
  created_at?: string;
  prepared_by: string;
  prospect_id: string;
  assigned_to: string;
  map: {
    area: number;
    zoom: number;
    center: {
      lat: number;
      lng: number;
    };
    overlays: MapOverlay[];
    address_components: {
      types: string[];
      long_name: string;
      short_name: string;
    }[];
  };
  status: ProposalStatus;
  address?: string;
  updated_at: string;
  services: {
    records: ProposalRecordService[];
    difficulty: number;
    percentIncrease: number;
  };
  file?: string;
  parent_id?: string;
  year?: number;
  deleted_at?: string;
  notes: {
    records: {
      id: string;
      note: string;
    }[];
  };
  accepted_at: string;
  proposal_type: ProposalType;
  attachments?: {
    records: {
      path: string;
      filename?: string;
    }[];
  };
};
// deprecated
type ProposalModel = ProposalRecord;

type ProposalViewRecord = ProposalRecord & {
  created_at: string;
  prospect_full_name?: ProspectRecord['full_name'];
  prospect_title?: ProspectRecord['title'];
  prospect_first_name?: ProspectRecord['first_name'];
  prospect_last_name?: ProspectRecord['last_name'];
  prospect_address?: ProspectRecord['address'];
  prospect_address_1?: ProspectRecord['address_1'];
  prospect_address_2?: ProspectRecord['address_2'];
  prospect_city?: ProspectRecord['city'];
  prospect_state?: ProspectRecord['state'];
  prospect_zip?: ProspectRecord['zip'];
  prospect_map?: ProspectRecord['map'];
  prospect_phone_1?: ProspectRecord['primary_phone'];
  prospect_phone_2?: ProspectRecord['phone_2'];
  prospect_email?: ProspectRecord['email'];
  prepared_by_email?: UserViewRecord['email'];
  prepared_by_first_name?: UserViewRecord['first_name'];
  prepared_by_last_name?: UserViewRecord['last_name'];
  prepared_by_phone?: UserViewRecord['phone'];
  assigned_email?: UserViewRecord['email'];
  assigned_first_name?: UserViewRecord['first_name'];
  assigned_last_name?: UserViewRecord['last_name'];
  assigned_phone?: UserViewRecord['phone'];
  parent_status?: string;
  search_column?: string;
  $refreshedAt?: number;
  $status?: RecordStatus;
  $error?: any;
};

function getInitial() {
  const init: {
    records: ProposalViewRecord[];
    page: PageVars;
    error: string | null;
  } = {
    records: [],
    page: {
      step: 0,
      prospect: {},
      map: {
        zoom: settings.maps.zoom,
        center: settings.maps.center,
        overlays: [],
        area: undefined,
      },
      services: {
        difficulty: 0,
        percentIncrease: 0,
        records: [],
      },
    },
    error: null,
  };

  return init;
}

const expiryMs = 1000 * 60 * 10; // 5 minutes
function isExpired(record: { $refreshedAt?: number }) {
  const now = Date.now();
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, datastore, subscribers } = params;

  // initialize
  const state = { [dataset]: getInitial() };

  /**
   *
   * @param record
   * @returns
   */
  const notifyFn = async (record: any) => {
    const authState = datastore.auth.getState();

    const result = await datastore.messages.create({
      id: uuidv4(),
      created_at: new Date().toISOString(),
      created_by: authState.user?.email,
      message_type: MessageType.NOTIFICATION,
      assigned_to: record.assigned_to,
      proposal_id: record.id,
      prospect_id: record.prospect_id,
      data: {
        title: 'Proposal',
        message: 'A proposal has been assigned to you',
        url: `/proposals/${record.id}`,
        module: 'proposals',
        topic: 'assigned-to',
      },
    });
    log.debug('notification', result);

    // TODO: send as browser notification
    await api.createNotification({
      id: uuidv4(),
      assigned_to: record.assigned_to,
      module: 'proposals',
      reference_id: record.id,
      topic: 'assigned-to',
      payload: {
        title: 'Proposal',
        body: 'A proposal has been assigned to you',
        data: {
          url: `/proposals/${record.id}`,
        },
      },
    });

    return result;
  };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      ilike,
      eq,
      gte,
      lte,
      order,
      limit = 300,
      custom,
      type = ActionType.REFRESH,
    } = opts || {};
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (ilike) query.ilike(ilike.column, ilike.pattern);
    if (gte) query.gte(gte.column, gte.value);
    if (lte) query.lte(lte.column, lte.value);
    if (eq) query.eq(eq.column, eq.value);
    if (order) query.order(order.column, order.value);
    if (limit) query.limit(limit);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(`fetch ${dataset} error`, result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload: ProposalViewRecord[] = result.data.map((x) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: { id: string }) => {
    const { id } = opts || {};

    const existing = state[dataset].records.find((x) => x.id === id);
    if (existing && !isExpired(existing)) return existing;

    const data = await refreshFn({
      match: { id },
      type: ActionType.UPSERT,
    });

    if (!data || !data[0]) return undefined;

    const proposal = data[0];
    if (data[0].parent_id) {
      const parent = await getFn({ id: data[0].parent_id });
      if (parent) {
        proposal.assigned_to = parent.assigned_to;
        proposal.prospect_id = parent.prospect_id;
        proposal.status = parent.status;
        proposal.map = parent.map;
        proposal.address = parent.address;
        proposal.notes = parent.notes;
      }
    }

    return data ? proposal : undefined;
  };

  const getChildren = async (opts: { id: string }) => {
    const { id } = opts || {};

    const parent = await getFn({ id });
    if (!parent) return;

    const data = await refreshFn({
      match: { parent_id: id },
      custom: (query) => query.is('deleted_at', null),
      type: ActionType.UPSERT,
    });

    return data?.map((d) => ({
      ...d,
      assigned_to: parent.assigned_to,
      prospect_id: parent.prospect_id,
      status: parent.status,
      map: parent.map,
      address: parent.address,
      notes: parent.notes,
    }));
  };

  /**
   * Gets data from state by default.
   * If it does not exist - tries to get from database
   *
   * @param params
   * @returns {Array}
   */
  const getRangeFn = async (params?: { id?: string; assigned_to?: string }) => {
    const { id, assigned_to } = params || {};

    const result = await refreshFn({
      custom: (query) => {
        query.limit(100);
        if (id) {
          query.eq('id', id);
        }
        if (assigned_to) {
          query.eq('assigned_to', assigned_to);
        }
        query.is('deleted_at', null);
      },
      order: {
        column: 'updated_at',
        value: { ascending: false },
      },
      type: ActionType.UPSERT,
    });

    return result;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: {
      records: Partial<ProposalRecord>[];
      error: any;
    }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: Partial<ProposalRecord>,
      opts?: {
        pick?: (keyof ProposalRecord)[];
        onConflict?: keyof ProposalRecord;
        match?: Partial<ProposalRecord>;
      }
    ): Promise<Partial<ProposalViewRecord> | undefined> => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = ProposalSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: Partial<ProposalViewRecord> = await schema
        .validate(record, { abortEarly: false })
        .then((result) => {
          return result as Partial<ProposalViewRecord>;
        })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      const client = await supabase.getClient();
      if (mode === UpsertMode.UPSERT) {
        const { onConflict = 'id' } = opts || {};
        const result = await client
          .from(dbTable)
          .upsert([valid], { onConflict })
          .select();
        respond({ records: result?.data || [], error: result.error });
      } else if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await client.from(dbTable).insert([valid]).select();
        respond({ records: result?.data || [], error: result.error });
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const { match = { id: valid.id } } = opts || {};
        const result: any = await client
          .from(dbTable)
          .update(valid)
          .match(match)
          .select();
        respond({ records: result?.data, error: result.error });
      }

      if (!valid.id) return;
      return getFn({ id: valid.id });
    };
  };

  const resetPageFn = () => {
    const { page } = getInitial();
    dispatch({
      type: ActionType.UPDATE_PAGE,
      dataset,
      payload: page,
    });
  };

  const updatePageFn = (params: PageVars) => {
    dispatch({
      type: ActionType.UPDATE_PAGE,
      dataset,
      payload: params,
    });
  };

  const deleteChildFn = async (props: { id: string; parent_id: string }) => {
    log.debug(`delete child proposal ${props.id} from ${props.parent_id}`);
    const deleteFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
    return deleteFn(
      { id: props.id, deleted_at: new Date().toISOString() },
      {
        pick: ['id', 'deleted_at'],
        match: { id: props.id, parent_id: props.parent_id },
      }
    );
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  };

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  };

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getChildren,
    getRange: getRangeFn,
    getState: () => state[dataset],
    create: async (...p: any) => {
      console.log(p);
      const authState = datastore.auth.getState();

      const fn = getUpsertFn(UpsertMode.CREATE_ONLY);
      const record = await fn.apply({}, p);
      const samePerson =
        authState.user?.email && authState.user?.email === record?.assigned_to;

      if (!samePerson && record && !record.$error && record.assigned_to) {
        notifyFn({ ...record, ...p });
      }
      return record;
    },
    update: async (payload: any, opts?: any) => {
      console.log(payload);
      const authState = datastore.auth.getState();

      const existing = await getFn({ id: payload.id });
      const changeAssignment =
        existing &&
        payload.assigned_to &&
        existing.assigned_to !== payload.assigned_to;

      const samePerson =
        authState.user?.email && authState.user?.email === payload.assigned_to;

      const fn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      const record = await fn(payload, opts);
      if (changeAssignment && !samePerson && record && !record.$error) {
        notifyFn({ ...record, ...payload });
      }
      return record;
    },
    deleteChild: deleteChildFn,
    resetPage: resetPageFn,
    updatePage: updatePageFn,
    refresh: refreshFn,
    delete: async (payload: any) => {
      const deleteFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      return deleteFn(
        { id: payload.id, deleted_at: new Date().toISOString() },
        { pick: ['id', 'deleted_at'] }
      );
    },
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.deleted_at === null
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: ProposalModel[] = action.payload;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: any) => x.id === y.id
      );
      if (existing) Object.assign(existing, x);
      else newState[dataset].records.push(x);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.deleted_at === null
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.DELETE) {
    const records: ProposalModel[] = action.payload;
    const ids = records.map((r) => r.id);
    newState[dataset].records = newState[dataset].records
      .filter((r: ProposalModel) => !ids.includes(r.id))
      .filter((x: any) => x.deleted_at === null);
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE_PAGE) {
    const newVars: PageVars = action.payload;
    const currVars: PageVars = newState[dataset].page;
    newState[dataset].page = {
      ...currVars,
      ...newVars,
      services: {
        ...currVars.services,
        ...(newVars.services || {}),
      },
      map: {
        ...(currVars.map || {}),
        ...(newVars.map || {}),
      },
    };
    log.debug('page vars', { currVars, newVars: newState[dataset].page });
    return newState;
  }
  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
};

function BuildProposals(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, {
    [dataset]: getInitial(),
  });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({
      state,
      dispatch,
      datastore: datastore.current,
      subscribers,
    });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if (typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    });
  }, [state]);

  return <>{children}</>;
}

export default BuildProposals;
export {
  dataset,
  getInitial,
  getFunctions,
  reducer,
  ActionType,
  ProposalStatus,
  ProposalType,
  CustomServiceSchema,
};

export type {
  ProposalModel,
  ProposalRecord,
  ProposalRecordService,
  ProposalViewRecord,
};
