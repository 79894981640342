import React from 'react';

import { useDataStore } from '../DataStore';
import { UserRoles } from '../../settings';
import NotFound from '../../pages/NotFound';

type IsAdminProps = {
  children?: React.ReactNode,
}

function IsAdmin(props: IsAdminProps) {
  const datastore = useDataStore();
  const initAuthState = datastore.auth.getState();

  const [authState, setAuthState] = React.useState<any>(initAuthState);

  React.useEffect(() => {
    const authId = datastore.auth.subscribe(setAuthState);
    return () => datastore.auth.unsubscribe(authId);
  }, [datastore]);

  return authState.record?.role === UserRoles.ADMIN ? <>{props.children}</> : <NotFound />;
}

export default IsAdmin;
