import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pageName = location.pathname.split('/');

    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: pageName[1],
    });
  }, [location]);
};

export default usePageTracking;
