import * as React from 'react';
// import log from 'loglevel';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FaceIcon from '@mui/icons-material/Face';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Time from './Time';
import HelperBox from '../../HelperBox';
import { useDataStore } from '../../../comp/DataStore';
import { MessageType } from '../../../comp/DataStore/Messages';
import VersionCheck from './VersionCheck';

interface HeaderProps {
  title: React.ReactNode | string;
  onDrawerToggle: () => void;
}

function Header(props: HeaderProps) {
  const { title, onDrawerToggle } = props;
  const navigate = useNavigate();
  const datastore = useDataStore();

  const [notif, setNotif] = React.useState(false);

  const headerTitle =
    typeof title === 'string' ? <Typography>{title}</Typography> : <>{title}</>;

  const goToProfile = () => {
    navigate('/users/profile');
  };

  const handleNotifClose = () => {
    setNotif(false);
  };

  const onClickNotif = React.useCallback((notif: any) => async () => {
    setNotif(false);
    navigate(`/notifications/${notif.id}`);
  }, [navigate]);

  const initAuth = datastore.auth.getState();
  const initMessages = datastore.messages.getState();
  const [authState, setAuthState] = React.useState<any>(initAuth);
  const [messagesState, setMessagesState] = React.useState<any>(initMessages);

  const notifRecords: any[] = React.useMemo(() => {
    if (!authState.user?.email) return [];

    return messagesState.records
      .filter((r: any) => {
        return r.message_type === MessageType.NOTIFICATION
          && r.assigned_to === authState.user?.email
          && !r.data.read
      })
      .sort((a: any, b: any) => {
        return String(b.created_at).localeCompare(String(a.created_at));
      })
  }, [messagesState, authState]);

  const handleNotifClick = (event: any) => {
    if (notifRecords.length > 0) setNotif(true);
  };

  const notifCount: string | undefined = React.useMemo(() => {
    if (notifRecords.length > 99) return '99+';
    else if (notifRecords.length === 0) return undefined;
    else return String(notifRecords.length);
  }, [notifRecords]);

  const notifs = React.useMemo(() => {
    const list = notifRecords.map((notif) => {
      return (
        <ListItem disablePadding key={notif.id}>
          <ListItemButton onClick={onClickNotif(notif)}>
            <ListItemText
              primary={notif.data.title}
              secondary={notif.data.message}
            />
          </ListItemButton>
        </ListItem>
      );
    });

    if (notifRecords.length === 0) {
      list.push(
        <ListItem disablePadding key="no-notifications">
          <ListItemText primary="No Notifications" />
        </ListItem>
      );
    }

    return list;
  }, [notifRecords, onClickNotif]);

  React.useEffect(() => {
    const messagesId = datastore.messages.subscribe(setMessagesState);
    const authId = datastore.auth.subscribe(setAuthState);
    return () => {
      datastore.messages.unsubscribe(messagesId);
      datastore.auth.unsubscribe(authId);
    }
  }, [datastore]);

  return (
    <React.Fragment>
      <AppBar
        color="primary"
        position="sticky"
        elevation={0}
        style={{ backgroundColor: '#274e13' }}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            {/* <Grid sx={{ display: { sm: 'block', xs: 'block' } }} item> */}
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            <Grid item>{headerTitle}</Grid>

            <Grid item xs />

            <Grid item>
              <VersionCheck/>
            </Grid>

            <Grid item>
              <Time />
            </Grid>

            <Grid item>
              <IconButton color="inherit" onClick={handleNotifClick}>
                <Badge badgeContent={notifCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Grid>

            <Grid item>
              <Tooltip title="User Profile">
                <IconButton
                  color="inherit"
                  sx={{ p: 0.5 }}
                  onClick={goToProfile}
                >
                  <FaceIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <HelperBox open={notif} onClose={handleNotifClose}>
        <Box sx={{ overflow: 'auto' }}>
          <List>{notifs}</List>
        </Box>
      </HelperBox>
    </React.Fragment>
  );
}

export default Header;
