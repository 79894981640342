import axios from 'axios';
import log from 'loglevel';

import { getClient as getSbClient } from './supabase';

let client: any;
async function getClient(props: any = {}) {
  const { force = false, session } = props;

  if (!force && client) return client;

  const { REACT_APP_IRRIGATION_API_BASE_URL: irrApiBaseUrl = '' } = process.env;
  const token = session?.access_token;

  client = axios.create({
    baseURL: `${irrApiBaseUrl}/api`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    timeout: 30000, // waiting time
  });

  if (token) log.debug(`Session token set in irrigation api client`);
  else log.warn(`Session token is NOT set`);

  return client;
}

const checkErr = (err: any) => {
  if (err.response && err.response.data) {
    const { error } = err.response.data || {};
    throw new Error(error.message, error);
  } else throw err;
};

const addUser = async (data: any) => {
  const client = await getClient();
  const response = await client.post(`/users`, data).catch(checkErr);
  log.debug('RESPONSE', response.data);
  return response.data;
};

const updateUser = async (id: string, data: any) => {
  const client = await getClient();
  const response = await client
    .post(`/users/${id || 'invalid-id'}`, data)
    .catch(checkErr);
  log.debug('RESPONSE', response.data);
  return response.data;
};

const deleteUser = async (id: string) => {
  const client = await getClient();
  const response = await client
    .delete(`/users/${id || 'invalid-id'}`)
    .catch(checkErr);
  log.debug('RESPONSE', response.data);
  return response.data;
};

const createNotification = async (data: any) => {
  const client = await getClient();
  const response = await client.post(`/notifications`, data).catch(checkErr);
  log.debug('RESPONSE', response.data);
  return response.data;
};

const updateNotification = async (id: string, data: any) => {
  const client = await getClient();
  const response = await client
    .post(`/notifications/${id || 'invalid-id'}`, data)
    .catch(checkErr);
  log.debug('RESPONSE', response.data);
  return response.data;
};

const uploadFile = async (data: any) => {
  const client = await getClient();
  const response = await client.post(`/files`, data).catch(checkErr);
  return response.data;
};

type CreatePdfParams = {
  data: any, // template data
  filename?: string, // filename to use
  gTemplateId: string, // override filename
  gFolderId?: string, // google folder to store the template
  imageUrl: any, //
};

type CreatePdfResponse = {
  file: string;
}

const createPdf = async (data: CreatePdfParams) => {
  const client = await getClient();
  const response = await client.post(`/create-pdf`, data).catch(checkErr);
  return response.data as CreatePdfResponse;
};

type DownloadDocParams = {
  file: string, // full path and filename
  name?: string, // override filename
};

const downloadDoc = async (data: DownloadDocParams) => {
  const { file = '', name } = data;

  const { REACT_APP_IRRIGATION_BASE_URL: irrAppBaseUrl = '' } = process.env;
  const response = await axios.get(`${irrAppBaseUrl}/${file}`, {
    responseType: 'blob',
  });
  log.debug('response', { ...response, data: null });

  const filename = name || String(file).split('/').slice(-1)[0];
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};

const sendEmail = async (data: any) => {
  const client = await getClient();
  const response = await client.post(`/email`, data).catch(checkErr);
  return response.data;
};

const sendEmailUsingMs = async (data: any) => {
  const sbClient = await getSbClient();
  const { data: { session } } = await sbClient.auth.getSession();
  if (!session) {
    throw new Error('Invalid session');
  }
  const msData = {
    ...(data || {}),
    from: `${session?.user?.email}`,
    useTransport: 'ms',
    transportParams: {
      auth: {
        token: String(session?.provider_token),
      },
    },
  };
  if (!session?.provider_token) {
    throw new Error('Invalid token');
  }
  return sendEmail(msData);
};

const getAuthToken = async () => {
  const client = await getClient();
  const response = await client.get(`/auth`).catch(checkErr);
  return response.data;
}

export default getClient;
export {
  getClient,
  addUser,
  updateUser,
  deleteUser,
  uploadFile,
  createPdf,
  downloadDoc,
  sendEmail,
  sendEmailUsingMs,
  createNotification,
  updateNotification,
  getAuthToken,
};
export type {
  DownloadDocParams,
  CreatePdfParams,
  CreatePdfResponse,
};