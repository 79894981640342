import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

import useDataStore from '../../DataStore';
import { SwEventType } from '../../DataStore/Sw';

function VersionCheck() {
  const datastore = useDataStore();
  const swState = datastore.sw.getState();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [hasUpdates, setHasUpdates] = React.useState(swState.updates);

  const handleOpen = () => {
    setOpenDialog(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
  }

  const handleRefresh = () => {
    const registration = datastore.sw.getRegistration();
    registration.waiting.postMessage({type: 'SKIP_WAITING'});
    window.location.reload();
  };

  React.useEffect(() => {
    const swId = datastore.sw.subscribe(SwEventType.UPDATE, (swState: any) => {
      setHasUpdates(swState.updates);
    });
    return () => {
      datastore.sw.unsubscribe(swId);
    }
  }, [datastore]);

  return (
    <>
    {!hasUpdates ? <></> : (
      <IconButton aria-label="check-updates" onClick={handleOpen}>
        <NewReleasesIcon sx={{ color: '#FFD700' }} />
      </IconButton>
    )}
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Updates Found</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          An update was found. Click Update to continue.
          <br/>
          <b>WARNING:</b> Any unsaved changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>Later</Button>
        <Button variant="contained" onClick={handleRefresh}>Update</Button>
      </DialogActions>
    </Dialog>    
    </>
  );
}

export default VersionCheck;
