import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  ActionType,
} from '../../types/datastore.d';

const dataset = 'proposalCalls';
const vwDataset = 'vw_proposal_calls';

type ProposalCallModel = {
  proposal_id: string;
  count: number;
}

function getInitial() {
  const init: {
    records: ProposalCallModel[];
    error: string | null;
  } = {
    records: [],
    error: null,
  };

  return init;
}

const expiryMs = 1000 * 60 * 10; // 5 minutes
function isExpired(record: { $refreshedAt: number }) {
  const now = Date.now();
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, datastore, subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      ilike,
      eq,
      gte,
      lte,
      order,
      custom,
      type = ActionType.REFRESH,
    } = opts || {};
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (ilike) query.ilike(ilike.column, ilike.pattern);
    if (gte) query.gte(gte.column, gte.value);
    if (lte) query.lte(lte.column, lte.value);
    if (eq) query.eq(eq.column, eq.value);
    if (order) query.order(order.column, order.value);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(`fetch ${dataset} error`, result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: { proposal_id: string }) => {
    const { proposal_id } = opts || {};

    // always get latest from db
    // const existing = state[dataset].records.find((x: ProposalCallModel) => x.proposal_id === proposal_id);
    // if (existing && !isExpired(existing)) return existing;

    const data = await refreshFn({
      match: { proposal_id },
      type: ActionType.UPSERT,
    });

    if (!data || !data[0]) {
      // take care of those without data
      // dispatch({
      //   type: ActionType.UPSERT,
      //   dataset,
      //   payload: [{
      //     proposal_id,
      //     $refreshedAt: Date.now(),
      //   }],
      // });
  
      return undefined;
    }

    const proposalCall = data[0];
    return data ? proposalCall : undefined;
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getState: () => state[dataset],
    refresh: refreshFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.UPDATE) {
    const records: ProposalCallModel[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.proposal_id === record.proposal_id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: ProposalCallModel[] = action.payload;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: any) => x.proposal_id === y.proposal_id
      );
      if (existing) Object.assign(existing, x);
      else newState[dataset].records.push(x);
    });

    return newState;
    /**
     */
  }
  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildProposalCalls(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ state, dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildProposalCalls;
export {
  dataset,
  getInitial,
  getFunctions,
  reducer,
  ActionType,
};

export type { ProposalCallModel };
