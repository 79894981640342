import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as supabase from '../../api/supabase';
import * as api from '../../api/irrigationApi';
import {
  ActionType,
  UpsertMode,
  RecordStatus,
  GetFunctionParams,
  RefreshOptions,
} from '../../types/datastore.d';
import { UserRoles } from '../../settings';
import { ProposalType } from './Proposals';

const dataset = 'users';
const vwDataset = 'vw_users';
const dbTable = 'users';

function UserSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      role: yup.string().oneOf(Object.values(UserRoles)).required(),
      email: yup.string().email().required().lowercase(),
      password: yup.string().optional(),
      phone: yup.string().nullable(),
      avatar_url: yup.string().nullable(),
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      salesperson: yup.boolean().default(false).required(),
      subscriptions: yup
        .array()
        .nullable()
        .of(
          yup.object().shape({
            endpoint: yup.string().required(),
            keys: yup.object().required().shape({
              auth: yup.string().required(),
              p256dh: yup.string().required(),
            }),
          })
        ),
      def_proposal_template: yup
        .string()
        .oneOf(Object.values(ProposalType))
        .nullable(),
      signature: yup.string().nullable(),
    });
}

type UserRecord = {
  email: string;
  created_at: string;
  first_name?: string;
  last_name?: string;
  role: UserRoles;
  phone?: string;
  avatar_url?: string;
  subscriptions?: any;
  salesperson: boolean;
  def_proposal_template: ProposalType;
  signature?: string;
};

// deprecated
type UserModel = UserRecord;

type UserViewRecord = {
  id: string;
  email: UserRecord['email'];
  created_at: UserRecord['created_at'];
  first_name?: UserRecord['first_name'];
  last_name?: UserRecord['last_name'];
  role: UserRoles;
  phone?: UserRecord['phone'];
  avatar_url?: UserRecord['avatar_url'];
  subscriptions?: UserRecord['subscriptions'];
  search_column: string;
  salesperson: boolean;
  def_proposal_template: ProposalType;
  signature?: string;
};

function getInitial() {
  const records: UserViewRecord[] = [];
  return { records };
}

function isExpired(record: { $refreshedAt: number }) {
  const now = Date.now();
  const expiryMs = 1000 * 60 * 10; // 5 minutes
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      custom,
      type = ActionType.REFRESH,
    } = opts || {};
    log.debug(`fetching ${vwDataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: {
    id?: string;
    email?: string;
    force?: boolean;
  }) => {
    const { id, email, force = false } = opts || {};

    const existing = state[dataset].records.find((x: any) => {
      if (id) return x.id === id;
      if (email) return x.email === email;
      return false;
    });
    if (!force && existing && !isExpired(existing)) return existing;

    const data = await refreshFn({
      custom: (query) => {
        if (id) query.eq('id', id);
        else if (email) query.eq('email', email);
        else query.eq('id', 'NULL');
      },
      type: ActionType.UPSERT,
    });
    return data ? data[0] : undefined;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: { records: any[]; error: any }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: any,
      opts?: { pick?: string[]; onConflict?: string; match?: any }
    ) => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = UserSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: any = await schema
        .validate(record, { abortEarly: false })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await api
          .addUser(valid)
          .then((r) => ({ records: [r.record] }))
          .catch((err) => ({ records: [], error: err }));
        return respond(result);
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const result: any = await api
          .updateUser(record.id, valid)
          .then((r) => ({ records: [{ ...r.record, id: record.id }] }))
          .catch((err) => ({ records: [], error: err }));
        return respond(result);
      }
      return getFn({ id: valid.id });
    };
  };

  const deleteFn = async (payload: any) => {
    const record = { ...payload };
    let error: any;
    await api.deleteUser(record.id).catch((e) => {
      error = e;
    });
    if (error) {
      log.error(error);
      return { $error: error };
    }
    dispatch({
      type: ActionType.DELETE,
      dataset,
      payload: record,
    });
  };

  const updateAppSettings = async (
    payload: Partial<UserRecord>,
    opts?: { pick?: string[]; onConflict?: string; match?: any }
  ) => {
    log.debug(`${UpsertMode.UPDATE_ONLY} ${dataset} parameters`, {
      payload,
      opts,
    });
    const record = {
      ...payload,
      $status: RecordStatus.LOADING,
    };
    dispatch({
      type: ActionType.UPDATE,
      dataset,
      payload: [record],
    });

    // Update state based on results
    const respond = (opts: { records: any[]; error: any }) => {
      const { records, error } = opts;
      log.debug(`${UpsertMode.UPDATE_ONLY} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    // Validate
    let schema = UserSchema();
    if (opts?.pick) {
      schema = schema.pick(opts.pick);
    }
    const valid: any = await schema
      .validate(record, { abortEarly: false })
      .catch((error) => {
        return respond({ records: [record], error });
      });
    if (valid.$error) return valid;
    log.debug(`${UpsertMode.UPDATE_ONLY} ${dataset} valid data`, valid);

    // Commit to database
    const client = await supabase.getClient();
    const { match = { email: valid.email } } = opts || {};
    const result: any = await client
      .from(dbTable)
      .update(valid)
      .match(match)
      .select();
    respond({ records: result?.data, error: result.error });
    return getFn({ email: valid.email });
  };

  const currSearch: any = { searchStr: '', timeout: null };
  const searchFn = async (params: any) => {
    const { searchStr = '' } = params;

    if (searchStr.length < 1) return;

    // wait for timeout to finish
    if (currSearch.timeout) {
      currSearch.searchStr = searchStr;
      return;
    }

    currSearch.timeout = new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 1000);
    });
    await currSearch.timeout;
    currSearch.timeout = null;

    log.debug(`searching ${dataset} for '${currSearch.searchStr}'`);
    const client = await supabase.getClient();
    const result = await client
      .from(vwDataset)
      .select()
      .or(
        [
          `first_name.ilike.*${currSearch.searchStr}*`,
          `last_name.ilike.*${currSearch.searchStr}*`,
          `email.ilike.*${currSearch.searchStr}*`,
        ].join(',')
      );

    if (!result.error) {
      dispatch({
        type: ActionType.UPSERT,
        dataset,
        payload: (result.data || []).map((x: any) => ({
          ...x,
          $refreshedAt: Date.now(),
        })),
      });
      return result.data;
    }

    log.error(result.error);
    return [];
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  };

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  };

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getState: () => state[dataset],
    create: getUpsertFn(UpsertMode.CREATE_ONLY),
    update: getUpsertFn(UpsertMode.UPDATE_ONLY),
    updateAppSettings,
    delete: deleteFn,
    refresh: refreshFn,
    search: searchFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: UserViewRecord[] = action.payload;
    records.forEach((x) => {
      const existing = newState[dataset].records.find(
        (y: any) => x.id === y.id
      );
      if (existing) Object.assign(existing, x);
      else newState[dataset].records.push(x);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.DELETE) {
    const record: any = action.payload;
    newState[dataset].records = newState[dataset].records.filter((x: any) => {
      if (record.email) return x.email !== record.email;
      if (record.id) return x.id !== record.id;
      return true;
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.REFRESH) {
    newState[dataset].records = action.payload;
    return newState;
    /**
     */
  } else if (action.type === ActionType.ERROR) {
    newState[dataset].error = action.payload;
    return newState;
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
};

function BuildUsers(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, {
    [dataset]: getInitial(),
  });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({
      dispatch,
      datastore: datastore.current,
      subscribers,
    });
    reload();
  }, [datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if (typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    });
  }, [state]);

  return <>{children}</>;
}

export default BuildUsers;
export { dataset, getInitial, getFunctions, reducer, ActionType };
export type { UserModel, UserRecord, UserViewRecord };
