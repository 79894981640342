import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import log from 'loglevel';
import Container from '@mui/material/Container';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import { MessageType } from '../../comp/DataStore/Messages';

function RedirectNotification() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  let { id } = useParams();

  React.useEffect(() => {
    datastore.messages.get({ id })
    .then((record: any[]) => {
      const message = record[0];
      if(message && message.message_type === MessageType.NOTIFICATION) {
        // mark read
        datastore.messages.update({
          ...message,
          data: {
            ...message.data,
            read: true,
          },
        }, {
          pick: ['id', 'message_type', 'data'],
          match: { id: message.id },
        });
        if (message.data.url) {
          navigate(message.data.url);
        }
      } else {
        navigate('/');
      }
    });

  }, [id, datastore.messages, navigate]);

  return (
    <Paperbase title={'Notifications'}  bottomSpacer="100px">
      <Container component="main">
        Opening notification...
      </Container>
    </Paperbase>
  );
}

export default RedirectNotification;
