import React from 'react';
// import log from 'loglevel';

import { useDataStore } from '../DataStore';

type UserNameProps = {
  user: {
    email: string;
  };
};

function UserName(props: UserNameProps) {
  const { user } = props;
  const datastore = useDataStore();

  const [text] = React.useState<string>(user.email);
  const [name, setName] = React.useState<string | undefined>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [noRecord, setNoRecord] = React.useState<boolean | undefined>(
    undefined
  );

  const usersState = datastore.users.getState();

  const getFullName = (u: any) => {
    if (!u) return;
    const fullname = [];
    if (u.first_name) fullname.push(u.first_name);
    if (u.last_name) fullname.push(u.last_name);
    if (fullname.length === 0) fullname.push(u.email);
    return fullname.join(' ');
  };

  React.useEffect(() => {
    if (name) return;
    if (loading) return;
    if (noRecord !== undefined) return;

    const existing = usersState.records.find(
      (x: any) => x.email === user.email
    );
    if (existing) {
      setName(getFullName(existing));
      return;
    }

    setLoading(true);
    datastore.users
      .get({ email: user.email })
      .then((result: any) => {
        setNoRecord(result === undefined);
        setName(getFullName(result));
      })
      .finally(() => setLoading(false));
  }, [
    text,
    name,
    loading,
    user,
    usersState.records,
    datastore.users,
    noRecord,
  ]);

  return <>{name || text}</>;
}

export default UserName;
