import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as dateFns from 'date-fns';
// import log from 'loglevel';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


import { GeneralFilters } from './Filters';
import { useDataStore } from '../../comp/DataStore';
import ProposalStatus from '../Proposals/ProposalStatus';
import UserName from '../../comp/HelperText/UserName';
import settings from '../../settings';


function getRefresh(params: any) {
  const { datastore, setLoading } = params;

  return async () => {
    setLoading(true);
    await datastore.proposals.getRange();
    setLoading(false);
  };
}

type RecentProposalProps = {
  filter?: GeneralFilters;
}

function RecentProposals(props: RecentProposalProps) {
  const { filter } = props;
  const datastore = useDataStore();
  const navigate = useNavigate();

  const authState = datastore.auth.getState();
  const initProposals = datastore.proposals.getState();
  const [proposalsState, setProposalsState] = React.useState<any>(initProposals);

  const [loading, setLoading] = React.useState(false);
  // const [genConfig, setGenConfig] = React.useState<any>({
  //   proposalStatus: '-',
  //   assignedTo: 'me',
  // }); // General Configuration
  const refreshRef = React.useRef({ runOnce: false });

  const refresh = getRefresh({ datastore, setLoading });

  const list = React.useMemo(() => {
    const pRecords = proposalsState.records
      .filter((p: any) => !p.deleted_at)
      .filter((p: any) => !p.parent_id)
      .filter((p: any) => {
        if (filter?.assignedTo === 'anyone') return true;
        return p.assigned_to === authState.user.email;
      })
      .filter((p: any) => {
        if (filter?.proposalStatus === '-') return true;
        return filter?.proposalStatus === p.status;
      })
      .sort((a: any, b: any) => {
        const aDate = dateFns.parseISO(a.updated_at);
        const bDate = dateFns.parseISO(b.updated_at)
        return dateFns.differenceInMilliseconds(bDate, aDate);
      })
      .map((p: any) => {
        const updatedAt = dateFns.parseISO(p.updated_at);
        const createdAt = dateFns.parseISO(p.created_at);
        const year = p.year || dateFns.format(createdAt, 'yyyy');
        const statusCode = p.parent_status || p.status;
        const status = settings.proposalStatus.find((x) => x.value === statusCode);
        return (
          <ListItemButton
            key={p.id}
            sx={{ bgcolor: '#EBEDEF', mb: '5px' }}
            onClick={() => {
              navigate(`/proposals/${p.id}`);
            }}
          >
            <Grid container>
              <Grid item xs={2}>
                <ProposalStatus proposalId={p.id} status={statusCode}>{status?.label}</ProposalStatus>
                {/* <ListItemText primary={status?.label} /> */}
              </Grid>
              <Grid item xs>
                <Typography>{`${p.prospect_full_name} - ${year}`}</Typography>
                <Typography sx={{ fontSize: '80%' }}>
                  {p.address || p.prospect_address}
                </Typography>
              </Grid>
              {filter?.assignedTo === 'anyone' ? (
                <Grid item xs>
                  <Typography><UserName user={{ email: p.assigned_to }}/></Typography>
                  {/* <ListItemText primary={p.assigned_to} /> */}
                </Grid>
              ) : <></>}
              <Grid item xs={2} textAlign="right">
                <Typography>{dateFns.format(updatedAt, 'M/d/yyyy')}</Typography>
                {/* <ListItemText primary={dateFns.format(updatedAt, 'M/d/yyyy')} /> */}
              </Grid>
            </Grid>
          </ListItemButton>
        );
      });

    return pRecords;
  }, [authState.user, navigate, proposalsState, filter]);

  // const onChangeGenConfig = (str: string) => (e: any) => {
  //   setGenConfig((curr: any) => ({
  //     ...curr,
  //     [str]: e.target.value,
  //   }));
  // };

  const onViewAll = React.useCallback(() => {
    if (filter?.assignedTo === 'anyone') {
      navigate('/proposals');
      return;
    }
    const p = { assigned_to: authState.user.email };
    const url = `/proposals?p=${encodeURIComponent(JSON.stringify(p))}`;
    navigate(url);
  }, [navigate, authState, filter]);

  React.useEffect(() => {
    if (!refreshRef.current || refreshRef.current.runOnce) return;
    refreshRef.current.runOnce = true;
    refresh();
  }, [refresh]);

  React.useEffect(() => {
    const proposalId = datastore.proposals.subscribe(setProposalsState);
    return () => {
      datastore.proposals.unsubscribe(proposalId);
    }
  }, [datastore]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5">Recent Proposals</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none ' }}
            onClick={onViewAll}
          >
            View All
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ textTransform: 'none ' }}
            onClick={() => navigate('/proposals/new')}
          >
            Add New
          </Button>
        </Stack>
      </Grid>
      {/* <Grid item xs={6}>
      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <FormControl size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="status-filter-label">Status</InputLabel>
          <Select
            key={`status`}
            labelId="status-filter-label"
            id="status-filter"
            name="status-filter"
            value={genConfig.proposalStatus || '-'} 
            label="Status"
            onChange={onChangeGenConfig('proposalStatus')}
          >
            <MenuItem value="-"><i>None Selected</i></MenuItem>
            {settings.proposalStatus.map((s) => (
              <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ minWidth: '120px' }}>
          <InputLabel id="status-filter-label">Assigned to</InputLabel>
          <Select
            key={`assigned-to`}
            labelId="assigned-to-filter-label"
            id="assigned-to-filter"
            name="assigned-to-filter"
            value={genConfig.assignedTo || 'me'} 
            label="Assigned to"
            onChange={onChangeGenConfig('assignedTo')}
          >
            <MenuItem value="me">Me</MenuItem>
            <MenuItem value="anyone">Anyone</MenuItem>
          </Select>
        </FormControl>
        </Stack>
      </Grid> */}
      <Grid item xs={12}>
        <List>{list.length !== 0 ? list.slice(0, 5) : (
          <ListItemButton
            key={`no-records-found`}
            sx={{ bgcolor: '#EBEDEF', mb: '5px' }}
          >
            <ListItemText primary={`No records found`} />
          </ListItemButton>
        )}</List>
      </Grid>
    </Grid>
  );
}

export default RecentProposals;
