import React from 'react';
// import log from 'loglevel';
// import BottomNavigation from '@mui/material/BottomNavigation';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

type ControlBarProps = {
  children?: React.ReactNode | string | number;
};

function ControlBar(props: ControlBarProps) {
  const theme = useTheme();
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: '20px',
        backgroundColor: '#F8F9F9',
        zIndex: theme.zIndex.appBar - 20,
      }}
      elevation={10}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ControlBar;
