import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
// import * as yup from 'yup';
import * as api from '../../api/irrigationApi';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  ActionType,
  RecordStatus,
} from '../../types/datastore.d';

const dataset = 'notifications';
const dbTable = 'notifications';

function getInitial() {
  const init: {
    records: any[];
    error: string | null;
  } = {
    records: [],
    error: null,
  };

  return init;
}

// const expiryMs = 1000 * 60 * 10; // 5 minutes
// function isExpired(record: { $refreshedAt: number }) {
//   const now = Date.now();
//   return now - (record.$refreshedAt || 0) > expiryMs;
// }

function getFunctions(params: GetFunctionParams) {
  const { dispatch, datastore, subscribers } = params;
  
  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const {
      match,
      range,
      in: optsIn,
      ilike,
      eq,
      gte,
      lte,
      type = ActionType.REFRESH,
    } = opts || {};
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(dbTable).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (ilike) query.ilike(ilike.column, ilike.pattern);
    if (gte) query.gte(gte.column, gte.value);
    if (lte) query.lte(lte.column, lte.value);
    if (eq) query.eq(eq.column, eq.value);
    const result = await query;

    if (result.error) {
      log.error(`fetch ${dataset} error`, result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  const updateFn = async (payload: any) => {
    const record = {
      ...payload,
      $status: RecordStatus.LOADING,
    };

    dispatch({
      type: ActionType.UPSERT,
      dataset,
      payload: [record],
    });

    const result = await api.updateNotification(record.id, record);
    log.debug('update notification result', result);
    Object.assign(record, {
      ...(result.data || {}),
      $status: result.error ? RecordStatus.ERROR : RecordStatus.OK,
      $error: result.error,
    });

    dispatch({
      type: ActionType.UPSERT,
      dataset,
      payload: [record],
    });
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    refresh: async () => {
      const authState = datastore.auth.getState();
      if (!authState.user?.email) return;
      return refreshFn({
        match: {
          assigned_to: authState.user.email,
          read: false,
        },
      });
    },
    update: updateFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.REFRESH) {
    newState[dataset].records = action.payload || [];

    // remove read items
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => !x.read
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.UPSERT) {
    const records: any[] = action.payload || [];

    // update records
    records.forEach((r) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === r.id
      );
      if (!existing) {
        newState[dataset].records.push(r);
      } else {
        Object.assign(existing, r);
      }
    });

    // remove read items
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => !x.read
    );

    return newState;
    /**
     */
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildNotifications(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ state, dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);
  
  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildNotifications;
export { dataset, getInitial, getFunctions, reducer, ActionType };
