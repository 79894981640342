import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as supabase from '../../api/supabase';
import { GetFunctionParams, RefreshOptions } from '../../types/datastore';
import { AddressComponentModel } from './Prospects';

const dataset = 'taxRate';
const dbTable = 'tax_rate';

type TaxRateRecord = {
  id: number;
  zip_code?: number | null;
  county?: number | null;
  state: string;
  tax_rate: number;
}

function getInitial() {
  // no data to store for now
  return {};
}

function findAddrType(addr: AddressComponentModel[], addrTypes: string[]) {
  return addr.find((x) => {
    let match = true;
    addrTypes.forEach((t) => {
      match = match && x.types.includes(t);
    });
    return match;
  });
}

const formatAddressByCountry: any = {
  US: (addr: AddressComponentModel[]) => ({
    street_num: findAddrType(addr, ['street_number']),
    street_name: findAddrType(addr, ['route']),
    subpremise: findAddrType(addr, ['subpremise']),
    sublocality: findAddrType(addr, ['sublocality']),
    city: findAddrType(addr, ['locality']),
    county: findAddrType(addr, ['administrative_area_level_2']),
    state: findAddrType(addr, ['administrative_area_level_1']),
    country: findAddrType(addr, ['country']),
    zip: findAddrType(addr, ['postal_code']),
  }),
};

function getFunctions(params: GetFunctionParams) {
  const { subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const { match, range, in: optsIn, custom } = opts || {};
    log.debug(`fetching ${dataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(dbTable).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(result.error);
      // dispatch({
      //   type: ActionType.ERROR,
      //   dataset,
      //   payload: result.error,
      // });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    // dispatch({
    //   type,
    //   dataset,
    //   payload,
    // });

    return payload;
  };

  const getAddress = (addrComp: AddressComponentModel[]) => {
    const country = findAddrType(addrComp, ['country']);
    if (country?.short_name && formatAddressByCountry[country.short_name]) {
      return formatAddressByCountry[country.short_name](addrComp);
    }
    return;
  };

  const noTaxRate = (addr: any) => {
    log.error('No tax rate record found for this address', addr);
    return undefined;
  };

  const getTaxRate = async (addrComp: AddressComponentModel[]) => {
    if (!addrComp) return noTaxRate(addrComp);

    const fmtAddr = getAddress(addrComp);
    if (!fmtAddr) return noTaxRate(addrComp);

    const getOne = (result?: TaxRateRecord[]) => (result ? result[0] : null);

    const [zipSpecific, countySpecific, stateSpecific] = await Promise.all([
      // zip specific
      refreshFn({
        custom: (query) => {
          query
            .eq('zip_code', fmtAddr.zip?.short_name || '0')
            .ilike('county', fmtAddr.county?.short_name || 'NULL')
            .ilike('state', fmtAddr.state?.short_name || 'NULL');
        },
      }).then(getOne),
      // county specific
      refreshFn({
        custom: (query) => {
          query
            .is('zip_code', null)
            .ilike('county', fmtAddr.county?.short_name || 'NULL')
            .ilike('state', fmtAddr.state?.short_name || 'NULL');
        },
      }).then(getOne),
      // state specific
      refreshFn({
        custom: (query) => {
          query
            .is('zip_code', null)
            .is('county', null)
            .ilike('state', fmtAddr.state?.short_name || 'NULL');
        },
      }).then(getOne),
    ]);

    if (zipSpecific) return zipSpecific;
    else if (countySpecific) return countySpecific;
    else if (stateSpecific) return stateSpecific;
    else return noTaxRate(fmtAddr);
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return { refresh: refreshFn, getAddress, getTaxRate, subscribe, unsubscribe };
}

function reducer(currState: any, action: any) {
  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
};

function BuildTaxRate(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, {
    [dataset]: getInitial(),
  });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({
      state,
      dispatch,
      datastore: datastore.current,
      subscribers,
    });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildTaxRate;
export { dataset, getInitial, getFunctions, reducer };
