import React from 'react';
import log from 'loglevel';

import { useDataStore } from '../DataStore';

type ProspectNameProps = {
  prospect: {
    id: string;
  };
}

function ProspectName(props: ProspectNameProps) {
  const { prospect } = props;
  const datastore = useDataStore();

  const [text, setText] = React.useState<string>('');
  const [name, setName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const prospectsState = datastore.prospects.getState();

  React.useEffect(() => {
    if (name) return;
    if (loading) return;

    const existing = prospectsState.records.find((x: any) => x.id === prospect.id);
    if (existing) {
      setName(existing.full_name);
      return;
    }

    setLoading(true);
    setText('<loading...>');
    datastore.prospects.get({ id: prospect.id })
      .then((result: any) => {
        setName(result.full_name);
      })
      .catch((err: Error) => {
        log.error(err);
        setName(`<prospect_id: ${prospect.id}>`);
      })
      .finally(() => setLoading(false));
  }, [text, name, loading, prospect, prospectsState.records, datastore.prospects]);

  return (
    <>{name || text}</>
  );
}

export default ProspectName;
