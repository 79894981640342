import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';

import ImageSet from '../../comp/ImageSet';
import UploadButton, { FileCategory } from '../../comp/UploadButton';
import { ServiceType, SeasonList } from '../../comp/DataStore/Services';
import { CustomServiceSchema } from '../../comp/DataStore/Proposals';
import settings from '../../settings';

type CustomServiceProp = {
  open: boolean;
  record?: {
    id: string;
    name: string;
    description?: string;
    season: SeasonList;
    type: ServiceType;
    image_url?: string;
    quantity?: number;
    uom?: string;
    fixed_price?: number;
  };
  onClose?: () => void;
  onSave?: (p: CustomServiceProp['record']) => void;
  onDelete?: (id: string) => void;
};

function CustomService(prop: CustomServiceProp) {
  const { open, record, onClose, onSave, onDelete } = prop;
  const newRecord = record === undefined;

  const [error, setError] = React.useState<any>();
  const [loading] = React.useState(false);
  const [warnImg, setWarnImg] = React.useState('');
  const refName = React.useRef<any>();
  const refDesc = React.useRef<any>();
  const refSeason = React.useRef<any>();
  const refType = React.useRef<any>();
  const refUpload = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  const refFixedPrice = React.useRef<any>();
  const refQuantity = React.useRef<any>();
  const refUom = React.useRef<any>();
  const [img, setImg] = React.useState<any>(record || {});

  const handleClose = () => {
    if (typeof onClose === 'function') onClose();
  };

  const handleSubmit = async () => {
    const service = {
      id: newRecord ? uuidv4() : record.id,
      name: refName.current.value,
      description: refDesc.current.value,
      season: refSeason.current.value,
      type: refType.current.value,
      image_url: img.image_url,
      fixed_price: refFixedPrice.current.value,
      quantity: refQuantity.current.value,
      uom: refUom.current.value,
    };
    log.debug('custom service', service);

    const validService: any = await CustomServiceSchema()
      .validate(service, { abortEarly: false })
      .catch((err) => {
        setError(err);
      });

    if (!validService) return;

    log.debug('valid custom service', validService);
    if (typeof onSave === 'function') {
      onSave(validService);
    }
  };

  const handleDelete = () => {
    if (typeof onDelete === 'function' && !newRecord) {
      onDelete(record.id);
    }
  };

  const handleChange = () => {
    setError(null);
  };

  const handleUpload = (f: any) => {
    log.debug('upload', f);
    setImg({ id: img.id, image_url: f.urlPath });
    setError(null);
  };

  const errorObj = React.useMemo(() => {
    log.debug('updating error Object');
    const value: any = {};
    if (!error) return value;
    if (error.name === 'ValidationError') {
      (error.inner || []).forEach((innerErr: any) => {
        value[innerErr.path] = innerErr;
      });
    }
    log.error(value);
    return value;
  }, [error]);

  const onImageLoad = (p: any) => {
    const { naturalWidth, naturalHeight } = p.ref;
    if (!naturalHeight || !naturalWidth) return;
    log.debug('Image size', { naturalWidth, naturalHeight });
    if (naturalHeight > naturalWidth) {
      setWarnImg(
        'The image is too long for the proposal. Use a landscape photo or crop the photo.'
      );
    } else {
      setWarnImg('');
    }
  };

  const onClickImage = () => {
    refUpload.current?.click();
  };

  React.useEffect(() => {
    if (img.id !== record?.id) {
      setImg(record || {});
      setWarnImg('');
      setError(null);
    }
  }, [newRecord, img, record]);

  return (
    <Box sx={{ maxWidth: 'md' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        scroll='paper'
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 10 }}
      >
        <DialogTitle>Custom Service</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: '5px' }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      key={`name-${record?.id}`}
                      autoFocus
                      id='name'
                      type='text'
                      label='Name'
                      name='name'
                      variant='outlined'
                      required
                      fullWidth
                      onChange={handleChange}
                      defaultValue={record?.name || ''}
                      inputRef={refName}
                      error={Boolean(errorObj?.name)}
                      helperText={errorObj?.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      key={`description-${record?.id}`}
                      id='description'
                      type='text'
                      label='Description'
                      name='description'
                      variant='outlined'
                      multiline
                      minRows={2}
                      fullWidth
                      onChange={handleChange}
                      defaultValue={record?.description || ''}
                      inputRef={refDesc}
                      error={Boolean(errorObj?.description)}
                      helperText={errorObj?.description?.message}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Box
                  onClick={onClickImage}
                  sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: errorObj?.image_url ? 'red' : 'transparent',
                  }}
                >
                  <ImageSet
                    key={`image-url-${record?.id}`}
                    src={img.image_url || ''}
                    alt={record?.name || '?'}
                    useOrig
                    onLoad={onImageLoad}
                    noImage='Upload image'
                  />
                </Box>
                <Box sx={{ display: 'none' }}>
                  <UploadButton
                    category={FileCategory.IMAGES}
                    inputProps={{
                      accept: 'image/png, image/jpeg',
                    }}
                    buttonProps={{
                      disabled: loading,
                      variant: 'outlined',
                      ref: refUpload,
                    }}
                    onUpload={handleUpload}
                  >
                    Upload Image
                  </UploadButton>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel id='season'>Season</InputLabel>
                  <Select
                    key={`season-${record?.id}`}
                    labelId='season'
                    id='season'
                    label='Season'
                    name='season'
                    defaultValue={record?.season || SeasonList.AS_SCHEDULED}
                    onChange={handleChange}
                    error={Boolean(errorObj?.season)}
                    inputRef={refSeason}
                  >
                    {settings.seasons
                      .sort((a: any, b: any) => a.order - b.order)
                      .map((season) => (
                        <MenuItem key={season.value} value={season.value}>
                          {season.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel id='type'>Type</InputLabel>
                  <Select
                    key={`type-${record?.id}`}
                    labelId='type'
                    id='type'
                    label='Type'
                    name='type'
                    disabled={loading}
                    defaultValue={record?.type || ServiceType.ADDITIONAL}
                    onChange={handleChange}
                    error={Boolean(errorObj['type'])}
                    inputRef={refType}
                  >
                    {settings.serviceType.map((sType) => (
                      <MenuItem key={sType.value} value={sType.value}>
                        {sType.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  key={`fixed-price-${record?.id}`}
                  id='fixed_price'
                  type='number'
                  label='Fixed Price'
                  name='fixed_price'
                  variant='outlined'
                  fullWidth
                  onChange={handleChange}
                  defaultValue={record?.fixed_price || Number(0).toFixed(2)}
                  inputRef={refFixedPrice}
                  error={Boolean(errorObj['fixed_price'])}
                  helperText={errorObj?.fixed_price?.message}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  key={`quantity-${record?.id}`}
                  id='quantity'
                  type='number'
                  label='Quantity'
                  name='quantity'
                  variant='outlined'
                  fullWidth
                  onChange={handleChange}
                  defaultValue={record?.quantity || 1}
                  inputRef={refQuantity}
                  error={Boolean(errorObj['quantity'])}
                  helperText={errorObj?.quantity?.message}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  key={`uom-${record?.id}`}
                  id='uom'
                  label='Uom'
                  name='uom'
                  variant='outlined'
                  fullWidth
                  inputProps={{ maxLength: 3 }}
                  onChange={handleChange}
                  defaultValue={record?.uom}
                  inputRef={refUom}
                  error={Boolean(errorObj['uom'])}
                  helperText={errorObj?.uom?.message}
                />
              </Grid>

              {!warnImg ? (
                <></>
              ) : (
                <Grid item xs={12}>
                  <Alert severity='warning'>
                    {'Warning: '}
                    {warnImg}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction='row' spacing={2} sx={{ p: '5px' }}>
            {newRecord ? (
              <></>
            ) : (
              <Button onClick={handleDelete} variant='outlined' color='error'>
                Delete
              </Button>
            )}
            <Button onClick={handleClose} variant='outlined'>
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant='contained'>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CustomService;
