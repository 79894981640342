import React from 'react';
import log from 'loglevel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';

import { useDataStore } from '../../comp/DataStore';
import { SettingsKey } from '../../comp/DataStore/Settings';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';

function getRefresh(params: any) {
  const { datastore, setLoading } = params;

  return async () => {
    setLoading(true);
    await datastore.settings.refresh();
    setLoading(false);
  };
}

type Props = {
  newRecord?: boolean;
};

let runOnce = false;
function BusinessSettings(props: Props) {
  const datastore = useDataStore();

  // Record state (selection)
  const [loading, setLoading] = React.useState(false);

  const refresh = getRefresh({ datastore, setLoading });
  const initSettings = datastore.settings.getState();
  const [settingsState, setSettingsState] = React.useState<any>(initSettings);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formdata = new FormData(event.currentTarget);

    const payload: any[] = [
      SettingsKey.BUSINESS_NAME,
      SettingsKey.ADDRESS,
      SettingsKey.CITY,
      SettingsKey.STATE,
      SettingsKey.ZIP,
      SettingsKey.PHONE,
    ].map((key) => {
      const value = formdata.get(key);
      return { key, value };
    });

    log.debug('payload', payload);
    await datastore.settings.update(payload);
    setLoading(false);
  };

  const getValue = (key: string): string => {
    const existing = settingsState.records.find((x: any) => x.key === key);
    if (!existing) {
      log.warn(`Missing configuration: ${key}`);
      return '';
    }
    return existing.value;
  };

  React.useEffect(() => {
    if (runOnce) return;
    runOnce = true;
    refresh();
  }, [refresh]);

  React.useEffect(() => {
    const settingsId = datastore.settings.subscribe(setSettingsState);
    return () => {
      datastore.settings.unsubscribe(settingsId);
    }
  }, [datastore]);

  return (
    <>
      {/* <Paperbase title="Settings">
     <Container component="main"> */}
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="left">
              <Grid item xs={12}>
                <TextField
                  key={`name-${SettingsKey.BUSINESS_NAME}`}
                  required
                  fullWidth
                  id={SettingsKey.BUSINESS_NAME}
                  label="Business Name"
                  name={SettingsKey.BUSINESS_NAME}
                  disabled={loading}
                  defaultValue={getValue(SettingsKey.BUSINESS_NAME)}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  key={`name-${SettingsKey.ADDRESS}`}
                  required
                  fullWidth
                  id={SettingsKey.ADDRESS}
                  label="Address"
                  name={SettingsKey.ADDRESS}
                  disabled={loading}
                  defaultValue={getValue(SettingsKey.ADDRESS)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  key={`name-${SettingsKey.CITY}`}
                  required
                  fullWidth
                  id={SettingsKey.CITY}
                  label="City"
                  name={SettingsKey.CITY}
                  disabled={loading}
                  defaultValue={getValue(SettingsKey.CITY)}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  key={`name-${SettingsKey.STATE}`}
                  required
                  fullWidth
                  id={SettingsKey.STATE}
                  label="State"
                  name={SettingsKey.STATE}
                  disabled={loading}
                  defaultValue={getValue(SettingsKey.STATE)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  key={`name-${SettingsKey.ZIP}`}
                  required
                  fullWidth
                  id={SettingsKey.ZIP}
                  label="ZIP"
                  name={SettingsKey.ZIP}
                  disabled={loading}
                  defaultValue={getValue(SettingsKey.ZIP)}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  key={`name-${SettingsKey.PHONE}`}
                  required
                  fullWidth
                  id={SettingsKey.PHONE}
                  label="Phone"
                  name={SettingsKey.PHONE}
                  disabled={loading}
                  defaultValue={getValue(SettingsKey.PHONE)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ControlBar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="right">
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  Update
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ControlBar>
      </Box>
    </>
  );
}

export default BusinessSettings;
