import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

type List = {
  key?: string;
  label: string;
  value: any;
};

type LongListProps = {
  records: List[];
  multiple?: boolean;
  onSelect?: (value?: List, all?: List[]) => void;
  onRemove?: (value?: List, all?: List[]) => void;
  selected?: List[];
};

function LongList(props: LongListProps) {
  const {
    multiple = false,
    records,
    onSelect,
    onRemove,
    selected,
    // setSelected,
  } = props;

  const key = (records[0] || {}).key !== undefined ? 'key' : 'label';
  const [intSelected, intSetSelected] = React.useState<List[]>(selected || []);

  const finalSelected = selected || intSelected;
  // const finalSetSelected = setSelected || intSetSelected;

  const toggleRecord = (record: any) => () => {
    const isSelected = finalSelected.find((x: any) => x[key] === record[key]);
    if (!isSelected) {
      if (typeof onSelect === 'function') {
        onSelect(record);
      }

      if (!multiple) {
        intSetSelected([record]);
        // if (setSelected) {
        //   setSelected([record]);
        // }
      } else {
        intSetSelected((curr: List[]) => [...curr, record]);
      }
    } else {
      if (typeof onRemove === 'function') {
        onRemove(record);
      }
      intSetSelected((curr: List[]) =>
        curr.filter((x) => x[key] !== record[key])
      );
    }
  };

  const lists = records.map((record) => {
    const isSelected = finalSelected.find((x: any) => {
      return x[key] === record[key];
    });
    const icon = isSelected ? (
      <CheckCircleOutlineIcon htmlColor="white" />
    ) : (
      <RadioButtonUncheckedIcon />
    );
    return (
      <Button
        key={record.key || record.label}
        variant={isSelected ? 'contained' : 'outlined'}
        disableElevation
        color={isSelected ? 'primary' : 'info'}
        sx={{
          mr: '5px',
          mb: '5px',
          textTransform: 'none',
        }}
        startIcon={icon}
        onClick={toggleRecord(record)}
      >
        {record.label}
      </Button>
    );
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        {lists}
      </Grid>
    </Grid>
  );
}

export default LongList;
