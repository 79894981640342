import PeopleIcon from '@mui/icons-material/People';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import StyleIcon from '@mui/icons-material/Style';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import CallIcon from '@mui/icons-material/Call';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AttachmentIcon from '@mui/icons-material/Attachment';

import {
  SeasonList,
  ServiceType,
  PricingModel,
} from './comp/DataStore/Services';

import { ProposalStatus, ProposalType } from './comp/DataStore/Proposals';

import { SalesKPI } from './comp/DataStore/SalesKPI';

import { MessageType } from './comp/DataStore/Messages';

import type { Settings } from './types/settings.d';

enum UserRoles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

const settings: Settings = {
  /**
   * WARNING: DO NOT CHANGE NAMESPACE UUID
   * some records uuid is based this
   */
  ns_uuid: '12e4cde4-691f-4dba-876a-bda16a769fa7',

  maps: {
    /* default zoom and location */
    zoom: 20,
    center: { lat: 41.11927585966912, lng: -73.41069885533089 },

    /* colors to use on shapes */
    colors: [
      '#32CD32', // green
      '#404040', // grey
      '#1E90FF', // blue
      '#FF1493', // pink
      '#FF8C00', // orange
      '#4B0082', // violet
    ],

    /**
     * Must be configured in .env file
     * before running
     */
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
  },

  /**
   * See enum Role
   */
  roles: [
    {
      label: 'Admin',
      value: UserRoles.ADMIN,
    },
    {
      label: 'User',
      value: UserRoles.USER,
    },
  ],

  /**
   * Header Navigation
   */
  navigation: [
    {
      label: 'Dashboard',
      link: '/dashboard',
      icon: <AutoGraphIcon />,
    },
    {
      label: 'Proposals',
      link: '/proposals',
      icon: <FeedOutlined />,
    },
    {
      label: 'Appointments',
      link: '/appointments',
      icon: <CalendarMonthOutlined />,
    },
    {
      label: 'Prospects',
      link: '/prospects',
      icon: <ContactsOutlinedIcon />,
    },
    {
      label: 'Services',
      link: '/services',
      icon: <LocalOfferOutlinedIcon />,
    },
    {
      label: 'Programs',
      link: '/programs',
      icon: <StyleIcon />,
    },
    {
      allowed: [UserRoles.ADMIN],
      label: 'Settings',
      link: '/settings',
      icon: <DisplaySettingsOutlinedIcon />,
      navigation: [
        {
          label: 'Business',
          link: '/settings/business',
        },
        // {
        //   label: 'Proposal Email',
        //   link: '/settings/proposal-email',
        // },
        {
          label: 'Walkthrough Email',
          link: '/settings/walkthrough-email',
        },
        {
          label: 'Proposals',
          link: '/settings/proposals',
        },
        {
          label: 'Prospects',
          link: '/settings/prospects',
        },
        {
          label: 'Services',
          link: '/settings/services',
        },
      ],
    },
    {
      allowed: [UserRoles.ADMIN],
      label: 'Users',
      link: '/users',
      icon: <PeopleIcon />,
    },
  ],

  seasons: [
    {
      label: 'All',
      value: SeasonList.ALL,
      order: 0,
    },
    {
      label: 'As / When Needed',
      value: SeasonList.AS_NEEDED,
      order: 1,
    },
    {
      label: 'As Scheduled',
      value: SeasonList.AS_SCHEDULED,
      order: 2,
    },
    {
      label: 'Spring',
      value: SeasonList.SPRING,
      order: 10,
    },
    {
      label: 'Early Spring',
      value: SeasonList.EARLY_SPRING,
      order: 11,
    },
    {
      label: 'Mid Spring',
      value: SeasonList.MID_SPRING,
      order: 12,
    },
    {
      label: 'Late Spring',
      value: SeasonList.LATE_SPRING,
      order: 13,
    },
    {
      label: 'Summer',
      value: SeasonList.SUMMER,
      order: 20,
    },
    {
      label: 'Early Summer',
      value: SeasonList.EARLY_SUMMER,
      order: 21,
    },
    {
      label: 'Mid Summer',
      value: SeasonList.MID_SUMMER,
      order: 22,
    },
    {
      label: 'Late Summer',
      value: SeasonList.LATE_SUMMER,
      order: 23,
    },
    {
      label: 'Fall',
      value: SeasonList.FALL,
      order: 30,
    },
    {
      label: 'Early Fall',
      value: SeasonList.EARLY_FALL,
      order: 31,
    },
    {
      label: 'Mid Fall',
      value: SeasonList.MID_FALL,
      order: 32,
    },
    {
      label: 'Late Fall',
      value: SeasonList.LATE_FALL,
      order: 33,
    },
    {
      label: 'Winter',
      value: SeasonList.WINTER,
      order: 40,
    },
    {
      label: 'Early Winter',
      value: SeasonList.EARLY_WINTER,
      order: 41,
    },
    {
      label: 'Mid Winter',
      value: SeasonList.MID_WINTER,
      order: 42,
    },
    {
      label: 'Late Winter',
      value: SeasonList.LATE_WINTER,
      order: 43,
    },
  ],
  serviceType: [
    {
      label: 'Lawn Care Services',
      value: ServiceType.CORE,
    },
    {
      label: 'Additional Services',
      value: ServiceType.ADDITIONAL,
    },
    {
      label: 'Tick Control',
      value: ServiceType.TICK_CONTROL,
    },
    {
      label: 'Irrigation Services',
      value: ServiceType.IRRIGATION,
    },
  ],
  pricingModel: [
    {
      label: 'SQFT',
      value: PricingModel.SQFT,
      // priceModifier: undefined,
      priceModifier: (params) => {
        const { basePrice = 0, area = 0, areaPriceRate = 0 } = params;

        // compute area
        const minArea = 5000;
        const areaTier =
          area <= minArea ? minArea : Math.round(area / 1000) * 1000;
        const areaCost = ((areaTier - minArea) / 1000) * areaPriceRate;

        return basePrice + areaCost;
      },
    },
    {
      label: 'Open',
      value: PricingModel.OPEN,
    },
    {
      label: 'Fixed',
      value: PricingModel.FIXED,
    },
  ],
  proposalStatus: [
    {
      label: 'Active',
      value: ProposalStatus.ACTIVE,
      order: 10,
    },
    {
      label: 'Request',
      value: ProposalStatus.REQUEST,
      order: 20,
    },
    {
      label: 'Sent',
      value: ProposalStatus.SENT,
      order: 30,
    },
    {
      label: 'Accepted',
      value: ProposalStatus.ACCEPTED,
      order: 40,
    },
    {
      label: 'Rejected',
      value: ProposalStatus.REJECTED,
      order: 50,
    },
    {
      label: 'Inactive',
      value: ProposalStatus.INACTIVE,
      order: 60,
    },
  ],
  proposalType: [
    {
      label: 'Lawn & Irrigation',
      description: '',
      value: ProposalType.LAWN_AND_IRRIGATION,
      order: 10,
    },
    {
      label: 'Lawn Only',
      description: '',
      value: ProposalType.LAWN,
      order: 20,
    },
    {
      label: 'Irrigation Only',
      description: '',
      value: ProposalType.IRRIGATION,
      order: 30,
    },
    {
      label: 'Custom',
      description: '',
      value: ProposalType.CUSTOM,
      order: 100,
    },
  ],
  salesKpi: [
    {
      label: 'Sold',
      value: SalesKPI.SOLD,
      order: 10,
    },
    {
      label: 'Booked',
      value: SalesKPI.BOOKED,
      order: 20,
    },
    {
      label: 'Closing Rate',
      value: SalesKPI.CLOSING,
      order: 30,
    },
  ],
  messageType: [
    {
      label: 'Comment',
      value: MessageType.COMMENT,
      icon: <ModeCommentIcon />,
      order: 10,
    },
    {
      label: 'Call',
      value: MessageType.CALL,
      icon: <CallIcon />,
      order: 20,
    },
    {
      label: 'Notification',
      value: MessageType.NOTIFICATION,
      icon: <NotificationsIcon />,
      order: 30,
    },
    {
      label: 'Attachments',
      value: MessageType.ATTACHMENTS,
      icon: <AttachmentIcon />,
      order: 40,
    },
  ],
};

export default settings;
export { UserRoles };
