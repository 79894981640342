import React from 'react';
import {
  Calendar as BigCalendar,
  dateFnsLocalizer,
  DateLocalizer,
  CalendarProps as BigCalendarProps,
} from 'react-big-calendar';
import withDragAndDrop, {
  withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import * as datefns from 'date-fns';
import fnsEnUs from 'date-fns/locale/en-US';

function getLocalizer() {
  return dateFnsLocalizer({
    ...datefns,
    locales: {
      'en-US': fnsEnUs,
    },
  });
}

interface CalendarProps
  extends Partial<BigCalendarProps>,
    Partial<withDragAndDropProps> {
  defaultDate?: Date;
  onInit?: (params: { start: Date; end: Date }) => void;
  localizer?: DateLocalizer;
}

interface BaseCalendarProps extends CalendarProps {
  CustomCalendar: any;
}

function BaseCalendar(props: BaseCalendarProps) {
  const {
    defaultView = 'week',
    defaultDate = new Date(),
    onInit,
    style = { height: '80vh' },
    CustomCalendar,
    ...otherProps
  } = props;

  const localizer = getLocalizer();
  const runOnce = React.useRef(false);

  const startAccessor = (d: any) => {
    return datefns.parseISO(d.start);
  };

  const endAccessor = (d: any) => {
    return datefns.parseISO(d.end);
  };

  React.useEffect(() => {
    if (runOnce.current) return;
    runOnce.current = true;
    if (typeof onInit !== 'function') return;
    const range: any = { start: null, end: null };
    if (defaultView === 'month') {
      range.start = datefns.startOfMonth(defaultDate);
      range.end = datefns.endOfMonth(defaultDate);
    } else if (defaultView === 'week') {
      range.start = datefns.startOfWeek(defaultDate);
      range.end = datefns.endOfWeek(defaultDate);
    } else if (defaultView === 'day') {
      range.start = datefns.startOfDay(defaultDate);
      range.end = datefns.endOfDay(defaultDate);
    } else if (defaultView === 'agenda') {
      range.start = defaultDate;
      range.end = datefns.addMonths(defaultDate, 1);
    }
    onInit(range);
  }, [defaultDate, defaultView, onInit]);

  return (
    <>
      <CustomCalendar
        {...otherProps}
        localizer={localizer}
        defaultView={defaultView}
        defaultDate={defaultDate.toISOString()}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        style={style}
      />
    </>
  );
}

function Calendar(props: CalendarProps) {
  return <BaseCalendar {...props} CustomCalendar={BigCalendar} />;
}

function DndCalendar(props: CalendarProps) {
  const DndBigCalendar = withDragAndDrop(BigCalendar);
  return <BaseCalendar {...props} CustomCalendar={DndBigCalendar} />;
}

export default Calendar;
export { Calendar, DndCalendar };
