import * as dateFns from 'date-fns';
// import log from 'loglevel';

import { ProposalRecordService, ProposalStatus, ProposalViewRecord } from '../../comp/DataStore/Proposals';
import { ServiceViewRecord, SeasonList, ServiceType } from '../../comp/DataStore/Services';
import settings from '../../settings';
import { DataContext } from '../../comp/DataStore';
import { Label, PriceFnParams } from '../../types/settings';
import { ProspectViewRecord } from '../../comp/DataStore/Prospects';

function toNum(x: number | string | null): string {
  if (x === null || x === undefined) return '';
  const n = typeof x === 'string' ? parseFloat(x) : x;
  return String(n.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // with thousands sep
}

type PriceModifierParams = {
  area: number,
  percentInc?: number,
  difficulty?: number,
  pricingFn?: (p: PriceFnParams) => number,
  service: {
    quantity?: number,
    fixed_price?: number,
    price?: number,
    computed_price?: number,
    low_price_rate?: number,
    high_price_rate?: number,
    price_multiplier?: number,
  },
  recompute?: Boolean,
}

function priceModifier(params: PriceModifierParams): number | undefined {
  const {
    percentInc = 0,
    difficulty = 0,
    area,
    pricingFn,
    service,
    recompute = false,
  } = params;
  const quantity = service.quantity || 1;

  if (service.fixed_price) return service.fixed_price * quantity;
  if (!service?.price) return undefined;
  if (!recompute && service.computed_price) return service.computed_price;

  let areaPriceRate: number | undefined = undefined;
  if (service.low_price_rate && service.high_price_rate) {
    const diff = Math.abs(service.high_price_rate - service.low_price_rate);
    areaPriceRate = service.low_price_rate + (diff * difficulty) / 100;
  }

  // compute base price
  let basePrice = service?.price;
  if (typeof pricingFn === 'function') {
    basePrice = pricingFn({ basePrice, area, areaPriceRate });
  }

  const multiplier = service?.price_multiplier || 1;
  const finalPrice = basePrice * multiplier * (1 + percentInc / 100) * quantity;

  return finalPrice;
}

/**
 * This is the proposal object that is used
 * that is used mainly in pages, templates, etc.
 */
type ProposalService = {
  id: string;
  orig_name: string;
  name: string;
  description?: string;
  price?: string;
  currentPrice?: string;
  fixed_price?: number;
  computed_price?: string;
  quantity?: number;
  uom?: string;
  custom: boolean;
  type?: ServiceType;
  image_url?: string;
  season?: string;
  seasonKey?: {
    label: string;
    value: SeasonList;
    order?: number;
  };
}

type ProposalServiceSummary = {
  label: string,
  type: string,
  subtotal: string,
  count: number,
}

type Proposal = {
  id: string;
  parent_id?: string;
  parent_status?: string;
  records: {
    prospect: ProspectViewRecord,
    proposal: ProposalViewRecord,
    assigned_to: any,
    appointments: any,
  };
  created_at: {
    ymd: string;
    year: string;
    month: string;
    day: string;
  };
  updated_at: {
    ymd: string;
    year: string;
    month: string;
    day: string;
  };
  prepared_by: {
    full_name: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
  };
  assigned_to: {
    full_name: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    signature?: string;
  };

  map: ProposalViewRecord['map'],

  status: ProposalStatus;
  year: number;
  type: string;
  typeKey?: Label;
  deleted_at?: string;

  prospect: {
    id: string,
    full_name?: string,
    title?: string,
    first_name?: string,
    last_name?: string,
    email?: string,
    address?: string,
    address_1?: string,
    address_2?: string,
    phone_1?: string,
    phone_2?: string,
    city?: string,
    state?: string,
    zip?: string,
  };

  services: {
    records: ProposalService[],
    summary: ProposalServiceSummary[],
    difficulty: number;
    percentIncrease: number;
    hasPriceDiff: Boolean;
    total_area_sqft: string;
    subtotal: string | number;
    tax: string;
    total: string;
  }

  notes: {
    records: {
      id: string;
      note: string;
    }[],
  }

  address?: string;
  file?: string;

  attachments?: {
    records: {
      path: string;
      filename?: string;
    }[],
  }
};

type NewProposalParams = {
  datastore: DataContext;
  id: string;
};

type GetServicesParams = {
  datastore: DataContext;
  area: number;
  services: ProposalViewRecord['services'],
};

async function getServices(params: GetServicesParams) {
  const { datastore, area, services } = params;
  const { records, difficulty, percentIncrease } = services;
  const serviceIds = records.filter((x) => !x.custom).map((x) => x.id);

  let subtotal = 0;

  const response: Partial<Proposal['services']> = {
    records: [],
    hasPriceDiff: false,
    summary: [],
    subtotal: 0,
  };

  const summary = settings.serviceType.map((sType) => ({
    label: sType.label,
    type: sType.value,
    subtotal: 0,
    count: 0,
  }));

  let serviceList: ServiceViewRecord[] = [];
  if (serviceIds.length > 0) {
    serviceList = await datastore.services.refresh({
      in: { column: 'id', values: serviceIds },
    }) || [];
  }

  records.forEach((r) => {
    const isCustom = Boolean(r.custom);
    let sr: ServiceViewRecord | undefined;
    let scr: ProposalRecordService['custom'];
    let season: Label | undefined;
    if (r.custom) {
      scr = r.custom;
      season = settings.seasons.find((x) => x.value === scr?.season);
    } else {
      sr = serviceList.find((x) => x.id === r.id);
      season = settings.seasons.find((x) => x.value === (sr?.season));
    }
    const priceModel = settings.pricingModel.find((x) => sr && x.value === sr.pricing);

    if (!sr && !scr) {
      throw new Error(`Cannot find service record ${r.id}: ${JSON.stringify({ service: r })}`);
    }

    const quantity = r.quantity || 1;
    const uom = (isCustom ? r.custom?.uom : r.uom);
    const origName: string = (isCustom ? scr?.name : sr?.name) || '';
    let name = origName;
    if (quantity > 1) name += ` (Qty: ${quantity}${uom})`;

    const price = priceModifier({
      percentInc: percentIncrease,
      difficulty,
      area,
      service: {
        quantity: (isCustom ? r.custom?.quantity : r.quantity),
        fixed_price: (isCustom ? r.custom?.fixed_price : r.fixed_price),
        price: (isCustom ? undefined : r.price),
        computed_price: (isCustom ? undefined : r.computed_price),
        low_price_rate: (isCustom ? undefined : sr?.low_price_rate),
        high_price_rate: (isCustom ? undefined : sr?.high_price_rate),
        price_multiplier: (isCustom ? undefined : sr?.price_multiplier),
      },
      pricingFn: priceModel?.priceModifier,
    });

    const currentPrice = priceModifier({
      percentInc: percentIncrease,
      difficulty,
      area,
      service: {
        quantity: (isCustom ? r.custom?.quantity : r.quantity),
        fixed_price: (isCustom ? r.custom?.fixed_price : r.fixed_price),
        price: (isCustom ? undefined : r.price),
        computed_price: (isCustom ? undefined : r.computed_price),
        low_price_rate: (isCustom ? undefined : sr?.low_price_rate),
        high_price_rate: (isCustom ? undefined : sr?.high_price_rate),
        price_multiplier: (isCustom ? undefined : sr?.price_multiplier),
      },
      pricingFn: priceModel?.priceModifier,
      recompute: true,
    });

    const service = {
      id: (isCustom ? scr?.id : sr?.id) || '',
      name,
      orig_name: origName,
      description: (isCustom ? scr?.description : sr?.description) || '',
      price,
      currentPrice,
      fixed_price: (isCustom ? scr?.fixed_price : r.fixed_price),
      quantity,
      uom,
      custom: isCustom,
      type: (isCustom ? scr?.type : sr?.type),
      image_url: (isCustom ? scr?.image_url : sr?.image_url),
      season: season?.label,
      seasonKey: season,
    };

    // convert to strings for response
    response.records?.push({
      ...service,
      price: `$${toNum(price || 0)}`,
      currentPrice: `$${toNum(currentPrice || 0)}`,
    });

    // compute summary
    const summaryType = summary.find((x) => x.type === service.type);
    if (summaryType) {
      summaryType.subtotal += service.price || 0;
      summaryType.count += 1;
    }

    // proposal subtotal
    subtotal += service.price || 0;
  });

  response.summary = summary.map((x) => ({
    ...x,
    subtotal: x.count > 0 ? `$${toNum(x.subtotal)}` : 'none selected',
  }));
  response.subtotal = subtotal; // `$${toNum(subtotal)}`;

  return response;
}

/**
 * Converts a Proposal Record from view to a Proposal Object
 * to be used in email and proposal templates
 * 
 */
async function newProposal(params: NewProposalParams) {
  const { datastore, id } = params;

  // get proposal
  const proposal = await datastore.proposals.get({ id });
  if (!proposal) return;

  const createdAt = dateFns.parseISO(proposal.created_at);
  const updatedAt = dateFns.parseISO(proposal.updated_at);

  let total_area_sqft: number = (proposal.map.overlays || []).reduce(
    (prev: number, curr: any) => prev + curr.area_sqft,
    0
  );
  if (proposal.map.area) {
    total_area_sqft = proposal.map.area;
  }

  // get prospect
  const prospectPromise = datastore.prospects.get({ id: proposal.prospect_id });

  // get assigned to
  let userPromise: Promise<any> = Promise.resolve({});
  if (proposal.assigned_to) {
    userPromise = datastore.users.refresh().then((users) => {
      return users?.find((x: any) => x.email === proposal.assigned_to) || {};
    });
  }

  // get services info
  let servicePromise = getServices({
    datastore,
    area: total_area_sqft,
    services: proposal.services,
  });

  // get appointments
  const eventsPromise: Promise<any> = datastore.appointments.getRange({
    proposal_id: proposal.id,
  });

  // get tax rate
  const taxRatePromise = await datastore.taxRate.getTaxRate(
    proposal.map.address_components
  );

  const [prospect, assignedTo, services, appointments, taxRate] = await Promise.all([
    prospectPromise,
    userPromise,
    servicePromise,
    eventsPromise,
    taxRatePromise,
  ]);

  if (!prospect) {
    throw new Error(`Cannot find prospect id: ${proposal.prospect_id}`);
  }

  let tax = 0;
  const subtotal = parseFloat(String(services.subtotal));
  if (taxRate) {
    tax = subtotal * (taxRate.tax_rate / 100);
  }

  const proposalType = settings.proposalType.find((x) => x.value === proposal.proposal_type);

  const data: Proposal = {
    id: proposal.id,
    year: proposal.year || parseInt(dateFns.format(createdAt, 'yyyy'), 10),
    records: {
      prospect,
      proposal,
      assigned_to: assignedTo,
      appointments,
    },
    created_at: {
      ymd: dateFns.format(createdAt, 'yyyy-MM-dd'),
      year: dateFns.format(createdAt, 'yyyy'),
      month: dateFns.format(createdAt, 'MMMM'),
      day: dateFns.format(createdAt, 'd'),
    },
    updated_at: {
      ymd: dateFns.format(createdAt, 'yyyy-MM-dd'),
      year: dateFns.format(updatedAt, 'yyyy'),
      month: dateFns.format(updatedAt, 'MMMM'),
      day: dateFns.format(updatedAt, 'd'),
    },
    prepared_by: {
      full_name: `${proposal.prepared_by_first_name} ${proposal.prepared_by_last_name}`,
      first_name: proposal.prepared_by_first_name,
      last_name: proposal.prepared_by_last_name,
      email: proposal.prepared_by_email,
      phone: proposal.prepared_by_phone,
    },
    assigned_to: {
      full_name: `${proposal.assigned_first_name} ${proposal.assigned_last_name}`,
      first_name: proposal.assigned_first_name,
      last_name: proposal.assigned_last_name,
      email: proposal.assigned_email,
      phone: proposal.assigned_phone,
      signature: assignedTo.signature,
    },
    prospect: {
      id: proposal.prospect_id,
      full_name: proposal.prospect_full_name,
      title: proposal.prospect_title,
      first_name: proposal.prospect_first_name,
      last_name: proposal.prospect_last_name,
      email: proposal.prospect_email,
      address:
        `${proposal.prospect_address_1} \n` +
        (proposal.prospect_address_2
          ? `${proposal.prospect_address_2} \n`
          : '') +
        `${proposal.prospect_city}, ${proposal.prospect_state}, ${proposal.prospect_zip}`,
      address_1: proposal.prospect_address_1,
      address_2: proposal.prospect_address_2,
      phone_1: proposal.prospect_phone_1,
      phone_2: proposal.prospect_phone_2,
      city: proposal.prospect_city,
      state: proposal.prospect_state,
      zip: proposal.prospect_zip,
    },
    services: {
      records: services.records || [],
      hasPriceDiff: false,
      summary: services.summary || [], difficulty: proposal.services.difficulty,
      percentIncrease: proposal.services.percentIncrease,
      total_area_sqft: toNum(total_area_sqft),
      subtotal: `$${toNum(subtotal)}`,
      tax: `$${toNum(tax)}`,
      total: `$${toNum(subtotal + tax)}`,
    },
    notes: proposal.notes,
    map: proposal.map,
    status: proposal.status,
    type: proposalType?.label || `${proposal.proposal_type}*`, // Mark unknown proposal types 
    typeKey: proposalType,
    deleted_at: proposal.deleted_at,
    address: proposal.address,
    file: proposal.file,
    attachments: proposal.attachments,
  };

  data.services.records.forEach((x) => {
    data.services.hasPriceDiff =
      data.services.hasPriceDiff || x.price !== x.currentPrice;
  });

  return data;
}

type NewProposalGroupParams = {
  datastore: any;
  ids: string[];
};

async function newProposalGroup(params: NewProposalGroupParams) { }

export default newProposalGroup;
export { toNum, priceModifier, newProposalGroup, newProposal };
export type { Proposal, ProposalService };