import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import log from 'loglevel';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ShowMap from './ShowMap';
import { useDataStore } from '../../comp/DataStore';
import { PricingModel } from '../../comp/DataStore/Services';
import HelperBox from '../../comp/HelperBox';
import LongList from '../../comp/LongList';
import { priceModifier, toNum } from './getProposalModel';
import CustomService from './CustomService';
import settings from '../../settings';

function sortAZ(a: any, b: any) {
  return String(a.label)
    .toLowerCase()
    .localeCompare(String(b.label).toLowerCase());
}

function sortZA(a: any, b: any) {
  return String(b.label)
    .toLowerCase()
    .localeCompare(String(a.label).toLowerCase());
}

function toRegex(str: string, flags?: string) {
  const escStr = str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return new RegExp(escStr, flags);
}

type ComputeServiceParams = {
  datastore: any;
  service: {
    id: string;
    custom?: any;
  };
  percentIncrease: number;
  difficulty: number;
  recompute?: boolean;
  area: number;
};

async function recomputeService(params: ComputeServiceParams) {
  const {
    datastore,
    service,
    percentIncrease = 0,
    difficulty = 0,
    area,
    recompute = true,
  } = params;

  let record: any;
  let pm: any;
  if (!service.custom) {
    record = await datastore.services.get({ id: service.id });
    if (!record) {
      log.error('service record', service);
      throw new Error(`Unknown service record: ${service.id}`);
    }
    pm = settings.pricingModel.find((y) => y.value === record?.pricing);
    Object.assign(record, service);
  } else {
    record = service.custom;
  }

  const newPrice = priceModifier({
    percentInc: percentIncrease,
    difficulty,
    area,
    pricingFn: pm?.priceModifier,
    service: record,
    recompute,
  });

  return {
    ...record,
    ...service,
    computed_price: newPrice,
  };
}

enum SORT_BY {
  'A_TO_Z' = 'A_TO_Z',
  'Z_TO_A' = 'Z_TO_A',
}

interface StepSelectServicesProps {
  loading?: boolean;
  proposal: any;
  dataRef?: React.MutableRefObject<any>;
}

function SelectServices(props: StepSelectServicesProps) {
  const datastore = useDataStore();
  const { loading, proposal, dataRef } = props;

  const serviceToList = (x: any) => ({
    key: x.id,
    label: x.name,
    value: x,
    isCustom: Boolean(x.custom),
  });

  const initPrograms = datastore.programs.getState();
  const initServices = datastore.services.getState();
  const [programsState, setProgramsState] = React.useState<any>(initPrograms);
  const [servicesState, setServicesState] = React.useState<any>(initServices);

  const initServSelected = proposal.services.records.map(serviceToList);
  const initPercIncrease = proposal.services.percentIncrease === undefined ? 0 : proposal.services.percentIncrease;
  const initDifficulty = proposal.services.difficulty === undefined ? 50 : proposal.services.difficulty;

  const [progSelected, progSetSelected] = React.useState<any[]>([]);
  const [servSelected, servSetSelected] =
    React.useState<any[]>(initServSelected);
  const [reCalculated, setRecalculated] = React.useState<any[]>([]);
  const [percIncrease, setPercIncrease] =
    React.useState<number>(initPercIncrease);
  const [difficulty, setDifficulty] = React.useState<number>(initDifficulty);
  const [map, setMap] = React.useState<any>(proposal.map);
  const [openHelper, setOpenHelper] = React.useState(false);
  const [servSortBy, servSetSortBy] = React.useState(SORT_BY.A_TO_Z);
  const [progSortBy, progSetSortBy] = React.useState(SORT_BY.A_TO_Z);
  const [modifyService, setModifyService] = React.useState<any>({ value: {} });
  const [search, setSearch] = React.useState<string>('');
  const [filter, setFilter] = React.useState<any>({});
  const servicesRef = React.useRef({ loaded: false });
  const programsRef = React.useRef({ loaded: false });
  const [custServOpen, setCustServOpen] = React.useState(false);
  const [custEdit, setCustEdit] = React.useState<any>({});

  const serviceRecords = React.useMemo(() => {
    // const sortFn = servSortBy === SORT_BY.A_TO_Z ? sortAZ : sortZA;
    return (
      servicesState.records
        // search filter
        .filter((x: any) => {
          const season = settings.seasons.find((ss) => ss.value === x.season);
          if (search) {
            const regex = toRegex(search, 'i');
            const result =
              regex.test(String(x.name || '')) ||
              regex.test(String(x.description || '')) ||
              regex.test(String(season?.label || '')) ||
              regex.test(String(x.type || ''));
            return result;
          }
          return true;
        })
        // season filter
        .filter((x: any) => {
          if (filter.season && filter.season !== '-') {
            return x.season === filter.season;
          }
          return true;
        })
        // service type filter
        .filter((x: any) => {
          if (filter.serviceType && filter.serviceType !== '-') {
            return x.type === filter.serviceType;
          }
          return true;
        })
        .map((x: any) => {
          const inList = servSelected.find(
            (y: any) => y.key === x.id && !y.isCustom
          );
          if (!inList) return x;
          x.quantity = inList.value.quantity;
          x.price = inList.value.price;
          x.fixed_price = inList.value.fixed_price;
          x.computed_price = inList.value.computed_price;
          return x;
        })
        .map(serviceToList)
      // .map((x: any) => ({
      //   key: x.id,
      //   label: x.name,
      //   value: x,
      // }))
      // .sort(sortFn)
    );
  }, [servicesState.records, servSelected, search, filter]);

  const programRecords = React.useMemo(() => {
    const list: any[] = [];
    const sortFn = progSortBy === SORT_BY.A_TO_Z ? sortAZ : sortZA;
    programsState.records
      .filter((x: any) => {
        if (search) {
          const regex = toRegex(search, 'i');
          const result =
            regex.test(String(x.name || '')) ||
            regex.test(String(x.description || ''));
          return result;
        }
        return true;
      })
      .forEach((x: any) => {
        list.push({
          key: x.id,
          label: x.name,
          value: x,
        });
      });
    list.sort(sortFn);
    return list;
  }, [programsState.records, progSortBy, search]);

  const addServices = (list: any[]) => {
    log.debug('list', list);
    list.forEach((x) => {
      servSetSelected((curr: any) => {
        if (!x.custom) {
          // check if it is existing in records
          const existing = serviceRecords.find((y: any) => y.key === x.id);
          if (!existing) return curr;

          // check if already in list
          const inList = curr.find((y: any) => y.key === existing.key);
          if (inList) return curr;

          // Setup price
          if (existing.value.price === undefined) {
            existing.value.price = existing.value.start_low_price;
          }

          return [...curr, existing]; // .sort(sortAZ);
        } else {
          // CUSTOM

          // check if already in the list
          const inList = curr.find((y: any) => y.key === x.id);
          if (inList) {
            // Replace with updated
            return curr.map((y: any) =>
              y.key === x.id ? serviceToList(x) : y
            );
          }

          return [...curr, serviceToList(x)];
        }
      });
    });
  };

  const removeServices = (list: any[]) => {
    list.forEach((x) => {
      servSetSelected((curr: any[]) => curr.filter((y) => y.key !== x.id));
    });
  };

  const onSelectProgram = (prog: any) => {
    addServices(prog.value.services);
    progSetSelected((curr: any[]) => {
      const inList = curr.find((y: any) => y.key === prog.key);
      if (inList) return curr;

      return [...curr, prog];
    });
  };

  const onRemoveProgram = (prog: any) => {
    removeServices(prog.value.services);
    progSetSelected((curr: any[]) => {
      return curr.filter((y: any) => y.key !== prog.key);
    });
  };

  const onSelectService = (prog: any) => {
    addServices([prog.value]);
  };

  const onRemoveService = (prog: any) => {
    removeServices([prog.value]);
  };

  const onModifyService = (prog: any) => () => {
    if (!prog.isCustom) {
      setModifyService(prog);
      setOpenHelper(true);
    } else {
      setCustEdit(prog);
      setCustServOpen(true);
    }
  };

  const closeHelper = () => {
    servSetSelected((curr: any) => {
      const newData: any[] = JSON.parse(JSON.stringify(curr));
      const item = newData.find((x) => x.key === modifyService.key);
      if (!item) return curr;
      item.value.quantity = modifyService.value.quantity;
      item.value.price = modifyService.value.price;
      item.value.fixed_price = modifyService.value.fixed_price;
      return newData; // .sort(sortAZ);
    });
    setOpenHelper(false);
  };

  const onChangeQuantity = (value: number) => {
    setModifyService((curr: any) => ({
      ...curr,
      value: { ...curr.value, quantity: value },
    }));
  };

  const onChangePrice = (value: number) => {
    setModifyService((curr: any) => ({
      ...curr,
      value: { ...curr.value, price: value },
    }));
  };

  const onChangeFixedPrice = (value?: number) => {
    const price = Number.isNaN(value) ? undefined : value;
    setModifyService((curr: any) => ({
      ...curr,
      value: { ...curr.value, fixed_price: price },
    }));
  };

  const onChangeDifficulty = (event: any) => {
    const { value } = event.target;
    setDifficulty(value);
  };

  const onChangePercIncrease = (value: number) => {
    setPercIncrease(value);
  };

  const onChangeSearch = () => {
    let timeout: any;
    return (e: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(e.target.value);
      }, 500);
    };
  };

  const onChangeFilter = (str: string) => (e: any) => {
    setFilter((curr: any) => ({
      ...curr,
      [str]: e.target.value,
    }));
  };

  const onSaveCustServ = (d: any) => {
    addServices([
      {
        id: d.id,
        fixed_price: d.fixed_price,
        quantity: d.quantity,
        custom: d,
      },
    ]);
    setCustServOpen(false);
  };

  const onDeleteCustServ = (id: string) => {
    removeServices([{ id }]);
    setCustServOpen(false);
  };

  const onCloseCustServ = () => {
    setCustServOpen(false);
  };

  const onSaveMap = (p: any) => {
    setMap(p.map);
  }

  const total_area_sqft = React.useMemo(() => {
    let area = (map?.overlays || []).reduce(
      (prev: number, curr: any) => prev + curr.area_sqft,
      0
    );
    if (map.area) {
      area = map.area;
    }
    return area;
  }, [map]);

  const toggleSortBy = (setSortFn: (p: any) => void) => () => {
    setSortFn((curr: SORT_BY) => {
      return curr === SORT_BY.A_TO_Z ? SORT_BY.Z_TO_A : SORT_BY.A_TO_Z;
    });
  };

  const showSlider = React.useMemo(() => {
    return (
      Boolean(modifyService.value.low_price_rate) &&
      Boolean(modifyService.value.high_price_rate)
    );
  }, [modifyService]);

  const subTotal = React.useMemo(() => {
    return reCalculated.reduce(
      (prev, curr) => prev + (curr.value.computed_price || 0),
      0
    );
  }, [reCalculated]);

  const selection = reCalculated.map((selected) => (
    <ButtonBase
      key={selected.key}
      onClick={onModifyService(selected)}
      sx={{ width: '100%' }}
    >
      <Box sx={{ display: 'flex', mb: '10px', width: '100%' }}>
        <Typography sx={{ flexGrow: 1, textAlign: 'left' }}>
          {selected.label}
        </Typography>
        <Typography sx={{ textAlign: 'right', ml: '10px' }}>
          {toNum(selected.value.computed_price || 0)}
        </Typography>
      </Box>
    </ButtonBase>
  ));

  React.useEffect(() => {
    const wait = setTimeout(async () => {
      Promise.all(
        servSelected.map(async (selected) => {
          const value = await recomputeService({
            datastore,
            service: selected.value,
            percentIncrease: percIncrease,
            difficulty,
            area: total_area_sqft,
          });
          return serviceToList(value);
        })
      ).then(setRecalculated);
    }, 50);

    return () => {
      clearTimeout(wait);
    };
  }, [datastore, difficulty, percIncrease, servSelected, total_area_sqft]);

  React.useEffect(() => {
    if (!dataRef) return;
    Object.assign(dataRef.current, {
      map: {
        ...(proposal.map),
        area: map.area,
      },
      services: {
        difficulty: difficulty || 0,
        percentIncrease: percIncrease,
        records: reCalculated.map((opt: any) => opt.value),
      },
    });
  }, [dataRef, reCalculated, percIncrease, difficulty, proposal.map, map]);

  React.useEffect(() => {
    if (servicesRef.current.loaded) return;
    servicesRef.current.loaded = true;
    datastore.services
      .refresh()
      .catch(() => (servicesRef.current.loaded = false));
  }, [datastore.services, servicesRef.current.loaded]);

  React.useEffect(() => {
    if (programsRef.current.loaded) return;
    programsRef.current.loaded = true;
    datastore.programs
      .refresh()
      .catch(() => (programsRef.current.loaded = false));
  }, [datastore.programs, programsRef.current.loaded]);

  React.useEffect(() => {
    const programsId = datastore.programs.subscribe(setProgramsState);
    const servicesId = datastore.services.subscribe(setServicesState);
    return () => {
      datastore.programs.unsubscribe(programsId);
      datastore.services.unsubscribe(servicesId);
    };
  }, [datastore]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            inputProps={{
              list: 'seasons-data',
            }}
            placeholder="Search..."
            onChange={onChangeSearch()}
            fullWidth
          />
          <Button
            onClick={() => {
              setCustEdit({}); // set blank
              setCustServOpen(true); // open
            }}
            variant="outlined"
            sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
          >
            Add Custom Service
          </Button>
          <CustomService
            open={custServOpen}
            record={custEdit.value}
            onClose={onCloseCustServ}
            onSave={onSaveCustServ}
            onDelete={onDeleteCustServ}
          />
        </Stack>

        <Box sx={{ p: '10px' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h5" sx={{ mb: '10px' }}>
              Service Programs
            </Typography>
            <IconButton
              sx={{ mb: '10px' }}
              onClick={toggleSortBy(progSetSortBy)}
            >
              {progSortBy === SORT_BY.A_TO_Z ? (
                <ArrowDownwardIcon />
              ) : (
                <ArrowUpwardIcon />
              )}
            </IconButton>
          </Stack>

          {programRecords.length ? (
            <LongList
              records={programRecords}
              multiple
              selected={progSelected}
              // setSelected={progSetSelected}
              onSelect={onSelectProgram}
              onRemove={onRemoveProgram}
            />
          ) : (
            <i>Empty</i>
          )}
        </Box>
        <Box sx={{ p: '10px' }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h5" sx={{ mb: '10px' }}>
              All Services
            </Typography>
            <IconButton
              sx={{ mb: '10px' }}
              onClick={toggleSortBy(servSetSortBy)}
            >
              {servSortBy === SORT_BY.A_TO_Z ? (
                <ArrowDownwardIcon />
              ) : (
                <ArrowUpwardIcon />
              )}
            </IconButton>

            <FormControl size="small" sx={{ minWidth: '120px' }}>
              <InputLabel id="season-filter-label">Season</InputLabel>
              <Select
                key={`assigned_to-${proposal.id}`}
                labelId="season-filter-label"
                id="season-filter"
                name="season-filter"
                value={filter.season || '-'}
                label="Season"
                onChange={onChangeFilter('season')}
              >
                <MenuItem value="-">
                  <i>None Selected</i>
                </MenuItem>
                {settings.seasons.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" sx={{ minWidth: '120px' }}>
              <InputLabel id="serviceType-filter-label">
                Service Type
              </InputLabel>
              <Select
                key={`assigned_to-${proposal.id}`}
                labelId="serviceType-filter-label"
                id="serviceType-filter"
                name="serviceType-filter"
                value={filter.serviceType || '-'}
                label="Service Type"
                onChange={onChangeFilter('serviceType')}
              >
                <MenuItem value="-">
                  <i>None Selected</i>
                </MenuItem>
                {settings.serviceType.map((s) => (
                  <MenuItem key={s.value} value={s.value}>
                    {s.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          {serviceRecords.length ? (
            <Box sx={{ mt: '10px' }}>
              <LongList
                records={serviceRecords}
                multiple
                selected={servSelected.filter((x: any) => !x.isCustom)}
                onSelect={onSelectService}
                onRemove={onRemoveService}
              />
            </Box>
          ) : (
            <i>Empty</i>
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Accordion elevation={3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="map">
            <Typography>Map</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShowMap map={proposal.map} onSave={onSaveMap} />
          </AccordionDetails>
        </Accordion>

        <Accordion elevation={3} defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="map">
            <Typography>Selected Services</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Box
            // sx={{ bgcolor: '#F8F8F8', p: '10px' }}
          >
            {/* <Typography variant="h5" sx={{ mb: '20px' }}>
              Selected Services
            </Typography> */}
            <FormControl fullWidth>
              <InputLabel id="difficulty-label">Difficulty</InputLabel>
              <Select
                key={`assigned_to-${proposal.id}`}
                labelId="difficulty-label"
                id="difficulty"
                name="difficulty"
                // percent value 0-100
                value={difficulty}
                label="Difficulty"
                onChange={onChangeDifficulty}
              >
                <MenuItem value={0}>Easy</MenuItem>
                <MenuItem value={50}>Medium</MenuItem>
                <MenuItem value={100}>Hard</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ pl: '20px', pr: '20px', mt: '20px' }}>
              <Slider
                step={1}
                value={percIncrease}
                min={0}
                max={100}
                valueLabelDisplay="auto"
                onChange={(e, value: any) => onChangePercIncrease(value)}
                marks={[
                  {
                    value: 0,
                    label: '0%',
                  },
                  {
                    value: 50,
                    label: '50%',
                  },
                  {
                    value: 100,
                    label: '100%',
                  },
                ]}
              />
            </Box>

            <Box sx={{ pl: '20px', pr: '20px', mt: '20px', mb: '20px' }}>
              {selection}
            </Box>

            <Box sx={{ display: 'flex', pl: '20px', pr: '20px', mb: '20px' }}>
              <Typography sx={{ flexGrow: 1 }}>
                <b>Sub Total</b>
              </Typography>
              <Typography>
                <b>{toNum(subTotal)}</b>
              </Typography>
            </Box>
          </Box>
          </AccordionDetails>
        </Accordion>
        <HelperBox open={openHelper} onClose={closeHelper} width={500}>
          <IconButton onClick={closeHelper}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <br />
          <Typography variant="h6">{modifyService.label}</Typography>
          <br />
          <Typography variant="body1">
            {modifyService.value.description}
          </Typography>
          <br />
          {modifyService.value.pricing !== PricingModel.SQFT ? (
            <></>
          ) : (
            <>
              <Typography variant="body1">
                {'Difficulity price range is from '}
                {modifyService.value.low_price_rate}
                {' to '}
                {modifyService.value.high_price_rate}
              </Typography>
              <br />
            </>
          )}
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Price"
              variant="outlined"
              value={modifyService.value.price || ''}
              type="number"
              onChange={(e) => {
                try {
                  const d = parseFloat(e.target.value);
                  onChangePrice(d);
                } catch (e) {
                  log.error(e);
                }
              }}
            />
            {!showSlider ? (
              <></>
            ) : (
              <Box sx={{ pl: '20px', pr: '20px' }}>
                <Slider
                  value={modifyService.value.price}
                  min={modifyService.value.start_low_price}
                  max={modifyService.value.start_high_price}
                  valueLabelDisplay="off"
                  onChange={(e, value: any) => onChangePrice(value)}
                  marks={[
                    {
                      value: modifyService.value.start_low_price,
                      label: modifyService.value.start_low_price,
                    },
                    {
                      value: modifyService.value.start_high_price,
                      label: modifyService.value.start_high_price,
                    },
                  ]}
                  // sx={{ pl: '40px', pr: '40px' }}
                />
              </Box>
            )}
            <Divider />
            <TextField
              fullWidth
              label="Fixed Price"
              variant="outlined"
              helperText="Specify a value regardless of area, difficulty, and price increase."
              value={
                Number.isNaN(modifyService.value.fixed_price) ||
                modifyService.value.fixed_price === undefined
                  ? ''
                  : modifyService.value.fixed_price
              }
              InputLabelProps={{ shrink: true }}
              type="number"
              onChange={(e) => {
                try {
                  const d = parseFloat(e.target.value);
                  onChangeFixedPrice(d);
                } catch (e) {
                  log.error(e);
                }
              }}
            />
            <TextField
              fullWidth
              label="Quantity"
              variant="outlined"
              value={modifyService.value.quantity || 1}
              type="number"
              onChange={(e) => {
                try {
                  const d = parseFloat(e.target.value);
                  onChangeQuantity(d);
                } catch (e) {
                  log.error(e);
                }
              }}
            />
          </Stack>
        </HelperBox>
      </Grid>
    </Grid>
  );
}

export default SelectServices;
export { SelectServices, recomputeService };
