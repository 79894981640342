import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Table from '../../comp/Table';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import { getThumbnail, ImageSize } from '../../comp/ImageSet';
import { ActionType } from '../../types/datastore.d';

function getHeaders(props: any) {
  const createLink = (to: string) => (e: any) => {
    e.preventDefault();
    props.navigate(to);
  };
  const root = 'prospects';

  const headers = [
    {
      accessor: 'avatar_url',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        const thumb = getThumbnail(props.value, ImageSize.xs);
        return (
          <div className="clickable" onClick={createLink(to)}>
            <Avatar alt={props.row.original.first_name} src={thumb}>
              {String(props.row.original.first_name)[0].toUpperCase()}
            </Avatar>
          </div>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'full_name',
      Cell: (props: any) => {
        const row = props.row.original;
        const to = `/${root}/${row.id}`;

        return (
          <div className="clickable" onClick={createLink(to)}>
            <Typography>{String(props.value)}</Typography>
            <Typography sx={{ fontSize: '90%' }}>{row.address}</Typography>
          </div>
        );
      },
    },
    {
      Header: 'Phone',
      accessor: 'primary_phone',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value)}
          </div>
        );
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (props: any) => {
        const to = `/${root}/${props.row.original.id}`;
        return (
          <div className="clickable" onClick={createLink(to)}>
            {String(props.value)}
          </div>
        );
      },
    },
    {
      accessor: 'address',
    }
  ];
  return headers;
}

let runOnce = false;
function ListProspects() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  const [search, setSearch] = React.useState<string>('');

  const initProspects = datastore.prospects.getState();
  const [prospectsState, setProspectsState] = React.useState<any>(initProspects);
  const searchTimeout = React.useRef<any>(null);

  React.useEffect(() => {
    if (runOnce) return;
    runOnce = true;
    datastore.prospects.refresh();
  }, [datastore.prospects]);

  const headers = getHeaders({ navigate });
  const columns = React.useMemo(() => headers, [headers]);
  const data = React.useMemo(
    () => prospectsState.records,
    [prospectsState]
  );

  const onChangeSearch = (e: any) => {
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      setSearch(e.target.value);
    }, 500);
  };

  const memoSearch = React.useMemo(() => search, [search]);

  React.useEffect(() => {
    if (!search) return;
    datastore.prospects.refresh({
      custom: (q: any) => {
        q.textSearch('search_column', search);
      },
      type: ActionType.UPSERT,
    }).then((result) => {
      // do something with result
    });
  }, [datastore.prospects, search, memoSearch])

  React.useEffect(() => {
    const prospectsId = datastore.prospects.subscribe(setProspectsState);
    return () => {
      datastore.prospects.unsubscribe(prospectsId);
    }
  }, [datastore]);

  return (
    <Paperbase title="Prospects" bottomSpacer="100px">
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={() => navigate('/prospects/new')}>
          New Prospect
        </Button>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search..."
          onChange={onChangeSearch}
        />
      </Stack>

      <div style={{ minHeight: 400, width: '100%' }}>
        <Table
          columns={columns}
          data={data}
          globalFilterValue={memoSearch}
          initialState={{
            hiddenColumns: [
              'address',
            ],
            globalFilter: memoSearch,
          }}
        />
      </div>
    </Paperbase>
  );
}

export default ListProspects;
