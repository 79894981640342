import React from 'react';
// import log from 'loglevel';

import { useDataStore } from '../DataStore';

function NotifListener() {
  const datastore = useDataStore();
  const [authState, setAuthState] = React.useState<any>({});

  React.useEffect(() => {
    const email = authState.user?.email;
    if (!email) return;

    datastore.messages.addReference({ assigned_to: email });
    return () => datastore.messages.deleteReference({ assigned_to: email });
  }, [datastore, authState]);

  // subscriptions
  React.useEffect(() => {
    const authId = datastore.auth.subscribe((state: any) => {
      setAuthState(state);
    })
    return () => {
      datastore.auth.unsubscribe(authId);
    };
  }, [datastore]);

  return <></>;
}

export default NotifListener;
