import * as React from 'react';
import log from 'loglevel';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import LoadScript from './LoadScript';

function geocoder(params: google.maps.GeocoderRequest) {
  const g = new google.maps.Geocoder();
  return g.geocode(params);
}

type InputAddressProps = {
  textFieldProps?: TextFieldProps;
  onSelect?: Function;
  customValue?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
};

function InputAddress(props: InputAddressProps) {
  const defaultRef: any = React.useRef<HTMLInputElement>(null);
  const { textFieldProps = {}, onSelect = () => {}, inputRef } = props;

  const ref = inputRef || defaultRef;

  React.useEffect(() => {
    if (ref.current) {
      const autocomplete = new google.maps.places.Autocomplete(ref.current, {
        componentRestrictions: {
          country: ['us'],
        },
        fields: [
          'place_id',
          'geometry',
          'name',
          'address_component',
          'adr_address',
          'formatted_address',
          'utc_offset_minutes',
        ],
      });
      autocomplete.addListener('place_changed', () => {
        const data = autocomplete.getPlace();
        log.debug('selected place', data);
        if (typeof onSelect === 'function') {
          onSelect(data);
        }
      });
    }
  }, [ref, onSelect]);

  return <TextField {...textFieldProps} inputRef={ref} />;
}

function InputAddressWrapper(props: InputAddressProps) {
  return (
    <LoadScript>
      <InputAddress {...props} />
    </LoadScript>
  );
}

export default InputAddressWrapper;
export { geocoder };
