import React from 'react';
// import log from 'loglevel';
import * as dateFns from 'date-fns';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { useDataStore } from '../DataStore';
import Message from './Message';

type DisplayBoxProps = {
  references: any;
  // for filtering output using a button
  filter?: (r: any) => boolean;
  latestAtTop?: boolean;
  onChange?: () => any;
};

function DisplayBox(props: DisplayBoxProps) {
  const { latestAtTop = false, onChange } = props;

  const datastore = useDataStore();

  const initMessages = datastore.messages.getState();
  const [messagesState, setMessagesState] = React.useState<any>(initMessages);

  const [selected, setSelected] = React.useState('');
  const runOnce = React.useRef(false);

  const records = React.useMemo(() => {
    const list: any[] = messagesState.records;

    if (typeof onChange === 'function') {
      setTimeout(() => onChange(), 100);
    }

    return list
      .filter((r: any) => {
        let match = true;
        Object.keys(props.references).forEach((key) => {
          match = match && r[key] === props.references[key];
        });
        return match;
      })
      .filter((r: any) => {
        if (props.filter) return props.filter(r);
        return true;
      })
      .sort((a: any, b: any) => {
        if (latestAtTop)
          return String(b.created_at).localeCompare(String(a.created_at));
        return String(a.created_at).localeCompare(String(b.created_at));
      });
  }, [props, messagesState.records, latestAtTop, onChange]);

  const nodes = React.useMemo(() => {
    const n: any[] = [];

    let currentDate: string;
    let currentUser: string;

    records.forEach((r) => {
      const d = dateFns.parseISO(r.created_at);
      let recordDate = dateFns.format(d, 'MMM d, yyyy');
      if (currentDate !== recordDate) {
        n.push(
          <Divider key={recordDate}>
            <Typography sx={{ fontSize: '80%' }}>{recordDate}</Typography>
          </Divider>
        );
        currentDate = recordDate;
      }

      n.push((<Message key={r.id} record={r} selected={selected} onClick={setSelected} />));

      if (currentUser !== r.created_by) {
        currentUser = r.created_by;
      }
    });

    return n;
  }, [records, selected]);

  React.useEffect(() => {
    if (runOnce.current) return;
    runOnce.current = true;
    datastore.messages.get(props.references);
  }, [props.references, datastore.messages]);

  React.useEffect(() => {
    const messagesId = datastore.messages.subscribe(setMessagesState);
    return () => {
      datastore.messages.unsubscribe(messagesId);
    }
  }, [datastore]);

  return <Box sx={{ p: '10px' }}>{nodes}</Box>;
}

export default DisplayBox;
