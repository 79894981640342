import React from 'react';
import log from 'loglevel';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PaletteIcon from '@mui/icons-material/Palette';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import RectangleTwoToneIcon from '@mui/icons-material/RectangleTwoTone';
import PentagonTwoToneIcon from '@mui/icons-material/PentagonTwoTone';
import PanToolIcon from '@mui/icons-material/PanTool';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';

import { MapEventType } from './GoogleMap';
import settings from '../../settings';

type GmapControlProps = {
  controller: any;
  colors?: string[];
  onSave?: () => any;
};

function GmapControl(props: GmapControlProps) {
  const { controller, colors = ['#283747'], onSave } = props;

  const [data, setData] = React.useState({
    zoom: settings.maps.zoom,
    center: settings.maps.center,
    selectedArea: { area_sqm: 0, area_sqft: 0 },
  });

  const [colorSwitch, setColorSwitch] = React.useState({
    open: false,
    value: colors[0],
    element: null,
  });

  const [listener, setListener] = React.useState<any>({});

  const [toolSwitch, setToolSwitch] = React.useState({ value: 'CUSTOM_PAN' });

  const openColors = (e: any) => {
    setColorSwitch({
      ...colorSwitch,
      open: true,
      element: e.currentTarget,
    });
  };

  const closeColors = (e: any) => {
    setColorSwitch({
      ...colorSwitch,
      open: false,
    });
  };

  const onColorChange = (e: any, newColor: any) => {
    setColorSwitch({
      ...colorSwitch,
      open: false,
      value: newColor,
    });
    controller.setColor(newColor);
  };

  const onToolChange = (e: any, value: any) => {
    if (value) {
      setToolSwitch({ value });
      const drawingMode = value === 'CUSTOM_PAN' ? null : value;
      controller.setDrawOptions({ drawingMode });
    }
  };

  const onDeleteShape = () => {
    controller.deleteSelection();
  };

  const onDeleteAll = () => {
    controller.deleteAll();
  };

  React.useEffect(() => {
    if (!listener.colors) {
      setListener({ ...listener, colors: true });
      controller.setColor(colorSwitch.value);
    }

    if (!listener.center) {
      setListener({ ...listener, center: true });
      controller.on(MapEventType.CENTER_CHANGED, (d: any) => {
        const newData = JSON.parse(JSON.stringify(data));
        Object.assign(newData, d);
        setData(newData);
      });
    }

    if (!listener.selected) {
      setListener({ ...listener, selected: true });
      controller.on(MapEventType.OVERLAY_SELECTED, (d: any) => {
        log.debug('GmapControls - overlay selected', d);
        const json = d.shape.toJSON();
        const { area_sqm, area_sqft } = json;
        setData({ ...data, selectedArea: { area_sqm, area_sqft } });
      });
    }
  }, [controller, data, listener, colorSwitch]);

  return (
    <Container disableGutters sx={{ p: '10px' }}>
      <Popover
        open={colorSwitch.open}
        anchorEl={colorSwitch.element}
        onClose={closeColors}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ToggleButtonGroup
          exclusive
          value={colorSwitch.value}
          aria-label="text alignment"
          onChange={onColorChange}
        >
          {colors.map((color) => (
            <ToggleButton key={`key-${color}`} value={color}>
              <RectangleTwoToneIcon htmlColor={color} />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Popover>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} justifyContent="center" textAlign="center">
          <Stack spacing={1} direction="row" justifyContent="center">
            <TextField
              value={Number(data.selectedArea.area_sqft).toFixed(2)}
              disabled
              label="Selected Area in SQFT"
            />
            <ToggleButtonGroup
              exclusive
              value={toolSwitch.value}
              aria-label="text alignment"
              onChange={onToolChange}
            >
              <ToggleButton value="CUSTOM_PAN">
                <PanToolIcon />
              </ToggleButton>
              <ToggleButton value="circle">
                <CircleTwoToneIcon htmlColor={colorSwitch.value} />
              </ToggleButton>
              <ToggleButton value="rectangle">
                <RectangleTwoToneIcon htmlColor={colorSwitch.value} />
              </ToggleButton>
              <ToggleButton value="polygon">
                <PentagonTwoToneIcon htmlColor={colorSwitch.value} />
              </ToggleButton>
            </ToggleButtonGroup>
            <Button variant="outlined" onClick={openColors}>
              <PaletteIcon htmlColor={colorSwitch.value} />
            </Button>
            <Button variant="outlined" onClick={onDeleteShape} color="error">
              <WrongLocationIcon />
            </Button>
            <Button variant="outlined" onClick={onDeleteAll} color="error">
              <DeleteForeverIcon />
            </Button>
            {onSave !== undefined ? (
              <Button variant="contained" onClick={onSave}>
                Save Progress
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </Container>
  );
}

export default GmapControl;
