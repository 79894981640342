import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UploadButton, { FileCategory } from '../../comp/UploadButton';
import ImageSet from '../../comp/ImageSet';
import SelectService from './SelectService';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import SelectedServices from './SelectedServices';
import CustomService from '../Proposals/CustomService';

function sortAZ(a: any, b: any) {
  return String(a.label)
    .toLowerCase()
    .localeCompare(String(b.label).toLowerCase());
}

function sortZA(a: any, b: any) {
  return String(b.label)
    .toLowerCase()
    .localeCompare(String(a.label).toLowerCase());
}

enum SORT_BY {
  'A_TO_Z' = 'A_TO_Z',
  'Z_TO_A' = 'Z_TO_A',
}

const Input = styled('input')({
  display: 'none',
});

type Props = {
  newRecord?: boolean;
};

function getRefresh(params: any) {
  const { datastore, setLoading, setData } = params;

  return async (id: string) => {
    setLoading(true);
    const record = await datastore.programs.get({ id });
    setData({
      loaded: true,
      record,
    });
    setLoading(false);
  };
}

function EditProgram(props: Props) {
  const datastore = useDataStore();
  const { newRecord = false } = props;

  const navigate = useNavigate();
  let { id } = useParams();
  if (newRecord) id = uuidv4();

  const initPrograms = datastore.programs.getState();
  const [programsState, setProgramsState] = React.useState<any>(initPrograms);

  const existing = programsState.records.find((x: any) => x.id === id);

  const initial = {
    record: existing || {
      id: uuidv4(),
      services: [],
    },
    loaded: false,
  };

  // Record state (selection)
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>(initial);
  const [servSortBy, servSetSortBy] = React.useState(SORT_BY.A_TO_Z);
  const [custServOpen, setCustServOpen] = React.useState(false);
  const [custEdit, setCustEdit] = React.useState<any>({});

  const refresh = getRefresh({ datastore, setLoading, setData });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formdata = new FormData(event.currentTarget);
    const payload: any = {
      id,
      name: formdata.get('name'),
      description: formdata.get('description'),
      image_url: formdata.get('image_url'),
      services: data.record.services,
    };
    newRecord
      ? datastore.programs.create(payload)
      : datastore.programs.update(payload);
    setData((curr: any) => ({
      ...curr,
      record: { ...curr.record, ...payload },
    }));

    setLoading(false);
    navigate('/programs');
  };

  const handleDelete = async () => {
    setLoading(true);
    if (newRecord || !id) return;
    datastore.programs.delete({ id });
    setLoading(false);
    navigate('/programs');
  };

  const handleUpload = (imgObj: any) => {
    const { urlPath } = imgObj;
    setData({ ...data, record: { ...data.record, image_url: urlPath } });
  };

  const handleFocus = () => {
    // setError('');
  };

  const onChangeService = (s: any[]) => {
    log.debug('services', s);
    setData((curr: any) => ({
      ...curr,
      // services: s,
      record: {
        ...curr.record,
        services: s,
      },
    }));
  };

  const toggleSortBy = (setSortFn: (p: any) => void) => () => {
    setSortFn((curr: SORT_BY) => {
      return curr === SORT_BY.A_TO_Z ? SORT_BY.Z_TO_A : SORT_BY.A_TO_Z;
    });
  };

  const onSaveCustServ = (d: any) => {
    setData((curr: any) => {
      const s: any[] = curr.record?.services || [];
      let e = s.find((s1) => s1.custom && s1.id === d.id);
      if (!e) {
        s.push({
          id: d.id,
          name: d.name,
          custom: d,
        });
      } else {
        e.name = d.name;
        e.custom = d;
      }

      return {
        ...curr,
        record: {
          ...curr.record,
          services: [...s],
        },
      };
    });
    setCustServOpen(false);
  };

  const onDeleteCustServ = (id: string) => {
    setData((curr: any) => {
      const s: any[] = curr.record?.services || [];
      const l = s.filter((s1) => !(s1.custom && s1.id === id));

      return {
        ...curr,
        record: {
          ...curr.record,
          services: [...l],
        },
      };
    });
    setCustServOpen(false);
  };

  const onCloseCustServ = () => {
    setCustServOpen(false);
  };

  const onModifyCustServ = (s: any) => {
    console.log(s);
    if (s.custom) {
      setCustEdit({ value: s.custom });
      setCustServOpen(true);
    }
  };

  React.useEffect(() => {
    if (newRecord) return;
    if (loading) return;
    if (data.loaded && data.record.id === id) {
      return;
    }
    refresh(String(id));
  }, [newRecord, refresh, loading, data.loaded, data.record.id, id]);

  React.useEffect(() => {
    const programsId = datastore.programs.subscribe(setProgramsState);
    return () => {
      datastore.programs.unsubscribe(programsId);
    };
  }, [datastore]);

  return (
    <Paperbase
      title={newRecord ? 'New Program' : 'Edit Program'}
      bottomSpacer='100px'
    >
      <Container component='main'>
        <Box component='form' onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={8}>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12}>
                  <TextField
                    key={`name-${data.record.id}`}
                    required
                    fullWidth
                    id='name'
                    label='Program Name'
                    name='name'
                    disabled={loading}
                    defaultValue={data.record.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    key={`desc-${data.record.id}`}
                    fullWidth
                    multiline
                    id='description'
                    label='Description'
                    name='description'
                    defaultValue={data.record.description}
                    disabled={loading}
                    onFocus={handleFocus}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => {
                      setCustEdit({}); // set blank
                      setCustServOpen(true); // open
                    }}
                    variant='outlined'
                    sx={{ whiteSpace: 'nowrap', minWidth: 'auto' }}
                  >
                    Add Custom Service
                  </Button>
                  <CustomService
                    open={custServOpen}
                    record={custEdit.value}
                    onClose={onCloseCustServ}
                    onSave={onSaveCustServ}
                    onDelete={onDeleteCustServ}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <Typography variant='h6' sx={{ mb: '10px' }}>
                      Select Services
                    </Typography>
                    <IconButton
                      sx={{ mb: '10px' }}
                      onClick={toggleSortBy(servSetSortBy)}
                    >
                      {servSortBy === SORT_BY.A_TO_Z ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </IconButton>
                  </Stack>
                  <SelectService
                    onChange={onChangeService}
                    selected={data.record.services}
                    sortFn={servSortBy === SORT_BY.A_TO_Z ? sortAZ : sortZA}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2} justifyContent='center'>
                <Grid item xs={12}>
                  <Input
                    key={`image-inp-${data.record.id}`}
                    type='hidden'
                    name='image_url'
                    value={data.record.image_url}
                    readOnly
                  />
                  <ImageSet
                    key={`image-url-${data.record.id}`}
                    src={data.record.image_url}
                    alt={data.record.name}
                    useOrig
                  />
                </Grid>
                <Grid item xs={12}>
                  <Accordion elevation={3} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id='map'>
                      <Typography>Selected Services</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <SelectedServices
                        services={data.record.services}
                        onClickService={onModifyCustServ}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ControlBar>
            <Grid container>
              <Grid item xs={12}>
                <Box textAlign='center'>
                  <Stack direction='row' spacing={3} justifyContent='right'>
                    {newRecord ? (
                      <></>
                    ) : (
                      <Button
                        type='button'
                        variant='outlined'
                        disabled={loading}
                        startIcon={<DeleteIcon />}
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    )}
                    <UploadButton
                      category={FileCategory.IMAGES}
                      inputProps={{
                        accept: 'image/png, image/jpeg',
                      }}
                      buttonProps={{
                        variant: 'outlined',
                      }}
                      onUpload={handleUpload}
                    >
                      Upload Image
                    </UploadButton>
                    <Button
                      type='submit'
                      color='primary'
                      variant='contained'
                      disabled={loading}
                      // size="large"
                      endIcon={<SendIcon />}
                    >
                      {newRecord ? 'Add' : 'Update'}
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </ControlBar>
        </Box>
      </Container>
    </Paperbase>
  );
}

export default EditProgram;
