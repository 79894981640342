import React from 'react';
import * as dateFns from 'date-fns';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import { useDataStore } from '../DataStore';
import { MessageType, MessageModel } from '../DataStore/Messages';
import UserNameHelper from '../HelperText/UserName'
import { downloadDoc } from '../../api/irrigationApi';
import settings from '../../settings';

type MessageProps = {
  record: MessageModel;
  selected?: string;
  onClick?: (id: string) => any;
};

function Message(props: MessageProps) {
  const { record, selected, onClick } = props;

  const datastore = useDataStore();
  const authState = datastore.auth.getState();

  const isCurrentUser = record.created_by === authState.user?.email;
  const isSelected = selected === record.id;

  const onCustomClick = (id: string) => () => {
    if (typeof onClick === 'function') onClick(id);
  };

  const getIcon = () => {
    const mDetail = settings.messageType.find(
      (x) => record.message_type === x.value
    );
    return mDetail ? (
      <Box sx={{ display: 'inline', color: '#aaaaaa' }}>{mDetail.icon}</Box>
    ) : (
      <></>
    );
  };

  const d = dateFns.parseISO(record.created_at);

  const downloadProposal = (doc: any) => () => {
    downloadDoc({
      file: doc.url,
      name: doc.filename,
    });  
  };

  return (
    <Box
      sx={{
        p: '5px',
        backgroundColor: isSelected ? '#D5DBDB' : undefined,
        '&:hover': {
          backgroundColor: '#F7F9F9',
        },
      }}
      onClick={onCustomClick(record.id)}
    >
      <Stack
        spacing={1}
        direction={isCurrentUser ? 'row-reverse' : 'row'}
        justifyContent="flex-start"
        sx={{ width: '100%', textAlign: isCurrentUser ? 'right' : 'left' }}
      >
        {getIcon()}
        <Box sx={{ width: '70%' }}>
          <Typography>{record.data.message}</Typography>
          {record.message_type !== MessageType.ATTACHMENTS ? <></> : (
            (record.data.attachments || []).map((a: any) => (
              <Chip key={a.url} size="small" label={a.filename} onClick={downloadProposal(a)} />
            ))
          )}
          <Typography sx={{ fontSize: '80%' }}>
            {dateFns.format(d, 'h:mm a')}
            {' '}
            <UserNameHelper user={{ email: (record.created_by || '') }} />
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default Message;
