import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import log from 'loglevel';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import BusinessSettings from './Business';
import WalkthroughEmail from './WalkthroughEmail';
import Services from './Services';
import Prospects from './Prospects';
import Proposals from './Proposals';

import siteSettings from '../../settings';

const StlyedList = styled(List)<{ component?: React.ElementType }>({
  '& .Mui-selected': {
    borderRight: '5px solid',
    borderColor: '#137F47',
  },
});

function getRefresh(params: any) {
  const { datastore } = params;

  return async () => {
    await datastore.settings.refresh();
  };
}

function getSettings() {
  const setup = siteSettings.navigation.find((x) =>
    x.link.startsWith('/settings')
  );
  return { settings: setup?.navigation || [] };
}

function SettingsLayout() {
  const datastore = useDataStore();
  const navigate = useNavigate();

  // Record state (selection)
  const refreshRef = React.useRef<any>(false);
  const refresh = getRefresh({ datastore });

  const handleSelect = (s: any) => {
    return () => {
      navigate(s.link);
    };
  };

  const { settings: setupList } = getSettings();

  React.useEffect(() => {
    if (window.location.pathname === '/settings') {
      log.debug(`redirecting to ${setupList[0].link}`);
      navigate(setupList[0].link);
    }
  });

  React.useEffect(() => {
    if (refreshRef && refreshRef.current) return;
    refreshRef.current = true;
    refresh();
  }, [refresh]);

  return (
    <Paperbase title="Settings" bottomSpacer="100px">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <Box
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <StlyedList>
              {setupList.map((s) => {
                const isActive = window.location.pathname.startsWith(s.link);
                return (
                  <ListItem disablePadding key={s.label}>
                    <ListItemButton
                      selected={isActive}
                      onClick={handleSelect(s)}
                    >
                      <ListItemText primary={s.label} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </StlyedList>
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Container component="main">
            <Routes>
              <Route path="business" element={<BusinessSettings />}></Route>
              <Route
                path="walkthrough-email"
                element={<WalkthroughEmail />}
              ></Route>
              <Route path="proposals" element={<Proposals />}></Route>
              <Route path="services" element={<Services />}></Route>
              <Route path="prospects" element={<Prospects />}></Route>
            </Routes>
          </Container>
        </Grid>
      </Grid>
    </Paperbase>
  );
}

export default SettingsLayout;
