import React from 'react';
import * as dateFns from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import log from 'loglevel';

import { DashboardTileParams } from '../../types/dashboard';
import useData, { UseDataParams } from './useData';
// import useDataStore from '../DataStore';
import { toNum } from '../../pages/Proposals/getProposalModel';

const showWhichKPI = (toShow: string) => {
  switch(toShow){
    case 'Sales':
      return 'accepted_value_dollar';
    case 'Booked':
      return 'booked_value_dollar';
    case 'Closing Rate':
      return 'closing_rate_perc';      
  }
}

function useSqlQuery(params: any): UseDataParams {
  // const datastore = useDataStore();
  // const { user } = datastore.auth.getState();
  const { filter } = params;

  const dateFrom = dateFns.format(filter?.period?.dateFrom || new Date(), 'yyyy-MM-dd');
  const dateTo = dateFns.format(filter?.period?.dateTo || new Date(), 'yyyy-MM-dd');

  const kpi = showWhichKPI(filter?.salesKpi || 'ACTIVE');

  // const where = [];
  // if (filter?.assignedTo === 'me' && user.email) {
  //   where.push(`pc.assigned_to = '${user.email}'`);
  // }

  // if (filter?.proposalStatus !== '-') {
  //   where.push(`pc.proposal_status = '${filter?.proposalStatus}'`);
  // }

  const name = 'Sales';
  const sqlQuery = `
    select 
      null as dim1,
      null as dim2,
      null as dim3,
      ${kpi !== 'closing_rate_perc' ? `sum(a.${kpi}) as val` : `avg(a.${kpi}) as val`}
      from (select * from get_sales_summary('${dateFrom}T00:00:00.000+0000'::date, '${dateTo}T23:59:59.999+0000'::date)) as a;
  `;
    // ${where.length > 0 ? ' and ' : ''}${where.join(' and ')}

  return { name, sqlQuery };
}

function TotalSales(params: DashboardTileParams) {
  const { filter } = params;
  const [currentSql, setCurrentSql] = React.useState('');
  const [records, setRecords] = React.useState<any[]>([]);

  const dataParams = useSqlQuery({ filter });
  const { loading, refresh } = useData(dataParams);

  React.useEffect(() => {
    if (loading) return;
    if (currentSql === dataParams.sqlQuery) return;
    setCurrentSql(dataParams.sqlQuery);
    refresh().then(setRecords);
  }, [loading, refresh, currentSql, dataParams]);

  return (
    <>
      <Typography variant="h5" sx={{ mb: '10px' }}>{filter?.salesKpi !== 'Closing Rate' ? `Firm Total ${filter?.salesKpi}` : 'Firm Avg. Closing Rate'}</Typography>
      {records
      .sort((a,b) => String(a.dim1).localeCompare(String(a.dim1)))
      .map((r) => (
        <Box key={r.dim1} sx={{ display: 'flex', mb: '2px', width: '100%' }}>
          <Typography variant="h4" sx={{ flexGrow: 1, textAlign: 'right', ml: '10px' }}>
            {filter?.salesKpi !== 'Closing Rate' && '$'}
            {toNum(r.val || 0)}
            {filter?.salesKpi === 'Closing Rate' && '%'}
          </Typography>
        </Box>
      ))}
    </>
  )
}

export default TotalSales;
