import React from 'react';
// import log from 'loglevel';
import Box from '@mui/material/Box';

import { useDataStore } from '../../comp/DataStore';
import LongList from '../../comp/LongList';

type SelectServiceInput = {
  selected?: any[];
  onChange?: (all: any[]) => void;
  sortFn?: (a: any, b: any) => any;
};

let runOnce = false;
function SelectServices(props: SelectServiceInput) {
  const { selected, onChange, sortFn } = props;

  const datastore = useDataStore();
  const [intSelected, intSetSelected] = React.useState<any[]>(selected || []);

  const initServices = datastore.services.getState();
  const [servicesState, setServicesState] = React.useState<any>(initServices);  

  const finalSelected = selected || intSelected;

  React.useEffect(() => {
    if (runOnce) return;
    runOnce = true;
    datastore.services.refresh();
  }, [datastore.services]);

  const selectedData = React.useMemo(() => {
    let init: any[] = [];
    if (finalSelected) {
      finalSelected.forEach((r: any) => {
        init.push({
          key: r.id,
          label: r.name,
          value: r,
        });
      });
    }
    return init;
  }, [finalSelected]);

  const data = React.useMemo(() => {
    const records = servicesState.records;
    const lists = records.map((r: any) => ({
      key: r.id,
      label: r.name,
      value: r,
    }));
    if (sortFn) lists.sort(sortFn);
    return lists;
  }, [servicesState.records, sortFn]);

  const onSelectFromList = (d: any) => {
    const records = [...finalSelected, d.value];
    intSetSelected(records);
    if (onChange) {
      onChange(records);
    }
  };

  const onRemoveFromList = (d: any) => {
    const records = finalSelected.filter((r) => r.id !== d.value.id);
    intSetSelected(records);
    if (onChange) {
      onChange(records);
    }
  };

  React.useEffect(() => {
    const servicesId = datastore.services.subscribe(setServicesState);
    return () => {
      datastore.services.unsubscribe(servicesId);
    }
  }, [datastore]);

  return (
    <Box>
      <LongList
        records={data}
        multiple
        selected={selectedData}
        onSelect={onSelectFromList}
        onRemove={onRemoveFromList}
      />
    </Box>
  );
}

export default SelectServices;
