import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// import log from 'loglevel';
import * as dateFns from 'date-fns';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import { EventStatus } from '../../comp/DataStore/Appointments';

type ViewAppointmentsProp = {
  records: any[],
  proposal?: any,
}

function ViewAppointments(props: ViewAppointmentsProp) {
  const { records } = props;

  return (
    <>
      <Box>
        {records.map((r: any) => {
          const d = dateFns.parseISO(r.start);
          return (
            <Stack key={r.id || uuidv4()} direction="row" spacing={1}>
              {r.status === EventStatus.DONE ? (
                <CheckBoxOutlinedIcon />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon />
              )}
              <Typography>
                {r.title}{" - "}
                {dateFns.format(d, 'MMM d, yyyy h:mm bbb')}
              </Typography>
            </Stack>
          );
        })}
        {!records || records.length === 0 ? (
          <Typography><i>No appointments yet</i></Typography>
        ) : <></>}
      </Box>
    </>
  );
}

export default ViewAppointments;
