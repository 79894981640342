import React from 'react';
import log from 'loglevel';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Delta from 'quill-delta';

import QuillEditor from '../../comp/Quill';
import type { QuillEditorProps } from '../../comp/Quill/Quill';
import { ProposalType } from '../../comp/DataStore/Proposals';
import ErrorHandler from '../../comp/ErrorHandler';

type ProposalTypeSettings = {
  type: ProposalType;
  fromName?: string;
  fromEmail?: string;
  emailSubject: string;
  emailBody: string | Delta;
  cc?: string[];
  bcc?: string[];
  gDocTemplate: string;
  gFolderId?: string;
  timestamp?: number;
};

type ProposalTypeFormParams = {
  initialData: Partial<ProposalTypeSettings>;
  onChange?: (data: Partial<ProposalTypeSettings>) => void;
  loading?: boolean;
  onChangeDelayMs?: number;
  error?: any;
};

function ProposalTypeForm(params: ProposalTypeFormParams) {
  const {
    initialData,
    onChange,
    onChangeDelayMs = 200,
    loading = false,
    error,
  } = params;

  const [data, setData] =
    React.useState<Partial<ProposalTypeSettings>>(initialData);
  const [status, setStatus] = React.useState({
    lastChange: 0,
    commitedChange: 0,
  });

  const quill = React.useRef<any>({});
  const init = () => Boolean(quill.current && quill.current.instance);

  const getGoogleId = (url: string): string => {
    if (/\/document\/d\//.test(url)) {
      // https://docs.google.com/document/d/sampleid12345/edit#
      // https://docs.google.com/document/d/sampleid12345/edit?usp=sharing
      const m = url.match(/\/document\/d\/([a-zA-Z0-9-_]+)/);
      const id = (m || [])[1];
      return id || url;
    }

    if (/\/drive\/folders\//.test(url)) {
      // https://drive.google.com/drive/folders/sampleid12345
      // https://drive.google.com/drive/folders/sampleid12345?usp=sharing
      const m = url.match(/\/drive\/folders\/([a-zA-Z0-9-_]+)\??/);
      const id = (m || [])[1];
      return id || url;
    }

    return url;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let inputValue = type === 'checkbox' ? checked : value;
    if (name === 'gDocTemplate' || name === 'gFolderId') {
      inputValue = getGoogleId(value);
    }

    setData((curr) => ({
      ...curr,
      [name]: inputValue,
    }));
    setStatus((c) => ({ ...c, lastChange: Date.now() }));
  };

  const handleQuillChange: QuillEditorProps['onChange'] = (qp) => {
    const { instance } = qp || {};
    if (instance) {
      setData((curr) => ({
        ...curr,
        emailBody: instance.getContents() as Delta,
      }));
      setStatus((c) => ({ ...c, lastChange: Date.now() }));
    }
  };

  const errorObj = React.useMemo(() => {
    log.debug('updating error Object');
    const value: any = {};
    if (!error) return value;
    if (error.name === 'ValidationError') {
      const otherErrors: any = {};
      (error.inner || []).forEach((innerErr: any) => {
        if (/^(cc|bcc)\[\d+\]$/.test(String(innerErr.path))) {
          const m = String(innerErr.path).match(/^(cc|bcc)\[\d+\]$/) || [];
          const m1 = m[1] as 'cc' | 'bcc';
          if (!otherErrors[m1]) {
            otherErrors[m1] = [];
          }
          otherErrors[m1].push(innerErr.message);
          return;
        }
        value[innerErr.path] = innerErr;
      });
      Object.assign(value, otherErrors);
    }
    log.error({ error, value });
    return value;
  }, [error]);

  React.useEffect(() => {
    if (status.commitedChange >= status.lastChange) return;
    const timer = setTimeout(() => {
      setStatus((c) => ({ ...c, commitedChange: Date.now() }));
      if (typeof onChange === 'function') {
        const d = { ...data } as Partial<ProposalTypeSettings>;
        d.cc = String(d.cc || '')
          .split(',')
          .map((x) => x.trim())
          .filter((x) => Boolean(x));
        d.bcc = String(d.bcc || '')
          .split(',')
          .map((x) => x.trim())
          .filter((x) => Boolean(x));
        onChange(d);
      }
    }, onChangeDelayMs);
    return () => {
      clearTimeout(timer);
    };
  }, [onChange, onChangeDelayMs, data, status]);

  // const copyToClipboard = (str: string) => () => {
  //   navigator.clipboard.writeText(str);
  // };

  // Setup Quill
  React.useEffect(() => {
    if (!init()) return;
    if (!initialData.timestamp) return;
    if (initialData.timestamp <= status.commitedChange) return;
    setData(initialData);
    setStatus({ lastChange: Date.now(), commitedChange: Date.now() });
    quill.current?.instance.setContents(initialData.emailBody);
  }, [initialData, status]);

  return (
    <>
      <Grid container justifyContent='center' spacing={2}>
        {/* <Grid item xs={6}>
          <TextField
            key={`from-name`}
            fullWidth
            id="fromName"
            label="From Name"
            name="fromName"
            helperText="The default name that will appear with the email. Leave blank to use sender's name"
            disabled={loading}
            value={data.fromName || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={errorObj.fromName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`from-email`}
            fullWidth
            type="email"
            id="fromEmail"
            label="From Email"
            name="fromEmail"
            helperText="The email to use in sending the email. Leave blank to use sender's email"
            disabled={loading}
            value={data.fromEmail || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={errorObj.fromEmail}
          />
        </Grid> */}
        <Grid item xs={12}>
          <TextField
            key={`email-subject`}
            required
            fullWidth
            id='emailSubject'
            label='Subject'
            name='emailSubject'
            disabled={loading}
            value={data.emailSubject || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={errorObj.emailSubject}
          />
        </Grid>
        <Grid item xs={12}>
          <QuillEditor
            quillRef={quill}
            disabled={loading}
            options={{}}
            onChange={handleQuillChange}
            error={errorObj.emailBody}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`cc-email`}
            fullWidth
            id='cc'
            label='Cc (Separated by comma)'
            name='cc'
            disabled={loading}
            value={data.cc || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={Array.isArray(errorObj.cc) ? errorObj.cc.length > 0 : false}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`bcc-email`}
            fullWidth
            id='bcc'
            label='Bcc (Separated by comma)'
            name='bcc'
            disabled={loading}
            value={data.bcc || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={
              Array.isArray(errorObj.bcc) ? errorObj.bcc.length > 0 : false
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`gdoc-template`}
            required
            fullWidth
            id='gDocTemplate'
            label='Google Doc Template'
            name='gDocTemplate'
            helperText='The Google Doc ID that will be used to create PDF proposals. URLs are converted to ID.'
            disabled={loading}
            value={data.gDocTemplate || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={errorObj.gDocTemplate}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            key={`gdoc-folder-id`}
            fullWidth
            id='gFolderId'
            label='Google Drive Folder'
            name='gFolderId'
            helperText='The Google Drive Folder where to put the proposals. URLs are converted to ID.'
            disabled={loading}
            value={data.gFolderId || ''}
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            error={errorObj.gFolderId}
          />
        </Grid>
      </Grid>
      <ErrorHandler error={error} />
    </>
  );
}

export default ProposalTypeForm;
export type { ProposalTypeSettings };
