import * as React from 'react';
import log from 'loglevel';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';

enum ImageSize {
  xs = '0300',
  sm = '0600',
  md = '0900',
  lg = '1200',
  xl = '1500',
}

type ImageSetProps = {
  src: string;
  alt: string;
  size?: ImageSize;
  useOrig?: boolean;
  noImage?: string | React.ReactNode;
  sx?: GridProps['sx'];
  onLoad?: (p: any) => any;
};

function getThumbnail(url: string, size = ImageSize.xs) {
  const { REACT_APP_IRRIGATION_BASE_URL: baseURL = '' } = process.env;
  const suffix = `.${size}.webp`;
  if (!url) return '';
  return `${baseURL}/${String(url).replace(/^images\//, 'thumbs/')}${suffix}`;
}

function ImageSet(props: ImageSetProps) {
  const { REACT_APP_IRRIGATION_BASE_URL: baseURL = '' } = process.env;
  const {
    src = '',
    alt,
    size = ImageSize.xs,
    useOrig = false,
    noImage = 'No Image',
    sx = {},
    onLoad,
  } = props;

  const [retry, setRetry] = React.useState(0);
  const [isError, setError] = React.useState(false);
  const [gridSx, setGridSx] = React.useState<any>({});
  const imgRef = React.useRef<any>();
  const ref = React.useRef<any>();

  const handleLoaded = (str: string) => (e: any) => {
    e.preventDefault();
    setRetry(0);
    setError(false);
    if (typeof onLoad === 'function') {
      onLoad({ ref: imgRef.current });
    }
  };

  const handleError = (str: string) => (e: any) => {
    e.preventDefault();
    log.error(`on error [${str}]`, e);
    setError(true);
  };

  React.useEffect(() => {
    // log.debug(`Retrying...`, { retry, isError });
    if (!isError || retry >= 3) return;
    const t = setTimeout(() => {
      setRetry(retry + 1);
      setError(false);
    }, 2000);

    return () => {
      clearTimeout(t);
    };
  }, [retry, isError]);

  const thumbImage = (
    <img
      ref={imgRef}
      key={`img-${size}`}
      src={`${getThumbnail(src, size)}?retry=${retry}`}
      alt={alt}
      width="100%"
      onLoad={handleLoaded(size)}
      onLoadedData={handleLoaded(size)}
      onError={handleError(size)}
    />
  );

  const origImage = (
    <img
      ref={imgRef}
      src={`${baseURL}/${src}?retry=${retry}`}
      alt={alt}
      width="100%"
      onLoad={handleLoaded('orig')}
      onLoadedData={handleLoaded('orig')}
      onError={handleError('orig')}
    />
  );

  const useImage = useOrig ? origImage : thumbImage;

  React.useEffect(() => {
    if (!ref.current) return;
    if (!src || isError) {
      if (gridSx.backgroundColor) return;
      setGridSx({
        backgroundColor: '#D3D3D3',
        minHeight: (ref.current.offsetWidth * 9) / 16,
        ...(sx || {}),
      });
    } else {
      if (!gridSx.backgroundColor) return;
      setGridSx({});
    }
  }, [src, gridSx, sx, isError]);

  return (
    <Grid container alignItems="center" ref={ref} sx={gridSx}>
      <Grid item xs={12} textAlign="center">
        <Box>
          <span data-retry={retry}>{src ? useImage : noImage}</span>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ImageSet;
export { getThumbnail, ImageSize };
