//@ts-nocheck
import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useData, { UseDataParams } from '../../comp/DashboardTiles/useData';

const statsTables = {
    product: {
        name: "Product Stats",
        table: "vw_weekly_metrics_product"
    },
    source: {
        name: "Source Stats",
        table: "vw_weekly_metrics_source"
    }
}

function useSqlQuery(type: string): UseDataParams {
    const tableType = statsTables[type];
    const name = tableType.name;
    const sqlQuery = `
        select
            a.week_start dim1,
            a.metric as dim2,
            a.index as dim3,
            a.value as val
        from
        (
            select * from ${tableType.table}
        ) as a
    `;
    return { name, sqlQuery };
}


function ProposalStats() {
    const [currentStatsView, setCurrentStatView] = React.useState('product');
    const [currentSql, setCurrentSql] = React.useState('');
    const [records, setRecords] = React.useState<any[]>([]);
  
    const dataParams = useSqlQuery(currentStatsView);
    const { loading, refresh } = useData(dataParams);
  
    const [pivotData, setPivotData] = React.useState({});
    const [dates, setDates] = React.useState([]);
    const [metrics, setMetrics] = React.useState([]);
    const [previousPivotData, setPreviousPivotData] = React.useState({});

    React.useEffect(() => {
      if (loading) return;
      if (currentSql === dataParams.sqlQuery) return;
      setPivotData({});
      setPreviousPivotData({});
      setDates([]);
      setMetrics([]);
      setCurrentSql(dataParams.sqlQuery);
      
      refresh().then(setRecords);
    }, [loading, refresh, currentSql, dataParams, currentStatsView]);


  React.useEffect(() => {
    if(!(records.length > 0)) return;
    const newPivotData = {};

    records.sort((a, b) => new Date(a.dim1) - new Date(b.dim1));

    records.forEach((row, index) => {
      const { dim1, dim2, dim3, val } = row;
      
      if (!newPivotData[dim1]) {
        newPivotData[dim1] = {};
      }
      newPivotData[dim1][dim2] = val;

    });

    setPivotData(newPivotData);
  }, [records]);

  React.useEffect(() => {
    if (Object.keys(pivotData).length === 0) return;

    const newPreviousPivotData = {};

    const sortedDates = Object.keys(pivotData).sort((a, b) => new Date(a) - new Date(b));

    for (let i = 0; i < sortedDates.length; i++) {
      const currentDate = sortedDates[i];
      const previousDate = sortedDates[i - 1];

      if (i === 0) {
        newPreviousPivotData[currentDate] = {};
        Object.keys(pivotData[currentDate]).forEach((metric) => {
          newPreviousPivotData[currentDate][metric] = 0;
        });
      } else {
        
        newPreviousPivotData[currentDate] = { ...pivotData[previousDate] };
      }
    }

    setPreviousPivotData(newPreviousPivotData);
  }, [pivotData]);

  React.useEffect(() => {
    if (Object.keys(pivotData).length === 0) return;
  
    const uniqueDates = Object.keys(pivotData).sort((a, b) => new Date(b) - new Date(a));
    setDates(uniqueDates);
  
    const uniqueMetrics = Array.from(
      new Set(
        Object.values(pivotData).flatMap((data) => Object.keys(data))
      )
    );
    setMetrics(uniqueMetrics);
  
    setPivotData(pivotData);

  }, [pivotData, previousPivotData]);


  const formatDate = (dateString) => {
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
        <div style={{paddingTop: '5px', paddingBottom: '5px'}}>
        <FormControl size="small" sx={{ minWidth: '120px' }}>
            <InputLabel id="period-filter-label">View by</InputLabel>
          <Select
            key={`statsview-to`}
            labelId="statsview-to-filter-label"
            id="statsview-to-filter"
            name="statsview-to-filter"
            value={currentStatsView}
            label="View By"
            onChange={(e) => setCurrentStatView(e.target.value)}
          >
            <MenuItem value="product">Product Line</MenuItem>
            <MenuItem value="source">Sources</MenuItem>
          </Select>
        </FormControl>
        </div>
      <TableContainer>
        <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Metric</TableCell>
              {dates.map((week) => (
                <TableCell key={week} style={{ minWidth: '100px' }}>{formatDate(week)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {metrics.map((metric) => (
              <TableRow key={metric}>
                <TableCell style={['Lawn', 'Irrigation', 'Lawn & Irrigation'].includes(metric) ? {
                    color: 'grey',
                    textAlign: 'right'
                } : undefined }>{metric}</TableCell>
                {dates.map((date, index) => (
                    <TableCell
                        key={`${metric}-${date}-${index}`}
                    >{Object.keys(pivotData[date]).includes(metric) ? (
                        <>
                          <span
                            style={{
                              color:
                                pivotData[date][metric] > previousPivotData[date][metric]
                                  ? 'green'
                                  : pivotData[date][metric] < previousPivotData[date][metric]
                                  ? 'red'
                                  : 'inherit',
                            }}
                          >
                            {pivotData[date][metric]}
                          </span>
                          {pivotData[date][metric] !== previousPivotData[date][metric] && (
                            <span style={{ marginLeft: '5px' }}>
                              {pivotData[date][metric] > previousPivotData[date][metric] ? '↑' : '↓'}
                            </span>
                          )}
                        </>
                      ) : (
                        0
                      )}
                    </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      </TableContainer>
    </div>
  );
}

export default ProposalStats;