import React from 'react';
import { v4 as uuidv4 } from 'uuid';
// import log from 'loglevel';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

type Note = {
  id: string;
  note: string;
};

type EditNoteProp = {
  open?: boolean;
  newRecord?: boolean;
  record?: Note;
  onSave?: (n: Note, newRecord?: boolean) => void;
  onDelete?: (n: Note) => void;
  onClose?: () => void;
};

function EditNote(props: EditNoteProp) {
  const { newRecord = false, record, open = false, onSave, onDelete, onClose } = props;
  const noteRef = React.useRef<any>({});
  const [error, setError] = React.useState<string>('');

  const handleSubmit = () => {
    if (!noteRef.current.value) {
      setError('This is required');
      return;
    } else {
      setError('');
    }

    if (onSave) {
      onSave({
        id: record?.id || uuidv4(),
        note: noteRef.current.value,
      }, newRecord);
    }
  };

  const handleDelete = () => {
    setError('');
    if (record && !newRecord && onDelete) onDelete(record);
  }

  const handleClose = () => {
    setError('');
    if (onClose) onClose();
  };

  return (
    <Box component="form">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{newRecord ? 'New Note' : 'Edit Note'}</DialogTitle>
        <DialogContent sx={{ minWidth: '500px' }}>
          <TextField
            autoFocus
            id="note"
            type="text"
            label="Note"
            name="note"
            variant="filled"
            multiline
            required
            fullWidth
            error={Boolean(error)}
            defaultValue={record?.note || ''}
            inputRef={noteRef}
          />
        </DialogContent>
        <DialogActions>
          {newRecord ? <></> : <Button onClick={handleDelete} color="error">Delete</Button>}
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{newRecord ? 'Save' : 'Update'}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

type ViewNotesProp = {
  notes?: {
    records: Note[];
  };
  update?: (newNotes: { records: Note [] }) => void;
};

function ViewNotes(props: ViewNotesProp) {
  const { notes, update } = props;

  const [open, setOpen] = React.useState(false);
  const [record, setRecord] = React.useState<Note | undefined>();

  const onEditMessage = (r: Note) => () => {
    setRecord(r);
    setOpen(true);
  };

  const onNewMessage = () => {
    setRecord(undefined);
    setOpen(true);
  };

  const onSave = (n: Note, newRecord?: boolean) => {
    const newNotes = {
      records: (notes?.records || []).map((r: Note) => {
        if (!newRecord && r.id === n.id) return n;
        return r;
      }),
    };
    if (newRecord) {
      newNotes.records.push(n);
    }
    if (update) {
      update(newNotes);
    }
    setOpen(false);
  };

  const onDelete = (n: Note) => {
    const newNotes = {
      records: (notes?.records || []).filter((r: Note) => r.id !== n.id),
    };
    if (update) {
      update(newNotes);
    }
    setOpen(false);
  }

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <EditNote
        open={open}
        record={record}
        newRecord={!Boolean(record)}
        onSave={onSave}
        onDelete={onDelete}
        onClose={onClose}
      />
      <Box>
        {notes?.records.map((n) => {
          return (
            <Box key={n.id}>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flexGrow: 1 }}>{n.note}</Typography>
                <IconButton id={n.id} onClick={onEditMessage(n)} size="small">
                  <EditIcon />
                </IconButton>
              </Stack>
              <Divider sx={{ mt: '10px', mb: '10px' }} />
            </Box>
          );
        })}
        <Button onClick={onNewMessage}>Add a note ...</Button>
      </Box>
    </Box>
  );
}

export default ViewNotes;
