import React from 'react';
// import log from 'loglevel';

import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useDataStore } from '../DataStore';
const dataset = 'prospects';

const ProspectsAutocomplete: typeof Autocomplete = (props) => {
  const datastore = useDataStore();

  const initProspects = datastore[dataset].getState();
  const [prospectsState, setProspectsState] = React.useState<any>(initProspects);

  const [open, setOpen] = React.useState(false);
  const [isSearching, setSearching] = React.useState(false);
  const loading = React.useMemo(() => open && isSearching, [open, isSearching]);

  const selected = React.useMemo<any>(() => {
    const pv: any = props.value;
    let existing: any;
    if (!pv.$refreshedAt) {
      existing = prospectsState.records.find((x: any) => x.id === pv.id);
    }
    return existing || pv;
  }, [props.value, prospectsState]);

  const onSelectOption = async (e: any, value: any, reason: any) => {
    if (reason === 'input') {
      setSearching(true);
      await datastore[dataset]
        .search({ searchStr: value })
        .finally(() => setSearching(false));
    }
  };

  const onChange = (...params: any) => {
    onSelectOption.apply({}, params)
      .finally(() => {
        if (typeof props.onChange === 'function') {
          props.onChange.apply({}, params);
        }
      });
  }

  const onInputChange = (...params: any) => {
    onSelectOption.apply({}, params)
      .finally(() => {
        if (typeof props.onInputChange === 'function') {
          props.onInputChange.apply({}, params);
        }
      });
  }

  const renderInput = (...params: any) => {
    return props.renderInput.apply({}, [{
      ...params[0],
      isLoading: loading,
    }]);
  };

  const renderOption = (buttonProps: any, opt: any) => {
    let label = '';
    if (opt) {
      if (opt.first_name) label += opt.first_name;
      if (opt.last_name) label += ' ' + opt.last_name;
      if (opt.email) label += ` (${opt.email})`;
    }

    return (
      <li {...buttonProps} key={opt.id}>
        <Stack>
          <Typography>{label}</Typography>
          <Typography sx={{ fontSize: '80%' }}>{opt.address}</Typography>
        </Stack>
      </li>
    );
  }

  // find selected value if not in records
  React.useEffect(() => {
    // const selected: any = props.value || {};
    if(!selected.id) return;
    if(selected.$refreshedAt) return;
    const existing = prospectsState.records.find((x: any) => x.id === selected.id);
    if (existing) return;
    setSearching(true);
    datastore[dataset].get({ id: selected.id }).finally(() => setSearching(false));
  }, [prospectsState, datastore, selected]);

  // subscribe to changes
  React.useEffect(() => {
    const prospectsId = datastore[dataset].subscribe(setProspectsState);
    return () => {
      datastore[dataset].unsubscribe(prospectsId);
    }
  }, [datastore]);

  return (
    <Autocomplete
      {...props}
      value={selected}
      options={prospectsState.records}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={(opt: any) => {
        let label = '';
        if (!opt) return label;
        if (opt.first_name) label += opt.first_name;
        if (opt.last_name) label += ' ' + opt.last_name;
        if (opt.email) label += ` (${opt.email})`;
        if (!label && opt.id) label = 'Loading...';
        return label;
      }}
      isOptionEqualToValue={(option: any, value: any) => {
        if (!value || !value.email) return true;
        return option.email === value.email;
      }}
    />
  )
}

export default ProspectsAutocomplete;
