import React from 'react';
import log from 'loglevel';
import { useNavigate, useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import { useDataStore } from '../../comp/DataStore';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import MessageButton from '../../comp/Messages/Button';
import ImageSet from '../../comp/ImageSet';
import ViewProspectAppointments from './ViewProspectAppointments';
import ViewRecentProposals from './ViewRecentProposals';
import { MessageType } from '../../comp/DataStore/Messages';
import { SettingsKey } from '../../comp/DataStore/Settings';
import ErrorHandler from '../../comp/ErrorHandler';

function ViewProspect() {
  const datastore = useDataStore();

  const navigate = useNavigate();
  const { id } = useParams();

  const prospectsState = datastore.prospects.getState();
  const existing = prospectsState.records.find((x: any) => x.id === id);

  const [prospect, setProspect] = React.useState<any>(existing || {});
  const [sourceCh, setSourceCh] = React.useState<any>({
    loading: false,
    records: undefined,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();

  const onClickEdit = () => navigate(`/prospects/${id}/edit`);

  const sourceChName = React.useMemo(() => {
    const sch = (sourceCh.records || []).find(
      (x: any) => x.id === prospect.source_channel
    );
    if (!sch) return prospect.source_channel;
    return sch.name;
  }, [prospect, sourceCh]);

  React.useEffect(() => {
    if (loading) return;
    if (!id) return;
    if (id === prospect.id) return;
    setLoading(true);
    datastore.prospects
      .get({ id })
      .then((p) => {
        if (!p) {
          setError(new Error('Unable to retrieve prospect'))
          setProspect({ id });
          return;
        }
        setProspect(p);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [id, loading, prospect, datastore.prospects]);

  React.useEffect(() => {
    if (sourceCh.records) return;
    if (sourceCh.loading) return;
    setSourceCh((c: any) => ({ ...c, loading: true }));
    datastore.settings
      .getKey(SettingsKey.PROSPECTS)
      .then((result: any) => {
        const records = result?.data?.sourceChannel?.records || [];
        setSourceCh((c: any) => ({ ...c, records }));
      })
      .finally(() => {
        setSourceCh((c: any) => ({ ...c, loading: false }));
      });
  }, [datastore, sourceCh]);

  return (
    <Paperbase title="View Prospect" bottomSpacer="100px">
      <Grid container>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{prospect.full_name}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{prospect.email}</Typography>
              <Typography>{prospect.primary_phone}</Typography>
              <Typography>{prospect.phone_2}</Typography>
              <Typography>{prospect.address}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>Source Channel: {sourceChName}</Typography>
              {prospect.source_channel_details ? (
                <Typography>, {prospect.source_channel_details}</Typography>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3}>
                <Box sx={{ p: '20px' }}>
                  <ViewProspectAppointments prospectId={id || ''} />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={3}>
                <Box sx={{ p: '20px' }}>
                  <ViewRecentProposals prospectId={id || ''} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Box sx={{ p: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Prospect's Photo</Typography>
              <ImageSet
                key={`avatar-url-${prospect.id}`}
                src={prospect.avatar_url}
                alt={prospect.name}
                useOrig
              />
            </Grid>
            <Grid item xs={12}>
              <MessageButton
                outputReferences={{
                  proposal_id: null,
                  prospect_id: prospect.id,
                }}
                inputReferences={{
                  prospect_id: prospect.id,
                }}
                filter={(r: any) => r.message_type !== MessageType.NOTIFICATION}
              >
                <Button variant="outlined">Show Messages</Button>
              </MessageButton>
            </Grid>
          </Grid>
          </Box>
        </Grid>
      </Grid>
      <ErrorHandler error={error} />
      <ControlBar>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="right">
              <Button
                disabled={loading}
                variant="outlined"
                onClick={onClickEdit}
                startIcon={<EditOutlinedIcon />}
              >
                Edit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </ControlBar>
    </Paperbase>
  );
}

export default ViewProspect;
