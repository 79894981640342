import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ThemeProvider } from '@mui/material/styles';

import settings from '../../../settings';
import { useDataStore } from '../../DataStore';

const StlyedList = styled(List)<{ component?: React.ElementType }>({
  '& .Mui-selected': {
    borderRight: '8px solid',
    borderColor: '#137F47',
  },
});

function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const theme = useTheme();

  const navigate = useNavigate();
  const datastore = useDataStore();

  const [state, setState] = React.useState({
    active: '',
  });

  const onClick = (idx: number) => () => {
    setState({ active: settings.navigation[idx].label });
    navigate(settings.navigation[idx].link);
  };

  const Lists = settings.navigation
    .filter(({ allowed }) => {
      if (typeof allowed === 'undefined') return true;
      const { record } = datastore.auth.getState();
      if (allowed.includes(record?.role)) return true;
      return false;
    })
    .map(({ label, link, icon }, idx) => {
      const isActive = window.location.pathname.startsWith(link);
      if (isActive && !state.active) setState({ active: label });

      return (
        <ListItem disablePadding key={`${idx}-${label}`}>
          <ListItemButton selected={isActive} onClick={onClick(idx)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </ListItemButton>
        </ListItem>
      );
    });
  // sx={{ fontSize: 22, color: '#fff' }}
  return (
    <ThemeProvider theme={theme}>
      <Drawer
        variant="permanent"
        sx={{
          zIndex: theme.zIndex.appBar + 10,
        }}
        {...other}
      >
        <StlyedList disablePadding>
          <ListItem>T&amp;B</ListItem>
          {Lists}
        </StlyedList>
      </Drawer>
    </ThemeProvider>
  );
}

export default Navigator;
