import React from 'react';

import Paperbase from '../comp/Layout/Paperbase/Paperbase';

function NotFound() {
  return (
    <Paperbase title="Help">
      Page does not exist.
    </Paperbase>
  )
}

export default NotFound