import React from 'react';
import log from 'loglevel';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  UpsertMode,
  ActionType,
  RecordStatus,
} from '../../types/datastore.d';
import { CustomServiceSchema } from './Proposals';
import { SeasonList, ServiceType } from './Services';

const dataset = 'programs';
const vwDataset = 'vw_programs';
const dbTable = 'programs';

function ProgramSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      updated_at: yup.string().required().default(new Date().toISOString()),
      name: yup.string().required(),
      description: yup.string().nullable().optional(),
      image_url: yup.string().nullable().optional(),
      enabled: yup.bool().default(true).required(),
      services: yup
        .array()
        .default([])
        .of(
          yup
            .object()
            .noUnknown()
            .shape({
              id: yup.string().uuid().required(),
              name: yup.string().optional(),
              custom: CustomServiceSchema()
                .optional()
                .nullable()
                .default(undefined),
            })
        ),
    });
}

type ProgramRecord = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  description?: string;
  image_url?: string[];
  enabled: boolean;
  services: {
    id: string;
    name?: string;
    custom?: {
      id: string;
      name: string;
      type: ServiceType;
      season: SeasonList;
      quantity: number;
      uom?: string;
      image_url: string;
      description: string;
      fixed_price: number;
    };
  }[];
};

type ProgramViewRecord = ProgramRecord & {
  search_column?: string;
  $refreshedAt?: number;
  $status?: RecordStatus;
  $error?: any;
};

function getInitial() {
  const records: ProgramViewRecord[] = [];
  return { records, error: null };
}

function isExpired(record: { $refreshedAt: number }) {
  const now = Date.now();
  const expiryMs = 1000 * 60 * 10; // 5 minutes
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(getFunctionParams: GetFunctionParams) {
  const { dispatch, subscribers } = getFunctionParams;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const { match, range, custom, type = ActionType.REFRESH } = opts || {};
    log.debug(`fetching ${vwDataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload: ProgramViewRecord[] = result.data.map((x) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: { id: string }) => {
    const { id } = opts || {};

    const existing = state[dataset].records.find((x: any) => x.id === id);
    if (existing && !isExpired(existing)) return existing;

    const data = await refreshFn({ match: { id }, type: ActionType.UPSERT });
    return data ? data[0] : undefined;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: {
      records: Partial<ProgramRecord>[];
      error: any;
    }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: Partial<ProgramRecord>,
      opts?: {
        pick?: (keyof ProgramRecord)[];
        onConflict?: keyof ProgramRecord;
        match?: Partial<ProgramRecord>;
      }
    ): Promise<Partial<ProgramRecord> | undefined> => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = ProgramSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: Partial<ProgramViewRecord> = await schema
        .validate(record, { abortEarly: false })
        .then((result) => {
          return result as Partial<ProgramViewRecord>;
        })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      const client = await supabase.getClient();
      if (mode === UpsertMode.UPSERT) {
        const { onConflict = 'id' } = opts || {};
        const result: any = await client
          .from(dbTable)
          .upsert([valid], { onConflict })
          .select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await client.from(dbTable).insert([valid]).select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const { match = { id: valid.id } } = opts || {};
        const result: any = await client
          .from(dbTable)
          .update(valid)
          .match(match)
          .select();
        respond({ records: result?.data, error: result.error });
      }

      if (!valid.id) return;
      return getFn({ id: valid.id });
    };
  };

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  };

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  };

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getState: () => state[dataset],
    create: getUpsertFn(UpsertMode.CREATE_ONLY),
    update: getUpsertFn(UpsertMode.UPDATE_ONLY),
    delete: async (payload: any) => {
      const deleteFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      return deleteFn(
        { id: payload.id, enabled: false },
        { pick: ['id', 'enabled'] }
      );
    },
    refresh: refreshFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.REFRESH) {
    newState[dataset].records = action.payload;
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.ERROR) {
    newState[dataset].error = action.payload;
    return newState;
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
};

function BuildPrograms(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, {
    [dataset]: getInitial(),
  });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({
      state,
      dispatch,
      datastore: datastore.current,
      subscribers,
    });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if (typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    });
  }, [state]);

  return <>{children}</>;
}

export default BuildPrograms;
export { dataset, getInitial, getFunctions, reducer };

export type { ProgramRecord, ProgramViewRecord };
