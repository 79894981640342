import React from 'react';
import log from 'loglevel';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import * as supabase from '../../api/supabase';
import {
  GetFunctionParams,
  RefreshOptions,
  UpsertMode,
  ActionType,
  RecordStatus,
  DatastoreKey,
} from '../../types/datastore.d';

const dataset = DatastoreKey.SERVICES;
const vwDataset = 'vw_services';
const dbTable = 'services';

enum SeasonList {
  ALL = 'ALL',
  AS_NEEDED = 'AS_NEEDED',
  AS_SCHEDULED = 'AS_SCHEDULED',
  SPRING = 'SPRING',
  EARLY_SPRING = 'EARLY_SPRING',
  MID_SPRING = 'MID_SPRING',
  LATE_SPRING = 'LATE_SPRING',
  SUMMER = 'SUMMER',
  EARLY_SUMMER = 'EARLY_SUMMER',
  MID_SUMMER = 'MID_SUMMER',
  LATE_SUMMER = 'LATE_SUMMER',
  FALL = 'FALL',
  EARLY_FALL = 'EARLY_FALL',
  MID_FALL = 'MID_FALL',
  LATE_FALL = 'LATE_FALL',
  WINTER = 'WINTER',
  EARLY_WINTER = 'EARLY_WINTER',
  MID_WINTER = 'MID_WINTER',
  LATE_WINTER = 'LATE_WINTER',
}

enum ServiceType {
  CORE = 'CORE',
  ADDITIONAL = 'ADDITIONAL',
  TICK_CONTROL = 'TICK_CONTROL',
  IRRIGATION = 'IRRIGATION',
}

enum PricingModel {
  SQFT = 'SQFT',
  OPEN = 'OPEN',
  FIXED = 'FIXED',
}

type ServiceRecord = {
  id: string;
  created_at: string;
  name: string;
  description?: string;
  season: SeasonList;
  type: ServiceType;
  pricing: PricingModel;
  start_low_price?: number;
  start_high_price?: number;
  low_price_rate?: number;
  high_price_rate?: number;
  image_url?: string;
  enabled: boolean;
  updated_at: string;
  price_multiplier?: number;
}

type ServiceViewRecord = ServiceRecord & {
  search_column: string;
  $refreshedAt: number;
}

// deprecated
type ServiceModel = ServiceRecord;

function ServiceModelSchema() {
  return yup
    .object()
    .noUnknown()
    .shape({
      id: yup.string().uuid().required(),
      updated_at: yup.string().required().default(new Date().toISOString()),
      name: yup.string().required(),
      description: yup.string().nullable().optional(),
      season: yup.string().required().oneOf(Object.values(SeasonList)),
      type: yup.string().required().oneOf(Object.values(ServiceType)),
      pricing: yup.string().required().oneOf(Object.values(PricingModel)),
      price_multiplier: yup.number().required().min(1).default(1),
      start_low_price: yup
        .number()
        .label('Start Low Price')
        .when(['pricing'], (pricing, schema) => {
          if (pricing === PricingModel.SQFT) return schema.required();
          else if (pricing === PricingModel.OPEN)
            return schema.optional().nullable();
          else if (pricing === PricingModel.FIXED) return schema.required();
        }),
      start_high_price: yup
        .number()
        .label('Start High Price')
        .when(
          ['pricing', 'start_low_price'],
          // @ts-ignore
          (pricing, start_low_price, schema) => {
            if (pricing === PricingModel.SQFT)
              return schema.min(start_low_price).required();
            else if (pricing === PricingModel.OPEN)
              return schema.optional().nullable();
            else if (pricing === PricingModel.FIXED)
              return schema.optional().nullable();
          }
        ),
      low_price_rate: yup
        .number()
        .label('Low Price Rate')
        .when(['pricing'], (pricing, schema) => {
          if (pricing === PricingModel.SQFT) return schema.required();
          else if (pricing === PricingModel.OPEN)
            return schema.optional().nullable();
          else if (pricing === PricingModel.FIXED) return schema.nullable();
        }),
      high_price_rate: yup
        .number()
        .label('High Price Rate')
        .when(
          ['pricing', 'low_price_rate'],
          // @ts-ignore
          (pricing, low_price_rate, schema) => {
            if (pricing === PricingModel.SQFT)
              return schema.min(low_price_rate).required();
            else if (pricing === PricingModel.OPEN)
              return schema.optional().nullable();
            else if (pricing === PricingModel.FIXED)
              return schema.optional().nullable();
          }
        ),
      image_url: yup.string().required('Image is required'),
      enabled: yup.bool().default(true).required(),
    });
}

function getInitial() {
  const records: ServiceModel[] = [];
  return { records, error: null };
}

function isExpired(record: { $refreshedAt: number }) {
  const now = Date.now();
  const expiryMs = 1000 * 60 * 10; // 5 minutes
  return now - (record.$refreshedAt || 0) > expiryMs;
}

function getFunctions(params: GetFunctionParams) {
  const { dispatch, subscribers } = params;

  // initialize
  const state: any = { [dataset]: getInitial() };

  /**
   * Get data from database
   * @param opts
   * @returns
   */
  const refreshFn = async (opts?: RefreshOptions) => {
    const { match, range, in: optsIn, custom, type = ActionType.REFRESH } = opts || {};
    log.debug(`fetching ${vwDataset} ...`);
    const client = await supabase.getClient();

    let query = client.from(vwDataset).select();
    if (match) query.match(match);
    if (range) query.range(range.from, range.to);
    if (optsIn) query.in(optsIn.column, optsIn.values);
    if (custom) custom(query);
    const result = await query;

    if (result.error) {
      log.error(result.error);
      dispatch({
        type: ActionType.ERROR,
        dataset,
        payload: result.error,
      });
      return;
    }

    log.debug(`fetch ${dataset} results`, result.data);
    const payload: ServiceViewRecord[] = result.data.map((x: any) => ({
      ...x,
      $refreshedAt: Date.now(),
    }));

    dispatch({
      type,
      dataset,
      payload,
    });

    return payload;
  };

  /**
   * Get data from state if not expired
   * @param opts
   * @returns
   */
  const getFn = async (opts: { id: string }) => {
    const { id } = opts || {};

    const existing = state[dataset].records.find((x: any) => x.id === id);
    if (existing && !isExpired(existing)) return existing;

    const data = await refreshFn({ match: { id }, type: ActionType.UPSERT });
    return data ? data[0] : undefined;
  };

  /**
   * Provides Generic function to commit to database
   * @param mode
   * @returns
   */
  const getUpsertFn = (upsertMode?: UpsertMode) => {
    const mode = upsertMode || UpsertMode.UPSERT;
    let actionType = ActionType.UPSERT;
    if (mode === UpsertMode.CREATE_ONLY) actionType = ActionType.CREATE;
    if (mode === UpsertMode.UPDATE_ONLY) actionType = ActionType.UPDATE;

    // Update state based on results
    const respond = (opts: { records: any[]; error: any }) => {
      const { records, error } = opts;
      log.debug(`${mode} ${dataset} commit`, opts);
      const merge = {
        $status: error ? RecordStatus.ERROR : RecordStatus.OK,
        $error: error,
      };
      const payload = records.map((x: object) => ({
        ...x,
        ...merge,
      }));
      dispatch({
        type: ActionType.UPDATE,
        dataset,
        payload,
      });
      return merge;
    };

    return async (
      payload: any,
      opts?: { pick?: string[]; onConflict?: string; match?: any }
    ) => {
      log.debug(`${mode} ${dataset} parameters`, { payload, opts });
      const record = {
        ...payload,
        $status: RecordStatus.LOADING,
      };
      dispatch({
        type: actionType,
        dataset,
        payload: [record],
      });

      // Validate
      let schema = ServiceModelSchema();
      if (opts?.pick) schema = schema.pick(opts.pick);
      const valid: any = await schema
        .validate(record, { abortEarly: false })
        .catch((error) => {
          return respond({ records: [record], error });
        });
      if (valid.$error) return valid;
      log.debug(`${mode} ${dataset} valid data`, valid);

      // Commit to database
      const client = await supabase.getClient();
      if (mode === UpsertMode.UPSERT) {
        const { onConflict = 'id' } = opts || {};
        const result: any = await client
          .from(dbTable)
          .upsert([valid], { onConflict })
          .select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.CREATE_ONLY) {
        const result: any = await client.from(dbTable).insert([valid]).select();
        respond({ records: result?.data, error: result.error });
      } else if (mode === UpsertMode.UPDATE_ONLY) {
        const { match = { id: valid.id } } = opts || {};
        const result: any = await client
          .from(dbTable)
          .update(valid)
          .match(match)
          .select();
        respond({ records: result?.data, error: result.error });
      }
      return getFn({ id: valid.id });
    };
  };

  /**
   * Update all price on all records
   */
  const updatePrice = async (opts: { multiplier: number }) => {
    const { multiplier } = opts;
    if (!multiplier || Number.isNaN(multiplier)) {
      throw new Error(`Invalid multiplier: ${multiplier}`);
    }

    const client = await supabase.getClient();
    await client.rpc('update_price', { p_multiplier: multiplier });

    return refreshFn();
  }

  const subscribe = (fn: Function, id?: string) => {
    const sid = id || uuidv4();
    if (!subscribers.current[sid]) {
      subscribers.current[sid] = { sid, fn };
    }
    return sid;
  }

  const unsubscribe = (sid: string) => {
    if (subscribers.current[sid]) {
      delete subscribers.current[sid];
      return true;
    }
    return false;
  }

  subscribe((s: any) => Object.assign(state, { [dataset]: s }));

  return {
    get: getFn,
    getState: () => state[dataset],
    create: getUpsertFn(UpsertMode.CREATE_ONLY),
    update: getUpsertFn(UpsertMode.UPDATE_ONLY),
    updatePrice,
    delete: async (payload: any) => {
      const deleteFn = getUpsertFn(UpsertMode.UPDATE_ONLY);
      return deleteFn(
        { id: payload.id, enabled: false },
        { pick: ['id', 'enabled'] }
      );
    },
    refresh: refreshFn,
    subscribe,
    unsubscribe,
  };
}

function reducer(currState: any, action: any) {
  const newState = JSON.parse(JSON.stringify(currState));
  if (action.type === ActionType.CREATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      newState[dataset].records.push(record);
    });
    return newState;
    /**
     */
  } else if (action.type === ActionType.UPDATE) {
    const records: any[] = action.payload;
    records.forEach((record) => {
      const existing = newState[dataset].records.find(
        (x: any) => x.id === record.id
      );
      if (!existing) return;
      Object.assign(existing, record);
    });
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.REFRESH) {
    newState[dataset].records = action.payload;
    // remove disabled
    newState[dataset].records = newState[dataset].records.filter(
      (x: any) => x.enabled
    );

    return newState;
    /**
     */
  } else if (action.type === ActionType.ERROR) {
    newState[dataset].error = action.payload;
    return newState;
  }

  return currState;
}

type BuildContextProps = {
  datastore: React.MutableRefObject<any>;
  reload: () => void;
  children?: React.ReactNode;
}

function BuildServices(props: BuildContextProps): JSX.Element {
  const { datastore, children, reload } = props;

  const [state, dispatch] = React.useReducer(reducer, { [dataset]: getInitial() });
  const subscribers = React.useRef<any>({});

  React.useEffect(() => {
    if (datastore.current[dataset]) return;
    datastore.current[dataset] = getFunctions({ state, dispatch, datastore: datastore.current, subscribers });
    reload();
  }, [state, datastore, reload]);

  React.useEffect(() => {
    const sc = subscribers.current;
    log.debug(`subscriber count for ${dataset}: ${Object.keys(sc).length}`);
    Object.keys(sc).forEach((key) => {
      const sub = sc[key];
      if(typeof sub.fn === 'function') {
        sub.fn(state[dataset]);
      }
    })
  }, [state]);

  return <>{children}</>;
}

export default BuildServices;
export {
  dataset,
  getInitial,
  getFunctions,
  reducer,
  ActionType,
  SeasonList,
  PricingModel,
  ServiceType,
};
export type { ServiceModel, ServiceRecord, ServiceViewRecord };
