import React from 'react';
import log from 'loglevel';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';

import { useDataStore } from '../../comp/DataStore';
import { SettingsKey } from '../../comp/DataStore/Settings';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';

type SourceChannel = {
  id: string;
  name: string;
};

type ProspectSettings = {
  sourceChannel: {
    records: SourceChannel[];
  };
};

function generateScId(name: string) {
  const id = String(name || '')
    .toLowerCase()
    .trim()
    .replace(/[\W+]+/g, '-');
  if (!id) throw new Error('Invalid name');
  return id;
}

const defaultData: ProspectSettings = {
  sourceChannel: {
    records: [],
  },
};

function ProspectsSettings() {
  const datastore = useDataStore();

  const settingsState = datastore.settings.getState();
  const existing: ProspectSettings | undefined = settingsState.records.find(
    (x: any) => x.key === SettingsKey.PROSPECTS
  );
  const [prospectSettings, setProspectSettings] =
    React.useState<ProspectSettings>(existing || defaultData);
  const [loading, setLoading] = React.useState(false);
  const [runOnce, setRunOnce] = React.useState(false);
  const [scError, setScError] = React.useState<string>('');
  const scRef = React.useRef<any>({});

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      key: SettingsKey.PROSPECTS,
      data: prospectSettings,
    }
    log.debug('payload', payload);
    await datastore.settings.update([payload]);
    setLoading(false);
  };

  const onDeleteSc = (scId: string) => (e: any) => {
    log.debug(scId, prospectSettings.sourceChannel.records);
    setProspectSettings((curr) => {
      const { sourceChannel } = curr;
      const records = sourceChannel.records.filter((x) => x.id !== scId);
      return { ...curr, sourceChannel: { ...sourceChannel, records } };
    });
  };

  const onAddSc = () => {
    const name: string = scRef.current.value;
    if (!name) {
      setScError('Input a name');
      return;
    }
    const id = generateScId(name);
    const existing = prospectSettings.sourceChannel.records.find(
      (x) => x.id === id
    );
    if (existing) {
      setScError('This channel already exist');
      return;
    }
    setScError('');
    setProspectSettings((curr) => {
      const { sourceChannel } = curr;
      sourceChannel.records.push({ id, name });
      return { ...curr, sourceChannel };
    });
    scRef.current.value = '';
  };

  React.useEffect(() => {
    if (loading) return;
    if (runOnce) return;
    setRunOnce(true);
    setLoading(true);
    datastore.settings
      .getKey(SettingsKey.PROSPECTS)
      .then((result: { data: ProspectSettings }) => {
        if (!result) return;
        setProspectSettings(result.data);
      })
      .finally(() => setLoading(false));
  }, [loading, runOnce, datastore]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Source Channel</Typography>
              <Typography variant="body1">
                This is the list of channels through which a prospect initially
                discovers your company.
              </Typography>
            </Grid>
            {!prospectSettings.sourceChannel?.records.length ? <></> : (
            <Grid item xs={12}>
              <Box>
                {prospectSettings.sourceChannel.records.map((sc) => (
                  <Chip
                    sx={{ borderRadius: '5px', mr: '5px', mb: '5px' }}
                    key={sc.id}
                    id={sc.id}
                    label={sc.name}
                    onDelete={onDeleteSc(sc.id)}
                  />
                ))}
              </Box>
            </Grid>
            )}
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  id="add_source_channel"
                  label="Name"
                  placeholder='Aa'
                  inputRef={scRef}
                  error={Boolean(scError)}
                  helperText={scError}
                />
                <IconButton onClick={onAddSc}>
                  <AddIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ControlBar>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="right">
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  endIcon={<SendIcon />}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ControlBar>
    </Box>
  );
}

export default ProspectsSettings;
