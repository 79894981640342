import React from 'react';
import Badge from '@mui/material/Badge';

import { useDataStore } from '../../comp/DataStore';
import { ProposalStatus as ProposalStatusEnum } from '../../comp/DataStore/Proposals';

type ProposalStatusProps = {
  proposalId: string;
  status: string;
  children: React.ReactNode;
};

function ProposalStatus(props: ProposalStatusProps) {
  const datastore = useDataStore();
  const { proposalId, status, children } = props;

  const [record, setRecord] = React.useState<any>({});
  const [attempts, setAttempt] = React.useState(0);

  React.useEffect(() => {
    if (proposalId === record.proposal_id) return;
    if (attempts >= 1) return;
    datastore.proposalCalls
      .get({ proposal_id: proposalId })
      .then((r: any) => {
        setRecord(r || {});
      })
      .finally(() => setAttempt((x) => x + 1));
  });

  return status === ProposalStatusEnum.ACTIVE ||
    status === ProposalStatusEnum.SENT ? (
    <Badge badgeContent={record.count} color="error" sx={{ pr: '10px' }}>
      {children}
    </Badge>
  ) : (
    <>{children}</>
  );
}

export default ProposalStatus;
