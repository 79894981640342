import React from 'react';
import { useNavigate } from 'react-router-dom';
import log from 'loglevel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

// import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import pkg from '../../../package.json';
import useDataStore from '../../comp/DataStore';
import { updateUser } from '../../api/supabase';
// import { sendEmail } from '../../api/microsoft';
import Paperbase from '../../comp/Layout/Paperbase/Paperbase';
import ControlBar from '../../comp/Layout/Paperbase/ControlBar';
import VersionCheck from '../../comp/Layout/Paperbase/VersionCheck';
// import { SwEventType } from '../../comp/DataStore/Sw';
import { Typography } from '@mui/material';
import { getAuthToken } from '../../api/irrigationApi';
import settings from '../../settings';
import { UserRecord } from '../../comp/DataStore/Users';
import { ProposalType } from '../../comp/DataStore/Proposals';

// function urlB64ToUint8Array(base64String: string) {
//   const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

//   const rawData = window.atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }
//   return outputArray;
// }

function Profile() {
  const navigate = useNavigate();
  const datastore = useDataStore();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const initAuth = datastore.auth.getState();
  const [authState, setAuthState] = React.useState<any>(initAuth);
  const [user, setUser] = React.useState<UserRecord>({
    ...(authState.record || {}),
    def_proposal_template:
      (authState.record || {}).def_proposal_template ||
      ProposalType.LAWN_AND_IRRIGATION,
  });

  // const swState = datastore.sw.getState();
  // const [hasUpdates, setHasUpdates] = React.useState(swState.updates);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const result = await updateUser({
      password: data.get('password'),
    });
    if (result.error && result.error.message) {
      setError(result.error.message);
      log.error(error);
    }
    setLoading(false);
  };

  const handleAppSettings = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const payload: Partial<UserRecord> = {
      email: user.email,
      def_proposal_template: user.def_proposal_template,
    };
    console.log({ payload });
    datastore.users
      .updateAppSettings(payload, { pick: ['email', 'def_proposal_template'] })
      .then(log.debug)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFocus = () => {
    setError('');
  };

  const onSignOut = async () => {
    await datastore.auth.logout();
    navigate('/');
  };

  // const getNotified = async () => {
  //   await datastore.users.refresh();
  //   const { id, email } = datastore.auth.getState().user;
  //   const user = await datastore.users.get({ email });
  //   const payload: any = {
  //     id,
  //     email,
  //     subscriptions: user.subscriptions || [],
  //   };
  //   const swReg: ServiceWorkerRegistration = datastore.sw.getRegistration();
  //   if (!swReg) {
  //     log.error('not yet registered');
  //     return;
  //   }
  //   const subscription = await swReg.pushManager.subscribe({
  //     userVisibleOnly: true,
  //     applicationServerKey: urlB64ToUint8Array(
  //       process.env.REACT_APP_VAPID_PUBLIC_KEY || ''
  //     ),
  //   });
  //   const sub = subscription.toJSON();
  //   const existing = payload.subscriptions.find(
  //     (x: any) => x.endpoint === sub.endpoint
  //   );
  //   if (!existing) {
  //     payload.subscriptions.push(sub);
  //     await datastore.users.update(payload, { pick: ['subscriptions'] });
  //   }
  // };

  // const onClickVersion = () => {
  //   log.debug('service-worker state', swState);
  // };
  React.useEffect(() => {
    setUser((x) => ({
      ...(authState.record || {}),
      def_proposal_template:
        (authState.record || {}).def_proposal_template ||
        ProposalType.LAWN_AND_IRRIGATION,
    }));
  }, [authState]);

  const onChangeDefProposalType = (event: SelectChangeEvent<ProposalType>) => {
    event.preventDefault();
    console.log(event.target);
    setUser((x: any) => ({ ...x, def_proposal_template: event.target.value }));
  };

  React.useEffect(() => {
    const authId = datastore.auth.subscribe(setAuthState);
    // const swId = datastore.sw.subscribe(SwEventType.UPDATE, (swState: any) => {
    //   setHasUpdates(swState.updates);
    // });
    return () => {
      datastore.auth.unsubscribe(authId);
      // datastore.sw.unsubscribe(swId);
    };
  }, [datastore]);

  return (
    <Paperbase title='User Profile' bottomSpacer='100px'>
      {/* <Box component='form' onSubmit={handleSubmit}> */}
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={9}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12}>
              <Box>
                You are signed in as&nbsp;
                <b>{authState.user?.email}</b>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                component='form'
                onSubmit={handleAppSettings}
                noValidate
                sx={{ mb: '20px' }}
              >
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>App Settings</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} justifyContent='left'>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Default Proposal Template</InputLabel>
                          <Select
                            key={`role-${user.email}`}
                            labelId='def_proposal_type'
                            id='def_proposal_type'
                            label='Default Proposal Type'
                            name='def_proposal_type'
                            disabled={loading}
                            value={user.def_proposal_template}
                            onChange={onChangeDefProposalType}
                          >
                            {settings.proposalType
                              .sort((a, b) => (a.order || 0) - (b.order || 0))
                              .map((x) => (
                                <MenuItem key={x.value} value={x.value}>
                                  {x.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  <AccordionActions>
                    <Button
                      type='submit'
                      color='primary'
                      variant='outlined'
                      disabled={loading}
                      endIcon={<SendIcon />}
                    >
                      {'Update'}
                    </Button>
                  </AccordionActions>
                </Accordion>
              </Box>
              <Box
                component='form'
                onSubmit={handleSubmit}
                noValidate
                sx={{ mb: '20px' }}
              >
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Security Settings</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} justifyContent='left'>
                      <Grid item xs={6}>
                        <TextField
                          margin='normal'
                          required
                          fullWidth
                          id='email'
                          label='Change Password'
                          name='password'
                          type='password'
                          onFocus={handleFocus}
                          onKeyDown={handleFocus}
                          inputProps={{ minLength: 6 }}
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  <AccordionActions>
                    <Button
                      type='submit'
                      color='primary'
                      variant='outlined'
                      disabled={loading}
                      endIcon={<SendIcon />}
                    >
                      {'Update'}
                    </Button>
                  </AccordionActions>
                </Accordion>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={12} textAlign='right'>
              <Button variant='contained' color='error' onClick={onSignOut}>
                Sign Out
              </Button>
            </Grid>
            {/* <Grid item xs={12} textAlign='right'>
              <Button
                variant='outlined'
                disabled={loading}
                onClick={() => getAuthToken().then(log.debug)}
              >
                Get Token
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <ControlBar>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={2}>
            {/* <Box onClick={onClickVersion}> */}
            <Stack direction='row' spacing={1} alignItems='center'>
              <VersionCheck />
              <Typography sx={{ fontSize: '90%' }}>v{pkg.version}</Typography>
            </Stack>
            {/* </Box> */}
          </Grid>
        </Grid>
      </ControlBar>
    </Paperbase>
  );
}

export default Profile;
