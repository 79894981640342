import React from 'react';
import { useLocation } from 'react-router-dom';
import log from 'loglevel';

import { useDataStore } from '../DataStore';

function ServiceWorker() {
  const datastore = useDataStore();
  const location = useLocation();

  const [loc, setLoc] = React.useState('');

  const swState = datastore.sw.getState();

  React.useEffect(() => {
    if (!swState.registration) return;
    if (loc === location.pathname) return;
    setLoc(location.pathname);
    log.debug('location changed', location);
    // const reg: ServiceWorkerRegistration = swState.registration;
    log.debug({ swState });
    // reg.update();
  }, [loc, location, swState]);

  return <></>;
}

export default ServiceWorker;
